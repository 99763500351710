import {
  assertExhaustive,
  type Block,
  QuestionBlockAnswerGrade,
  type QuestionBlockAnswerGradeModel,
} from '@lp-lib/game';
import { type Logger } from '@lp-lib/logger-base';

import { useAnalytics } from '../../../../analytics/AnalyticsContext';
import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { apiService } from '../../../../services/api-service';
import { useStreamSessionId } from '../../../Session';
import {
  useFetchGameSessionGamePack,
  useOndStateBlockBrandName,
} from '../../hooks';
import { uncheckedIndexAccess_UNSAFE } from '../../../../utils/uncheckedIndexAccess_UNSAFE';

// Note(falcon): this is similar to QuestionBlockAnswerGrade, but we want to be
// agnostic of any one block type. callers can adapt custom grading to this
// model if they so desire.
export type Grade = 'incorrect' | 'half' | 'correct';
export class QuestionBlockAnswerGradeConverter {
  static To(grade: Grade): QuestionBlockAnswerGrade {
    switch (grade) {
      case 'correct':
        return QuestionBlockAnswerGrade.CORRECT;
      case 'incorrect':
        return QuestionBlockAnswerGrade.INCORRECT;
      case 'half':
        return QuestionBlockAnswerGrade.HALF;
      default:
        assertExhaustive(grade);
        return QuestionBlockAnswerGrade.NONE;
    }
  }

  static From(from: QuestionBlockAnswerGrade): Grade | null {
    switch (from) {
      case QuestionBlockAnswerGrade.CORRECT:
        return 'correct';
      case QuestionBlockAnswerGrade.INCORRECT:
        return 'incorrect';
      case QuestionBlockAnswerGrade.HALF:
        return 'half';
      case QuestionBlockAnswerGrade.NONE:
        return null;
      default:
        assertExhaustive(from);
        return null;
    }
  }
}

export type GradeResult = {
  model?: QuestionBlockAnswerGradeModel;
  grade: QuestionBlockAnswerGrade;
  timeMs: number;
  error?: unknown;
  cached?: boolean;
};

export type GradeEvents = {
  'submission-graded': (args: {
    question: string;
    results: GradeResult[];
    userSubmission: string;
    correctAnswers: string[];
    submitterUid: string;
  }) => void;
};

function mapGradeResultToEvaluation(result: GradeResult) {
  if (result.error) return 'error';
  switch (result.grade) {
    case QuestionBlockAnswerGrade.CORRECT:
    case QuestionBlockAnswerGrade.HALF:
      return 'correct';
    case QuestionBlockAnswerGrade.INCORRECT:
    case QuestionBlockAnswerGrade.NONE:
      return 'wrong';
    default:
      assertExhaustive(result.grade);
      break;
  }
  return result.grade === QuestionBlockAnswerGrade.INCORRECT
    ? 'correct'
    : 'wrong';
}

export function useTrackSubmissionGraded(block: Block, logger?: Logger) {
  const analytics = useAnalytics();
  const sessionId = useStreamSessionId();
  const pack = useFetchGameSessionGamePack();
  const brandName = useOndStateBlockBrandName();
  return useLiveCallback(
    async (args: Parameters<GradeEvents['submission-graded']>[0]) => {
      const extraEventProps = uncheckedIndexAccess_UNSAFE({});
      args.results.forEach((result, index) => {
        const evaluation = mapGradeResultToEvaluation(result);
        switch (result.model) {
          case 'Preset':
            extraEventProps['presetAnswersEvaluation'] = evaluation;
            extraEventProps['isEvaluationCached'] = !!result.cached;
            break;
          case 'GPTv3':
            extraEventProps['gptEvaluation'] = evaluation;
            extraEventProps['timeMs'] = result.timeMs;
            extraEventProps['isEvaluationCached'] = !!result.cached;
            break;
          case 'GPTv4':
            extraEventProps['gpt4Evaluation'] = evaluation;
            extraEventProps['gpt4TimeMs'] = result.timeMs;
            extraEventProps['isEvaluationCached'] = !!result.cached;
            break;
          case undefined:
          default:
            break;
        }
        if (index === args.results.length - 1) {
          extraEventProps['finalEvaluation'] = evaluation;
        }
      });
      let username = 'unknown';
      let email = 'unknown';
      // The API only allows admin/cloud contoller/account owner to query the
      // user info. If we play the game with local controller, it could happen
      // that we get the access denied error in local dev & PR env.
      try {
        const resp = await apiService.user.getUser(args.submitterUid);
        username = resp.data.user.username;
        email = resp.data.user.email;
      } catch (error) {
        logger?.error('failed to get user', error);
      }
      analytics.track('AI Submission Graded', {
        sessionId,
        blockId: block.id,
        blockType: block.type,
        packId: pack?.id,
        packName: pack?.name,
        userSubmission: args.userSubmission,
        correctAnswers: args.correctAnswers.join(', '),
        submitterUid: args.submitterUid,
        submitterUsername: username,
        submitterEmail: email,
        brandName,
        question: args.question,
        ...extraEventProps,
      });
    }
  );
}
