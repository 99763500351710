import { createContext, type ReactNode, useContext, useMemo } from 'react';
import { proxy, useSnapshot } from 'valtio';

import { type VideoEffectsSettingsUser } from '@lp-lib/game';

import { StorageFactory } from '../../utils/storage';
import {
  markSnapshottable,
  type ValtioSnapshottable,
  ValtioUtils,
} from '../../utils/valtio';
import { type VideoEffectsSettings } from './types';
import { VideoEffectsSettingsUtils } from './VideoEffectsSettingsUtils';

const context = createContext<VideoEffectsSettingsLocalStore | null>(null);

class VideoEffectsSettingsLocalStore {
  private storage;
  private s;

  constructor(persist: boolean, defaultValues?: Partial<VideoEffectsSettings>) {
    this.storage = StorageFactory<
      'userVideoEffectsSettings',
      VideoEffectsSettingsUser
    >(persist ? 'local' : 'dummy');
    this.s = proxy({
      ves: VideoEffectsSettingsUtils.WithDefaults(defaultValues),
    });
  }

  get settings(): Readonly<VideoEffectsSettings> {
    return this.s.ves;
  }

  listenable = (): Readonly<ValtioSnapshottable<VideoEffectsSettings>> => {
    return markSnapshottable(this.s.ves);
  };

  replaceFromExternal(ves: VideoEffectsSettings) {
    ValtioUtils.update(this.s.ves, ves);
    this.persist();
  }

  toggleEffect(
    effect: 'greenScreen' | 'podium' | 'intro' | 'outro',
    enabled: boolean
  ) {
    const setting = this.s.ves[effect];
    if (!setting) return;
    setting.enabled = enabled;
    this.persist();
  }

  load() {
    const settings = this.storage.get('userVideoEffectsSettings');
    if (!settings) return;
    const full = VideoEffectsSettingsUtils.FromUser(settings);
    if (!full) return;
    ValtioUtils.update(this.s.ves, full);
  }

  persist() {
    const full = this.s.ves;
    const settings = VideoEffectsSettingsUtils.ToUser(full);
    this.storage.set('userVideoEffectsSettings', settings);
  }
}

export function VideoEffectsSettingsLocalProvider(props: {
  children?: ReactNode;
  persist?: boolean;
  defaultValues?: Partial<VideoEffectsSettings>;
}): JSX.Element {
  const store = useMemo(() => {
    const store = new VideoEffectsSettingsLocalStore(
      props.persist ?? true,
      props.defaultValues
    );
    store.load();
    return store;
  }, [props.persist, props.defaultValues]);
  return <context.Provider value={store}>{props.children}</context.Provider>;
}

export function useLocalVideoEffectsSettingsStore(): VideoEffectsSettingsLocalStore {
  const ctx = useContext(context);
  if (!ctx) throw new Error('VESLP not in tree!');
  return ctx;
}

export function useLocalVideoEffectsSettings(): Readonly<VideoEffectsSettings> {
  const ctx = useContext(context);
  if (!ctx) throw new Error('VESLP not in tree!');
  return useSnapshot(ctx.listenable());
}
