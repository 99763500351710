import { useEffect, useRef } from 'react';

import { useMyInstance } from '../../hooks/useMyInstance';
import { useRemoteStream } from '../../hooks/useRemoteStream';
import { ClientTypeUtils } from '../../types';
import { useCloneSingletonMediaStream } from '../Device';
import { useGamePlayUIConfiguration } from '../Game/Blocks/Common/GamePlay/GamePlayUIConfigurationProvider';
import { useGameHostingController } from '../Game/GameHostingProvider';
import { useIsLiveGamePlay, useIsRecording } from '../Game/hooks';
import { FloatLayout } from '../Layout';
import { getSynchronousRawAnchorRect } from '../LayoutAnchors/LayoutAnchors';
import { Loading } from '../Loading';
import { Placeholder } from '../Participant';
import { useHost, useParticipantFlags } from '../Player';
import { useLocalVideoEffectsSettings } from '../VideoEffectsSettings/LocalSettings';
import { type VideoEffectsSettings } from '../VideoEffectsSettings/types';
import { VideoEffectsSettingsUtils } from '../VideoEffectsSettings/VideoEffectsSettingsUtils';
import { VideoEffectsMediaElement } from '../VideoFrameProcessing';
import {
  useDebugStream,
  useIsCoreChannelJoined,
  useRTCService,
} from '../WebRTC';

interface DupHostStreamPlayerProps {
  containerClassName: string;
  videoClassName?: string;
  disablePlaceholder?: boolean;
}

export const DupHostStreamView = ({
  containerClassName,
  videoClassName,
  disablePlaceholder,
}: DupHostStreamPlayerProps): JSX.Element => {
  const host = useHost();

  const settings = useLocalVideoEffectsSettings();
  const isLiveGamePlay = useIsLiveGamePlay();
  const isRecording = useIsRecording();
  const me = useMyInstance();
  const isHost = ClientTypeUtils.isHost(me);

  // Ensure the preview is only shown when the host is recording.
  const showGreenScreenPreview =
    isLiveGamePlay &&
    isRecording &&
    VideoEffectsSettingsUtils.HasAnyEnabled(settings) &&
    isHost;

  return (
    <div className={containerClassName}>
      {showGreenScreenPreview ? (
        <LocalLiveHostChromakeyPreviewStreamView
          settings={settings}
          videoClassName={videoClassName}
        />
      ) : (
        <RemoteDupHostStreamView videoClassName={videoClassName} />
      )}
      {host && !disablePlaceholder && me && (
        <Placeholder clientId={me?.clientId} />
      )}
    </div>
  );
};

function RemoteDupHostStreamView(props: { videoClassName?: string }) {
  const ref = useRef<HTMLVideoElement | null>(null);
  const isOndGamePlay = !useIsLiveGamePlay();
  const stageRTCService = useRTCService('stage');
  const stageJoined = useIsCoreChannelJoined('stage');
  const ondRTCService = useRTCService('ond');
  const ondJoined = useIsCoreChannelJoined('ond');
  const controllerClientId = useGameHostingController()?.clientId;
  const host = useHost();
  const flags = useParticipantFlags(host?.clientId);

  const rtcService = isOndGamePlay ? ondRTCService : stageRTCService;
  const rtcJoined = isOndGamePlay ? ondJoined : stageJoined;
  const mediaStream = useRemoteStream(rtcService, controllerClientId);
  useDebugStream(ref.current, controllerClientId, rtcService);

  useEffect(() => {
    if (!ref.current) return;
    if (mediaStream) {
      ref.current.srcObject = mediaStream;
    } else {
      ref.current.srcObject = null;
    }
  }, [isOndGamePlay, mediaStream]);

  const showVideo = isOndGamePlay
    ? true
    : flags?.video && flags?.hasCamera !== false;

  return (
    <div
      className={`w-full h-full absolute top-0 left-0 z-5 overflow-hidden ${
        props.videoClassName ?? ''
      }`}
    >
      <video
        ref={ref}
        className={`w-full h-full absolute object-cover ${
          showVideo ? 'block' : 'hidden'
        }`}
        autoPlay
        muted
      />
      {!rtcJoined && (
        <div className='w-full h-full absolute bg-black flex items-center justify-center'>
          <Loading text='' />
        </div>
      )}
    </div>
  );
}

function LocalLiveHostChromakeyPreviewStreamView(props: {
  videoClassName?: string;
  settings: VideoEffectsSettings;
}) {
  const localMediaStream = useCloneSingletonMediaStream();

  if (!localMediaStream) return null;

  return (
    <VideoEffectsMediaElement
      className={`w-full h-full z-5 ${props.videoClassName ?? ''}`}
      fit='object-cover'
      streamOrSrc={localMediaStream}
      settings={props.settings}
      autoplay
      muted
    />
  );
}

export const DupHostStreamViewWrapper = (props: {
  zIndex?: string;
}): JSX.Element | null => {
  const left = getSynchronousRawAnchorRect('dup-host-stream-anchor')?.left ?? 0;
  const uiConfig = useGamePlayUIConfiguration();
  return !uiConfig.allowMinihost ? null : (
    <FloatLayout useCustomPaddingX className='left-0'>
      <div
        className={`absolute ${
          props.zIndex || ''
        } top-1/4 transform-gpu translate-x-[-88%] transition-position`}
        style={{
          left,
        }}
      >
        <div className='perspective-700'>
          <DupHostStreamView
            containerClassName='w-48 h-48 lg:w-64 lg:h-64 2xl:w-72 2xl:h-72 rounded-5.5xl rotate-y-1 overflow-hidden'
            videoClassName='rounded-5xl border-4 border-opacity-60'
          />
        </div>
      </div>
    </FloatLayout>
  );
};

// eslint-disable-next-line import/no-default-export
export default DupHostStreamView;
