import {
  type ClientRole,
  type IAgoraRTCRemoteUser,
  type RemoteStreamType,
  type UID,
  type VideoPlayerConfig,
} from 'agora-rtc-sdk-ng';

import { type Logger } from '@lp-lib/logger-base';

import logger from '../../logger/logger';
import { uuidv4 } from '../../utils/common';
import { Emitter } from '../../utils/emitter';
import { type MicVolumeMeter } from '../../utils/MicVolumeMeter';
import { AudioBus } from '../audio/audio-bus';
import {
  type AgoraJoinStatus,
  type AgoraNumericUID,
  type AudioTrack,
  type CustomVideoQualityPreset,
  type IAIDenoiserAPI,
  type ICustomRTCService,
  type IMediaDeviceRTCService,
  type IRTCService,
  type MediaDeviceSettings,
  type RTCServiceListenerEvents,
  type TrackState,
  type VideoTrack,
} from './types';
import { type AgoraVideoAutoRecovery } from './video-recovery';

abstract class DummyBaseRTCService implements IRTCService {
  protected id: string;
  readonly name: string;
  readonly uid: UID;
  readonly audioBus: AudioBus;
  readonly log: Logger;
  protected readonly emitter = new Emitter<RTCServiceListenerEvents>();
  on = this.emitter.on.bind(this.emitter);
  off = this.emitter.off.bind(this.emitter);
  constructor(options: { uid: string; name: string; log?: Logger }) {
    this.id = `${options.name || 'agora'}:${uuidv4()}`;
    this.name = options.name;
    this.uid = options.uid;
    this.audioBus = new AudioBus();
    this.log = options.log || logger.scoped('dummyRTCService');
  }
  async join(
    _channel: string,
    _role: ClientRole
  ): Promise<AgoraNumericUID | null> {
    return null;
  }
  leave(): Promise<void> {
    return Promise.resolve();
  }
  setClientRole(_role: ClientRole): Promise<void> {
    return Promise.resolve();
  }
  publish(): Promise<void> {
    return Promise.resolve();
  }
  publishAudio(): Promise<void> {
    return Promise.resolve();
  }
  publishVideo(): Promise<void> {
    return Promise.resolve();
  }
  unpublish(): Promise<void> {
    return Promise.resolve();
  }
  subscribeEvents(): void {
    return;
  }
  unsubscribeEvents(): void {
    return;
  }
  getTracksByUid(_uid: UID): [AudioTrack | undefined, VideoTrack | undefined] {
    return [undefined, undefined];
  }
  play(
    _uid: UID,
    _element: string | HTMLElement,
    _config?: VideoPlayerConfig
  ): { audio: boolean; video: boolean } {
    return { audio: false, video: false };
  }
  playAudio(_uid: UID): boolean {
    return false;
  }
  playVideo(
    _uid: UID,
    _element: string | HTMLElement,
    _config?: VideoPlayerConfig
  ): boolean {
    return false;
  }
  stop(_uid: UID): void {
    return;
  }
  stopAudio(_uid: UID): void {
    return;
  }
  stopVideo(_uid: UID): void {
    return;
  }
  stopAll(): void {
    return;
  }
  setRemoteVideoStreamType(
    _uid: UID,
    _streamType: RemoteStreamType
  ): Promise<void> {
    return Promise.resolve();
  }
  setRemoteUserVolume(_volume: number): void {
    return;
  }
  joinStatus(): AgoraJoinStatus | undefined {
    return;
  }
  isLocalUser(_uid: UID): boolean {
    return false;
  }
  updateVideoTrackState(_uid: UID, _state: TrackState | null): void {
    return;
  }
  getVideoTrackStateMap(): Record<UID, TrackState> {
    return {};
  }
  getVideoTrackStateByUid(_uid: UID): TrackState | null {
    return null;
  }
  resubscribe(_uid: UID, _mediaType: 'audio' | 'video'): Promise<void> {
    return Promise.resolve();
  }

  subscribeVideo(_user: UID | IAgoraRTCRemoteUser): Promise<void> {
    return Promise.resolve();
  }
  subscribeAudio(_user: UID | IAgoraRTCRemoteUser): Promise<void> {
    return Promise.resolve();
  }
  unsubscribeVideo(_user: UID | IAgoraRTCRemoteUser): Promise<void> {
    return Promise.resolve();
  }
  unsubscribeAudio(_user: UID | IAgoraRTCRemoteUser): Promise<void> {
    return Promise.resolve();
  }
  denosier(): IAIDenoiserAPI | undefined {
    return;
  }
  close(): Promise<void> {
    this.emitter.clear();
    return Promise.resolve();
  }
}

export class DummyMediaDeviceRTCService
  extends DummyBaseRTCService
  implements IMediaDeviceRTCService
{
  videoEncoderConfig: CustomVideoQualityPreset = '240p';
  videoRecovery?: AgoraVideoAutoRecovery | undefined;
  getMicDeviceSettings(): MediaDeviceSettings {
    return { autoGainControl: false, noiseSuppression: false };
  }
  toggleAudio(_val: boolean): Promise<void> {
    return Promise.resolve();
  }
  muteAudio(_val: boolean): void {
    return;
  }
  toggleVideo(_val: boolean): Promise<void> {
    return Promise.resolve();
  }
  toggleMicDeviceSetting(
    _setting: keyof MediaDeviceSettings,
    _val: boolean
  ): Promise<void> {
    return Promise.resolve();
  }
  switchAudioInput(_deviceId: string): Promise<void> {
    return Promise.resolve();
  }
  switchAudioOuptut(_deviceId: string): Promise<void> {
    return Promise.resolve();
  }
  switchVideo(_deviceId: string): Promise<void> {
    return Promise.resolve();
  }
  setEncoderConfiguration(
    _config: CustomVideoQualityPreset | 'default'
  ): Promise<boolean> {
    return Promise.resolve(true);
  }
  getVolumeMeter(): MicVolumeMeter | undefined {
    return;
  }
  attemptMusicShare(): Promise<void> {
    return Promise.resolve();
  }
  stopMusicShare(): Promise<void> {
    return Promise.resolve();
  }
}

export class DummyCustomRTCService
  extends DummyBaseRTCService
  implements ICustomRTCService
{
  switchAudio(_track: MediaStreamTrack): void {
    return;
  }
  switchVideo(_track: MediaStreamTrack): void {
    return;
  }
}
