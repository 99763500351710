import { useMemo } from 'react';

import { type Participant, type TeamId } from '../../types';
import { useFirebaseValue } from '../Firebase';
import { useTeamMembers } from '../TeamAPI/TeamV1';
import { useParticipantsByClientIds } from './exposed';

export function useSelectTeamParticipants(
  teamId: TeamId,
  excludeMemberId?: string,
  pinMemberId?: string
): Participant[] {
  const teamMembers = useTeamMembers(teamId, true, pinMemberId);
  const memberIds = useMemo(() => {
    const candidates = excludeMemberId
      ? teamMembers?.filter((m) => m.id !== excludeMemberId)
      : teamMembers;
    return candidates?.map((m) => m.id);
  }, [teamMembers, excludeMemberId]);
  const teamParticipants = useParticipantsByClientIds(memberIds ?? []);
  return teamParticipants;
}

export function useHeartbeat(clientId: Nullable<string>) {
  const [val] = useFirebaseValue<Nullable<{ lastHeartbeatAt: number }>>(
    `heartbeat/${clientId ?? ''}`,
    {
      enabled: !!clientId,
      seedValue: null,
      seedEnabled: false,
      readOnly: true,
    }
  );
  return val?.lastHeartbeatAt;
}
