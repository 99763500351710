import { HiddenCanvas } from '../../utils/canvas';
import { releaseMediaStream } from '../../utils/media';
import { requestVideoFrameCallbackAvailable } from '../../utils/requestVideoFrameCallback';
import { releaseVideoElement } from '../../utils/video';
import {
  createLoopWithTechniquePriority,
  type LoopCanceler,
} from '../Loop/loop';
import { DrawableCanvasVideoFrameBuffer } from './DrawableCanvasVideoFrameBuffer';
import type VideoFrameBuffer from './vendor/amazon-chime-sdk-js/VideoFrameBuffer';
import type VideoFrameProcessor from './vendor/amazon-chime-sdk-js/VideoFrameProcessor';
import type VideoFrameProcessorPipelineObserver from './vendor/amazon-chime-sdk-js/VideoFrameProcessorPipelineObserver';

// Only used if requestVideoFrameCallback is not available.
const DEFAULT_FALLBACK_FRAMERATE = 15;

// Warn via observer if processing a single frame takes longer than this millisecond
// value, which indicates a horribly detrimental experience (there is much more
// going on in the app than video frame processing).
const LONG_FRAME_WARN_MS = (1000 / DEFAULT_FALLBACK_FRAMERATE) * 2;

// Original Interface heavily modified from https://github.com/aws/amazon-chime-sdk-js/blob/457dd7c1db9630817af92e9d170c994b8fa98b8f/src/videoframeprocessor/DefaultVideoFrameProcessorPipeline.ts
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

/**
 * This is based on the VideoFrameProcessorPipeline interface from amazon-chime
 * (https://github.com/aws/amazon-chime-sdk-js/blob/457dd7c1db9630817af92e9d170c994b8fa98b8f/src/videoframeprocessor/DefaultVideoFrameProcessorPipeline.ts),
 * but with all MediaStream knowledge removed, async processing removed (slow!),
 * and cleanup of the internal lifecycles, data structures, and internal data
 * mutation. The original assumed a MediaStream would always be the primary
 * input and output (continuous processing), and used async processing in the
 * render path which can be expensive when pushing pixels via canvas. It does
 * have some neat error handling, but that is mostly irrelevant if the
 * processing pipeline is preloaded up front (as this implementation is). This
 * attempts to be more efficient and simpler by assuming only HTMLVideoElement
 * as an input interface, which is the only (as of 2022) viable options for any
 * canvas processing to proceed.
 *
 * You probably want to use the GreenScreenMediaElement, rather than using this
 * pipeline directly. This pipeline comes with its own loop scheduling, and is
 * generally meant to be used for preview (realtime feedback when tweaking
 * processing parameters). The VideoMixer reuses the same stages/processors, but
 * they are not exposed directly and so cannot be adjusted once initially
 * configured.
 */
export class VideoElementFrameProcessorPipeline {
  private videoInput: HTMLVideoElement | null = null;

  private canvasOutput: HTMLCanvasElement = document.createElement('canvas');
  private outputCtx = this.canvasOutput.getContext('2d');

  private sourceBuffer: DrawableCanvasVideoFrameBuffer | null =
    new DrawableCanvasVideoFrameBuffer(new HiddenCanvas('vfpp-input'));

  private observers: Set<VideoFrameProcessorPipelineObserver> =
    new Set<VideoFrameProcessorPipelineObserver>();

  private loopCanceler: LoopCanceler | null = null;

  constructor(
    private stages: VideoFrameProcessor[],
    private createLoopFn = createLoopWithTechniquePriority
  ) {}

  set processors(stages: VideoFrameProcessor[]) {
    this.stages = stages;
  }

  get processors(): VideoFrameProcessor[] {
    return this.stages;
  }

  /**
   * If a media stream is needed, call captureStream().
   */
  getOutputAsCanvas(): HTMLCanvasElement {
    return this.canvasOutput;
  }

  destroy(): void {
    this.stop();
    this.destroyInput();
    this.sourceBuffer?.destroy();
    this.sourceBuffer = null;
    if (this.stages) {
      for (const stage of this.stages) {
        stage.destroy();
      }
    }
    this.observers.clear();
  }

  async setInput(moved: HTMLVideoElement | null): Promise<void> {
    this.destroyInput();

    if (moved === null) {
      return;
    }

    this.videoInput = moved;
  }

  private destroyInput(): void {
    if (this.videoInput) {
      if (!this.videoInput.paused) this.videoInput.pause();

      if (
        this.videoInput.srcObject &&
        this.videoInput.srcObject instanceof MediaStream
      ) {
        releaseMediaStream(this.videoInput.srcObject);
      }
      releaseVideoElement(this.videoInput);
    }
  }

  addObserver(observer: VideoFrameProcessorPipelineObserver): this {
    this.observers.add(observer);
    return this;
  }

  removeObserver(observer: VideoFrameProcessorPipelineObserver): this {
    this.observers.delete(observer);
    return this;
  }

  process = async (): Promise<void> => {
    const processStart = performance.now();

    // ensure video has dimensions before processing
    if (!this.videoInput?.videoHeight || !this.videoInput?.videoWidth) return;
    if (!this.sourceBuffer) return;

    // match dimensions of the source buffer/inputcanvas (will hold unprocessed
    // frame) and the video input
    if (
      this.videoInput.videoHeight !== this.sourceBuffer.height ||
      this.videoInput.videoWidth !== this.sourceBuffer.width
    ) {
      this.sourceBuffer.width = this.videoInput.videoWidth;
      this.sourceBuffer.height = this.videoInput.videoHeight;
    }

    // copy frame from source to canvas
    this.sourceBuffer.ctx?.clearRect(
      0,
      0,
      this.sourceBuffer.width,
      this.sourceBuffer.height
    );
    this.sourceBuffer.ctx?.drawImage(this.videoInput, 0, 0);

    // Create a new processing array since any stage can modify the buffers
    // array passed to `process()` (and usually does).
    let buffers: VideoFrameBuffer[] = [this.sourceBuffer];

    for (const s of this.stages) {
      buffers = s.process(buffers);
    }

    // copy final frame to output canvas. Floor everything to ensure integer
    // math for canvas speed.
    const imageSource = buffers[0].asCanvasImageSource();
    const frameWidth = Math.floor(
      Number(
        imageSource && 'codedWidth' in imageSource
          ? imageSource.codedWidth
          : imageSource?.width
      )
    );
    const frameHeight = Math.floor(
      Number(
        imageSource && 'codedHeight' in imageSource
          ? imageSource.codedHeight
          : imageSource?.height
      )
    );

    if (
      imageSource &&
      frameWidth !== 0 &&
      frameHeight !== 0 &&
      !isNaN(frameWidth) &&
      !isNaN(frameHeight)
    ) {
      if (
        frameWidth !== this.canvasOutput.width ||
        frameHeight !== this.canvasOutput.height
      ) {
        this.canvasOutput.width = frameWidth;
        this.canvasOutput.height = frameHeight;
      }

      this.outputCtx?.clearRect(0, 0, frameWidth, frameHeight);
      this.outputCtx?.drawImage(
        imageSource,
        0,
        0,
        frameWidth,
        frameHeight,
        0,
        0,
        frameWidth,
        frameHeight
      );
    }

    const end = performance.now();
    const delta = end - processStart;

    if (delta > LONG_FRAME_WARN_MS) {
      for (const obs of this.observers) {
        obs.processingLatencyTooHigh?.(delta);
      }
    }
  };

  start(bestEffortTargetFramerate = DEFAULT_FALLBACK_FRAMERATE): void {
    if (!this.videoInput) return;
    this.loopCanceler = this.createLoopFn(
      [
        { kind: 'rvfcb', tick: this.process, video: this.videoInput },
        {
          kind: 'raf-accumulated',
          update: () => undefined,
          draw: this.process,
          updateTime: 1000 / bestEffortTargetFramerate,
          drawTime: 1000 / bestEffortTargetFramerate,
          panicAt: Infinity,
        },
      ],
      {
        rvfcb: () => requestVideoFrameCallbackAvailable(this.videoInput),
        'raf-accumulated': () => true,
      }
    );
  }

  stop(): void {
    this.loopCanceler?.();
    this.loopCanceler = null;
  }
}
