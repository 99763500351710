import { HiddenCanvas } from '../../../utils/canvas';
import { CanvasImageSourceFrameBuffer } from '../CanvasImageSourceFrameBuffer';
import { DrawableCanvasVideoFrameBuffer } from '../DrawableCanvasVideoFrameBuffer';
import { FitOperation } from '../FitOperation';
import { matchDimensionsIfNeeded } from '../utils/matchDimensionsIfNeeded';
import type VideoFrameBuffer from '../vendor/amazon-chime-sdk-js/VideoFrameBuffer';
import type VideoFrameProcessor from '../vendor/amazon-chime-sdk-js/VideoFrameProcessor';

export class LoopingSourceProcessor implements VideoFrameProcessor {
  private canvasVideoFrameBuffer = new DrawableCanvasVideoFrameBuffer(
    new HiddenCanvas('looping-video-processor')
  );

  private source: CanvasImageSourceFrameBuffer | null = null;
  private mediaController: HTMLVideoElement | null = null;
  public enabled = true;

  constructor(public ordering: 'under' | 'over' = 'under') {}

  setSource(
    source: HTMLImageElement | HTMLVideoElement | HTMLCanvasElement
  ): void {
    if (this.source) this.source.destroy();
    if (this.mediaController) this.mediaController = null;

    this.source = new CanvasImageSourceFrameBuffer(source);
    if (source instanceof HTMLVideoElement) {
      this.mediaController = source;
    }
  }

  play(): void {
    if (this.mediaController && this.mediaController.paused) {
      this.mediaController.play();
    }
  }

  pause(): void {
    if (this.mediaController && !this.mediaController.paused) {
      this.mediaController.pause();
    }
  }

  process(buffers: VideoFrameBuffer[]): VideoFrameBuffer[] {
    const ctx = this.canvasVideoFrameBuffer.ctx;
    if (!ctx || !this.source || buffers.length === 0 || !this.enabled)
      return buffers;

    // Ensure the work buffer has a relevant size, using the first buffer
    const first = buffers[0];
    matchDimensionsIfNeeded(first, this.canvasVideoFrameBuffer);

    const width = this.canvasVideoFrameBuffer.width;
    const height = this.canvasVideoFrameBuffer.height;

    // Clear between iterations
    ctx.clearRect(0, 0, width, height);

    // Position the background video a 1:1 scale, directly in the center. Note:
    // videoWidth/videoHeight is guaranteed to be an integer value, so
    // floor(x,y) is safe and does not lose or add pixels.
    const sourceWidth = this.source.width;
    const sourceHeight = this.source.height;

    const {
      x: destX,
      y: destY,
      width: destWidth,
      height: destHeight,
    } = FitOperation.cover(width, height, sourceWidth, sourceHeight);

    if (this.ordering === 'under') {
      // First copy the "background" video
      const source = this.source.asCanvasImageSource();
      if (source) ctx.drawImage(source, destX, destY, destWidth, destHeight);
    }

    // Then composite the incoming, over
    for (const b of buffers) {
      const source = b.asCanvasImageSource();
      if (source)
        ctx.drawImage(source, 0, 0, width, height, 0, 0, width, height);
    }

    if (this.ordering === 'over') {
      // Then copy the "background" video
      const source = this.source.asCanvasImageSource();
      if (source) ctx.drawImage(source, destX, destY, destWidth, destHeight);
    }

    buffers[0] = this.canvasVideoFrameBuffer;
    return buffers;
  }

  async destroy(): Promise<void> {
    this.canvasVideoFrameBuffer.destroy();
    this.source = null;
  }
}
