import logo from '../../assets/img/logo-text.svg';
import logo3d from '../../assets/img/lp-3d-logo.png';
import { getStaticAssetPath } from '../../utils/assets';
import { err2s } from '../../utils/common';
import { Modal } from '../common/Modal';
import { LegalDisclaimer } from '../common/Utilities';
import { useLoginState } from './hooks';

interface LoginModalLayoutProps {
  children: React.ReactNode;
  footer?: React.ReactNode;
  hideLegalDisclaimer?: boolean;
  progress?: {
    current: number;
    max: number;
  };
}

export const LoginModalLayout = ({
  children,
  footer,
  hideLegalDisclaimer = false,
  progress,
}: LoginModalLayoutProps): JSX.Element => {
  return (
    <Modal>
      <div className='w-176 h-121 flex flex-col items-center relative text-white text-center'>
        <img src={logo} className='w-63 absolute -top-27' alt='logo' />

        {children}

        <div className='absolute top-full w-full flex flex-col justify-center items-center'>
          <div
            className={`w-50 mt-5 ${hideLegalDisclaimer ? 'hidden' : 'block'}`}
          >
            <LegalDisclaimer text='By joining Luna Park' />
          </div>

          {footer}

          {progress && (
            <div className='mt-10'>
              <ProgressBar {...progress} />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export function LoginModalLayoutV2({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  return (
    <div className='w-full h-full relative flex items-center justify-center'>
      <video
        className='w-full h-full absolute top-0 left-0 object-cover z-0'
        src={getStaticAssetPath('videos/onboarding-background-2023_fhd.mp4')}
        muted
        loop
        autoPlay
      />
      {children}
    </div>
  );
}

export function LoginHeader(props: { noSlogan?: boolean }) {
  const state = useLoginState();

  return (
    <div className='flex flex-col items-center justify-center absolute -top-10 transform-gpu -translate-y-full w-full'>
      <img src={logo3d} className='w-50 h-20' alt='logo' />
      {state.error ? (
        <div className='text-2xl font-medium text-center text-red-002'>
          {err2s(state.error)}
        </div>
      ) : (
        !props.noSlogan && (
          <div className='text-2xl font-medium text-center lg:whitespace-nowrap'>
            Your coworkers are connecting on Luna Park!
          </div>
        )
      )}
    </div>
  );
}

const ProgressBar = ({
  current,
  max,
}: {
  current: number;
  max: number;
}): JSX.Element => {
  return (
    <div className='flex flex-col items-center'>
      <div className='flex justify-center items-center'>
        {Array.from(new Array(max).keys()).map((index) => (
          <div
            key={index}
            className={`w-12.5 h-2 rounded mx-0.5 ${
              index + 1 <= current ? 'bg-primary' : 'bg-secondary'
            }`}
          ></div>
        ))}
      </div>

      <div className='flex justify-center items-center'>
        {current} of {max}
      </div>
    </div>
  );
};
