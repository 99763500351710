import sample from 'lodash/sample';

import {
  type DtoTTSRenderRequest,
  EnumsBrandPredefinedBlockScenario,
  EnumsTTSCacheControl,
  EnumsTTSRenderPolicy,
  type ModelsTTSScript,
} from '@lp-lib/api-service-client/public';
import {
  BlockType,
  type HeadToHeadBlock,
  type Media,
  type MediaData,
  type ScoreboardBlock,
  type SpotlightBlock,
  type SpotlightBlockV2,
  type TitleBlockV2,
  type TitleCard,
} from '@lp-lib/game';

import { type Game } from '../../../types/game';
import { TagQuery } from '../../../utils/TagQuery';
import { VoiceOverUtils } from '../../VoiceOver/utils';
import {
  hasVariables,
  prependVariable,
  renderVariable,
} from '../../VoiceOver/VariableRegistry';
import {
  makeVoiceOverRegistryPlan,
  type VoiceOverRegistryPlan,
} from '../../VoiceOver/VoiceOverRegistry';
import { makePlaybackIdGen, type PlaybackDescItem } from './intoPlayback';

/**
 * Technically this is "intoPlaybackV1Items" but the filename pairs well with
 * the existing intoPlayback.
 */
export function intoPlaybackV1(
  games: Game[],

  playbackId = makePlaybackIdGen()
): PlaybackDescItem[] {
  const blocks = games.flatMap((g) => g.blocks ?? []);
  const items = [];

  for (const b of blocks) {
    const item: PlaybackDescItem = {
      id: playbackId.next(),
      injected: false,
      block: b,
      // we play 1 session with v1 games.
      sessionUnitIndex: 0,
      voiceOverPlans: [],
    };

    switch (b.type) {
      case BlockType.SCOREBOARD:
        processScoreboardBlockVoiceOverPlans(
          item,
          b,
          undefined,
          new TagQuery(null),
          'single',
          false
        );
        break;
      case BlockType.TITLE_V2:
        processTitleBlockV2(item, b, undefined);
        break;
      case BlockType.SPOTLIGHT:
      case BlockType.SPOTLIGHT_V2:
        processSpotlightBlockVoiceOverPlans(item, b, undefined);
        break;
      case BlockType.HEAD_TO_HEAD:
        processHeadToHeadBlockVoiceOverPlans(item, b, undefined);
        break;
      default:
        break;
    }

    items.push(item);
  }

  return items;
}

function processTitleBlockV2(
  item: PlaybackDescItem,
  block: TitleBlockV2,
  aiHostVoiceId: Nullable<string>
) {
  const cards = block.fields.cards ?? [];
  if (cards.length === 0) return;

  for (let i = 0; i < cards.length; i++) {
    const card = cards[i];
    processTitleBlockV2Card(item, block, card, aiHostVoiceId);
  }
}

export function processTitleBlockV2Card(
  item: PlaybackDescItem,
  block: TitleBlockV2,
  card: TitleCard,
  aiHostVoiceId: Nullable<string>
) {
  const ttsQuery = new TagQuery(block.fields.ttsScripts);

  const ttsOptions =
    block.fields.ttsOptions?.[0] ??
    VoiceOverUtils.AsTTSRenderRequest(card.voiceOver?.runtime)
      ?.ttsRenderSettings ??
    VoiceOverUtils.AsTTSRenderRequest(
      card.voiceOver?.fallback?.renderDescription
    )?.ttsRenderSettings ??
    undefined;

  if (card.teamIntroEnabled) {
    const introduction = ttsQuery.selectFirst(['introduction', card.id]);
    const individualTeamScript = ttsQuery.selectFirst([
      'individual-team',
      card.id,
    ]);
    const conclusion = ttsQuery.selectFirst(['conclusion', card.id]);

    const introPlan = makeVoiceOverRegistryPlan();
    const teamPlan = makeVoiceOverRegistryPlan();
    const outroPlan = makeVoiceOverRegistryPlan();

    if (introduction) {
      introPlan.entries.push({
        script: introduction.script,
        voiceId: aiHostVoiceId,
        ttsRenderSettings: aiHostVoiceId ? undefined : ttsOptions,
        policy: EnumsTTSRenderPolicy.TTSRenderPolicyReadThrough,
        cacheControl: EnumsTTSCacheControl.TTSCacheControlLongLive,
      });

      item.voiceOverPlans?.push({
        plan: introPlan,
        tags: [card.id, 'introduction'],
      });
    }

    if (individualTeamScript) {
      teamPlan.entries.push({
        script: individualTeamScript.script,
        voiceId: aiHostVoiceId,
        ttsRenderSettings: aiHostVoiceId ? undefined : ttsOptions,
        policy: EnumsTTSRenderPolicy.TTSRenderPolicyReadThrough,
        cacheControl: EnumsTTSCacheControl.TTSCacheControlShortLive,
      });

      item.voiceOverPlans?.push({
        plan: teamPlan,
        tags: [card.id, 'individual-team'],
      });
    }

    if (conclusion) {
      outroPlan.entries.push({
        script: conclusion.script,
        voiceId: aiHostVoiceId,
        ttsRenderSettings: aiHostVoiceId ? undefined : ttsOptions,
        policy: EnumsTTSRenderPolicy.TTSRenderPolicyReadThrough,
        cacheControl: EnumsTTSCacheControl.TTSCacheControlLongLive,
      });

      item.voiceOverPlans?.push({
        plan: outroPlan,
        tags: [card.id, 'conclusion'],
      });
    }
  } else {
    // create a plan for the card
    const plan = makeVoiceOverRegistryPlan();
    const ttsRuntime =
      ttsQuery.selectFirst(['runtime', card.id]) ??
      card.voiceOver?.runtime ??
      card.voiceOver?.fallback?.renderDescription;

    // Priority 1: the runtime script content, legacy runtime script content,
    // fallback script content
    if (ttsRuntime) {
      plan.entries.push({
        script: ttsRuntime.script,
        voiceId: aiHostVoiceId,
        ttsRenderSettings: aiHostVoiceId ? undefined : ttsOptions,
        policy: EnumsTTSRenderPolicy.TTSRenderPolicyReadThrough,
        cacheControl: EnumsTTSCacheControl.TTSCacheControlShortLive,
      });
    }

    // Priority 2: the pre-rendered fallback media
    if (
      card.voiceOver?.fallback?.media &&
      card.voiceOver?.fallback?.mediaData
    ) {
      plan.entries.push({
        media: card.voiceOver?.fallback?.media,
        mediaData: card.voiceOver?.fallback?.mediaData,
      });
    }

    if (plan.entries.length)
      item.voiceOverPlans?.push({ plan, tags: [card.id, 'card'] });
  }
}

export function processScoreboardBlockVoiceOverPlans(
  item: PlaybackDescItem,
  block: ScoreboardBlock,
  aiHostVoiceId: string | undefined | null,
  sharedTTSScripts: TagQuery<ModelsTTSScript>,
  unitPositionTag: 'single' | 'first' | 'mid' | 'last',
  skipCohostedVoiceOvers: boolean
) {
  // never render voiceovers for cohosted games.
  if (skipCohostedVoiceOvers) return;

  const isScoreboardScenario =
    item.scenario ===
    EnumsBrandPredefinedBlockScenario.BrandPredefinedBlockScenarioScoreboard;

  if (VoiceOverUtils.HasAtLeastOneConfig(block.fields.voiceOver)) {
    // we have some legacy configuration, so we'll use that and try to maintain the old semantics.
    const vo = block.fields.voiceOver;
    const runtime = VoiceOverUtils.AsTTSRenderRequest(vo?.runtime);
    const fallback = VoiceOverUtils.AsTTSRenderRequest(
      vo?.fallback?.renderDescription
    );
    let fallbackMedia = undefined;
    if (vo?.fallback?.media && vo?.fallback?.mediaData) {
      fallbackMedia = {
        media: vo.fallback.media,
        mediaData: vo.fallback.mediaData,
      };
    }
    // for legacy blocks, we need to "inject" the position aware script at the beginning of the runtime script, and
    // set up the fallbacks.
    const prependPositionAwareScript = isScoreboardScenario;
    const planScoreboardScenarioFallback = isScoreboardScenario;
    item.voiceOverPlans = buildScoreboardBlockVoiceOverPlans(
      runtime,
      fallback,
      fallbackMedia,
      aiHostVoiceId,
      sharedTTSScripts,
      unitPositionTag,
      prependPositionAwareScript,
      planScoreboardScenarioFallback
    );
    return;
  }

  // we have no legacy voiceover settings, so we're going to assume we're in the "new world". here we expect some
  // tts options or an aiHostVoiceId, otherwise we cannot render.
  const ttsRenderSettings = block.fields.ttsOptions?.[0];
  if (!aiHostVoiceId && !ttsRenderSettings) return;

  if (block.fields.ttsScripts && block.fields.ttsScripts.length > 0) {
    // the block has explicit scripts it wants to use.
    const query = new TagQuery(block.fields.ttsScripts);
    const preferredScript = sample(query.select(['runtime'], ['fallback']));
    const runtime = preferredScript
      ? { script: preferredScript.script, aiHostVoiceId, ttsRenderSettings }
      : undefined;

    const fallbackScript = sample(query.select(['fallback'], ['runtime']));
    const fallback = fallbackScript
      ? { script: fallbackScript.script, aiHostVoiceId, ttsRenderSettings }
      : undefined;

    item.voiceOverPlans = buildScoreboardBlockVoiceOverPlans(
      runtime,
      fallback,
      undefined, // there is no media in the new world.
      aiHostVoiceId,
      sharedTTSScripts,
      unitPositionTag,
      false, // in the new world we don't need to prepend the position aware script. it must be explicit.
      isScoreboardScenario // we can automatically select a fallback if we are in a scoreboard scenario.
    );
  } else if (isScoreboardScenario) {
    // the block has no explicit ttsScripts, but we are in a block scenario. we should use the shared scripts.
    const runtime = {
      script: '%positionAwareScoreboardScript%',
      aiHostVoiceId,
      ttsRenderSettings,
    };

    item.voiceOverPlans = buildScoreboardBlockVoiceOverPlans(
      runtime,
      undefined, // there is no explicit fallback, but one will be selected.
      undefined, // there is no media in the new world.
      aiHostVoiceId,
      sharedTTSScripts,
      unitPositionTag,
      false, // in the new world we don't need to prepend the position aware script. it must be explicit.
      isScoreboardScenario // we can automatically select a fallback if we are in a scoreboard scenario.
    );
  }
}

function buildScoreboardBlockVoiceOverPlans(
  runtime: Nullable<DtoTTSRenderRequest>,
  fallback: Nullable<DtoTTSRenderRequest>,
  fallbackMedia: Nullable<{ media: Media; mediaData: MediaData }>,
  aiHostVoiceId: string | undefined | null,
  sharedTTSScripts: TagQuery<ModelsTTSScript>,
  unitPositionTag: 'single' | 'first' | 'mid' | 'last',
  prependPositionAwareScript: boolean,
  planScoreboardScenarioFallback: boolean
) {
  const scriptTag = 'positionAwareScoreboardScript';
  const plan: VoiceOverRegistryPlan = { entries: [] };

  // priority 1: runtime script, likely with variables
  if (runtime) {
    let runtimeScript = runtime.script;

    // we can handle injected scripts here...
    const select = sharedTTSScripts.select([scriptTag, unitPositionTag]);
    const variadic = select.filter((s) => hasVariables(s.script));
    const injectableScript = sample(variadic) ?? sample(select);

    if (injectableScript) {
      if (prependPositionAwareScript) {
        runtimeScript = prependVariable(runtimeScript, scriptTag);
      }
      runtimeScript = renderVariable(
        runtimeScript,
        scriptTag,
        injectableScript.script
      );
    }
    plan.entries.push({
      ...runtime,
      script: runtimeScript,
      voiceId: aiHostVoiceId,
      ttsRenderSettings: aiHostVoiceId ? undefined : runtime.ttsRenderSettings,
      policy: EnumsTTSRenderPolicy.TTSRenderPolicyReadThrough,
      cacheControl: EnumsTTSCacheControl.TTSCacheControlShortLive,
    });
  }

  // Priority N: The rest are all a form of fallback.

  if (!aiHostVoiceId && fallbackMedia) {
    // priority 2: handcrafted prerendered fallback media (static) if there are
    // no ai voice settings (because otherwise the voices would be different
    // and there would be a mismatch)
    plan.entries.push(fallbackMedia);
  } else if (fallback) {
    // priority 3: handcrafted fallback description, rendered at game start,
    // if there are aivoicesettings/block settings/renderdesc settings
    plan.entries.push({
      ...fallback,
      voiceId: aiHostVoiceId,
      ttsRenderSettings: aiHostVoiceId ? undefined : fallback.ttsRenderSettings,
      policy: EnumsTTSRenderPolicy.TTSRenderPolicyReadThrough,
      cacheControl: EnumsTTSCacheControl.TTSCacheControlLongLive,
    });
  } else if (
    planScoreboardScenarioFallback &&
    (aiHostVoiceId || runtime?.ttsRenderSettings)
  ) {
    // priority 4: random fallback that is rendered at game start but has no variables
    const scripts = sharedTTSScripts
      .select([scriptTag, unitPositionTag])
      .filter((s) => !hasVariables(s.script));
    const script = sample(scripts);
    if (script) {
      plan.entries.push({
        script: script.script,
        voiceId: aiHostVoiceId,
        ttsRenderSettings: aiHostVoiceId
          ? undefined
          : runtime?.ttsRenderSettings,
        policy: EnumsTTSRenderPolicy.TTSRenderPolicyReadThrough,
        cacheControl: EnumsTTSCacheControl.TTSCacheControlLongLive,
      });
    }
  }
  return plan.entries.length === 0 ? [] : [{ plan, tags: [] }];
}

export function processSpotlightBlockVoiceOverPlans(
  item: PlaybackDescItem,
  block: SpotlightBlock | SpotlightBlockV2,
  aiHostVoiceId: string | undefined | null
) {
  const ttsScripts = new TagQuery(block.fields.ttsScripts);
  const preferred = sample(ttsScripts.select(['runtime'], ['fallback']));
  const fallback = sample(ttsScripts.select(['fallback'], ['runtime']));

  item.voiceOverPlans =
    VoiceOverUtils.BuildRuntimeFallbackVoiceOverPlansFromLegacy(
      block.fields.voiceOver,
      preferred,
      fallback,
      block.fields.ttsOptions,
      aiHostVoiceId,
      ['intro']
    );
}

export function processHeadToHeadBlockVoiceOverPlans(
  item: PlaybackDescItem,
  block: HeadToHeadBlock,
  aiHostVoiceId: string | undefined | null
) {
  item.voiceOverPlans = [];
  const ttsScripts = new TagQuery(block.fields.ttsScripts);

  // intro
  const [introPreferred] = VoiceOverUtils.SamplePreferredFallbackScripts(
    ttsScripts.select(['intro'])
  );

  item.voiceOverPlans.push(
    ...VoiceOverUtils.BuildRuntimeFallbackVoiceOverPlansFromLegacy(
      block.fields.introductoryVoiceOver,
      introPreferred,
      undefined,
      block.fields.ttsOptions,
      aiHostVoiceId,
      ['intro']
    )
  );
}
