import React, {
  type ReactNode,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from 'react';
import { useLatest, usePrevious } from 'react-use';
import { proxy, useSnapshot } from 'valtio';

import { type GameSessionStatus } from '@lp-lib/game';

import { useLiveCallback } from '../../../../../hooks/useLiveCallback';
import {
  useIsController,
  useMyInstance,
} from '../../../../../hooks/useMyInstance';
import {
  markSnapshottable,
  type ValtioSnapshottable,
} from '../../../../../utils/valtio';
import {
  type FirebaseService,
  FirebaseValueHandle,
  useFirebaseContext,
} from '../../../../Firebase';
import { FirebaseUtils } from '../../../../Firebase/utils';
import { useIsStreamSessionAlive } from '../../../../Session';
import { useIsTeamCaptainScribe, useTeams } from '../../../../TeamAPI/TeamV1';
import { useVenueId } from '../../../../Venue/VenueProvider';
import {
  useGameSessionActionsSignalManager,
  useGameSessionBlockId,
  useGameSessionLocalTimer,
  useGameSessionStatus,
  useOndPlaybackVersion,
} from '../../../hooks';
import { ondWaitEnd } from '../../../OndPhaseRunner';
import { OndVersionChecks } from '../../../OndVersionChecks';
import { triggerAllTeamsFinishedAnim } from '../../../store';
import { useGamePlayEmitter } from './GamePlayProvider';

interface TeamSubmissionStatusAPI {
  markSubmitted: () => Promise<void>;
}

// NOTE: the `Partial<>` is a way to ensure more typesafety when indexing into
// the object. Index signatures do not allow specifying optional keys, and
// Partial is easier to read than `| undefined`.

type SubmissionStatusSchema = {
  [venueId: string]: Partial<{
    [blockId: string]: Partial<{
      [teamId: string]: Partial<{
        [gameSessionStatusDuringSubmission: string]: Nullable<TeamSubmission>;
      }>;
    }>;
  }>;
};

type TeamSubmission = {
  teamId: string;
  status: 'waiting' | 'submitted';
};

export type TeamSubmissionStatus = {
  teamId: string;
  name: string;
  status: 'waiting' | 'submitted';
};

type Context = {
  teamSubmissions: TeamSubmissionStatus[];
  // the teamSubmissions might not reflect the latest value. The last tick
  // calculated the submissions for prevSessionStatus, and the current tick goes
  // into a new sessionStatus. Before the re-evaludation, the teamSubmissions
  // is outdated. In additional to the teamSubmissions, we also store the last
  // sessionStatus to help determine if the data is outdated.
  sessionStatus: GameSessionStatus | null;
};

const context = React.createContext<Nullable<Context, false>>(null);

function useSubmissionStatusContextProxy(): ValtioSnapshottable<Context> {
  const ctx = useContext(context);
  if (!ctx) throw new Error('SubmissionStatusProvider is not in the tree!');
  return markSnapshottable(ctx);
}

export function useTeamSubmissionStatuses(): Context {
  const proxy = useSubmissionStatusContextProxy();
  return {
    teamSubmissions: useSnapshot(proxy)
      .teamSubmissions as TeamSubmissionStatus[],
    sessionStatus: useSnapshot(proxy).sessionStatus,
  };
}

export function useTeamSubmissionStatusAPI(): TeamSubmissionStatusAPI {
  const venueId = useVenueId();
  const blockId = useGameSessionBlockId();
  const gsStatus = useGameSessionStatus() ?? null;
  const me = useMyInstance();
  const clientId = me?.clientId;
  const teamId = me?.teamId;
  const isTeamCaptainScribe = useIsTeamCaptainScribe(teamId, clientId);
  const svc = useFirebaseContext().svc;

  const markSubmitted = useCallback(async () => {
    if (
      !venueId ||
      !blockId ||
      !teamId ||
      gsStatus === null ||
      !isTeamCaptainScribe
    )
      return;

    const handle = handleForTeam(svc, venueId, blockId, teamId, gsStatus);
    await handle.set({
      teamId,
      status: 'submitted',
    });
  }, [venueId, blockId, teamId, gsStatus, isTeamCaptainScribe, svc]);

  return useMemo(
    () => ({
      markSubmitted,
    }),
    [markSubmitted]
  );
}

/**
 * A hook to listen for the 'finished' GamePlayEndState and mark the submission
 * status as submitted. Note that only the team captain should write the
 * submission status. However, it's safe to use this hook without explicitly
 * checking for the team captain; the markSubmitted action is a noop for
 * non-team captains.
 */
export function useGamePlayFinishedSubmissionMarker(): void {
  const submissionStatusAPI = useTeamSubmissionStatusAPI();
  const emitter = useGamePlayEmitter();

  useEffect(() => {
    return emitter.once('ended', (_, state) => {
      if (state === 'finished') {
        submissionStatusAPI.markSubmitted();
      }
    });
  }, [emitter, submissionStatusAPI]);
}

/**
 * A provider designed for controllers. This provider is responsible for
 * clearing submission statuses when blocks are reset.
 */
function ControllerSubmissionStatusProvider(props: {
  children?: ReactNode;
}): JSX.Element {
  const venueId = useVenueId();
  const blockId = useGameSessionBlockId();
  const svc = useFirebaseContext().svc;
  const signalman = useGameSessionActionsSignalManager();

  useLayoutEffect(() => {
    const handle = handleForVenue(svc, venueId);
    return signalman.connect({
      name: 'reset',
      after: () => handle.remove(),
    });
  }, [signalman, svc, venueId]);

  useLayoutEffect(() => {
    if (!blockId) return;
    const handle = handleForBlock(svc, venueId, blockId);
    return signalman.connect({
      name: 'reset-block',
      after: () => handle.remove(),
    });
  }, [blockId, signalman, svc, venueId]);

  return <>{props.children}</>;
}

/**
 * A provider for all users. Provides read only access to the underlying data.
 */
function RootSubmissionStatusProvider(props: {
  children?: ReactNode;
}): JSX.Element {
  const venueId = useVenueId();
  const blockId = useGameSessionBlockId();
  const gsStatus = useGameSessionStatus() ?? null;
  const isSessionAlive = useIsStreamSessionAlive();

  const teams = useLatest(
    useTeams({
      updateStaffTeam: true,
      excludeStaffTeam: true,
    })
  );

  const ctxValue = useRef<Context>(
    proxy({ teamSubmissions: [], sessionStatus: null })
  );
  const svc = useFirebaseContext().svc;

  useLayoutEffect(() => {
    if (gsStatus === null || !blockId || !isSessionAlive) return;

    const handle = handleForBlock(svc, venueId, blockId);

    handle.on((v) => {
      const knownSubmissions = v ?? {};

      // NOTE: it is very important that all teams have an entry here, because
      // the check for all teams submitting only uses the contents of the array
      // and does not cross check with the known teams.

      ctxValue.current.teamSubmissions = teams.current.map((team) => {
        const submission = knownSubmissions[team.id]?.[gsStatus];
        return {
          // only inserted here
          name: team.name,

          // defaults
          teamId: team.id,
          status: 'waiting',
          submittedDuring: null,

          // defaults overridden by specific submission
          ...submission,
        };
      });
      ctxValue.current.sessionStatus = gsStatus;
    });

    return () => handle.off();
  }, [blockId, gsStatus, isSessionAlive, svc, teams, venueId]);

  return (
    <context.Provider value={ctxValue.current}>
      {props.children}
    </context.Provider>
  );
}

export function SubmissionStatusProvider(props: {
  children?: ReactNode;
}): JSX.Element {
  const isController = useIsController();
  if (isController) {
    return (
      <RootSubmissionStatusProvider>
        <ControllerSubmissionStatusProvider>
          {props.children}
        </ControllerSubmissionStatusProvider>
      </RootSubmissionStatusProvider>
    );
  }

  return (
    <RootSubmissionStatusProvider>
      {props.children}
    </RootSubmissionStatusProvider>
  );
}

export function useSubmissionStatusAllSubmitted() {
  const { teamSubmissions } = useTeamSubmissionStatuses();
  return useMemo(
    () =>
      teamSubmissions.length > 0 &&
      teamSubmissions.every((s) => s.status === 'submitted'),
    [teamSubmissions]
  );
}

export function useSubmissionStatusSubmittedTeamIds() {
  const { teamSubmissions } = useTeamSubmissionStatuses();
  return useMemo(
    () =>
      teamSubmissions
        .filter((t) => t.status === 'submitted')
        .map((t) => t.teamId),
    [teamSubmissions]
  );
}

function useSubmissionStatusWaitEnderInternal(
  enabled: boolean,
  onAllSubmitted: () => Promise<void> | void
) {
  const currGSStatus = useGameSessionStatus();
  const prevGSStatus = usePrevious(currGSStatus);
  const hasTriggered = useRef(false);
  const gameSessionLocalTimer = useGameSessionLocalTimer();
  const allSubmitted = useSubmissionStatusAllSubmitted();

  const afterAllSubmitted = useLiveCallback(onAllSubmitted);

  useLayoutEffect(() => {
    if (
      !enabled ||
      !gameSessionLocalTimer ||
      gameSessionLocalTimer <= 3 ||
      !allSubmitted ||
      currGSStatus === undefined ||
      hasTriggered.current
    )
      return;
    triggerAllTeamsFinishedAnim();

    hasTriggered.current = true;

    afterAllSubmitted();
  }, [
    enabled,
    gameSessionLocalTimer,
    currGSStatus,
    afterAllSubmitted,
    allSubmitted,
  ]);

  useLayoutEffect(() => {
    // when the game status changes, reset the guard. This allows one submission
    // skip per gameSessionStatus change.
    if (currGSStatus !== prevGSStatus && hasTriggered.current) {
      hasTriggered.current = false;
    }
  }, [currGSStatus, enabled, prevGSStatus]);
}

export function useSubmissionStatusWaitEnder(
  onAllSubmitted: () => Promise<void> | void = ondWaitEnd
): void {
  const isController = useIsController();
  const playbackVersion = useOndPlaybackVersion();
  useSubmissionStatusWaitEnderInternal(
    isController &&
      OndVersionChecks(playbackVersion).ondAllowAllTeamsFinishedAnim,
    onAllSubmitted
  );
}

function handleForVenue(svc: FirebaseService, venueId: string) {
  const ref = svc.prefixedSafeRef<SubmissionStatusSchema>(
    `game-session-submission-status`
  );
  return new FirebaseValueHandle(FirebaseUtils.Rewrap(ref.child(venueId)));
}

function handleForBlock(
  svc: FirebaseService,
  venueId: string,
  blockId: string
) {
  return new FirebaseValueHandle(
    FirebaseUtils.Rewrap(handleForVenue(svc, venueId).ref.child(blockId))
  );
}

function handleForTeam(
  svc: FirebaseService,
  venueId: string,
  blockId: string,
  teamId: string,
  gameSessionStatus: Exclude<GameSessionStatus, null>
) {
  const child = handleForBlock(svc, venueId, blockId)
    .ref.child(teamId)
    .child(String(gameSessionStatus));
  return new FirebaseValueHandle(FirebaseUtils.Rewrap(child));
}
