import {
  createContext,
  type ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLatest, usePreviousDistinct } from 'react-use';

import {
  EnumsBrandPredefinedBlockScenario,
  EnumsSharedAssetPurpose,
  type ModelsTTSLabeledRenderSettings,
  type ModelsTTSScript,
} from '@lp-lib/api-service-client/public';
import {
  type TitleBlockV2,
  type TitleCard,
  type VoiceOver,
} from '@lp-lib/game';
import { MediaFormatVersion } from '@lp-lib/media';

import { useArrayState } from '../../../../hooks/useArrayState';
import {
  getFeatureQueryParam,
  useFeatureQueryParam,
} from '../../../../hooks/useFeatureQueryParam';
import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { apiService } from '../../../../services/api-service';
import { fromMediaDTO } from '../../../../utils/api-dto';
import { uuidv4 } from '../../../../utils/common';
import { MediaUtils } from '../../../../utils/media';
import { TagQuery } from '../../../../utils/TagQuery';
import { useAwaitFullScreenConfirmCancelModal } from '../../../ConfirmCancelModalContext';
import { ModalWrapper } from '../../../ConfirmCancelModalContext/ModalWrapper';
import { DraggableList } from '../../../DraggableList';
import { UncontrolledRangeInput } from '../../../Form/UncontrolledRangeInput';
import { DeleteIcon } from '../../../icons/DeleteIcon';
import { ErrorIcon } from '../../../icons/ErrorIcon';
import { ImageIcon } from '../../../icons/ImageIcon';
import { MiniMediaEditor } from '../../../MediaUploader/MiniMediaEditor';
import { useBlockEditorStore } from '../../../RoutedBlock';
import { SwitcherControlled } from '../../../Switcher';
import {
  defaultTTSOptions,
  TTSOptionEditor,
} from '../../../VoiceOver/TTSOptionEditor';
import {
  TTSScriptEditor,
  TTSScriptEditorExpandable,
} from '../../../VoiceOver/TTSScriptEditor';
import { SupportedVariables } from '../../../VoiceOver/TTSScriptsEditor';
import { VoiceOverUtils } from '../../../VoiceOver/utils';
import {
  hasVariable,
  prependVariable,
} from '../../../VoiceOver/VariableRegistry';
import { VoiceOverEditor } from '../../../VoiceOver/VoiceOverEditor';
import { makeExampleVariableRegistrySnapshot } from '../../hooks/useCreateGameInfoSnapshot';
import {
  FieldContainer,
  SimpleFieldEditor,
} from '../Common/Editor/FieldEditorUtilities';
import {
  AdditionalSettings,
  AdditionalSharedSettingsEditor,
  type EditorProps,
  useEditor,
} from '../Common/Editor/Internal';
import {
  type TTSScriptsUpdater,
  useTTSScriptsUpdater,
} from './TitleBlockTTSScriptsUpdater';

const voiceOverEnabled = getFeatureQueryParam('block-editor-voiceover');

function ReorderCardRow(props: { card: TitleCard }): JSX.Element {
  const { card } = props;
  const mediaUrl = MediaUtils.PickMediaUrl(card.media, {
    priority: [MediaFormatVersion.SM],
    videoThumbnail: 'first',
  });
  return (
    <div className='w-full h-full flex items-center gap-3'>
      <div className='w-22 h-12.5 rounded-lg'>
        {mediaUrl ? (
          <img
            src={mediaUrl}
            alt='luna park'
            className='w-full h-full object-contain rounded-lg'
          ></img>
        ) : (
          <div className='w-full h-full rounded-lg border border-secondary bg-dark-gray flex justify-center items-center'>
            <ImageIcon className='w-6 h-6 text-secondary' />
          </div>
        )}
      </div>

      <div className='flex-1 overflow-hidden'>
        {card.text ? (
          <p className='text-base font-bold truncate'>{card.text}</p>
        ) : (
          <p className='text-sms font-normal text-secondary italic'>
            No Text Available
          </p>
        )}
      </div>
    </div>
  );
}

function ReorderCardsModal(props: {
  cards: TitleCard[];
  onComplete: (reordered: TitleCard[]) => void;
  onClose: () => void;
}): JSX.Element {
  const [cards, , dao] = useArrayState({
    init: props.cards,
    compare: (a, b) => a.id === b.id,
  });

  const handleSave = () => {
    props.onComplete(cards);
  };

  const handleCancel = () => {
    props.onClose();
  };

  const handleMove = (from: number, to: number): void => {
    dao.moveItem(from, to);
  };

  const handleDelete = (card: TitleCard) => {
    dao.deleteItem(card);
  };

  return (
    <ModalWrapper
      onClose={props.onClose}
      borderStyle='gray'
      containerClassName='w-148'
    >
      <div className='w-full px-3 py-4 text-white flex flex-col items-center gap-6'>
        <h2 className='text-xl font-medium'>Reorder Cards</h2>

        <div className='w-full px-3 max-h-120 overflow-y-auto scrollbar'>
          <DraggableList
            items={cards}
            sourceType='title-block-cards'
            acceptableType='title-block-cards'
            onMove={handleMove}
            onDelete={handleDelete}
            render={(card) => <ReorderCardRow card={card} />}
          />
        </div>

        <div className='flex justify-center items-center gap-4'>
          <button
            type='button'
            className='btn-secondary w-32 h-10'
            onClick={handleCancel}
          >
            Cancel
          </button>
          <button
            type='button'
            className='btn-primary w-32 h-10'
            onClick={handleSave}
          >
            Save
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
}

function TitleCardView(props: {
  settings: ModelsTTSLabeledRenderSettings | null | undefined;
  card: TitleCard;
  onUpdate: (updates: Partial<TitleCard>) => void;
  onDelete: () => void;
  onMigrateRuntime: () => void;
  blockScenario?: EnumsBrandPredefinedBlockScenario | null;
}): JSX.Element {
  const { card, onUpdate, onDelete, onMigrateRuntime, blockScenario } = props;

  const handleUpdate = (updates: Partial<TitleCard>) => {
    onUpdate(updates);
  };

  const handleDelete = () => {
    onDelete();
  };

  return (
    <FieldContainer>
      <div className='w-full flex items-start gap-4'>
        {card.teamIntroEnabled ? (
          <TeamIntroTitleCardView
            card={card}
            handleUpdate={handleUpdate}
            blockScenario={blockScenario}
            settings={props.settings}
          />
        ) : (
          <NormalTitleCardView
            card={card}
            handleUpdate={handleUpdate}
            handleMigrateRuntime={onMigrateRuntime}
            blockScenario={blockScenario}
            settings={props.settings}
          />
        )}

        <button
          type='button'
          onClick={handleDelete}
          className='w-7.5 h-7.5 rounded-lg border border-secondary btn flex justify-center items-center text-red-002 bg-black'
        >
          <DeleteIcon />
        </button>
      </div>
    </FieldContainer>
  );
}

// HACK: CSS Grid is hard, I didn't want to futz anymore, and we're probably
// going to change this entire layout anyway.
const GAP = () => <div className='py-1 col-span-full invisible'></div>;

function TeamIntroTitleCardView(props: {
  settings: ModelsTTSLabeledRenderSettings | null | undefined;
  card: TitleCard;
  handleUpdate: (updates: Partial<TitleCard>) => void;
  blockScenario?: EnumsBrandPredefinedBlockScenario | null;
}) {
  const { card, handleUpdate } = props;

  const ctx = useTTSScriptsUpdaterContext();

  return (
    <div
      className='w-full'
      style={{
        // NOTE(drew): it's just easier to manually configure than try to cram it
        // into tailwind.
        display: 'grid',
        gridTemplateColumns: '[labels] 0.33fr [controls] 0.66fr',
        gridAutoFlow: 'row',
        gridGap: '.8em',
      }}
    >
      <header className='flex flex-col gap-1 col-start-1 col-span-full'>
        <h3 className='text-lg font-bold'>Introduce Teams</h3>
        <p className='text-sms text-icon-gray'>
          Each team will be introduced by the AI Host during this card.
        </p>
      </header>

      <label
        className='flex flex-col gap-1 '
        htmlFor={`${card.id}-title-card-media`}
      >
        <span className='text-base text-white font-bold '>
          Team Intro Media
        </span>
        <p className='text-sms text-icon-gray'>
          Media will display behind the team videos during intro.
        </p>
      </label>

      <MiniMediaEditor
        id={`${card.id}-title-card-media`}
        video
        volumeSelectable
        volumeStyle='row'
        mediaData={card.mediaData}
        media={card.media}
        onChange={(mediaData, media) => {
          handleUpdate({
            mediaData,
            media,
          });
        }}
        sharedAssetPurposes={[
          EnumsSharedAssetPurpose.SharedAssetPurposeTitleInstructionCard,
        ]}
        onSharedAssetSelected={(item) => {
          handleUpdate({
            mediaData: { ...card.mediaData, id: item.mediaId },
            media: fromMediaDTO(item.media),
            text: item.data?.cardText,
          });
        }}
      />

      <GAP />

      {!voiceOverEnabled ? null : (
        <>
          <label>
            <span className='text-base text-white font-bold '>
              Introduction Script
            </span>
            <p className='text-sms text-secondary'>
              Before introducing the teams, the host will read this script. This
              uses TTS Settings.
            </p>
          </label>

          <TTSScriptEditorExpandable
            settings={props.settings}
            defaultValue={
              ctx.getSingleTTSScript(['introduction', card.id])?.script
            }
            onChange={(script) => {
              ctx.handleTTSScriptUpdate({
                tags: ['introduction', card.id],
                script,
              });
            }}
            previewMedia={card.media}
            previewLabel='Team Intro Media'
            onBeforeRender={async (script) => {
              if (!script) return null;
              const variables = makeExampleVariableRegistrySnapshot();
              return (await variables.render(script)).script;
            }}
            delayedStartMs={
              ctx.getSingleTTSPlaybackOption(['introduction', card.id])
                ?.delayMs ?? 0
            }
            onDelayedStartMsChange={(delayMs) => {
              ctx.handleTTSPlaybackOptionsUpdate({
                tags: ['introduction', card.id],
                delayMs,
              });
            }}
          />
        </>
      )}

      <GAP />

      {!voiceOverEnabled ? null : (
        <>
          <label>
            <span className='text-base text-white font-bold '>
              Individual Team Script
            </span>
            <p className='text-sms text-secondary'>
              The host will use this to introduce each individual team.
              Available variables:{' '}
              <span className='font-mono'>%teamIntroTeamName%</span>,{' '}
              <span className='font-mono'>%teamIntroContestantNames%</span>.
              This uses TTS Settings.
            </p>
          </label>

          <TTSScriptEditor
            settings={props.settings}
            defaultValue={
              ctx.getSingleTTSScript(['individual-team', card.id])?.script
            }
            onBeforeRender={async (script) => {
              if (!script) return null;
              const variables = makeExampleVariableRegistrySnapshot();
              return (await variables.render(script)).script;
            }}
            onChange={(v) => {
              ctx.handleTTSScriptUpdate({
                tags: ['individual-team', card.id],
                script: v,
              });
            }}
          />

          <label htmlFor={`${card.id}-individual-team-delayStartMs`}>
            <span className='text-xs text-icon-gray font-bold'>
              Individual Team Script Delayed Start
            </span>
            <p className='text-sms text-secondary'>
              Wait this long (milliseconds) before starting the voice over.
            </p>
          </label>
          <UncontrolledRangeInput
            className='flex justify-between items-center w-full gap-2'
            name={`${card.id}-individual-team-delayStartMs`}
            min={0}
            max={10000}
            step={1}
            defaultValue={
              ctx.getSingleTTSPlaybackOption(['individual-team', card.id])
                ?.delayMs ?? 0
            }
            onChange={(value) => {
              ctx.handleTTSPlaybackOptionsUpdate({
                tags: ['individual-team', card.id],
                delayMs: value,
              });
            }}
          />
        </>
      )}

      <GAP />

      {!voiceOverEnabled ? null : (
        <>
          <label>
            <span className='text-base text-white font-bold '>
              Conclusion Script
            </span>
            <p className='text-sms text-secondary'>
              After introducing the teams, the host will read this script. This
              uses TTS Settings.
            </p>
          </label>

          <TTSScriptEditor
            settings={props.settings}
            defaultValue={
              ctx.getSingleTTSScript(['conclusion', card.id])?.script
            }
            onBeforeRender={async (script) => {
              if (!script) return null;
              const variables = makeExampleVariableRegistrySnapshot();
              return (await variables.render(script)).script;
            }}
            onChange={(v) => {
              ctx.handleTTSScriptUpdate({
                tags: ['conclusion', card.id],
                script: v,
              });
            }}
          />

          <label htmlFor={`${card.id}-individual-team-delayStartMs`}>
            <span className='text-xs text-icon-gray font-bold'>
              Conclusion Script Delayed Start
            </span>
            <p className='text-sms text-secondary'>
              Wait this long (milliseconds) before starting the voice over.
            </p>
          </label>
          <UncontrolledRangeInput
            className='flex justify-between items-center w-full gap-2'
            name={`${card.id}-individual-team-delayStartMs`}
            min={0}
            max={10000}
            step={1}
            defaultValue={
              ctx.getSingleTTSPlaybackOption(['conclusion', card.id])
                ?.delayMs ?? 0
            }
            onChange={(value) => {
              ctx.handleTTSPlaybackOptionsUpdate({
                tags: ['conclusion', card.id],
                delayMs: value,
              });
            }}
          />
        </>
      )}
    </div>
  );
}

function NormalTitleCardView(props: {
  card: TitleCard;
  settings: ModelsTTSLabeledRenderSettings | null | undefined;
  handleUpdate: (updates: Partial<TitleCard>) => void;
  handleMigrateRuntime: () => void;
  blockScenario?: EnumsBrandPredefinedBlockScenario | null;
}) {
  const { card, handleUpdate, blockScenario, handleMigrateRuntime } = props;

  const enableLegacyVoiceOverEditor = useFeatureQueryParam(
    'show-legacy-vo-editors'
  );
  const showLegacyVoiceOverEditor =
    VoiceOverUtils.HasAtLeastOneConfig(card.voiceOver) &&
    enableLegacyVoiceOverEditor;

  const ctx = useTTSScriptsUpdaterContext();

  const exampleRegistry = useMemo(() => {
    const vr = makeExampleVariableRegistrySnapshot();
    if (
      blockScenario ===
      EnumsBrandPredefinedBlockScenario.BrandPredefinedBlockScenarioOpeningTitle
    ) {
      vr.set(
        'positionAwareOpeningTitleScript',
        async () => "Ready for what's up next? Let's jump right in."
      );
    }
    return vr;
  }, [blockScenario]);

  const voiceOverScriptSubtitle = useMemo(() => {
    if (
      !card.voiceOver?.runtime ||
      card.voiceOver.runtime.settings?.noInjectedScripts ||
      hasVariable(
        card.voiceOver.runtime.script,
        'positionAwareOpeningTitleScript'
      ) ||
      ctx.getSingleTTSScript(['runtime', card.id])?.script // we already have a new world script
    )
      return null;

    switch (blockScenario) {
      case EnumsBrandPredefinedBlockScenario.BrandPredefinedBlockScenarioOpeningTitle:
        return (
          <div className='mt-1 text-sms font-normal text-tertiary'>
            Note: this card uses a legacy runtime configuration. This script
            will be prepended with <em>%positionAwareOpeningTitleScript%</em>.
            For clarity, we recommend migrating to the new voice over scripts
            pattern.
            <button
              type='button'
              className='mt-2 btn-secondary text-xs w-full h-8'
              onClick={handleMigrateRuntime}
            >
              Migrate
            </button>
          </div>
        );
    }
    return null;
  }, [
    blockScenario,
    card.id,
    card.voiceOver?.runtime,
    ctx,
    handleMigrateRuntime,
  ]);

  const handleVoiceOverChange = useLiveCallback(
    async (updated: VoiceOver | null) => {
      handleUpdate({ voiceOver: { ...card.voiceOver, ...updated } });
    }
  );

  return (
    <div
      className='w-full'
      style={{
        display: 'grid',
        gridTemplateColumns: '[labels] 0.33fr [controls] 0.66fr',
        gridAutoFlow: 'row',
        gridGap: '.8em',
      }}
    >
      <header className='flex flex-col gap-1 col-span-full'>
        <h3 className='text-lg font-bold'>Title Card</h3>
        <p className='text-sms text-icon-gray'>
          Pair a voice over with a media file.
        </p>
      </header>

      <section className='col-span-full grid grid-cols-subgrid'>
        <label className='text-base text-white font-bold'>Slide Text</label>

        <input
          className='field mb-0 flex-1'
          defaultValue={card.text}
          onBlur={(event) => handleUpdate({ text: event.target.value })}
          maxLength={100}
          placeholder='Max 100 characters'
        />
      </section>

      <section className='col-span-full grid grid-cols-subgrid'>
        <label className='text-base text-white font-bold'>Slide Media</label>
        <div className='flex items-start gap-4'>
          <MiniMediaEditor
            id={`${card.id}-title-card-media`}
            volumeStyle='row'
            video
            volumeSelectable
            mediaData={card.mediaData}
            media={card.media}
            onChange={(mediaData, media) => {
              handleUpdate({
                mediaData,
                media,
              });
            }}
            sharedAssetPurposes={[
              EnumsSharedAssetPurpose.SharedAssetPurposeTitleInstructionCard,
            ]}
            onSharedAssetSelected={(item) => {
              handleUpdate({
                mediaData: { ...card.mediaData, id: item.mediaId },
                media: fromMediaDTO(item.media),
                text: item.data?.cardText,
              });
            }}
          />
        </div>
      </section>

      <section className='col-span-full grid grid-cols-subgrid'>
        <div className='text-base text-white font-bold'>Card Mods</div>
        <div className='flex flex-col gap-2.5'>
          <div className='flex items-center gap-2 text-icon-gray text-sms'>
            <SwitcherControlled
              name={`${card.id}-fullscreen`}
              checked={card.fullscreen}
              onChange={(checked: boolean): void => {
                handleUpdate({ fullscreen: checked });
              }}
            />
            Display card as full screen
          </div>
          <div className='flex items-center gap-2 text-icon-gray text-sms'>
            <SwitcherControlled
              name={`${card.id}-playBackgroundMusicWithMedia`}
              checked={card.playBackgroundMusicWithMedia}
              onChange={(checked: boolean): void => {
                handleUpdate({ playBackgroundMusicWithMedia: checked });
              }}
            />
            Allow background music to play over media
          </div>
          <div className='flex items-center gap-2 text-icon-gray text-sms'>
            <SwitcherControlled
              name={`${card.id}-animated-transition`}
              checked={card.animatedTransition}
              onChange={(checked: boolean): void => {
                handleUpdate({ animatedTransition: checked });
              }}
            />
            Animate the media flying in over the stage background
          </div>
          <div className='flex items-center gap-2 text-icon-gray text-sms'>
            <SwitcherControlled
              name={`${card.id}-break-into-teams`}
              checked={card.breakIntoTeams ?? false}
              onChange={(checked: boolean): void => {
                handleUpdate({ breakIntoTeams: checked });
              }}
            />
            Break into teams
          </div>
        </div>
      </section>

      <section className='col-span-full grid grid-cols-subgrid'>
        <div>
          <div className='text-base text-white font-bold '>
            Voice Over Script{' '}
            <SupportedVariables variableRegistry={exampleRegistry} />
          </div>
          <p className='text-sms text-secondary'>
            This is generated as early as the start of the game (no variables)
            or as soon as the variable values are available (usually just as the
            card shows).
          </p>
          {voiceOverScriptSubtitle}
        </div>

        <TTSScriptEditorExpandable
          settings={props.settings}
          defaultValue={
            ctx.getSingleTTSScript(['runtime', card.id])?.script ??
            (showLegacyVoiceOverEditor
              ? undefined
              : card.voiceOver?.runtime?.script ??
                card.voiceOver?.fallback?.renderDescription?.script)
          }
          onBeforeRender={async (script) => {
            if (!script) return null;
            return (await exampleRegistry.render(script)).script;
          }}
          onChange={(script) => {
            ctx.handleTTSScriptUpdate({
              tags: ['runtime', card.id],
              script,
            });
          }}
          supportedVariables={exampleRegistry}
          previewMedia={card.media}
          previewLabel='Slide Media'
          delayedStartMs={
            ctx.getSingleTTSPlaybackOption(['runtime', card.id])?.delayMs ?? 0
          }
          onDelayedStartMsChange={(delayMs) => {
            ctx.handleTTSPlaybackOptionsUpdate({
              tags: ['runtime', card.id],
              delayMs,
            });
          }}
        />
      </section>

      {showLegacyVoiceOverEditor && (
        <FieldContainer
          styles={{ layout: 'flex flex-col gap-4 col-span-full' }}
        >
          <div className='w-full'>
            <VoiceOverEditor
              id={card.id}
              voiceOver={card.voiceOver}
              onChange={handleVoiceOverChange}
              runtimeScriptSubtitle={voiceOverScriptSubtitle}
              onGenerateExampleRuntimeState={(state) => {
                return {
                  ...state,
                  blockScenario: props.blockScenario ?? undefined,
                };
              }}
            />
          </div>
        </FieldContainer>
      )}
    </div>
  );
}

function TitleCardsManagement(props: {
  settings: ModelsTTSLabeledRenderSettings | null | undefined;
  defaultCards: TitleCard[];
  onUpdate: (cards: TitleCard[]) => void;
  onMigrateRuntime: (card: TitleCard) => void;
  blockScenario?: EnumsBrandPredefinedBlockScenario | null;
}): JSX.Element {
  const { defaultCards: defaultTitleCards, onUpdate, onMigrateRuntime } = props;
  const triggerFullScreenModal = useAwaitFullScreenConfirmCancelModal();
  const [cards, setCards, dao] = useArrayState<TitleCard>({
    init: defaultTitleCards,
    compare: (a, b) => a.id === b.id,
    prepend: false,
  });
  const prevCards = usePreviousDistinct(cards);
  const onUpdateRef = useLatest(onUpdate);

  useEffect(() => {
    if (!prevCards) return;

    onUpdateRef.current(cards);
  }, [cards, onUpdateRef, prevCards]);

  const handleAdd = () => {
    dao.addItem({
      id: uuidv4(),
      text: '',
      teamIntroEnabled: false,
      fullscreen: false,
      playBackgroundMusicWithMedia: false,
      animatedTransition: false,
    });
  };

  const handleAddTeamIntro = () => {
    dao.addItem({
      id: uuidv4(),
      text: '',
      teamIntroEnabled: true,
      fullscreen: false,
      playBackgroundMusicWithMedia: false,
      animatedTransition: false,
    });
  };

  const handleDelete = (card: TitleCard) => {
    dao.deleteItem(card);
  };

  const handleUpdate = (id: string, updates: Partial<TitleCard>) => {
    dao.updateItem({
      id,
      ...updates,
    });
  };

  const handleReorder = () => {
    triggerFullScreenModal({
      kind: 'custom',
      element: (p) => (
        <ReorderCardsModal
          cards={cards}
          onComplete={(reordered) => {
            setCards(reordered);
            p.internalOnConfirm();
          }}
          onClose={p.internalOnCancel}
        />
      ),
    });
  };

  return (
    <div className='w-full flex flex-col gap-3'>
      <header className='w-full flex items-center justify-between'>
        <div className='text-base font-bold'>
          Title Card(s)
          <span className='ml-2 text-sms font-medium text-icon-gray'>
            Host will manually advance title cards
          </span>
        </div>
        <button
          type='button'
          className='btn text-base font-normal'
          onClick={handleReorder}
        >
          Reorder Cards
        </button>
      </header>

      <main className='w-full flex flex-col gap-3'>
        {cards.map((card) => (
          <TitleCardView
            key={card.id}
            card={card}
            onUpdate={(updates) => handleUpdate(card.id, updates)}
            onMigrateRuntime={() => onMigrateRuntime(card)}
            onDelete={() => handleDelete(card)}
            blockScenario={props.blockScenario}
            settings={props.settings}
          />
        ))}
      </main>

      <SimpleFieldEditor
        name='Add Card'
        description={<>Add a new card to the title block.</>}
      >
        <div className='flex flex-col gap-2 items-end'>
          <button
            type='button'
            className='btn-secondary text-base w-full h-10'
            onClick={handleAdd}
          >
            Add Card
          </button>

          <button
            type='button'
            className='btn-secondary text-base w-full h-10'
            onClick={handleAddTeamIntro}
          >
            Add Team Intro
          </button>
        </div>
      </SimpleFieldEditor>
    </div>
  );
}

export function WarnNeedDefaultVOSettings<B extends TitleBlockV2>(
  props: EditorProps<B>
): JSX.Element | null {
  const requiresTTSOptions =
    props.block.fields.cards?.some((c) => c.teamIntroEnabled) ||
    (props.block.fields.ttsScripts && props.block.fields.ttsScripts.length > 0);
  const hasTTSOptions =
    props.block.fields.ttsOptions && props.block.fields.ttsOptions?.length > 0;

  if (hasTTSOptions || !requiresTTSOptions) return null;

  return (
    <div className='flex flex-col gap-2'>
      <h2 className='flex items-center gap-4 text-base font-bold text-red-001'>
        <ErrorIcon className='w-5 h-5  fill-[#EE3529]' />
        Action Required: The configuration of this block requires defined TTS
        options. You can edit this in the advanced section.
      </h2>
    </div>
  );
}

export function TitleBlockV2Editor(
  props: EditorProps<TitleBlockV2>
): JSX.Element | null {
  const { block } = props;
  const store = useBlockEditorStore();
  const { updateField } = useEditor(props);

  const handleCardsUpdate = async (updated: TitleCard[]) => {
    const cardsWithoutMedia = updated.map((card) => ({
      ...card,
      media: null,
    }));

    props.setSavingChanges(true);
    try {
      await apiService.block.updateField<TitleBlockV2>(block.id, {
        field: 'cards',
        value: cardsWithoutMedia,
      });
      store.setBlockField({
        blockId: block.id,
        blockField: { cards: updated },
      });
    } catch (err) {
      throw err;
    } finally {
      props.setSavingChanges(false);
    }
  };

  const handleTTSOptionsChange = useLiveCallback(
    async (value: ModelsTTSLabeledRenderSettings[]) => {
      props.setSavingChanges(true);
      await updateField('ttsOptions', value);
      props.setSavingChanges(false);
    }
  );

  const handleMigrateRuntime = useLiveCallback(async (card: TitleCard) => {
    const voiceOver = card.voiceOver;
    if (!voiceOver?.runtime) return;

    const nextTTSScripts: ModelsTTSScript[] = [
      ...(block.fields.ttsScripts ?? []),
    ];
    const query = new TagQuery(nextTTSScripts);

    // if we already have a new world script, then do nothing.
    if (query.selectFirst(['runtime', card.id])) return;

    nextTTSScripts.push({
      id: uuidv4(),
      script: prependVariable(
        voiceOver.runtime.script,
        'positionAwareOpeningTitleScript'
      ).trim(),
      tags: ['runtime', card.id],
    });

    await updateField('ttsScripts', nextTTSScripts);
    if (
      !props.block.fields.ttsOptions ||
      props.block.fields.ttsOptions.length === 0
    ) {
      await updateField('ttsOptions', defaultTTSOptions);
    }
  });

  return (
    <div className='w-full'>
      <main className='w-full my-7.5 flex flex-col gap-4 text-white'>
        <h2 className='text-2xl text-white'>Title Block V2</h2>
        <WarnNeedDefaultVOSettings {...props} />

        <TTSScriptsUpdaterProvider {...props}>
          <TitleCardsManagement
            defaultCards={block.fields.cards || []}
            onUpdate={handleCardsUpdate}
            blockScenario={props.blockScenario}
            settings={block.fields.ttsOptions?.at(0)}
            onMigrateRuntime={handleMigrateRuntime}
          />
        </TTSScriptsUpdaterProvider>
        <WaitAfterEachCardEditor
          block={block}
          onChange={(waitAfterEachCardSec) =>
            updateField('waitAfterEachCardSec', waitAfterEachCardSec)
          }
        />
      </main>

      <AdditionalSettings>
        <AdditionalSharedSettingsEditor {...props} />
        <TTSOptionEditor
          description='Defines the available TTS options for the card.'
          onChange={handleTTSOptionsChange}
          value={block.fields.ttsOptions}
        />
      </AdditionalSettings>
    </div>
  );
}

function WaitAfterEachCardEditor(props: {
  block: TitleBlockV2;
  onChange: (waitAfterEachCardSec: number) => void;
}) {
  const { block, onChange } = props;
  const [advanced, setAdvanced] = useState(false);

  if (advanced) {
    return (
      <SimpleFieldEditor
        name={
          <div className='flex items-baseline gap-2'>
            <div>Wait After Each Card</div>
            <button
              type='button'
              className='btn text-primary text-xs px-2 py-1 hover:bg-lp-gray-001 rounded transition-colors'
              onClick={() => setAdvanced(false)}
            >
              Simple
            </button>
          </div>
        }
        description={
          <>
            Determines how long the game will wait after each title card. When
            set to 0, the game will advance to the next card immediately,
            without user intervention. When set to a value greater than 0, the
            game will advance to the next card after the specified number of
            seconds, however, the coordinator may skip this delay.
          </>
        }
      >
        <div className='w-full flex items-center justify-end'>
          <div className='w-full flex items-center'>
            <input
              type='number'
              defaultValue={block.fields.waitAfterEachCardSec}
              onBlur={(e) => props.onChange(e.target.valueAsNumber)}
              className='field flex-1 h-10 m-0 w-full rounded-r-none'
              min={0}
            />
            <div className='bg-layer-002 rounded-r-xl h-10 flex items-center justify-center border-secondary border border-l-0 font-bold text-sms text-white px-3'>
              seconds
            </div>
          </div>
        </div>
      </SimpleFieldEditor>
    );
  } else {
    const isAutoPlay = block.fields.waitAfterEachCardSec === 0;
    return (
      <SimpleFieldEditor
        name={
          <div className='flex items-baseline gap-2'>
            <div>Manual Play</div>
            <button
              type='button'
              className='btn text-primary text-xs px-2 py-1 hover:bg-lp-gray-001 rounded transition-colors'
              onClick={() => setAdvanced(true)}
            >
              Advanced
            </button>
          </div>
        }
        description={
          <>
            Determines how the title cards are advanced during the game.
            <div className='pt-2'>
              <strong>{isAutoPlay ? 'Auto Play' : 'Manual Play'}:</strong>{' '}
              {isAutoPlay
                ? 'The cards will advance automatically during the game without user intervention.'
                : 'The coordinator will be prompted to manually advance through the title cards during the game.'}
            </div>
          </>
        }
      >
        <div className='w-full flex items-center justify-end'>
          <SwitcherControlled
            name='manual-play'
            checked={!isAutoPlay}
            onChange={(checked: boolean): void => {
              onChange(checked ? 10 * 60 : 0);
            }}
          />
        </div>
      </SimpleFieldEditor>
    );
  }
}

const TTSScriptsUpdaterContext = createContext<null | TTSScriptsUpdater>(null);

export function useTTSScriptsUpdaterContext() {
  const ctx = useContext(TTSScriptsUpdaterContext);
  if (!ctx) throw new Error('UpdateFnContext not found');
  return ctx;
}

export function TTSScriptsUpdaterProvider(
  props: EditorProps<TitleBlockV2> & { children: ReactNode }
): JSX.Element {
  const { updateFieldLocalFirst: updateField } = useEditor(props);
  const value = useTTSScriptsUpdater(updateField, props.block);

  return (
    <TTSScriptsUpdaterContext.Provider value={value}>
      {props.children}
    </TTSScriptsUpdaterContext.Provider>
  );
}
