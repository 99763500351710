import { useEffect, useState } from 'react';

import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { useGlobalSubtitlesManager } from '../../VoiceOver/SubtitlesManagerProvider';
import { VoiceOverRegistry } from '../../VoiceOver/VoiceOverRegistry';
import { useOndVideoMixer_UNSTABLE } from './gameSessionHooks';

export function useNewVoiceOverRegistry() {
  const [reg, setReg] = useState<null | VoiceOverRegistry>(null);
  const vm = useOndVideoMixer_UNSTABLE();
  const sub = useGlobalSubtitlesManager();
  const createRegistry = useLiveCallback(() => {
    // Only create when requested
    const r = new VoiceOverRegistry();
    r.setVideoMixer(vm);
    r.setSubtitlesManager('broadcast', sub);
    setReg(r);
    return r;
  });

  useEffect(() => {
    // The VM is technically reactive, so if it gets re-created, ensure we
    // update the registry. But this will be wonky and will likely be an error
    // condition.
    reg?.setVideoMixer(vm);
  }, [reg, vm]);

  useEffect(() => {
    return () => {
      reg?.destroy();
    };
  }, [reg]);

  return createRegistry;
}
