import {
  copy,
  createPointEdgeProjectionResult,
  projectPointEdge,
  set,
  v2,
  type Vector2,
} from 'pocket-physics';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { type BoundingBox, createBoundingBox } from '@lp-lib/game';

import { type CustomVideoQualityPreset } from '../../services/webrtc';
import { uuidv4 } from '../../utils/common';
import { useSnapshot, ValtioUtils } from '../../utils/valtio';
import { Draggable, type DraggableXYPoint } from '../Draggable';
import {
  VideoEffectsMediaElement,
  type VideoEffectsMediaElementProps,
} from '../VideoFrameProcessing';
import {
  usePreviewVideoEffectsSettings,
  usePreviewVideoEffectsSettingsStore,
} from './Provider';

function translateNormalizedEdgeTo(
  edge: { e0: Vector2; e1: Vector2 },
  box: BoundingBox
) {
  set(edge.e0, edge.e0.x * box.width, edge.e0.y * box.height);
  set(edge.e1, edge.e1.x * box.width, edge.e1.y * box.height);
  return edge;
}

function solveForBestHandlePosition(
  handlePositions: Vector2[],
  edge: { e0: Vector2; e1: Vector2 },
  sourceBox: BoundingBox,
  projection = createPointEdgeProjectionResult(),
  handle = v2(0.5, 0.5)
): Vector2 {
  // The factor to be able to transform a point from sourceBox coordinate
  // space to containerBox coordinate space.
  const xform = v2(sourceBox.x, sourceBox.y);

  for (let i = 0; i < handlePositions.length; i++) {
    const h = handlePositions[i];

    // Transform handle % positions along the edge line to pixels and do not
    // edit original vector. Apply xform to translate the sourceBox pixels to
    // containerBox coordinate space.
    const v = set(
      v2(),
      h.x * sourceBox.width + xform.x,
      h.y * sourceBox.height + xform.y
    );

    projectPointEdge(v, edge.e0, edge.e1, projection);
    if (projection.u >= 0 && projection.u <= 1) {
      // point is visible on the tested edge, use it!
      copy(handle, h);
      break;
    }
  }

  return handle;
}

const DefaultHandlePositions = {
  top: 0.5,
  right: 0.5,
  left: 0.5,
};

export function MaskRectEditor(props: {
  disabled?: boolean;
  streamOrSrc: MediaStream | string;
  targetVideoProfile: CustomVideoQualityPreset;
  muted?: boolean;
  autoplay?: boolean;
  controls?: boolean;
  resetMaskBox: { current: null | (() => void) };
  boundingBox?: boolean;
  guidelines?: boolean;
}): JSX.Element {
  const [videoSizeInfo, setVideoSizeInfo] = useState({
    sourceBox: createBoundingBox(),
    canvasBox: createBoundingBox(),
    containerBox: createBoundingBox(),
  });
  const previewStore = usePreviewVideoEffectsSettingsStore();

  const pctToPx = (
    pct: number,
    dimension: 'width' | 'height',
    relative: 'topleft' | 'bottomright' = 'topleft'
  ) => {
    if (!videoSizeInfo.sourceBox.width || !videoSizeInfo.sourceBox.height)
      return 0;
    const base =
      dimension === 'width'
        ? videoSizeInfo.sourceBox.width
        : videoSizeInfo.sourceBox.height;
    const px = base * pct;
    return relative === 'bottomright' ? base - px : px;
  };

  const pxToPct = (
    px: number,
    dimension: 'width' | 'height',
    relative: 'topleft' | 'bottomright' = 'topleft'
  ) => {
    if (!videoSizeInfo.sourceBox.width || !videoSizeInfo.sourceBox.height)
      return 0;

    const base =
      dimension === 'width'
        ? videoSizeInfo.sourceBox.width
        : videoSizeInfo.sourceBox.height;
    const pct = px / base;

    return relative === 'bottomright' ? 1 - pct : pct;
  };

  // The draggable handles are DOM powered, and outside the React render cycles.
  // They emit changes and do not react to incoming props. Forcing a re-render
  // with different `key=` values on the handles is one way to force them to
  // reset their data from props. The store keeps a reset count to allow
  // resetting from the store, and a local reset uuid is kept to allow resetting
  // from outside the store (either locally or via callback).
  const previewStoreResetCount = useSnapshot(previewStore.settings).resetCount;
  const [resetCount, setResetCount] = useState(uuidv4());
  useLayoutEffect(() => {
    setResetCount((c) => (previewStoreResetCount > 0 ? uuidv4() : c));
  }, [previewStoreResetCount]);

  // If the width or height changes, it likely means the editor has been
  // resized. Trigger a restart of the handles so they are visibly positioned
  // accurately.
  const prevVideoRectRef = useRef(videoSizeInfo);
  useEffect(() => {
    if (
      prevVideoRectRef.current.sourceBox.width !==
        videoSizeInfo.sourceBox.width ||
      prevVideoRectRef.current.sourceBox.height !==
        videoSizeInfo.sourceBox.height
    ) {
      setResetCount(uuidv4());
    }
  }, [videoSizeInfo.sourceBox.height, videoSizeInfo.sourceBox.width]);

  const [initialHandlePositions, setInitialHandlePositions] = useState(() => ({
    ...DefaultHandlePositions,
  }));

  props.resetMaskBox.current = () => {
    // Set the maskrect to the largest _visible_ possible percentage size
    // (0,0,0,0) while keeping the mask handles within the Field/container.

    // This only happens on user-click and the intent is RESET, so it's ok to
    // always set the values. If the goal were to preserve existing valid
    // values, then bounds checking and conditional setting would be necessary

    // Each of these is the same process, but due to the use of side-relative
    // percentages (top, right, bottom, left), it's difficult to abstract these.
    // 1. compute 2d transform value (basically the dominating axis of a 2d
    //    matrix)
    // 2. transform the testPoint from the containerBox coordinate space to the
    //    sourceBox coordinate space. In most cases, the untransformed testPoint
    //    is the width, height, or 0.
    // 3. convert the testPoint to a percentage value relative to sourceBox
    // 4. pick the maximum value and apply. We know that 0% is the minimum, so
    //    anything below that is invalid.

    {
      // right side
      const xform = videoSizeInfo.sourceBox.x;
      const testPoint = videoSizeInfo.containerBox.width - xform;
      const pct = pxToPct(testPoint, 'width', 'bottomright');
      const right = Math.max(pct, 0);
      previewStore.updateMask({ right });
    }

    {
      // left side
      const xform = videoSizeInfo.sourceBox.x;
      const testPoint = -xform;
      const pct = pxToPct(testPoint, 'width', 'topleft');
      const left = Math.max(pct, 0);
      previewStore.updateMask({ left });
    }

    {
      // top side
      const xform = videoSizeInfo.sourceBox.y;
      const testPoint = -xform;
      const pct = pxToPct(testPoint, 'height', 'topleft');
      const top = Math.max(pct, 0);
      previewStore.updateMask({ top });
    }

    // Bottom mask cannot be user-adjusted, but keeping these calculations here
    // const xform = videoSizeInfo.sourceBox.y;
    // const testPoint = videoSizeInfo.containerBox.height - xform;
    // const pct = pxToPct(testPoint, 'height', 'bottomright');
    // const bottom = Math.max(pct, 0);
    previewStore.updateMask({ bottom: 0 });

    {
      // Solve for best possible handle positions, given that a handle's default
      // value of 0.5 could be outside the field when the reset button is
      // clicked.

      const edges = [
        { e0: v2(0, 0), e1: v2(1, 0) },
        { e0: v2(1, 0), e1: v2(1, 1) },
        { e0: v2(1, 1), e1: v2(0, 1) },
        { e0: v2(0, 1), e1: v2(0, 0) },
      ].map((edge) =>
        translateNormalizedEdgeTo(edge, videoSizeInfo.containerBox)
      );

      // NOTE: these handles are in "source box space", meaning 0,0 is upper
      // left of the source box, and 1,1 is the bottom right. This is different
      // than the handles when stored, and when flushed to the DOM, which are
      // stored in pct from their corresponding edge. This doesn't actually
      // matter beyond this calculation, since only the dominating axis value is
      // used for initial handle positioning (e.g. for the right handle, only
      // the `x` is actually stored as a percentage).

      // NOTE: these handle coordinates are in priority order of most ideal
      // handle position to least ideal. Most ideal is halfway down the box, but
      // that might be impossible if the halfway point is outside the
      // container/field.

      const testPositions = [0.25, 0.75, 0.33, 0.66, 0.12, 0.88, 0, 1];

      const top = solveForBestHandlePosition(
        [DefaultHandlePositions.top, ...testPositions].map((p) => v2(p, 0)),
        edges[0],
        videoSizeInfo.sourceBox
      );

      const right = solveForBestHandlePosition(
        [DefaultHandlePositions.right, ...testPositions].map((p) => v2(1, p)),
        edges[1],
        videoSizeInfo.sourceBox
      );

      const left = solveForBestHandlePosition(
        [DefaultHandlePositions.left, ...testPositions].map((p) => v2(0, p)),
        edges[3],
        videoSizeInfo.sourceBox
      );

      setInitialHandlePositions((ps) => ({
        ...ps,
        top: top.x,
        right: right.y,
        left: left.y,
      }));
    }

    // Force the handles to be reset
    setResetCount(uuidv4());
  };

  const noRenderHandles =
    props.disabled ||
    !videoSizeInfo.sourceBox.width ||
    !videoSizeInfo.sourceBox.height;

  const onPointerDownUp = (down: boolean) =>
    previewStore.changeProcessorEnabled('greenScreen', !down);

  return (
    // Note(drew): m-1 is to allow room for the ring to be visible. The parent
    // component generally has `overflow: hidden`, which cuts off the ring.
    <div className='w-full m-1'>
      <div className='w-full relative ring-2 ring-red-500 overflow-hidden'>
        {noRenderHandles ? null : (
          <div
            className='absolute'
            style={{
              left: `${videoSizeInfo.sourceBox.x}px`,
              top: `${videoSizeInfo.sourceBox.y}px`,
              width: `${videoSizeInfo.sourceBox.width}px`,
              height: `${videoSizeInfo.sourceBox.height}px`,
            }}
          >
            <div className='relative w-full h-full'>
              <MaskRectHandle
                key={`left-${resetCount}`}
                className='w-4 h-14'
                initialPx={{
                  x: pctToPx(
                    previewStore.settings.greenScreen.maskPct.left,
                    'width'
                  ),
                  y: pctToPx(initialHandlePositions.left, 'height'),
                }}
                limitX={videoSizeInfo.sourceBox.width}
                limitY={videoSizeInfo.sourceBox.height}
                onMove={(p) => {
                  previewStore.updateMask({
                    left: pxToPct(p.x, 'width'),
                  });
                }}
                onPointerDownUp={onPointerDownUp}
              />

              <MaskRectHandle
                key={`right-${resetCount}`}
                className='w-4 h-14'
                initialPx={{
                  x: pctToPx(
                    previewStore.settings.greenScreen.maskPct.right,
                    'width',
                    'bottomright'
                  ),
                  y: pctToPx(initialHandlePositions.right, 'height'),
                }}
                limitX={videoSizeInfo.sourceBox.width}
                limitY={videoSizeInfo.sourceBox.height}
                onMove={(p) => {
                  previewStore.updateMask({
                    right: pxToPct(p.x, 'width', 'bottomright'),
                  });
                }}
                onPointerDownUp={onPointerDownUp}
              />

              <MaskRectHandle
                key={`top-${resetCount}`}
                className='w-14 h-4'
                initialPx={{
                  x: pctToPx(initialHandlePositions.top, 'width'),
                  y: pctToPx(
                    previewStore.settings.greenScreen.maskPct.top,
                    'height'
                  ),
                }}
                limitX={videoSizeInfo.sourceBox.width}
                limitY={videoSizeInfo.sourceBox.height}
                onMove={(p) => {
                  previewStore.updateMask({
                    top: pxToPct(p.y, 'height'),
                  });
                }}
                onPointerDownUp={onPointerDownUp}
              />
            </div>
          </div>
        )}

        <PreviewChromakeyVFPPVideoElement
          streamOrSrc={props.streamOrSrc}
          muted={props.muted}
          autoplay={props.autoplay}
          controls={props.controls}
          onVideoSize={(sourceBox, canvasBox, containerBox) =>
            setVideoSizeInfo({ sourceBox, canvasBox, containerBox })
          }
          targetVideoProfile={props.targetVideoProfile}
          boundingBox={props.boundingBox}
        />

        {!props.guidelines ? null : (
          <>
            <div
              className={`
                absolute
                top-0 left-1/2 transform -translate-x-1/2
                w-1.5 h-full 
                bg-white bg-opacity-40
                pointer-events-off
              `}
            ></div>
            <div
              className={`
                absolute
                top-1/3 left-0 transform -translate-y-1/2
                w-full h-1.5 
                bg-white bg-opacity-40
                pointer-events-off
              `}
            ></div>
          </>
        )}
      </div>
    </div>
  );
}

function PreviewChromakeyVFPPVideoElement(
  props: Omit<VideoEffectsMediaElementProps, 'settings' | 'processorsEnabled'>
): JSX.Element {
  const settings = usePreviewVideoEffectsSettings();
  // HACK: Valtio will render-optimize, but object-identity is not updated if a
  // child property changes. Create a new object to ensure that an update to the
  // settings triggers any effects that are depending on the settings object as
  // a whole.
  const nextSettings = ValtioUtils.detachCopy(settings);

  return <VideoEffectsMediaElement {...props} settings={nextSettings} />;
}

function MaskRectHandle(props: {
  initialPx: { x: number; y: number };
  limitX: number;
  limitY: number;
  className: string;
  onMove: (offset: DraggableXYPoint) => void;
  onPointerDownUp: (down: boolean) => void;
}) {
  return (
    <Draggable<HTMLDivElement, HTMLButtonElement>
      initialOffsetX={props.initialPx.x}
      initialOffsetY={props.initialPx.y}
      limitX={props.limitX}
      limitY={props.limitY}
      onMove={props.onMove}
      onPointerDownUp={props.onPointerDownUp}
    >
      {(cRef, hRef) => (
        <div ref={cRef} className={`absolute z-3`}>
          <button
            ref={hRef}
            /** NOTE(drew): display:block is to ensure that the absolute parent
             * always takes the smallest size to encompase the size of the
             * child. When it it left as the default inline-block, the top
             * handle will sometimes have an extra gap of whitespace that
             * distorts the layout. I tried using the `block` tailwind class but
             * it was not applying. */
            style={{
              display: 'block',
            }}
            className={`
              ${props.className}
              bg-primary cursor-pointer
              transform -translate-x-1/2 -translate-y-1/2
            `}
          ></button>
        </div>
      )}
    </Draggable>
  );
}
