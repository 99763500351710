import { useEffect, useLayoutEffect, useMemo, useState } from 'react';

import {
  MediaType,
  RoundRobinMode,
  type RoundRobinQuestionBlock,
  RoundRobinQuestionBlockGameSessionStatus,
} from '@lp-lib/game';

import { useMyInstance } from '../../../../hooks/useMyInstance';
import { ClientTypeUtils, type Participant } from '../../../../types';
import { ordinal } from '../../../../utils/common';
import { MediaPickPriorityHD, MediaUtils } from '../../../../utils/media';
import { useGainPointsAnimationGamePlayTrigger } from '../../../GainPointsAnimation/useGainPointsAnimationGamePlayTrigger';
import { FloatBoard, FloatLayout } from '../../../Layout';
import { LayoutAnchor } from '../../../LayoutAnchors/LayoutAnchors';
import { useSyncPersistentPointsRevealAnswer } from '../../../PersistentPoints/Provider';
import { useMyTeamId } from '../../../Player';
import { useSoundEffect } from '../../../SFX';
import { useMyClientType } from '../../../Venue/VenuePlaygroundProvider';
import { GoAnimation } from '../../GameBlockCardAnimations';
import {
  useGameSessionLocalTimer,
  useGameSessionStatus,
  useIsGamePlayPaused,
  useIsLiveGamePlay,
  useTimerRecover,
} from '../../hooks';
import { GamePlayEndTransition } from '../Common/GamePlay/GamePlayEndTransition';
import { GamePlayMediaLayout } from '../Common/GamePlay/GamePlayMediaLayout';
import {
  GamePlayMediaPlayer,
  type GamePlayProps,
  Leaderboard,
  useCountdownPlaySFX,
  useGamePlayMediaPlayable,
  useGamePlayMediaUISync,
  useGamePlayUITransitionControl,
} from '../Common/GamePlay/Internal';
import { ProgressRing } from '../Common/GamePlay/ProgressRing';
import { StageBgSingleFrame } from '../Common/GamePlay/StageBgSingleFrame';
import {
  useGamePlayFinishedSubmissionMarker,
  useSubmissionStatusWaitEnder,
} from '../Common/GamePlay/SubmissionStatusProvider';
import { useTrackSubmissionGraded } from '../Common/grader';
import { useRankedTeamScores, useStableBlock } from '../Common/hooks';
import { useGradeAPI } from './grader';
import { roundRobinGameLongDuration } from './RoundRobinQuestionBlockEditor';
import {
  GamePlayLocalControl,
  type GamePlayLocalState,
  useGamePlayLocalControl,
} from './RoundRobinQuestionBlockLocalControl';
import { ScheduledQuestionCards } from './RoundRobinQuestionCard';
import {
  RoundRobinQuestionClues,
  RoundRobinQuestionPlay,
} from './RoundRobinQuestionPlay';
import {
  useEmitGamePlayEndedState,
  useIsGameCompleted,
  useRaceTeams,
  useRoundRobinGameSummary,
} from './RoundRobinQuestionProvider';
import { RoundRobinRaceGoAnimation } from './RoundRobinRace';
import { type Question } from './types';
import { RoundRobinQuestionUtils } from './utils';

function useModeAwareRanking(block: RoundRobinQuestionBlock) {
  const teamScores = useRankedTeamScores('currentScore', null, 'always');
  const raceTeams = useRaceTeams();

  if (block.fields.mode === RoundRobinMode.Race) {
    return raceTeams.map((t) => ({
      teamId: t.teamId,
      teamName: t.teamName,
      score: t.score,
      showRank: true,
    }));
  }
  return teamScores.map((t) => ({
    teamId: t.team.id,
    teamName: t.team.name,
    score: t.currentScore,
    showRank: false,
  }));
}

function Results(props: {
  block: RoundRobinQuestionBlock;
}): JSX.Element | null {
  const { block } = props;
  const myTeamId = useMyTeamId();
  const summary = useRoundRobinGameSummary();
  const ranks = useModeAwareRanking(block);

  const { play } = useSoundEffect('showResults');

  useEffect(() => {
    play();
  }, [play]);

  return (
    <>
      <div className='absolute w-full h-full bg-lp-black-001'></div>

      <FloatBoard
        containerZIndex='z-20'
        containerDisplay='flex'
        bgStyle='border-2 border-cyan rounded-xl bg-lp-black-001'
        title='GAME RESULTS'
      >
        <div className=' w-full h-full pt-16 pb-20 px-16 overflow-y-auto scrollbar flex flex-col'>
          {ranks.map((s, i) => {
            const correctCount = summary[s.teamId]?.correctQuestionsCount ?? 0;
            const rankInfo = s.showRank ? `${ordinal(i + 1)} Place, ` : '';
            return (
              <div
                key={s.teamId}
                className={`w-full animate-fade-in-up min-h-12 mb-4 pl-6 rounded-3xl bg-lp-gray-009 flex flex-row justify-between items-center ${
                  s.teamId === myTeamId ? 'text-tertiary' : 'text-white'
                }`}
              >
                <p className={`text-lg font-bold font-cairo`}>{s.teamName}</p>
                <div className='flex flex-row justify-center items-center'>
                  <p className='text-3xs font-bold'>{`${rankInfo}${correctCount} Correct`}</p>
                  <div className='w-11 h-11 mr-0.75 ml-3 rounded-full bg-warning text-black flex flex-col justify-center items-center'>
                    <div className='font-extrabold text-xl leading-5 font-cairo'>
                      {s.score}
                    </div>
                    <p className='text-3xs text-black'>pts</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </FloatBoard>
      <FloatLayout className='flex items-center justify-center'>
        <LayoutAnchor
          id='gameplay-points-animation-top'
          className='w-full max-w-4xl'
        />
      </FloatLayout>
    </>
  );
}

function HostView(): JSX.Element {
  const summary = useRoundRobinGameSummary();

  return (
    <FloatLayout className='flex items-center justify-center'>
      <div className='w-120 h-68 xl:w-160 xl:h-90 2xl:w-220 2xl:h-124'>
        <Leaderboard
          rightColumn={{
            header: (
              <div className='flex flex-row w-80 justify-between'>
                <div className='w-30 text-center'>Clues This Question</div>
                <div className='w-30 text-center'>Clues Total</div>
                <div className='w-20 text-center'>Completion</div>
              </div>
            ),
            body: (teamId) => {
              const correctCount = summary[teamId]?.correctQuestionsCount ?? 0;
              const questionsCount = summary[teamId]?.questionsCount ?? 0;
              const currentUsedCluesCount =
                summary[teamId]?.currentUsedCluesCount ?? 0;
              const usedCluesCount = summary[teamId]?.usedCluesCount ?? 0;

              return (
                <div className='flex flex-row w-80 justify-between'>
                  <div className='w-30 text-center'>
                    {currentUsedCluesCount}
                  </div>
                  <div className='w-30 text-center'>{usedCluesCount}</div>
                  <div className='w-20 text-center'>
                    {correctCount}/{questionsCount} -{' '}
                    {isNaN(correctCount / questionsCount)
                      ? 0
                      : Math.round((correctCount / questionsCount) * 100)}{' '}
                    %
                  </div>
                </div>
              );
            },
          }}
        />
      </div>
    </FloatLayout>
  );
}

function GameTimerAndClues(props: {
  block: RoundRobinQuestionBlock;
  question: Question | null;
  isSubmitter: boolean;
  showTimer?: boolean;
}): JSX.Element {
  const { block, question, isSubmitter, showTimer } = props;
  const currentTime = useGameSessionLocalTimer();
  const visualGameTimeSec = RoundRobinQuestionUtils.GameTimeSec(block);
  const durationFormattedMMSS = visualGameTimeSec >= roundRobinGameLongDuration;

  return (
    // NOTE: FloatLayout is absolute positioned.
    <FloatLayout useCustomPaddingX className='left-35 flex justify-center z-45'>
      <div className='h-1/3 flex flex-col justify-start items-center'>
        <div>
          <ProgressRing
            className={`${showTimer ? 'visible' : 'invisible'}`}
            radiusBreakpoints={{
              '': 50,
              xl: 50,
              'lp-sm': 50,
              '2xl': 50,
              '3xl': 50,
              'lp-md': 50,
              'lp-lg': 50,
            }}
            strokeBreakpoints={{
              '': 8,
              xl: 8,
              'lp-sm': 8,
              '2xl': 8,
              '3xl': 8,
              'lp-md': 8,
              'lp-lg': 8,
            }}
            textClassName={`${
              durationFormattedMMSS ? 'text-2xl' : 'text-4xl'
            } font-black`}
            currentTime={currentTime || 0}
            totalTime={visualGameTimeSec}
            withPingAnimations
            durationFormattedMMSS={durationFormattedMMSS}
          />
          <LayoutAnchor
            id='round-robin-progress-ring'
            className='w-full h-0 absolute'
          />
        </div>
        {question && (
          <div className='flex items-center'>
            <RoundRobinQuestionClues
              question={question}
              isSubmitter={isSubmitter}
              block={block}
            />
          </div>
        )}
      </div>
    </FloatLayout>
  );
}

function GamePlayground(props: {
  isHost: boolean;
  me: Participant;
  gameSessionStatus: RoundRobinQuestionBlockGameSessionStatus;
  block: RoundRobinQuestionBlock;
  state: GamePlayLocalState;
  showPrompt: boolean;
}): JSX.Element | null {
  const { isHost, me, gameSessionStatus, block, state, showPrompt } = props;
  const gradeAPI = useGradeAPI(block);
  const onSubmissionGraded = useTrackSubmissionGraded(block, gradeAPI.logger);

  useLayoutEffect(() => {
    if (gradeAPI.aiGrade.ctrl === 'disabled') return;
    return gradeAPI.on('submission-graded', onSubmissionGraded);
  }, [gradeAPI, onSubmissionGraded]);

  const isGamePlayPaused = useIsGamePlayPaused();
  const isCompleted = useIsGameCompleted(block, state.questions);

  const scheduledQuestions = useMemo(
    () =>
      !!state.currentQuestion
        ? state.questions.filter(
            (q) => q.index > (state.currentQuestion?.index || 0)
          )
        : state.questions,

    [state.currentQuestion, state.questions]
  );

  const isSubmitter =
    (state.currentQuestion &&
      state.currentSubmitter &&
      me.id === state.currentSubmitter.id) ??
    false;

  if (isHost) return <HostView />;

  const paused = isGamePlayPaused || isCompleted;

  return (
    <>
      <GameTimerAndClues
        block={block}
        question={state.currentQuestion}
        isSubmitter={isSubmitter}
        showTimer={showPrompt}
      />
      {block.fields.hotSeatUI && showPrompt && (
        <ScheduledQuestionCards
          scheduledQuestions={scheduledQuestions}
          candidates={state.candidates}
          isTransition={state.isTransition}
        />
      )}

      {state.currentQuestion && state.currentSubmitter && (
        <RoundRobinQuestionPlay
          key={state.currentQuestion.index}
          isSubmitter={isSubmitter}
          isGamePlaying={
            gameSessionStatus ===
              RoundRobinQuestionBlockGameSessionStatus.GAME_START && !paused
          }
          block={block}
          question={state.currentQuestion}
          submitter={state.currentSubmitter}
          isTransition={state.isTransition}
          subsequentSubmitter={state.candidates}
          grader={gradeAPI}
          showPrompt={showPrompt}
        />
      )}

      {state.currentQuestion && (
        <QuestionBackground question={state.currentQuestion} />
      )}
      {/* Background Overlay */}
      {/* <div className='w-screen h-screen inset-0 absolute bg-black bg-opacity-80'></div> */}

      <GamePlayEndTransition />
    </>
  );
}

function QuestionBackground(props: { question: Question }): JSX.Element | null {
  const { question } = props;
  const media = question.backgroundMedia;
  const mediaUrl = MediaUtils.PickMediaUrl(media, {
    priority: MediaPickPriorityHD,
  });

  if (!media || !mediaUrl) {
    return null;
  }

  return (
    <div className='w-screen h-screen inset-0 absolute'>
      {media.type === MediaType.Video && (
        <video
          src={mediaUrl}
          muted
          loop
          autoPlay
          className='rounded-xl w-full h-full object-cover'
        ></video>
      )}
      {media.type === MediaType.Image && (
        <img
          className={`rounded-xl w-full h-full object-cover`}
          src={mediaUrl}
          alt='luna-park'
        />
      )}
    </div>
  );
}

export function RoundRobinQuestionGamePlay(
  props: GamePlayProps<RoundRobinQuestionBlock>
): JSX.Element | null {
  const block = useStableBlock(props.block);
  const gameSessionStatus =
    useGameSessionStatus<RoundRobinQuestionBlockGameSessionStatus>();
  const isHost = ClientTypeUtils.isHost(useMyClientType());
  const isLive = useIsLiveGamePlay();
  const me = useMyInstance();
  const currentTime = useGameSessionLocalTimer();

  const [uiState, uiControl] = useGamePlayUITransitionControl();
  const [state, control] = useGamePlayLocalControl(block);
  const [showPrompt, setShowPrompt] = useState(false);
  const gameTimeSec = useMemo(
    () => RoundRobinQuestionUtils.GameTimeSec(block),
    [block]
  );
  const raceMode = block.fields.mode === RoundRobinMode.Race;

  useEffect(() => {
    // in race mode, show prompt after go animation
    if (raceMode) return;
    setShowPrompt(true);
  }, [raceMode]);

  useCountdownPlaySFX(
    gameTimeSec,
    currentTime,
    gameSessionStatus === RoundRobinQuestionBlockGameSessionStatus.GAME_START ||
      gameSessionStatus === RoundRobinQuestionBlockGameSessionStatus.GAME_END
  );
  useTimerRecover(async (status) => {
    if (status === RoundRobinQuestionBlockGameSessionStatus.GAME_START) {
      return gameTimeSec;
    }
  });
  useEmitGamePlayEndedState({
    block,
    gameSessionStatus,
    questions: state.questions,
  });
  useSyncPersistentPointsRevealAnswer(state.showResults);
  useGainPointsAnimationGamePlayTrigger();
  useGamePlayFinishedSubmissionMarker();
  useSubmissionStatusWaitEnder();

  useGamePlayMediaUISync({
    block,
    gameSessionStatus,
    media: state.gamePlayMedia,
    state: uiState,
    control: uiControl,
  });
  const mediaPlayable = useGamePlayMediaPlayable({
    block,
    gameSessionStatus,
    media: state.gamePlayMedia,
    state: uiState,
    custom: () =>
      gameSessionStatus === RoundRobinQuestionBlockGameSessionStatus.GAME_START,
  });
  const fullscreen = state.mediaPlayerLayout === 'fullscreen';

  if (!me) return null;
  if (gameSessionStatus === null || gameSessionStatus === undefined)
    return null;

  const inGame = [
    RoundRobinQuestionBlockGameSessionStatus.GAME_INIT,
    RoundRobinQuestionBlockGameSessionStatus.GAME_START,
    RoundRobinQuestionBlockGameSessionStatus.GAME_END,
  ].includes(gameSessionStatus);

  return (
    <div className={`fixed w-screen h-screen text-white`}>
      <GamePlayLocalControl
        isHost={isHost}
        isLive={isLive}
        block={block}
        gameSessionStatus={gameSessionStatus}
        state={state}
        control={control}
      />

      {state.gamePlayMedia && state.mediaPlayerLayout && (
        <StageBgSingleFrame gamePlayMedia={state.gamePlayMedia} />
      )}
      {uiState.playGoAnimation && (
        <>
          {raceMode ? (
            <RoundRobinRaceGoAnimation
              gameTimeSec={gameTimeSec}
              onFinished={() => setShowPrompt(true)}
            />
          ) : (
            <GoAnimation />
          )}
        </>
      )}
      <GamePlayMediaLayout fullscreen={fullscreen}>
        {state.gamePlayMedia && state.mediaPlayerLayout && (
          <GamePlayMediaPlayer
            gamePlayMedia={state.gamePlayMedia}
            play={mediaPlayable}
            mode={fullscreen || !uiState.mediaEndEffect ? 'full' : 'small'}
            layout={state.mediaPlayerLayout}
          />
        )}
      </GamePlayMediaLayout>
      {inGame && (
        <GamePlayground
          isHost={isHost}
          me={me}
          block={block}
          gameSessionStatus={gameSessionStatus}
          state={state}
          showPrompt={showPrompt}
        />
      )}
      {state.showResults && <Results block={block} />}
    </div>
  );
}
