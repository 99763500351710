import pluralize from 'pluralize';
import {
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { ProfileIndex } from '@lp-lib/crowd-frames-schema';
import {
  assertExhaustive,
  type GuessWhoBlock,
  GuessWhoBlockGameSessionStatus,
} from '@lp-lib/game';

import { useAsyncCall } from '../../../../hooks/useAsyncCall';
import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { useMyInstance } from '../../../../hooks/useMyInstance';
import { ClientTypeUtils, isStaff } from '../../../../types';
import { err2s } from '../../../../utils/common';
import { CrowdFramesAvatar } from '../../../CrowdFrames';
import { GuessWhoIcon } from '../../../icons/Block/GuessWhoBlockIcon';
import { FilledCheckIcon } from '../../../icons/CheckIcon';
import { LayoutAnchor } from '../../../LayoutAnchors/LayoutAnchors';
import { Loading } from '../../../Loading';
import {
  useLastJoinedParticipantByUserId,
  useParticipantByUserIds,
  useParticipantFlags,
} from '../../../Player';
import { useSoundEffect } from '../../../SFX';
import { StageMode, useSelectOnStageMembers } from '../../../Stage';
import { StreamView } from '../../../Stage/StreamView';
import { useIsTeamCaptainScribe } from '../../../TeamAPI/TeamV1';
import { useMyClientType } from '../../../Venue/VenuePlaygroundProvider';
import { useRTCService } from '../../../WebRTC';
import { useGameSessionLocalTimer, useGameSessionStatus } from '../../hooks';
import {
  GamePlaySubmitButton,
  GamePlaySubmitButtonText,
} from '../Common/GamePlay/GamePlaySubmitButton';
import { GamePlayTextTransition } from '../Common/GamePlay/GamePlayTextTransition';
import {
  type GamePlayUIStateControl,
  useCountdownPlaySFX,
  useOneTimePlayGoAnimation,
} from '../Common/GamePlay/GamePlayUtilities';
import { ProgressRing } from '../Common/GamePlay/ProgressRing';
import { useTeamSubmissionStatusAPI } from '../Common/GamePlay/SubmissionStatusProvider';
import {
  playAnims,
  type TransitionInfo,
  useAnimationExecutorRunner,
  xform,
} from '../Common/GamePlay/useBlockAnimationExecutorRunner';
import {
  useCurrMatchPrompt,
  useCurrMatchState,
  useCurrMatchVotesByChoice,
  useGeneratedMatchPrompts,
  useGuessWhoGamePlayAPI,
  useGuessWhoPrompt,
  useGuessWhoShowGuessers,
  useMySubmission,
  useMyTeamSubmissionProgressSummary,
  usePlayerSubmissionSummary,
} from './GuessWhoProvider';
import { TIME_TO_GUESS_WHO_DURATION_SEC } from './utils';
import { uncheckedIndexAccess_UNSAFE } from '../../../../utils/uncheckedIndexAccess_UNSAFE';

function Layout(props: {
  children?: ReactNode;
  styles?: Partial<{
    bg: string;
  }>;
}) {
  return (
    <div
      className={`
        relative
        w-full min-w-194 h-full 
        ${props.styles?.bg ?? 'bg-black bg-opacity-80'} 
        rounded-xl
        flex flex-col items-center 
    `}
    >
      {props.children}
    </div>
  );
}

const longDuration = 240;

function PromptResponsePhase(props: {
  block: GuessWhoBlock;
  uiControl: GamePlayUIStateControl;
  counting?: boolean;
  ended?: boolean;
}): JSX.Element | null {
  const { block, uiControl, ended, counting } = props;
  const me = useMyInstance();

  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [promptTimeSec] = useState(block.fields.promptTimeSec);
  const durationFormattedMMSS = promptTimeSec >= longDuration;
  const api = useGuessWhoGamePlayAPI();
  const prompt = useGuessWhoPrompt();
  const mySubmission = useMySubmission();
  const teamProgress = useMyTeamSubmissionProgressSummary();
  const submitted = !!mySubmission;
  const [done, setDone] = useState(false);

  const time = useGameSessionLocalTimer();
  const remainingTime = ended ? 0 : time ?? 0;
  useCountdownPlaySFX(promptTimeSec, remainingTime, true);
  useOneTimePlayGoAnimation(uiControl, () => remainingTime > 0 && !!counting);

  // recover submission, this can happen if player refreshed the page after submitting
  useEffect(() => {
    if (!textAreaRef.current || !mySubmission || done) return;
    textAreaRef.current.value = mySubmission.response;
  }, [mySubmission, done]);

  const {
    state: { transformed: state },
    error,
    call: submit,
  } = useAsyncCall(
    useLiveCallback(async () => {
      if (!me || !textAreaRef.current || !textAreaRef.current.value) return;
      const submission = await api.submitResponse(
        textAreaRef.current.value,
        me.firstName ?? me.username
      );
      setDone(true);
      return submission;
    })
  );

  // submit when times up
  useEffect(() => {
    if (!ended || submitted) return;
    submit();
  }, [ended, submit, submitted]);

  const isTeamCaptain = useIsTeamCaptainScribe(me?.teamId, me?.clientId);
  const submissionStatusAPI = useTeamSubmissionStatusAPI();

  // mark submitted when all team members created their drawings
  useEffect(() => {
    if (
      !isTeamCaptain ||
      !teamProgress ||
      teamProgress.numOfSubmissions !== teamProgress.numOfPlayers
    ) {
      return;
    }
    submissionStatusAPI.markSubmitted();
  }, [isTeamCaptain, submissionStatusAPI, teamProgress]);

  const onClick = async () => {
    await submit();
  };

  if (isStaff(me) || !prompt) return null;

  const submittable =
    counting && !ended && !submitted && !!time && !state.isRunning;
  const promptStyle = block.fields.introMedia
    ? 'text-xl mt-4'
    : 'text-2xl xl:text-3.5xl xl:leading-10';
  const gradient = submitted
    ? `bg-gradient-to-tr from-primary-start to-primary-end`
    : `bg-gradient-to-r from-gamecard-btn-start via-white to-gamecard-btn-end`;
  const justify = block.fields.introMedia ? 'justify-end' : 'justify-center';

  return (
    <Layout>
      <ProgressRing
        className='absolute left-0 top-0 transform -translate-x-1/2 -translate-y-1/2'
        currentTime={remainingTime}
        textClassName={durationFormattedMMSS ? 'text-lg' : 'text-xl'}
        totalTime={promptTimeSec}
        withPingAnimations
        durationFormattedMMSS={durationFormattedMMSS}
      />
      <div className='flex flex-col items-center justify-center w-full h-full'>
        <div
          className={`flex-1 w-full px-12 xl:px-24 py-3 xl:py-6 flex flex-col items-center ${justify} text-center`}
        >
          <div className='w-full line-clamp-5'>
            <LayoutAnchor
              id={'gameplay-question-text-anchor'}
              className='w-full'
              layoutReportDelayMs={0}
            />
            <div className={`${promptStyle} font-bold text-white`}>
              {prompt.text}
            </div>
          </div>
        </div>

        <div className='flex-none w-full px-22 xl:px-36 py-1 flex flex-col items-center justify-center'>
          <textarea
            ref={textAreaRef}
            className='h-30 py-2 px-2.5 resize-none mb-0 scrollbar field text-base'
            placeholder='Enter your answer here'
            disabled={!submittable}
          />
          <div
            className={`-mt-2 ${counting || ended ? 'visible' : 'invisible'}`}
          >
            <GamePlaySubmitButton onClick={onClick} disabled={!submittable}>
              <GamePlaySubmitButtonText style={{ gradient }}>
                {state.isRunning ? (
                  <>Submitting</>
                ) : (
                  <div>{submitted ? 'Submitted!' : 'Submit'}</div>
                )}
              </GamePlaySubmitButtonText>
            </GamePlaySubmitButton>
          </div>
          <div className='h-4.5'>
            {error && (
              <div className='text-sms text-red-002'>{err2s(error)}</div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}

function TimeToGuessWho(): JSX.Element {
  const [transitionInfo, setTransitionInfo] = useState<TransitionInfo>({
    state: 'running',
    durationMs: TIME_TO_GUESS_WHO_DURATION_SEC * 1000,
  });

  const onEnd = useCallback(async () => {
    setTransitionInfo({
      state: 'ended',
      durationMs: 0,
    });
  }, []);

  return (
    <div className='fixed inset-0'>
      <GamePlayTextTransition
        text='Time to Guess Who!'
        transitionInfo={transitionInfo}
        onEnd={onEnd}
      />
    </div>
  );
}

const MAX_AVATARS_PER_BUTTON = 5;

function GuessWhoButton(props: {
  playerId: string;
  playerName: string;
  guesserPlayerIds: string[];
  disabled?: boolean;
  myGuess?: boolean;
}): JSX.Element {
  const api = useGuessWhoGamePlayAPI();
  const showGuessers = useGuessWhoShowGuessers();
  const guessers = useParticipantByUserIds(props.guesserPlayerIds, true);

  const onClick = useLiveCallback(() => {
    api.submitGuess(props.playerId);
  });

  const crowdFrames = useMemo(() => {
    if (!showGuessers) return null;

    const guessersToShow = guessers.slice(0, MAX_AVATARS_PER_BUTTON);
    const remaining = guessers.length - MAX_AVATARS_PER_BUTTON;

    return (
      <div className='vh-sm:hidden absolute -top-7.5 left-1 right-1 flex items-center overflow-hidden'>
        <div className='flex items-center -space-x-1'>
          {guessersToShow.map((guesser) => (
            <div
              key={guesser.id}
              className='w-8 h-8 vh-sm:w-8 vh-sm:h-8 lp-sm:w-10 lp-sm:h-10 relative'
            >
              <CrowdFramesAvatar
                participant={guesser}
                profileIndex={ProfileIndex.wh100x100fps8}
                enablePointerEvents={true}
              />
            </div>
          ))}
        </div>
        {remaining > 0 && <div className='pl-1 font-bold'>+{remaining}</div>}
      </div>
    );
  }, [guessers, showGuessers]);

  return (
    <div className={`relative ${showGuessers ? 'mt-7.5 vh-sm:mt-0' : 'mt-0'} `}>
      {crowdFrames}
      <button
        type='button'
        className={`
          btn btn-secondary
          w-32 h-12 vh-sm:w-32 vh-sm:h-12 lp-sm:w-45 lp-sm:h-12
          border ${props.myGuess ? 'border-tertiary' : 'border-transparent'}
          text-center truncate ${
            props.myGuess ? 'text-tertiary' : 'text-white'
          } px-2
        `}
        disabled={props.disabled}
        onClick={onClick}
      >
        {props.playerName}
      </button>
    </div>
  );
}

function GuessWhoButtons(): JSX.Element | null {
  const currentMatchState = useCurrMatchState();
  const currentMatchPrompt = useCurrMatchPrompt();
  const me = useMyInstance();
  const playerId = me?.id;
  const isHost = ClientTypeUtils.isHost(useMyClientType());
  if (!currentMatchPrompt || !playerId) return null;

  // we need to invert this map.
  const votes = currentMatchState.votes ?? {};
  const votesByPlayerId = Object.entries(votes).reduce(
    (acc, [playerId, vote]) => {
      if (!acc[vote]) acc[vote] = [];
      acc[vote].push(playerId);
      return acc;
    },
    uncheckedIndexAccess_UNSAFE({})
  );

  const myVote = votes[playerId];
  const buttons = currentMatchPrompt.choices.map((choice) => {
    return (
      <GuessWhoButton
        key={choice.playerId}
        guesserPlayerIds={votesByPlayerId[choice.playerId] ?? []}
        disabled={
          isHost ||
          currentMatchState.phase !== 'vote' ||
          currentMatchPrompt.playerId === playerId ||
          !!myVote
        }
        myGuess={
          // allow the host to see the correct choice.
          isHost
            ? choice.playerId === currentMatchPrompt.playerId
            : choice.playerId === myVote
        }
        {...choice}
      />
    );
  });

  return (
    <div className='w-full flex flex-wrap justify-center gap-3 xl:gap-7.5'>
      {buttons}
    </div>
  );
}

function PlayerResponse(props: { noIcon?: boolean }): JSX.Element | null {
  const currentMatchPrompt = useCurrMatchPrompt();
  if (!currentMatchPrompt) return null;

  const response = currentMatchPrompt.submission.response;
  const textSize = response.length <= 80 ? 'text-3.5xl' : 'text-xl';
  return (
    <div className='w-full flex items-center justify-center -space-x-2'>
      {!props.noIcon && (
        <div className='relative flex-none flex items-end justify-center w-[70px] h-[70px] rounded-full bg-[#8F00FF] overflow-hidden'>
          <GuessWhoIcon className='flex-none w-16 h-16 fill-current text-black -mb-1' />
        </div>
      )}
      <div
        className={`${textSize} text-tertiary font-bold bg-black rounded-lg py-2.5 pl-7 pr-5 max-w-full`}
        style={{
          boxShadow: '-6px 6px 0px 0px #303436',
        }}
      >
        <div className='line-clamp-6 py-1'>{`“${response}”`}</div>
      </div>
    </div>
  );
}

type NamedElements = 'response' | 'response-placeholder' | 'backdrop';

function* SelectWhoSaidThisAnimation(
  reg: Map<NamedElements, HTMLElement | null>,
  totalDurationMs: number
) {
  const response = reg.get('response');
  const responsePlaceholder = reg.get('response-placeholder');
  const backdrop = reg.get('backdrop');

  if (!response || !responsePlaceholder || !backdrop) return;

  const a: Animation[] = [];

  const responseRect = response.getBoundingClientRect();
  const responsePlaceholderRect = responsePlaceholder.getBoundingClientRect();
  const translateX = responsePlaceholderRect.left - responseRect.left;
  const translateY = responsePlaceholderRect.top - responseRect.top;
  a.push(
    response.animate(
      [
        {
          transform: 'none',
        },
        {
          transform: xform({
            translateX: `${translateX}px`,
            translateY: `${translateY}px`,
          }),
        },
      ],
      {
        duration: totalDurationMs / 2,
        fill: 'both',
        easing: 'ease',
        delay: 1000,
      }
    )
  );

  a.push(
    backdrop.animate(
      [
        {
          opacity: 0,
        },
        {
          opacity: 1,
        },
      ],
      { duration: totalDurationMs, fill: 'both', easing: 'ease', delay: 1000 }
    )
  );

  playAnims(...a);
  yield Promise.all(a.map((a) => a.finished));
  a.length = 0;
}

function SelectWhoSaidThis(): JSX.Element | null {
  const prompt = useGuessWhoPrompt();
  const state = useCurrMatchState();
  const questions = useGeneratedMatchPrompts();
  const { play: playTextAppearSFX } = useSoundEffect('guessWhoTextAppear');
  useEffect(() => {
    if (!prompt || state.phase !== 'vote') return;
    playTextAppearSFX();
  }, [playTextAppearSFX, prompt, state.phase]);

  const [transitionInfo, setTransitionInfo] = useState<TransitionInfo>({
    state: 'running',
    durationMs: 2000,
  });

  const onEnd = useCallback(async () => {
    setTransitionInfo({
      state: 'ended',
      durationMs: 0,
    });
  }, []);

  const { multiRef } = useAnimationExecutorRunner(
    'select-who-said-this',
    transitionInfo,
    SelectWhoSaidThisAnimation,
    onEnd,
    'select-who-said-this'
  );

  if (!prompt || state.phase !== 'vote') return null;

  const totalQuestions = questions?.sequence.length ?? 0;
  const index = state.index + 1;
  return (
    <Layout styles={{ bg: 'bg-transparent' }}>
      <div
        ref={(el) => multiRef('backdrop', el)}
        className='flex flex-col items-center w-full h-full bg-black bg-opacity-80 rounded-xl'
      >
        <div className='pt-7.5 font-medium text-xl'>Select Who Said This</div>
        <div className='pt-2.5 px-7.5 text-sms text-center'>{prompt.text}</div>
        <div className='w-full pt-4 px-7.5'>
          <div
            ref={(el) => multiRef('response-placeholder', el)}
            className={`${
              transitionInfo?.state === 'ended' ? 'visible' : 'invisible'
            }`}
          >
            <PlayerResponse />
          </div>
        </div>
        <div className='flex-1 min-h-0 w-full flex items-center justify-center my-4 px-7.5 overflow-x-hidden overflow-y-scroll scrollbar'>
          <GuessWhoButtons />
        </div>
        {totalQuestions > 0 && (
          <div className='absolute top-4.5 right-5 font-bold'>
            {index} of {totalQuestions}
          </div>
        )}
      </div>

      {transitionInfo?.state !== 'ended' && (
        <div className='absolute inset-0 flex items-center justify-center'>
          <div
            ref={(el) => multiRef('response', el)}
            className='absolute animate-fade-in left-7.5 right-7.5'
          >
            <PlayerResponse />
          </div>
        </div>
      )}
    </Layout>
  );
}

function RevealTransition(): JSX.Element | null {
  const prompt = useCurrMatchPrompt();
  const state = useCurrMatchState();
  const { play: playDrumRollSFX } = useSoundEffect('guessWhoDrumRoll');
  useEffect(() => {
    if (!prompt || state.phase !== 'reveal-transition') return;
    playDrumRollSFX();
  }, [playDrumRollSFX, prompt, state.phase]);

  if (!prompt || state.phase !== 'reveal-transition') return null;

  return (
    <Layout>
      <div className='flex flex-col items-center w-full h-full gap-2.5'>
        <div className='pt-4 px-15.5'>
          <PlayerResponse noIcon />
        </div>
        <div className='w-full h-full flex items-center justify-center gap-8'>
          <div className='relative w-65 h-65 rounded-3.5xl bg-[#8F00FF] overflow-hidden'>
            <div className='absolute -bottom-2.5'>
              <GuessWhoIcon className='w-64 h-64 fill-current text-black' />
            </div>
          </div>
          <div className='text-white text-3.5xl font-bold'>
            This response
            <br />
            belongs to...
          </div>
        </div>
      </div>
    </Layout>
  );
}

function SubmitterView(): JSX.Element | null {
  const prompt = useCurrMatchPrompt();
  const rtcService = useRTCService('stage');
  const participant = useLastJoinedParticipantByUserId(prompt?.playerId);
  const flags = useParticipantFlags(participant?.clientId);
  const stageMembers = useSelectOnStageMembers(StageMode.BLOCK_CONTROLLED);
  const player = stageMembers.find((m) => m.id === participant?.clientId);

  if (!player) return null;

  return (
    <StreamView
      className={`w-full h-full ${
        flags?.onStageMuted
          ? 'rounded-full border-blue-700 border-4'
          : `rounded-xl`
      } drop-shadow-lg`}
      member={player}
      rtcService={rtcService}
      disableRemove
    />
  );
}

const MAX_CORRECT_GUESSERS = 8;

function RevealTimer(): JSX.Element | null {
  const state = useCurrMatchState();
  const time = useGameSessionLocalTimer() ?? 0;

  if (state.phase !== 'reveal' || time > 30) return <div className='h-4.5' />;
  return (
    <div className='h-4.5 flex items-baseline gap-px text-icon-gray text-sms animate-fade-in-up'>
      {time} seconds remaining
    </div>
  );
}

function StageControl(): JSX.Element | null {
  const api = useGuessWhoGamePlayAPI();
  const prompt = useCurrMatchPrompt();
  const state = useCurrMatchState();
  const me = useMyInstance();

  const onClick = useLiveCallback(() => {
    api.endReveal(state.index);
  });

  if (!prompt || !me || prompt.playerId !== me.id) return null;

  return (
    <div className='flex flex-col items-center gap-2'>
      <div className='self-start'>Take 30s to explain...</div>
      <button
        type='button'
        className='btn-secondary w-50 h-15 flex items-center justify-center gap-2.5'
        onClick={onClick}
        disabled={state.phase === 'done'}
      >
        {state.phase === 'done' ? (
          <Loading text='' />
        ) : (
          <>
            <FilledCheckIcon className='w-7.5 h-7.5 fill-current' />
            I’m Done
          </>
        )}
      </button>
      <RevealTimer />
    </div>
  );
}

function Reveal(): JSX.Element | null {
  const prompt = useCurrMatchPrompt();
  const votes = useCurrMatchVotesByChoice();
  const { play: playDrumHitSFX } = useSoundEffect('guessWhoDrumHit');
  useEffect(() => {
    playDrumHitSFX();
  }, [playDrumHitSFX]);

  const correctGuessers = prompt?.playerId ? votes[prompt.playerId] ?? [] : [];
  const guessers = useParticipantByUserIds(correctGuessers, true);
  const crowdFrames = useMemo(() => {
    if (guessers.length === 0) return null;

    const guessersToShow = guessers.slice(0, MAX_CORRECT_GUESSERS);
    const remaining = guessers.length - MAX_CORRECT_GUESSERS;

    return (
      <div className='flex item-center gap-1'>
        {guessersToShow.map((guesser) => (
          <div key={guesser.id} className='w-10 h-10 relative'>
            <CrowdFramesAvatar
              participant={guesser}
              profileIndex={ProfileIndex.wh100x100fps8}
              enablePointerEvents={true}
            />
          </div>
        ))}
        {remaining > 0 && (
          <div className='h-10 font-bold flex items-center'>+3</div>
        )}
      </div>
    );
  }, [guessers]);

  if (!prompt) return null;

  return (
    <Layout>
      <div className='flex flex-col items-center w-full h-full gap-2.5'>
        <div className='pt-4 px-15.5'>
          <PlayerResponse noIcon />
        </div>
        <div className='w-full h-full flex items-center justify-center gap-8'>
          <div className='relative w-65 h-65 rounded-3.5xl'>
            <SubmitterView />
          </div>
          <div className='h-65 flex flex-col items-start justify-between'>
            <div className='flex-shrink h-4' />
            <div className='flex-none'>
              <div className='text-white italic'>This response belongs to</div>
              <div className='text-white text-3.5xl font-bold'>
                {prompt.playerName}
              </div>
            </div>
            <div className='flex-none'>
              <StageControl />
            </div>
          </div>
        </div>

        <div className='vh-sm:hidden w-full px-7.5 pb-7.5 flex flex-col items-center justify-center gap-2.5'>
          {correctGuessers.length === 0 ? (
            <div className='font-medium text-xl'>No one guessed correctly!</div>
          ) : crowdFrames === null ? (
            <div className='font-medium text-xl'>
              {correctGuessers.length}{' '}
              {pluralize('player', correctGuessers.length)} guessed correctly!
            </div>
          ) : (
            <>
              <div className='font-medium text-xl'>
                Here’s who guessed correctly!
              </div>
              {crowdFrames}
            </>
          )}
        </div>
      </div>
    </Layout>
  );
}

function NoSubmissions(): JSX.Element | null {
  return (
    <Layout>
      <div className='flex flex-col items-center justify-center w-full h-full'>
        <div className='font-medium text-xl'>No Submissions!</div>
        <div className='text-sms text-icon-gray'>Auto-progressing...</div>
      </div>
    </Layout>
  );
}

function MatchPromptPhase(): JSX.Element | null {
  const generatedMatchPrompts = useGeneratedMatchPrompts();
  const prompt = useCurrMatchPrompt();
  const state = useCurrMatchState();

  if (!generatedMatchPrompts || generatedMatchPrompts.sequence.length === 0) {
    return <NoSubmissions />;
  }

  if (!prompt) return null;

  switch (state.phase) {
    case 'vote':
      return <SelectWhoSaidThis />;
    case 'reveal-transition':
      return <RevealTransition />;
    case 'reveal':
      return <Reveal />;
    case 'done':
      return <Reveal />;
    default:
      assertExhaustive(state.phase);
      break;
  }
  return null;
}

function PlayerPromptSubmissions(_props: {
  block: GuessWhoBlock;
}): JSX.Element {
  const playerSubmissions = usePlayerSubmissionSummary();

  return (
    <Layout>
      <div className='w-full h-full flex flex-col'>
        <div
          className='uppercase font-bold italic text-white'
          style={{
            textShadow: '4px 3px 0px rgba(0, 0, 0, 0.25)',
          }}
        >
          submissions
        </div>

        <div className='flex-1 flex overflow-hidden rounded bg-lp-black-001 border border-secondary'>
          <div className='flex-1 overflow-y-scroll overflow-x-none scrollbar'>
            <div className='flex flex-col gap-1'>
              <div className='h-8 px-4 grid grid-cols-3 items-center bg-lp-black-001'>
                <div className='col-span-1 font-bold text-xs truncate tracking-wide'>
                  Player Name
                </div>
                <div className='col-span-2 font-bold text-xs truncate tracking-wide'>
                  Response
                </div>
              </div>
              {Object.entries(playerSubmissions).map(
                ([playerId, submission]) => (
                  <div
                    key={playerId}
                    className='h-8 px-4 grid grid-cols-3 items-center bg-lp-black-002'
                  >
                    <div className='col-span-1 font-bold text-2xs truncate'>
                      {submission?.playerName}
                    </div>
                    <div className='col-span-2 font-bold text-2xs truncate'>
                      {submission?.response}
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export function GuessWhoPlayground(props: {
  block: GuessWhoBlock;
  uiControl: GamePlayUIStateControl;
  isHost: boolean;
}): JSX.Element | null {
  const { block, uiControl, isHost } = props;
  const gss = useGameSessionStatus<GuessWhoBlockGameSessionStatus>();

  switch (gss) {
    case GuessWhoBlockGameSessionStatus.LOADED:
      return null;
    case GuessWhoBlockGameSessionStatus.INTRO:
      return null;
    case GuessWhoBlockGameSessionStatus.PROMPT_INIT:
      return isHost ? (
        <PlayerPromptSubmissions block={block} />
      ) : (
        <PromptResponsePhase block={block} uiControl={uiControl} />
      );
    case GuessWhoBlockGameSessionStatus.PROMPT_COUNTING:
      return isHost ? (
        <PlayerPromptSubmissions block={block} />
      ) : (
        <PromptResponsePhase block={block} uiControl={uiControl} counting />
      );
    case GuessWhoBlockGameSessionStatus.PROMPT_DONE:
      return isHost ? (
        <PlayerPromptSubmissions block={block} />
      ) : (
        <PromptResponsePhase block={block} uiControl={uiControl} ended />
      );
    case GuessWhoBlockGameSessionStatus.MATCH_PROMPT_INIT:
      return <TimeToGuessWho />;
    case GuessWhoBlockGameSessionStatus.MATCH_PROMPT_COUNTING:
      return <MatchPromptPhase />;
    case GuessWhoBlockGameSessionStatus.MATCH_PROMPT_DONE:
      return <MatchPromptPhase />;
    case GuessWhoBlockGameSessionStatus.RESULTS:
    case GuessWhoBlockGameSessionStatus.SCOREBOARD:
    case GuessWhoBlockGameSessionStatus.END:
    case null:
    case undefined:
      break;
    default:
      assertExhaustive(gss);
      break;
  }

  return null;
}
