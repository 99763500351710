import { assertExhaustive, BlockType } from '@lp-lib/game';

import {
  useGameSessionBlock,
  usePauseOndSubmissionTimer,
  useSubscribeGameSessionTeamData,
} from '../../../hooks';
import { AIChatBlockGamePlay } from '../../AIChat';
import CreativePromptBlockGamePlay from '../../CreativePrompt/CreativePromptBlockGamePlay';
import { DrawingPromptBlockGamePlay } from '../../DrawingPrompt';
import { GuessWhoBlockGamePlay } from '../../GuessWho/GuessWhoBlockGamePlay';
import { HeadToHeadBlockGamePlay } from '../../HeadToHead';
import { HiddenPictureBlockGamePlay } from '../../HiddenPicture/HiddenPictureBlockGamePlay';
import { IcebreakerBlockGamePlay } from '../../Icebreaker';
import { InstructionBlockGamePlay } from '../../Instruction';
import { JeopardyBlockGamePlay } from '../../Jeopardy/JeopardyBlockGamePlay';
import { MarketingBlockGamePlay } from '../../Marketing/MarketingBlockGamePlay';
import { MemoryMatchGamePlay } from '../../MemoryMatch';
import { MultipleChoiceBlockGamePlay } from '../../MultipleChoice';
import { OverRoastedBlockGamePlay } from '../../OverRoasted';
import { PuzzleBlockGamePlay } from '../../Puzzle';
import { QuestionBlockGamePlay } from '../../Question/QuestionBlockGamePlay';
import { RandomizerBlockGamePlay } from '../../Randomizer';
import { RapidBlockGamePlay } from '../../Rapid/RapidBlockGamePlay';
import { RoundRobinQuestionGamePlay } from '../../RoundRobinQuestion';
import { ScoreboardBlockGamePlay } from '../../Scoreboard/ScoreboardBlockGamePlay';
import { SpotlightBlockGamePlay } from '../../Spotlight';
import { SpotlightBlockV2GamePlay } from '../../SpotlightV2';
import { TeamRelayBlockGamePlay } from '../../TeamRelay';
import { TitleBlockV2GamePlay } from '../../TitleV2';

export function GamePlay(): JSX.Element | null {
  const gameSessionBlock = useGameSessionBlock();

  useSubscribeGameSessionTeamData();
  usePauseOndSubmissionTimer();

  if (gameSessionBlock === null) return null;

  const blockType = gameSessionBlock.type;

  switch (blockType) {
    case BlockType.CREATIVE_PROMPT:
      return (
        <CreativePromptBlockGamePlay gameSessionBlock={gameSessionBlock} />
      );
    case BlockType.RAPID:
      return <RapidBlockGamePlay gameSessionBlock={gameSessionBlock} />;
    case BlockType.QUESTION:
      return <QuestionBlockGamePlay gameSessionBlock={gameSessionBlock} />;
    case BlockType.SCOREBOARD:
      return <ScoreboardBlockGamePlay gameSessionBlock={gameSessionBlock} />;
    case BlockType.SPOTLIGHT:
      return <SpotlightBlockGamePlay gameSessionBlock={gameSessionBlock} />;
    case BlockType.SPOTLIGHT_V2:
      return <SpotlightBlockV2GamePlay gameSessionBlock={gameSessionBlock} />;
    case BlockType.TEAM_RELAY:
      return <TeamRelayBlockGamePlay block={gameSessionBlock} />;
    case BlockType.RANDOMIZER:
      return <RandomizerBlockGamePlay />;
    case BlockType.MULTIPLE_CHOICE:
      return (
        <MultipleChoiceBlockGamePlay gameSessionBlock={gameSessionBlock} />
      );
    case BlockType.MEMORY_MATCH:
      return <MemoryMatchGamePlay block={gameSessionBlock} />;
    case BlockType.PUZZLE:
      return <PuzzleBlockGamePlay block={gameSessionBlock} />;
    case BlockType.ROUND_ROBIN_QUESTION:
      return (
        <RoundRobinQuestionGamePlay
          key={gameSessionBlock.id}
          block={gameSessionBlock}
        />
      );
    case BlockType.TITLE_V2:
      return (
        <TitleBlockV2GamePlay
          key={gameSessionBlock.id}
          block={gameSessionBlock}
        />
      );
    case BlockType.INSTRUCTION:
      return (
        <InstructionBlockGamePlay
          key={gameSessionBlock.id}
          block={gameSessionBlock}
        />
      );
    case BlockType.OVERROASTED:
      return (
        <OverRoastedBlockGamePlay
          key={gameSessionBlock.id}
          block={gameSessionBlock}
        />
      );
    case BlockType.DRAWING_PROMPT:
      return (
        <DrawingPromptBlockGamePlay
          key={gameSessionBlock.id}
          block={gameSessionBlock}
        />
      );
    case BlockType.HIDDEN_PICTURE:
      return (
        <HiddenPictureBlockGamePlay
          key={gameSessionBlock.id}
          block={gameSessionBlock}
        />
      );
    case BlockType.AI_CHAT:
      return (
        <AIChatBlockGamePlay
          key={gameSessionBlock.id}
          block={gameSessionBlock}
        />
      );
    case BlockType.GUESS_WHO:
      return (
        <GuessWhoBlockGamePlay
          key={gameSessionBlock.id}
          block={gameSessionBlock}
        />
      );
    case BlockType.ICEBREAKER:
      return (
        <IcebreakerBlockGamePlay
          key={gameSessionBlock.id}
          block={gameSessionBlock}
        />
      );
    case BlockType.MARKETING:
      return (
        <MarketingBlockGamePlay
          key={gameSessionBlock.id}
          block={gameSessionBlock}
        />
      );
    case BlockType.JEOPARDY:
      return (
        <JeopardyBlockGamePlay
          key={gameSessionBlock.id}
          block={gameSessionBlock}
        />
      );
    case BlockType.HEAD_TO_HEAD:
      return (
        <HeadToHeadBlockGamePlay
          key={gameSessionBlock.id}
          block={gameSessionBlock}
        />
      );
    default: {
      assertExhaustive(blockType);
      return null;
    }
  }
}
