export type GreenScreenSettingsV0 = {
  enabled: boolean;
  sharpPrefixedKeyColor: string;
  similarity: number;
  smoothness: number;
  spill: number;
  maskPct: RelativeRect;
};

export type GLCompatChromakeyParameters = {
  // r,g,b: [0-1, 0-1, 0-1] // e.g. percentage of 255
  keyColor: readonly [number, number, number];
  similarity: number;
  smoothness: number;
  spill: number;
};

function hexColorToRGBPct(sharpLeadingHex: string) {
  const noSharp = sharpLeadingHex.match(/^#([0-9a-f]{6})$/i)?.[1];
  return [
    parseInt(noSharp ? noSharp.substr(0, 2) : '0', 16) / 255,
    parseInt(noSharp ? noSharp.substr(2, 2) : '0', 16) / 255,
    parseInt(noSharp ? noSharp.substr(4, 2) : '0', 16) / 255,
  ] as const;
}

export const GreenScreenUIValuesRange = { min: 1, max: 1000 } as const;

/**
 * Map a 1-1000 ui value to 0-1 float. Without accounting for the range map,
 * values at 0 or 1 will produce incorrect results
 */
function mapRange1000ToGL(value: number) {
  return (
    (value - GreenScreenUIValuesRange.min) /
    (GreenScreenUIValuesRange.max - GreenScreenUIValuesRange.min)
  );
}
export class GreenScreenValuesUtils {
  /** GreenScreenSettings store values 1-1000, but webgl needs floats, 0-1. */
  static ToGLCompat(
    settings: Omit<GreenScreenSettingsV0, 'enabled' | 'maskPct'>
  ): GLCompatChromakeyParameters {
    return {
      keyColor: hexColorToRGBPct(settings.sharpPrefixedKeyColor),
      similarity: mapRange1000ToGL(settings.similarity),
      smoothness: mapRange1000ToGL(settings.smoothness),
      spill: mapRange1000ToGL(settings.spill),
    };
  }
}

export type RelativeRect = {
  left: number;
  top: number;
  right: number;
  bottom: number;
};

export type BoundingBox = {
  width: number;
  height: number;
  x: number;
  y: number;
};

type FitOperationKind = 'cover' | 'contain' | 'natural-center';

export type BoundingBoxSettings = {
  box: BoundingBox;
  fit: FitOperationKind;
};

type StageSettings = {
  enabled: boolean;
  id: string | null;
};

type PodiumSettings = {
  enabled: boolean;
  id: string | null;
};

type IntroSettings = {
  enabled: boolean;
  id: string | null;
};

type OutroSettings = {
  enabled: boolean;
  id: string | null;
};

/**
 * DTO model use for effects settings stored on blocks. These should always be converted to VideoEffectsSettings
 * with VideoEffectsSettingsUtils.FromBlock().
 */
export type VideoEffectsSettingsBlock = {
  greenScreen: GreenScreenSettingsV0;
  boundingBox: BoundingBoxSettings;
  stage: StageSettings;
  podium: PodiumSettings;
};

/**
 * DTO used for data transfer to/from the user's localStorage
 */
export type VideoEffectsSettingsUser = {
  greenScreen: GreenScreenSettingsV0;
  boundingBox: BoundingBoxSettings;
  stage: StageSettings;
  podium: PodiumSettings;
  intro: IntroSettings;
  outro: OutroSettings;
};

function copy<T>(incoming: T): T {
  return JSON.parse(JSON.stringify(incoming));
}

// NOTE(drew): It doesn't make a lot of sense to put these defaults here. But
// unfortunately enough areas of the app need them that it causes
// circular/cyclic dependencies that prevent the app from properly booting. The
// Main cycle, currently, is caused by the use of an index.ts re-rexport from
// web-app/src/components/GreenScreenSettings. GreenScreenSettings has an
// indirect reference to the gameSessionHooks/Actions.

// Example cycle 1: src/components/Game/store/ondVideoMixerGameUtils.ts ->
// src/components/VideoMixer/index.ts ->
// src/components/VideoMixer/TrackInitConfigBuilder.ts ->
// src/components/GreenScreenSettings/index.ts ->
// src/components/GreenScreenSettings/Block.tsx -> src/hooks/useLoadGame.ts ->
// src/components/Game/hooks/index.ts ->
// src/components/Game/hooks/gameSessionHooks.ts ->
// src/components/Game/GameUtils.ts -> src/components/Game/Blocks/Shared.tsx ->
// src/components/Game/Blocks/TeamRelay/utils.ts ->
// src/components/GameMode/Provider.tsx ->
// src/components/Game/store/gameSessionActions/index.ts ->
// src/components/Game/store/gameSessionActions/core.ts ->
// src/components/Game/store/gameSessionStore.ts ->
// src/components/Game/store/ondVideoMixerGameUtils.ts

// Example cycle 2: src/components/Game/store/gameSessionActions/ond.ts ->
// src/services/webrtc/index.ts -> src/services/webrtc/agora.ts ->
// src/services/webrtc/camera-video-mixer.ts ->
// src/components/VideoMixer/index.ts ->
// src/components/VideoMixer/TrackInitConfigBuilder.ts ->
// src/components/GreenScreenSettings/index.ts ->
// src/components/GreenScreenSettings/Block.tsx -> src/hooks/useLoadGame.ts ->
// src/components/Game/hooks/index.ts ->
// src/components/Game/hooks/gameSessionHooks.ts ->
// src/components/Game/GameUtils.ts -> src/components/Game/Blocks/Shared.tsx ->
// src/components/Game/Blocks/TeamRelay/utils.ts ->
// src/components/GameMode/Provider.tsx ->
// src/components/Game/store/gameSessionActions/index.ts ->
// src/components/Game/store/gameSessionActions/ond.ts

const GreenScreenSettingsDefaults: GreenScreenSettingsV0 = {
  enabled: false,
  sharpPrefixedKeyColor: '#00ff00',
  similarity: 32,
  smoothness: 80,
  spill: 1,
  maskPct: {
    left: 0.25,
    top: 0.16,
    right: 0.25,
    bottom: 0,
  },
} as const;

export function defaultGreenScreenSettings(): GreenScreenSettingsV0 {
  return copy(GreenScreenSettingsDefaults);
}

const BoundingBoxSettingsDefaults: BoundingBoxSettings = {
  box: {
    width: 1,
    height: 1,
    x: 0,
    y: 0,
  },
  fit: 'contain' as const,
};

export function defaultBoundingBoxSettings(): BoundingBoxSettings {
  return copy(BoundingBoxSettingsDefaults);
}

function roughlyEqual(a: number, b: number, precision = 0.001): boolean {
  return Math.abs(a - b) <= precision;
}

export function createBoundingBox(from?: BoundingBox): BoundingBox {
  return {
    width: 0,
    height: 0,
    x: 0,
    y: 0,
    ...from,
  };
}

export function compareBoundingBox(a: BoundingBox, b: BoundingBox): boolean {
  return (
    roughlyEqual(a.height, b.height) &&
    roughlyEqual(a.width, b.width) &&
    roughlyEqual(a.x, b.x) &&
    roughlyEqual(a.y, b.y)
  );
}

export function compareBoundingBoxSettings(
  a: BoundingBoxSettings,
  b: BoundingBoxSettings
): boolean {
  return a.fit === b.fit && compareBoundingBox(a.box, b.box);
}
