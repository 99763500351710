import { proxy } from 'comlink';
import { ref } from 'valtio';

import {
  type DtoPlayedBlockEntry,
  type DtoPlayedBlockEntryPlaybackSummary,
  EnumsBlockType,
} from '@lp-lib/api-service-client/public';
import {
  type Block,
  type BlockActionWaitModeConfig,
  type BlockRecording,
  GameSessionUtil,
} from '@lp-lib/game';
import { type Logger } from '@lp-lib/logger-base';

import { type OnDGameAnalytics } from '../../../analytics/game';
import {
  getFeatureQueryParam,
  getFeatureQueryParamArray,
  getFeatureQueryParamNumber,
} from '../../../hooks/useFeatureQueryParam';
import logger from '../../../logger/logger';
import { apiService } from '../../../services/api-service';
import { profileForCustomVideo } from '../../../services/webrtc';
import { SessionMode } from '../../../types';
import { type AbortSignalableRunner } from '../../../utils/AbortSignalableRunner';
import { toDTOEnum } from '../../../utils/api-dto';
import { backoffSleep } from '../../../utils/backoffSleep';
import {
  assertDefinedFatal,
  assertExhaustive,
  err2s,
  once,
  required,
  sleep,
} from '../../../utils/common';
import { ValtioUtils } from '../../../utils/valtio';
import { type BlockId } from '../../GameRecorder/types';
import { createWorkerPoweredLoop } from '../../Loop/createWorkerPoweredLoop';
import { createLoop } from '../../Loop/loop';
import { type useGetStreamSessionId } from '../../Session';
import { type useTownhallAPI } from '../../Townhall';
import { VideoMixer } from '../../VideoMixer';
import { VariableRegistry } from '../../VoiceOver/VariableRegistry';
import { type VoiceOverRegistry } from '../../VoiceOver/VoiceOverRegistry';
import { type useBlockLifecycleRulesEvaluator } from '../Blocks/Common/LifecycleRules/BlockLifecycleRulesEvaluator';
import { type useCreateGameInfoSnapshot } from '../hooks';
import { OndVersionChecks } from '../OndVersionChecks';
import {
  type PlaybackDesc,
  type PlaybackDescItem,
  type PlaybackItemId,
} from '../Playback/intoPlayback';
import { type usePlaybackInfoWriteExtra } from '../Playback/PlaybackInfoProvider';
import {
  end,
  loadGameSession,
  next,
  present,
  replayVideo,
  triggerBlockTitleAnim,
} from '../store/gameSessionActions/core';
import {
  setOndGamePlayResuming,
  setOndGamePlayRunning,
} from '../store/gameSessionActions/ond-state-writers';
import type {
  BlockSession,
  GameSessionOndState,
  GameSessionStore,
} from '../store/gameSessionStore';
import { asBlockId } from './asBlockId';
import { blockTitleAnimationHalfDuration } from './blockTitleAnimationHalfDuration';
import { BlockToVideoMixerTrackMap } from './BlockToVideoMixerTrackMap';
import {
  type BlockPlaybackPlan,
  type BlockPlaybackPlanInit,
  createActionExecutedObserver,
  createBlockPlaybackPlan,
  getOrCreateBlockPlaybackPlan,
  logBlockPlaybackPlan,
  logBlockTickInfo,
  type SecondQuantizedActionMap,
  SecondQuantizedActionMapUtils,
  type SecondQuantizedRecordingAction,
} from './ond-timing';
import {
  type GameSessionOndWaitModeDerivedData,
  type GameSessionOndWaitModeInfo,
  type GameSessionOndWaitModeResumeData,
  type OndPlaybackJump,
  type OndWaitModeExtSignal,
} from './OndPhaseRunnerTypes';
import {
  BlockToPreloadedMediasMap,
  ensureCurrentHostMediaIsScheduled,
  maybePreloadAndScheduleNextHostVideo,
  maybePreloadNextVoiceOvers,
  maybeScheduleOutroHostVideo,
  maybeWaitForOutroVideoToEnd,
  scheduleBlockTrackMap,
  scheduleHostIntroVideo,
} from './ondVideoMixerGameUtils';
import {
  generateV3Recording,
  recordingNeedsPreparation,
} from './recordingGenerator';
import { type GeneratedBlockRecording } from './types';

// NOTE(drew): I wanted to use `unique symbol` here, but unfortunately ran into
// this exact bug:
// https://github.com/microsoft/TypeScript/issues/24506#issuecomment-1278478659
const Shutdown = 'shutdown' as const;

interface BaseContext {
  /**
   * The wall clock elapsed time since the last call to update()
   */
  deltaTimeMs: number;

  /**
   * The fixed update time that each fixedUpdate is called from.
   */
  fixedDeltaTimeMs: number;
}

interface Phase<Context extends BaseContext = BaseContext> {
  readonly log?: Logger;
  readonly name: string;

  /**
   * Is executed by the runner when the phase is _entered_ aka started. This
   * only happens once per instance.
   */
  enter?(context: Context): void | Promise<void>;

  /**
   * Ticks at a constant, even rate, aligned to the runner's `fixedDeltaTimeMs`.
   */
  fixedUpdate?(
    context: Context
  ): Promise<Phase | 'shutdown'> | Phase | 'shutdown';

  /**
   * Ticks at a constant, even rate, aligned to the runner's `maxDeltaTimeMs`.
   * The deltaTimeMs will be the actual wall-clock time since the last update.
   */
  update?(context: Context): Promise<Phase | 'shutdown'> | Phase | 'shutdown';

  /**
   * Is executed by the runner when the phase is _exited_ aka ended. This only
   * happens once per instance. `destroying` will be true if the entire runner
   * is being destroyed, instead of a purposeful phase switch from
   * fixedUpdate/update.
   */
  exit?(context: Context, destroying: boolean): void | Promise<void>;
}

class OndPhaseNotAwake implements Phase {
  name = 'ond-phase-not-awake';
}

interface IPhaseObserver {
  beforeFixedUpdate?: (phaseName: string, deltaTimeMs: number) => void;
  afterFixedUpdate?: (phaseName: string, deltaTimeMs: number) => void;

  beforeUpdate?: (phaseName: string, deltaTimeMs: number) => void;
  afterUpdate?: (phaseName: string, deltaTimeMs: number) => void;

  enter?: (phaseName: string) => void;
  exit?: (phaseName: string) => void;

  fixedUpdateTickAlignmentAssigned?: (
    phaseName: string,
    alignment: number
  ) => void;

  beforeStart?: (phaseName: string) => void;
  afterStart?: (phaseName: string) => void;

  beforeAwake?: (phaseName: string) => void;
  afterAwake?: (phaseName: string) => void;

  beforeShutdown?: (phaseName: string) => void;
  afterShutdown?: (phaseName: string) => void;

  beforeDestroy?: (phaseName: string) => void;
  afterDestroy?: (phaseName: string) => void;
}

class DefaultPhaseObserver implements IPhaseObserver {
  log = logger.scoped('ond-phase-runner');

  enter(name: string) {
    this.log.info(`enter: ${name}`);
  }

  exit(name: string) {
    this.log.info(`exit: ${name}`);
  }

  afterStart(name: string) {
    this.log.info(`started: ${name}`);
  }

  afterAwake(name: string) {
    this.log.info(`awakened: ${name}`);
  }

  afterShutdown(name: string) {
    this.log.info(`shutdown: ${name}`);
  }

  fixedUpdateTickAlignmentAssigned(name: string, alignment: number): void {
    this.log.info(`offset assigned: ${name}, ${alignment}`);
  }

  beforeDestroy(name: string) {
    this.log.info(`beforeDestroy: ${name}`);
  }

  afterDestroy(name: string) {
    this.log.info(`afterDestroy: ${name}`);
  }
}

export class OndPhaseRunner<Context extends BaseContext = BaseContext>
  implements AbortSignalableRunner
{
  private isAwake = false;
  private phase: Phase = new OndPhaseNotAwake();
  private phaseFixedUpdateTickAlignment: null | number = null;
  private lastTickTime = 0;
  private fixedUpdateTickAlignment = 0;
  private loopCanceler: ReturnType<typeof createLoop> | null = null;
  private fixedUpdateRunning = false;
  private updateRunning = 0;
  private finishedPromise = once(this.aborter.signal, 'abort');
  private destroying = false;

  constructor(
    private context: Context,
    private initialPhase: Phase<Context>,
    public aborter = new AbortController(),
    private fixedDeltaTimeMs = 100,
    private maxDeltaTimeMs = 1000 / 30,
    private observer: IPhaseObserver = new DefaultPhaseObserver(),
    private useWorker = getFeatureQueryParam('game-on-demand-tick-use-worker'),
    private loopMethod = getFeatureQueryParamArray(
      'game-on-demand-tick-loop-method'
    )
  ) {
    // Chain the (possibly-external) AbortController
    this.aborter.signal.addEventListener(
      'abort',
      async () => await this.destroy()
    );
  }

  get finished(): Promise<unknown> {
    return this.finishedPromise;
  }

  get phaseName(): string {
    return this.phase.name;
  }

  static CreatedVRefed(
    ...params: ConstructorParameters<typeof OndPhaseRunner>
  ): OndPhaseRunner {
    return ref(new OndPhaseRunner(...params));
  }

  async awake(): Promise<void> {
    if (this.isAwake) return;
    this.observer.beforeAwake?.(this.phaseName);
    await this.toPhase(this.initialPhase);
    this.isAwake = true;
    this.observer.afterAwake?.(this.phaseName);
  }

  async start(): Promise<void> {
    this.observer.beforeStart?.(this.phaseName);

    const updateTime = this.fixedDeltaTimeMs;
    const drawTime = this.maxDeltaTimeMs;

    if (this.useWorker) {
      const loopWorker = createWorkerPoweredLoop();
      await loopWorker.startAccumulated(
        this.loopMethod,
        {
          updateTime,
          drawTime,
          panicAt: 5000,
        },
        proxy(async () => await this.tickFixedUpdate()),
        proxy(async () => await this.tickUpdate())
      );
      this.loopCanceler = () => {
        loopWorker.stop();
        loopWorker.terminate();
      };
    } else {
      this.loopCanceler = createLoop({
        kind: 'timer-accumulated',
        panicAt: 5000,

        updateTime: this.fixedDeltaTimeMs,
        update: async () => await this.tickFixedUpdate(),

        drawTime: this.maxDeltaTimeMs,
        draw: async () => await this.tickUpdate(),
      });
    }

    this.observer.afterStart?.(this.phaseName);
  }

  async destroy(): Promise<unknown> {
    if (this.destroying) return this.finishedPromise;

    this.observer.beforeDestroy?.(this.phaseName);

    this.destroying = true;
    this.loopCanceler?.();

    await this.phase.exit?.(this.context, true);
    if (!this.aborter.signal.aborted) {
      this.aborter.abort('destroy');
    }

    this.observer.afterDestroy?.(this.phaseName);
    return this.finishedPromise;
  }

  private async tickFixedUpdate() {
    // Phase fixedUpdate code (e.g. block ticking) expects to only tick
    // fixedUpdate at approximately 1hz (1000ms). In order to ensure this
    // happens as fast as possible after changing phases without resetting the
    // loop timing, we actually tick internally much faster than 1hz. Then, the
    // first time a specific phase instance's fixedUpdate is called, we mark
    // which tick fraction (relative to 1hz) we're on, and use it for the
    // lifetime of the phase. This ensures the phase starts ticking as soon as
    // it can, but still only continues to tick at 1hz. Without this correction,
    // a mismatch can occur where the loop signals a tick, the phase ends, and
    // the next phase will not be called for 1000ms + "transition time"ms (aka
    // how long the phase took to transition or load or await whatever).
    const PHASE_FIXED_UPDATE_TICK_MS = 1000;

    // Using modulo to keep the tickoffset between 0 and
    // PHASE_PHASE_FIXED_UPDATE_TICK_MS.
    this.fixedUpdateTickAlignment =
      (this.fixedUpdateTickAlignment + this.fixedDeltaTimeMs) %
      PHASE_FIXED_UPDATE_TICK_MS;

    if (!this.phase.fixedUpdate || this.fixedUpdateRunning) return;

    if (this.phaseFixedUpdateTickAlignment === null) {
      // No alignment yet, assign it!
      const fixedUpdateTickAlignment = this.fixedUpdateTickAlignment;
      this.phaseFixedUpdateTickAlignment = fixedUpdateTickAlignment;
      this.observer.fixedUpdateTickAlignmentAssigned?.(
        this.phaseName,
        fixedUpdateTickAlignment
      );
    } else if (
      // We have an alignment, but skip the update call if the current tick does
      // not match.
      this.fixedUpdateTickAlignment !== this.phaseFixedUpdateTickAlignment
    )
      return;

    this.fixedUpdateRunning = true;
    this.observer.beforeFixedUpdate?.(this.phaseName, this.fixedDeltaTimeMs);

    this.context.fixedDeltaTimeMs = this.fixedDeltaTimeMs;
    const next = await this.phase.fixedUpdate(this.context);

    this.observer.afterFixedUpdate?.(
      this.phaseName,
      this.context.fixedDeltaTimeMs
    );

    const transitioned = await this.toPhase(next);
    this.fixedUpdateRunning = false;
    return transitioned;
  }

  private async tickUpdate(tailOptimize = false) {
    if (!this.phase.update || (!tailOptimize && this.updateRunning > 0)) return;
    this.updateRunning += 1;

    const now = Date.now();
    const deltaTime = now - this.lastTickTime;
    this.observer.beforeUpdate?.(this.phaseName, deltaTime);
    this.context.deltaTimeMs = deltaTime;
    const next = await this.phase.update(this.context);
    this.lastTickTime = now;
    this.observer.afterUpdate?.(this.phaseName, this.context.deltaTimeMs);

    const transitioned = await this.toPhase(next);

    // If this update caused a transition, immediately run the next update until
    // it does not. This is an optimization to allow Phases that immediately
    // return a new Phase to execute quickly. Otherwise n Phase changes take `n
    // * maxDeltaTimeMs`!
    const MAX_TAIL_RECURSION = 100;
    if (
      transitioned &&
      this.updateRunning < MAX_TAIL_RECURSION &&
      transitioned !== Shutdown
    ) {
      await this.tickUpdate(true);
    }

    this.updateRunning -= 1;
    return transitioned;
  }

  private async toPhase(
    next: Phase | typeof Shutdown
  ): Promise<boolean | typeof Shutdown> {
    if (next === Shutdown) {
      this.observer.beforeShutdown?.(this.phaseName);
      await this.destroy();
      this.observer.afterShutdown?.(next.toString());
      return Shutdown;
    }

    if (next && next !== this.phase) {
      this.observer.exit?.(this.phaseName);
      await this.phase.exit?.(this.context, false);

      this.phaseFixedUpdateTickAlignment = null;
      this.phase = next;

      this.observer.enter?.(this.phaseName);
      await this.phase.enter?.(this.context);
      return true;
    }

    return false;
  }
}

const DEFAULT_RETRY_COUNT = 10;
const MAX_BACK_OFF_MS = 30000;

type OndGamePlaybackDependencies = {
  blockRecordingCreator: BlockRecordingCreator;
  gameSessionStore: GameSessionStore;
  playedBlockTracker: PlayedBlockTracker;
  townhallAPI: ReturnType<typeof useTownhallAPI>;
  ondVoiceOverRegistry: VoiceOverRegistry;
  blockLifecycleRulesEvaluator: ReturnType<
    typeof useBlockLifecycleRulesEvaluator
  >;
};

export class PlayedBlockTracker {
  constructor(
    private createGameInfoSnapshot: ReturnType<
      typeof useCreateGameInfoSnapshot
    >,
    private api = apiService,
    private log = logger.scoped('ond-phase-runner-played-block-tracker')
  ) {}

  async trackPlayedBlock(
    playbackItem: PlaybackDescItem,
    playbackSummary: DtoPlayedBlockEntry['playbackSummary']
  ) {
    const snapshot = this.createGameInfoSnapshot(playbackItem.id);

    if (!snapshot) {
      this.log.info(
        'cannot track played block; failed to create GameInfoSnapshot'
      );
      return;
    }

    const participants = snapshot.participants.map((p) => p.id);
    this.log.info('tracking played block', {
      sessionId: snapshot.sessionId,
      blockId: playbackItem.block.id,
      blockType: playbackItem.block.type,
      participants,
      brandId: playbackItem.brand?.id,
      brandName: playbackItem.brand?.name,
      playbackSummary,
    });

    const entries: DtoPlayedBlockEntry[] = participants.map((uid) => ({
      uid,
      blockId: playbackItem.block.id,
      blockType: toDTOEnum(playbackItem.block.type, EnumsBlockType),
      brandId: snapshot.brand?.id,
      brandName: snapshot.brand?.name,
      playbackSummary,
    }));
    let attempts = 1;
    while (attempts <= DEFAULT_RETRY_COUNT) {
      try {
        await this.api.session.trackPlayedBlocks(snapshot.sessionId, {
          entries,
        });
        return;
      } catch (e) {
        this.log.error('failed to track played block', e, {
          blockId: playbackItem.block.id,
          participants,
        });
        await backoffSleep(attempts - 1, MAX_BACK_OFF_MS);
      }
      attempts++;
    }
  }
}

export class BlockRecordingCreator {
  constructor(
    private createGameInfoSnapshot: ReturnType<
      typeof useCreateGameInfoSnapshot
    >,
    private getSessionId: ReturnType<typeof useGetStreamSessionId>,
    private writeExtra: ReturnType<typeof usePlaybackInfoWriteExtra>,
    private analytics: OnDGameAnalytics,
    private log = logger.scoped('ond-phase-runner-block-rec-creator')
  ) {}

  private prepared = new Map<
    BlockId,
    {
      preparedCompleted: boolean;
      prepared: Promise<GeneratedBlockRecording | null>;
      unprepared: Promise<GeneratedBlockRecording | null>;
    }
  >();

  async prepare(
    playbackItem: PlaybackDescItem,
    context: {
      playbackVersion: number;
      aiHostVoiceId: Nullable<string>;
      ondVoiceOverRegistry: VoiceOverRegistry;
    }
  ): Promise<GeneratedBlockRecording | null> {
    const { id, block } = playbackItem;
    const existing = this.prepared.get(id);
    if (existing) {
      return await existing.prepared;
    }

    try {
      const start = Date.now();
      const prepared = this.get(playbackItem, context, true);
      const unprepared = this.get(playbackItem, context, false);

      const value = { preparedCompleted: false, prepared, unprepared };
      this.prepared.set(id, value);

      unprepared.then(() =>
        this.log.info(
          `minimal rec preparation finished for block in ${Math.round(
            Date.now() - start
          )}ms`,
          {
            blockId: block.id,
            playbackItemId: id,
          }
        )
      );

      const recs = await Promise.allSettled([prepared, unprepared]);
      // if the prepared rec fails, we should consider it uncomplete, and use the unprepared promise.
      value.preparedCompleted = recs[0].status === 'fulfilled';
      this.log.info(
        `full rec preparation finished for block in ${Math.round(
          Date.now() - start
        )}ms`,
        {
          blockId: block.id,
          playbackItemId: id,
        }
      );

      if (recs[0].status === 'fulfilled') {
        return recs[0].value;
      } else if (recs[1].status === 'fulfilled') {
        return recs[1].value;
      } else {
        return null;
      }
    } catch (errs) {
      if (Array.isArray(errs)) {
        this.log.error(
          'failed to resolve recording',
          errs.map((err, idx) => `[${idx}]: ${err2s(err)}`).join('\n'),
          {
            blockId: block.id,
            playbackVersion: context.playbackVersion,
            playbackItemId: id,
          }
        );
      }
      // Clear out failure to attempt again? Might be too dangerous in case of loop.
      // this.prepared.delete(block.id);
      return null;
    }
  }

  private async get(
    playbackItem: PlaybackDescItem,
    context: {
      playbackVersion: number;
      aiHostVoiceId: Nullable<string>;
      ondVoiceOverRegistry: VoiceOverRegistry;
    },
    waitForPreparedBlock: boolean
  ): Promise<GeneratedBlockRecording | null> {
    const { id, block } = playbackItem;
    if (OndVersionChecks(context.playbackVersion).ondHostRecordingRequired)
      return block.recording
        ? { recording: block.recording, extra: undefined }
        : null;

    const recording = await generateV3Recording(
      block,
      this.createGameInfoSnapshot(id),
      {
        waitForPreparedBlock,
        scenario: playbackItem.scenario,
        aiHostVoiceId: context.aiHostVoiceId,
        voiceOverPlans: playbackItem.voiceOverPlans,
        ondVoiceOverRegistry: context.ondVoiceOverRegistry,
      }
    );

    if (recording?.extra) {
      const update = waitForPreparedBlock
        ? { preparedExtra: recording.extra, unpreparedExtra: undefined }
        : { preparedExtra: undefined, unpreparedExtra: recording.extra };

      await this.writeExtra(SessionMode.OnDemand, playbackItem.id, update);
    }
    return recording;
  }

  async for(
    playbackItem: PlaybackDescItem,
    context: {
      playbackVersion: number;
      aiHostVoiceId: Nullable<string>;
      ondVoiceOverRegistry: VoiceOverRegistry;
    }
  ): Promise<BlockRecording | null> {
    const { id, block } = playbackItem;
    const preparationRequired = recordingNeedsPreparation(block);
    const cached = this.prepared.get(id);
    const sessionId = this.getSessionId() ?? null;

    // If nothing exists in cache, it's likely we need something immediately, so
    // do not wait for full-prepare
    if (!cached) {
      this.log.info('rec not found for block, jit generating', {
        blockId: block.id,
        playbackItemId: id,
      });
      if (preparationRequired) {
        this.analytics.trackBlockRecordingPlanned({
          blockId: block.id,
          sessionId,
          preparationRequired: true,
          preparationCompleted: false,
        });
      }
      return (await this.get(playbackItem, context, false))?.recording ?? null;
    }

    if (preparationRequired) {
      // this guard isn't necessary, but it will avoid superfluous eventing.
      this.analytics.trackBlockRecordingPlanned({
        blockId: block.id,
        sessionId,
        preparationRequired: true,
        preparationCompleted: cached.preparedCompleted,
      });
    }

    // Prioritize the prepared version, if it is completed. When this method is
    // called, we _need_ the recording ASAP.
    if (cached.preparedCompleted) {
      this.log.info('using runtime-prepared rec for block', {
        blockId: block.id,
        playbackItemId: id,
      });
      return (await cached.prepared)?.recording ?? null;
    } else {
      this.log.info('using fallback rec for block, runtime not ready', {
        blockId: block.id,
        playbackItemId: id,
      });
      return (await cached.unprepared)?.recording ?? null;
    }
  }
}

type BlockPlaybackInfo = {
  block: Block;
  playbackPlan: BlockPlaybackPlan;
  recording: BlockRecording;
};

export class OndPhaseContext {
  private currentBlockPlaybackInfo: null | BlockPlaybackInfo = null;
  private _deltaTimeMs = 0;
  private _fixedDeltaTimeMs = 0;

  private _preloadedMediaCache = new BlockToPreloadedMediasMap();

  constructor(
    // NOTE: this data is what is considered "shared" across all Phases of an
    // Ond game. Most importantly, data should only be placed here if it is
    // considered necessary to resume a game, whether from a refresh or
    // recovery. Try to avoid exposing data here, as ownership is tough (e.g.
    // which phase can read, write, or reset specific properties)

    private data: {
      playbackVersion: number;
      playback: PlaybackDesc;
      currentPlaybackItemId: Nullable<PlaybackItemId>;
      jump?: Nullable<OndPlaybackJump>;
      blockProgressSec: number;
      sessionProgressSec: number;
      /**
       * soft: keep existing state, hard: destroy existing state (e.g. resuming
       * into different block)
       */
      resuming: 'soft' | 'hard' | false;
      needsBlockTitleTrigger: boolean;
      recording: BlockRecording | null;
      desyncCorrectionEnabled: boolean;
      fractionalShiftActionsEnabled: boolean;
      waitModeResumeData: GameSessionOndWaitModeResumeData | null;
    },
    public dependencies: OndGamePlaybackDependencies
  ) {
    // On init, we need to have one. When it is later nullish, it indicates that
    // we're out of blocks to play this game!
    assertDefinedFatal(
      data.currentPlaybackItemId,
      'OndPhaseContext.currentPlaybackItemId'
    );

    this.data = ValtioUtils.detachCopy(this.data);

    const checks = OndVersionChecks(this.playbackVersion);
    if (checks.ondExpectOutro && checks.ondOutroRequiresInjectedBlock) {
      // TODO: move into playbackdesc creation
      // this.appendOutroBlock();
    }
  }

  get playbackDesc(): Readonly<PlaybackDesc> {
    return this.data.playback;
  }

  get playbackItemsLength(): number {
    return this.data.playback.items.length;
  }

  get deltaTimeMs(): number {
    return this._deltaTimeMs;
  }

  set deltaTimeMs(ms: number) {
    this._deltaTimeMs = ms;
  }

  set fixedDeltaTimeMs(ms: number) {
    this._fixedDeltaTimeMs = ms;
  }

  get fixedDeltaTimeMs(): number {
    return this._fixedDeltaTimeMs;
  }

  get blockPlaybackInfo(): null | BlockPlaybackInfo {
    if (this.currentBlock?.id !== this.currentBlockPlaybackInfo?.block.id)
      return null;
    return this.currentBlockPlaybackInfo;
  }

  get currentPlaybackItem() {
    // Early return
    if (!this.data.currentPlaybackItemId) return null;
    return this.data.playback.items.find(
      (item) => item.id === this.data.currentPlaybackItemId
    );
  }

  get blockPlayedReportingSummary(): DtoPlayedBlockEntryPlaybackSummary {
    const playbackItem = this.currentPlaybackItem;
    const playbackItemsIndex =
      this.data.playback.items.findIndex(
        (item) => item.id === this.data.currentPlaybackItemId
      ) ?? -1;
    const playbackItemsLength = this.playbackItemsLength;

    const playheadMs =
      this.dependencies.gameSessionStore.videoMixers.ondHostVideo?.playheadMs ??
      0;
    const startMs =
      this.blockPlaybackInfo?.playbackPlan.extra.blockTimelineTimeStartMs ?? 0;
    const playbackItemDurationSec = Math.round((playheadMs - startMs) / 1000);

    return {
      playbackItemsLength,
      playbackItemsIndex,
      playbackItemInjected: playbackItem?.injected ?? false,
      playbackItemScenario: playbackItem?.scenario,
      playbackItemDurationSec,
      sessionProgressSec: this.sessionProgressSec,
    };
  }

  get jump() {
    return this.data.jump;
  }

  get nextPlaybackItem() {
    return this.followingPlaybackItem(this.data.currentPlaybackItemId);
  }

  incrementCurrentPlaybackItem(): void {
    const nextItem = this.nextPlaybackItem;
    const didJump =
      this.data.jump &&
      this.data.jump.jumpAfterPlaybackItemId ===
        this.data.currentPlaybackItemId;
    this.data.currentPlaybackItemId = nextItem?.id ?? null;
    this.data.blockProgressSec = 0;
    this.currentBlockPlaybackInfo = null;

    // clear the jump register if we jumped.
    if (didJump) {
      this.data.jump = null;
    }
  }

  followingPlaybackItem(afterId: Nullable<PlaybackItemId>) {
    return OndPhaseContext.GetFollowingPlaybackItem(
      this.playbackDesc,
      afterId,
      this.data.jump
    );
  }

  static GetFollowingPlaybackItem(
    playback: PlaybackDesc,
    afterId: Nullable<PlaybackItemId>,
    jump: Nullable<OndPlaybackJump>
  ) {
    if (!afterId) return null;

    if (jump?.jumpAfterPlaybackItemId === afterId) {
      return playback.items.find(
        (item) => item.id === jump.jumpToPlaybackItemId
      );
    }

    const index = playback.items.findIndex((item) => item.id === afterId);
    if (index === -1 || index + 1 >= playback.items.length) return null;
    return playback.items[index + 1];
  }

  get currentBlock(): Block | null {
    const item = this.currentPlaybackItem;
    return item?.block ?? null;
  }

  get sessionProgressSec(): number {
    return this.data.sessionProgressSec;
  }

  get blockProgressSec(): number {
    return this.data.blockProgressSec;
  }

  get blockTimeElapsedMs(): number {
    return this.blockProgressSec * 1000;
  }

  get blockTimeRemainingMs(): number | null {
    const plan = this.blockPlaybackInfo?.playbackPlan;
    if (!plan) return null;
    return (
      plan.blockEndingSec * 1000 +
      plan.blockRemainderMs -
      this.blockProgressSec * 1000
    );
  }

  get playbackVersion(): number {
    return this.data.playbackVersion;
  }

  get aiHostVoiceId(): Nullable<string> {
    return this.data.playback.aiHost?.voiceId;
  }

  get ondVoiceOverRegistry(): VoiceOverRegistry {
    return this.dependencies.ondVoiceOverRegistry;
  }

  get desyncCorrectionEnabled(): boolean {
    return this.data.desyncCorrectionEnabled;
  }

  get fractionalShiftActionsEnabled(): boolean {
    return this.data.fractionalShiftActionsEnabled;
  }

  get resuming(): false | 'soft' | 'hard' {
    return this.data.resuming;
  }

  get needsBlockTitleTrigger(): boolean {
    return this.data.needsBlockTitleTrigger;
  }

  get preloadedMediaCache(): BlockToPreloadedMediasMap {
    return this._preloadedMediaCache;
  }

  /**
   * Create a context and "prepare" the first block's recording. For a V3 game
   * this means just generate it locally (or make a quick request for config).
   * For a V3.1 game this means request the voiceovers from the backend, which
   * may need to be generated.
   */
  static FromPrepareForStartVRefed(
    playback: PlaybackDesc,
    dependencies: OndGamePlaybackDependencies,
    startBlockId?: PlaybackItemId
  ): {
    context: OndPhaseContext;
    prepared: Promise<GeneratedBlockRecording | null>;
  } {
    const context = this.FromStart(playback, dependencies, startBlockId);
    if (!context.currentPlaybackItem) {
      return { context: ref(context), prepared: Promise.resolve(null) };
    }

    const prepare = async () => {
      if (!context.currentPlaybackItem) return null;

      // prepare fallbacks...
      const fallbacks = [];
      // no variables for fallbacks...
      const empty = new VariableRegistry();
      for (const item of playback.items) {
        if (!item.voiceOverPlans) continue;
        for (const vo of item.voiceOverPlans) {
          const group = await context.ondVoiceOverRegistry.getOrCreateGroup(
            vo.plan
          );
          fallbacks.push(group.load(empty));
        }
      }

      await Promise.all(fallbacks);

      return await context.dependencies.blockRecordingCreator.prepare(
        context.currentPlaybackItem,
        context
      );
    };

    return {
      context: ref(context),
      prepared: prepare(),
    };
  }

  static FromStart(
    playback: PlaybackDesc,
    dependencies: OndGamePlaybackDependencies,
    startItemId?: PlaybackItemId,
    playbackVersion = playback.ondPlaybackVersion
  ): OndPhaseContext {
    return new OndPhaseContext(
      {
        playback,
        playbackVersion,
        currentPlaybackItemId: startItemId ?? playback.startItemId,
        jump: null,
        blockProgressSec: 0,
        sessionProgressSec: 0,
        resuming: false,
        needsBlockTitleTrigger: false,
        recording: null,
        desyncCorrectionEnabled: true,
        fractionalShiftActionsEnabled: getFeatureQueryParam(
          'game-on-demand-fractional-shift-actions'
        ),
        waitModeResumeData: null,
      },
      dependencies
    );
  }

  static FromResume(
    playback: PlaybackDesc,
    dependencies: OndGamePlaybackDependencies,
    blockSession: BlockSession | null,
    startBlockId?: PlaybackItemId,
    playbackVersion = playback.ondPlaybackVersion
  ): OndPhaseContext {
    const gss = dependencies.gameSessionStore;

    let currentPlaybackItemId = playback.startItemId;
    let blockProgressSec = 0;
    let gameProgressSec = 0;
    let isResumingFromDifferentBlock = false;
    let waitModeResumeData: GameSessionOndWaitModeResumeData | null = null;

    if (
      startBlockId &&
      (blockSession?.block?.id !== startBlockId || !blockSession)
    ) {
      // we're being explicit about where we want to resume from. ignore what is in firebase.
      blockProgressSec = 0;
      gameProgressSec = 0;
      currentPlaybackItemId = startBlockId;
      isResumingFromDifferentBlock = true;
    } else if (gss.ondState) {
      blockProgressSec = gss.ondState.blockProgressSec ?? 0;
      gameProgressSec = gss.ondState.sessionProgressSec ?? 0;
      currentPlaybackItemId =
        gss.ondState.currentPlaybackItemId ?? playback.startItemId;

      if (gss.ondState.waitModeInfo && gss.ondState.waitModeExtSignal) {
        const info = gss.ondState.waitModeInfo;
        if (info && info.elapsedSec !== null) {
          // TS HACK: without this the elapsedSec will not narrow to 0, and
          // remains at `null | 0` and becomes incompatible. Not sure why. We
          // only want to attempt a wait mode resume if we have "valid" wait
          // mode data as a result of entering at least once.
          const { elapsedSec } = info;

          waitModeResumeData = {
            info: {
              ...info,
              elapsedSec,
            },
            extSignal: gss.ondState.waitModeExtSignal,
          };
        }
      }
    }

    return new OndPhaseContext(
      {
        playback,
        playbackVersion,
        currentPlaybackItemId,
        blockProgressSec,
        sessionProgressSec: gameProgressSec,
        resuming: isResumingFromDifferentBlock ? 'hard' : 'soft',
        // We only need a block title trigger if it's the start of the block.
        // This should only happen if we are "resuming" via a "skip intro"/"skip
        // to gameplay" operation. Even a refresh of the host should not
        // retrigger the block title. This check is needed because this is
        // in-memory data not persisted in any way to firebase.
        needsBlockTitleTrigger: blockProgressSec === 0,
        recording: null,
        desyncCorrectionEnabled: true,
        fractionalShiftActionsEnabled: getFeatureQueryParam(
          'game-on-demand-fractional-shift-actions'
        ),
        waitModeResumeData,
      },
      dependencies
    );
  }

  static SkipPreGameHostedTutorial(context: OndPhaseContext) {
    const hostedTutorial =
      context.playbackDesc.preGame?.instructions?.hostedTutorial;
    const jumpTo = hostedTutorial?.itemIdAfter;
    if (!jumpTo) {
      // note: it's possible we still have an opening title to play. when hostedTutorial
      // or jumpTo is not defined it means there's nothing _skippable_.
      return context;
    }
    return new OndPhaseContext(
      {
        ...context.data,
        currentPlaybackItemId: jumpTo,
        jump: null, // defensive. this shouldn't be set...
      },
      context.dependencies
    );
  }

  setBlockPlaybackInfo(
    block: Block,
    playbackPlan: BlockPlaybackPlan,
    recording: BlockRecording
  ): void {
    if (this.currentBlock?.id !== block.id)
      throw new Error('BLOCK ID MISMATCH');
    this.currentBlockPlaybackInfo = {
      block,
      playbackPlan,
      recording,
    };
  }

  markResumingComplete(): void {
    this.data.resuming = false;
  }

  resetBlockTitleTriggered(): void {
    this.data.needsBlockTitleTrigger = false;
  }

  incrementBlockProgress(inc: number): void {
    /**
     * The runner looks for the next action based on the _blockProgressSec_,
     * Which expected an integer value as the lookup key. This is a stopgap that
     * removes the fraction part. It still could be wrong if the integer key
     * are not matched. Check {@link executeOndAction} for more details.
     */
    this.data.blockProgressSec += Math.floor(inc);
  }

  incrementSessionProgress(inc: number): void {
    this.data.sessionProgressSec += inc;
  }

  /**
   * This property is only set when resuming from a saved state. Therefore,
   * there is no set/get, only consume to avoid out of sync copies
   */
  consumeWaitModeResumeData(): GameSessionOndWaitModeResumeData | null {
    const data = this.data.waitModeResumeData;
    this.data.waitModeResumeData = null;
    return data;
  }

  setJump(jump: Nullable<OndPlaybackJump>): void {
    this.data.jump = jump;
  }
}

function createVRefedVideoMixer(
  context: { playbackVersion: number },
  profileIndex = getFeatureQueryParamArray('game-on-demand-host-video-profile')
): VideoMixer {
  const config = profileForCustomVideo(profileIndex);
  return ref(
    new VideoMixer({
      drawFps: config.framerate,
      renderWidth: config.width,
      renderHeight: config.height,
      audioRenderingOnly: OndVersionChecks(context.playbackVersion)
        .ondAudioOnly,
    })
  );
}

export class OndPhaseResume implements Phase {
  readonly name = 'ond-phase-resume';
  readonly log = logger.scoped(this.name);

  private gameResumingCountdown = 0;

  async enter(context: OndPhaseContext): Promise<void> {
    this.gameResumingCountdown = 3;
    // Immediately set the state+number so that the countdown is visible.
    await setOndGamePlayResuming(this.gameResumingCountdown);

    const gss = context.dependencies.gameSessionStore;
    if (context.resuming === 'hard') {
      // NOTE(drew): for v1 Ond playback, if we're resuming and there is an existing
      // video mixer, blockProgressSec must be retrieved in order for everything to
      // remain synced. If in the future a pause->resume operation is meant to
      // restart a block, then it's probably best to destroy the video mixer here.
      if (gss.videoMixers.ondHostVideo) {
        gss.ondHostVideoMixerTrackIds.destroy();
        await gss.videoMixers.ondHostVideo.destroy();
        gss.videoMixers.ondHostVideo = null;
      }

      // By this point, all necessary data should be in the context. So it's
      // safe to kill firebase data.
      await ondWaitReset('hard resume');
    }

    if (!gss.videoMixers.ondHostVideo) {
      gss.videoMixers.ondHostVideo = createVRefedVideoMixer(context);
      gss.ondHostVideoMixerTrackIds = BlockToVideoMixerTrackMap.CreateVRefed();
    }
  }

  async fixedUpdate(
    context: OndPhaseContext
  ): Promise<OndPhaseTryStartBlock | OndPhaseWaitModeTick | this> {
    if (!context.resuming) return new OndPhaseTryStartBlock();

    const gss = context.dependencies.gameSessionStore;

    // The resumingCountdown was set before, so it has already been on screen by
    // the time this tick runs. Decrement immediately so that "3" doesn't hang
    // on the screen for an extra second.
    if (this.gameResumingCountdown > 1) {
      this.gameResumingCountdown -= 1;
      this.log.debug('resume count', { timer: this.gameResumingCountdown });
      await setOndGamePlayResuming(this.gameResumingCountdown);
      return this;
    } else {
      this.log.info('resumed');
      gss.videoMixers.ondHostVideo?.play();
      context.markResumingComplete();
      await setOndGamePlayResuming(0);
      await setOndGamePlayRunning(context.playbackVersion);
      return new OndPhaseTryStartBlock();
    }
  }
}

export class OndPhaseBoot implements Phase {
  readonly name = 'ond-phase-boot';
  readonly log = logger.scoped(this.name);

  constructor(
    private playIntro = true,
    private bootSleepMs = getFeatureQueryParamNumber(
      'game-on-demand-v31-boot-sleep-ms'
    )
  ) {}

  async enter(context: OndPhaseContext): Promise<void> {
    const gss = context.dependencies.gameSessionStore;

    // Immediately set these initial values to blank out any that carried over
    // from the previous game. We set them before gamePlayRunning so that no
    // flickers of previous values are seen.
    await gss.refs.ondState?.update({
      blockProgressSec: context.blockProgressSec,
      sessionProgressSec: context.sessionProgressSec,
      currentPlaybackItemId: context.currentPlaybackItem?.id ?? null,
      jump: context.jump ?? null,
      waitModeInfo: null,
      waitModeExtSignal: null,
    });
    await setOndGamePlayRunning(context.playbackVersion);

    // Intro is skipped during v3.1
    const checks = OndVersionChecks(context.playbackVersion);
    this.playIntro = this.playIntro ? checks.ondExpectIntro : this.playIntro;
  }

  async update(
    context: OndPhaseContext
  ): Promise<OndPhaseTryStartBlock | OndPhaseShutdown> {
    const gss = context.dependencies.gameSessionStore;
    const checks = OndVersionChecks(context.playbackVersion);

    // Re-init glue mapping of on-demand blocks -> VideoMixer TrackIds
    gss.ondHostVideoMixerTrackIds.destroy();
    gss.ondHostVideoMixerTrackIds = BlockToVideoMixerTrackMap.CreateVRefed();

    // Reset video mixer
    if (gss.videoMixers.ondHostVideo) {
      await gss.videoMixers.ondHostVideo.destroy();
      gss.videoMixers.ondHostVideo = null;
    }

    if (!gss.videoMixers.ondHostVideo) {
      gss.videoMixers.ondHostVideo = createVRefedVideoMixer(context);
    }

    if (!gss.ondHostVideoMixerTrackIds.get('intro')) {
      const videoMixer = gss.videoMixers.ondHostVideo;

      // Add intro video, wait until block video begins to start game
      this.log.info(`video-mixer: scheduling intro video`, {
        playIntro: this.playIntro,
      });

      const introGroup = this.playIntro
        ? scheduleHostIntroVideo(gss.ondHostVideoMixerTrackIds, videoMixer)
        : null;

      const startingItem = context.currentPlaybackItem;

      this.log.info('requesting starting-block recording');

      // NOTE(drew): This does not wait for the fully-prepared block recording.
      // If we get to this point, it's time for the game to go! The Icebreaker
      // countdown will hopefully prevent starting too soon.
      const recording = startingItem
        ? await context.dependencies.blockRecordingCreator.for(
            startingItem,
            context
          )
        : null;

      const nextPlayableBlock = context.followingPlaybackItem(
        startingItem?.id
      )?.block;

      if (this.playIntro && !introGroup)
        return new OndPhaseShutdown('No intro block');

      const introGroupPrimaryItem = introGroup?.get('primary');

      // A playback plan is required to schedule voiceovers. Normally this would
      // be handled when the block is prepared and begins.
      const v31PlaybackPlan =
        startingItem && recording
          ? createBlockPlaybackPlan({
              blockId: startingItem.id,
              blockProgressSec: 0,
              desyncCorrectionEnabled: true,
              fractionalShiftActionsEnabled: true,
              ondTrackIds: gss.ondHostVideoMixerTrackIds,
              recording,
              videoMixer,
            })
          : null;

      // NOTE(drew): if there is no starting block (e.g. it is unplayable
      // according to v1 rules, or in v3 a recording cannot be generated for it,
      // such as a title block), then the user will likely see a black screen
      // for a moment once the game starts until the next playable block is
      // loaded. This is to prevent needing to duplicate filtering or selection
      // logic here, at the start of the game, compared to during the game. This
      // particular case is an edge case that only LP-employees/content
      // producers should encounter: it is highly unlikely we would publish a
      // minigame that has an unplayable first block!
      const firstScheduled = startingItem
        ? scheduleBlockTrackMap(
            context.playbackVersion,
            null,
            startingItem.block,
            nextPlayableBlock,
            // TODO(drew): the recording is use for host videos, the playback
            // plan is used for voiceovers. Probably best to split these up.
            recording,
            v31PlaybackPlan,
            0,
            this.playIntro && introGroupPrimaryItem
              ? {
                  kind: 'after-track-id',
                  trackId: introGroupPrimaryItem.trackId,
                  withForcedFadeIn: this.playIntro,
                }
              : // If intro is disabled (dev-only), then pretend there is a current
                // block that is immediately ending in terms of scheduling.
                {
                  kind: 'after-current-block',
                  blockEndingSec: 0,
                  blockRemainderMs: 0,
                  timer: 0,
                  withForcedFadeIn: this.playIntro,
                },
            gss.ondHostVideoMixerTrackIds,
            context.preloadedMediaCache,
            videoMixer,
            this.log
          )
        : null;

      if (firstScheduled && startingItem) {
        this.log.info('scheduled first block', {
          blockId: startingItem.id,
        });
      }

      // Without an intro, block until the first block is loaded before starting
      // video mixer playback. This avoids any content clipping.
      if (!introGroup) {
        await firstScheduled?.intoPlayables();
      } else {
        firstScheduled?.intoPlayables();
        // Wait until at least the intro is loaded before starting videomixer playback
        await introGroup?.intoPlayables();
      }

      this.log.info('intro and/or starting block media loaded');

      // NOTE(drew): Agora seems to have a delay when first publishing to a
      // channel that results in about ~500ms of content being dropped. In V3.1
      // playback without an intro, there is no content to absorb this delay, so
      // add an artificial wait :(
      if (checks.ondAudioOnly && !checks.ondExpectIntro) {
        await sleep(this.bootSleepMs);
      }

      gss.ondPhaseEmitter.emit(
        'boot-play-intro',
        introGroupPrimaryItem?.media instanceof HTMLVideoElement
          ? introGroupPrimaryItem.media.duration
          : 0,
        startingItem
          ? startingItem.block
          : context.nextPlaybackItem?.block ?? null
      );
      videoMixer.play();

      await Promise.all([
        // Do not wait for the first block to start if audio-only, since there
        // is no guarantee that the first title card has a voiceover. It could
        // be gameplaymedia-only.
        !checks.ondAudioOnly ? firstScheduled?.allStarted() : Promise.resolve(),
        // NOTE(drew): This is only necessary if the first block is not
        // recordable. Waiting like this potentially adds to desync since there
        // is overlap between intro and outro
        introGroupPrimaryItem?.ended,
      ]);

      this.log.info('all initial tracks started');

      // TODO: probably want this to happen about 1s before we try to start
      triggerBlockTitleAnim(startingItem?.block ?? null);
    }

    return new OndPhaseTryStartBlock();
  }
}

class OndPhaseShutdown implements Phase {
  readonly name = 'ond-phase-shutdown';
  readonly log = logger.scoped(this.name);

  constructor(private reason?: Error | string) {}

  async update(context: OndPhaseContext) {
    const gss = context.dependencies.gameSessionStore;

    // NOTE: It's tempting to call endOndGame from here. But the runner might
    // be shutdown for a reason other than ending the game, such as a pause or
    // crash. We don't want to unnecessarily clear the saved state.

    const onDemandHostVideoMixerTrackIds = gss.ondHostVideoMixerTrackIds;
    const ondHostVideo = gss.videoMixers.ondHostVideo;
    if (ondHostVideo && onDemandHostVideoMixerTrackIds)
      await maybeWaitForOutroVideoToEnd(
        ondHostVideo,
        onDemandHostVideoMixerTrackIds,
        this.log
      );

    if (this.reason instanceof Error) {
      this.log.error('shutdown', this.reason);
    } else {
      this.log.info('shutdown', { reason: this.reason });
    }
    return Shutdown;
  }
}

class OndPhaseNextBlock implements Phase {
  readonly name = 'ond-phase-next-block';

  async update(context: OndPhaseContext) {
    // check for a jump.
    const gss = context.dependencies.gameSessionStore;
    if (gss.ondState?.jump) {
      context.setJump(gss.ondState.jump);
    } else if (context.jump) {
      // there's no longer a jump, so clear it from the state.
      context.setJump(null);
    }
    context.incrementCurrentPlaybackItem();

    if (!context.currentPlaybackItem) {
      return new OndPhaseShutdown('No more blocks');
    }

    return new OndPhaseTryStartBlock();
  }
}

class OndPhaseTryStartBlock implements Phase {
  readonly name = 'ond-phase-try-start-block';
  readonly log = logger.scoped(this.name);

  async update(context: OndPhaseContext) {
    const gss = context.dependencies.gameSessionStore;
    const ondStateRef = required(gss.refs.ondState, 'ondStateRef');

    const playbackItem = context.currentPlaybackItem;
    if (!playbackItem) {
      await ondStateRef.update({ currentPlaybackItemId: null });
      return new OndPhaseNextBlock();
    }

    const {
      videoMixers: { ondHostVideo },
    } = gss;

    const videoMixer = ondHostVideo;
    // If the prepared recording is available, use it! If not, use what is
    // available.
    const recording = await context.dependencies.blockRecordingCreator.for(
      playbackItem,
      context
    );

    // No Recording indicates that something is actually quite wrong: somehow
    // there was not a recording for this block and it could not be generated.
    if (!recording || !videoMixer) return new OndPhaseNextBlock();

    // NOTE: these indices are used only or resuming an Ond game after pausing
    await ondStateRef.update({
      currentPlaybackItemId: context.currentPlaybackItem?.id ?? null,
      blockBrandName: playbackItem.brand?.name ?? null,
      blockBrandHasHostedTutorial: Boolean(
        playbackItem?.hostedTutorial ?? false
      ),
      jump: context.jump ?? null,
    });

    // We pass the recording to the BlockTick phase so that it is not generated
    // multiple times. It's needed both here, to ensure that there _is_ a
    // recording, and within the block tick to generate the PlaybackPlan. By
    // generating the playback plan _later_ (than right now), there is a better
    // chance of it compensating for accumulated time between now and when the
    // block tick actually begins.
    return new OndPhaseBlockTick(recording);
  }
}

/**
 * @returns True if the action caused the system to enter "wait mode"
 */
const executeOndAction = async (
  actionMap: SecondQuantizedActionMap,
  timer: number,
  waitMode: GameSessionOndState['waitModeExtSignal'],
  block: Block,
  fractionalShiftActionsEnabled: boolean,
  observer: (action: SecondQuantizedRecordingAction) => void,
  log: Logger,
  townhallApi: ReturnType<typeof useTownhallAPI>,
  lifecycleRuleEvaluator: (nextStatus: number) => Promise<void>
): Promise<BlockActionWaitModeConfig | false> => {
  // Already in wait mode? then we didn't enter wait mode, we were already
  // present. "You cannot enter a room you're already within."
  if (waitMode?.mode) return false;

  let waitModeConfig: BlockActionWaitModeConfig | false = false;

  // The keys in actionMap are integer values (offset secs from 0), we try the
  // "correction" in case the input timer has fraction part.
  const correctedTimer = Math.floor(timer);
  if (correctedTimer !== timer) {
    log.warn('timer changed after correction', { timer, correctedTimer });
  }

  const actions = actionMap[correctedTimer];
  if (!actions) {
    log.info('no actions for timer tick', { correctedTimer, actionMap });
    return false;
  }

  let accumulatedFractionalShift = 0;

  for (const action of actions) {
    if (fractionalShiftActionsEnabled) {
      // HACK: attempt to delay by the amount the action was shifted when it was
      // second-quantized. Multiple actions could happen during the same second:
      // accumulate the delay to keep each action within its quantized bound.
      const delay = action.fractionalShiftMs - accumulatedFractionalShift;
      log.info('fractional shift actions enabled, delaying', {
        fractionalShiftMs: action.fractionalShiftMs,
        accumulatedFractionalShift,
        delay,
      });
      await new Promise((resolve) => setTimeout(resolve, delay));
      accumulatedFractionalShift += action.fractionalShiftMs;
    }

    if (action.simple === 'replay-video') {
      observer(action);
      await replayVideo();
    } else if (action.simple === 'townhall-to-crowd') {
      observer(action);
      await townhallApi.setNext({
        mode: 'crowd',
        countdownSec: (action.original.durationMs ?? 0) / 1000,
        source: 'ondphaserunner',
        type: 'global',
      });
    } else if (action.simple === 'townhall-to-teams') {
      observer(action);
      await townhallApi.setNext({
        mode: 'team',
        countdownSec: (action.original.durationMs ?? 0) / 1000,
        source: 'ondphaserunner',
        type: 'global',
      });
    } else if (typeof action.simple === 'number') {
      const map = GameSessionUtil.StatusMapFor(block.type);
      if (!map) return false;
      observer(action);
      const endStatus = map.end;
      if (action.simple === map.loaded) {
        await loadGameSession({ blockToPreload: block });
      } else if (action.simple === map.intro) {
        await present(block);
      } else if (action.simple === endStatus) {
        await end();
      } else if (action.simple > map.intro && action.simple < endStatus) {
        await next({ overrideNextStatus: action.original.gameSessionStatus });
      }

      // we've transitioned at this point...
      const nextStatus = action.original.gameSessionStatus ?? action.simple;
      await lifecycleRuleEvaluator(nextStatus);

      if (action.original.waitMode?.wait) {
        waitModeConfig = action.original.waitMode;
      }
    }
  }
  return waitModeConfig;
};

class OndPhaseBlockTick implements Phase {
  readonly name = 'ond-phase-block-tick';
  readonly log = logger.scoped(this.name);

  constructor(private initialRecording?: BlockRecording) {}

  async enter(context: OndPhaseContext) {
    const block = context.currentBlock;
    const gss = context.dependencies.gameSessionStore;
    const {
      videoMixers: { ondHostVideo },
    } = gss;

    if (!block) return;

    const videoMixer = ondHostVideo;
    const recording = this.initialRecording;

    if (!recording || !videoMixer) return;

    // Consider it "consumed".
    this.initialRecording = undefined;

    // TODO: consider a plan that is updated each tick (or whenever) using the
    // actual deltaTime of the videomixer playhead from the time the block
    // started (or the host video started), and executing actions when they
    // overlap (like a normal game engine). That removes the need for an entire
    // "plan", since it would just always try to catch up regardless.

    // This playback system is inherently squishy. Asynchronous behavior, network
    // conditions, and the sheer speed of the client CPU will impact whether
    // action playback is synchronized to the host videos. The videos are
    // continuous, while the actions are discrete and the game system is quantized
    // to 1hz.

    // The plan is only valid locally, and should only be created once per block.
    // If the game is resumed, the values will be consistent. If the game is
    // transferred to another cloud controller or host, then recreate the plan
    // locally.
    const planIniter: BlockPlaybackPlanInit = {
      blockId: block.id,
      ondTrackIds: gss.ondHostVideoMixerTrackIds,
      recording,
      videoMixer,
      blockProgressSec: context.blockProgressSec,
      desyncCorrectionEnabled: context.desyncCorrectionEnabled,
      fractionalShiftActionsEnabled: context.fractionalShiftActionsEnabled,
    };
    const plan = getOrCreateBlockPlaybackPlan(gss, planIniter);

    logBlockPlaybackPlan(context.blockProgressSec, this.log, videoMixer, plan);

    context.setBlockPlaybackInfo(block, plan, recording);
  }

  private blockTitleAnimationTriggered = false;

  async fixedUpdate(context: OndPhaseContext): Promise<Phase> {
    const gss = context.dependencies.gameSessionStore;
    const {
      ondState,
      videoMixers: { ondHostVideo },
    } = gss;

    const videoMixer = ondHostVideo;
    if (!context.blockPlaybackInfo || !videoMixer)
      return new OndPhaseBlockEnd();

    const { block, recording, playbackPlan } = context.blockPlaybackInfo;
    const { blockEndingSec, blockRemainderMs, actionMap } = playbackPlan;

    const gameProgressSec = context.sessionProgressSec;
    const blockProgressSec = context.blockProgressSec;

    {
      // If we have resume data, kick as soon as possible into wait mode. This
      // cannot happen during the Resume phase itself because during `enter()`,
      // `OndPhaseBlockTick` sets up the `blockPlaybackInfo` in the context.
      // This is critical data required to do time planning for ticking in both
      // normal and wait mode. This is one tradeoff to keep the
      // blockPlaybackInfo creation as late as possible (to account for timing
      // delays) and in a single place (it has many dependencies).
      const resumeData = context.consumeWaitModeResumeData();
      if (resumeData) {
        return new OndPhaseWaitModeTick(resumeData);
      }
    }

    gss.ondPhaseEmitter.emit(
      'block-tick',
      block,
      blockProgressSec,
      blockEndingSec,
      () => context.nextPlaybackItem?.block ?? null
    );

    // NOTE: currently only a block recording Action can initiate wait mode. A
    // new API will be needed, and checked here, if an external initiator of
    // waitmode is required.

    // Guard: avoid ticking forever. if the block has progressed beyond the plans defined end,
    // then just end the block.
    if (blockProgressSec > blockEndingSec) {
      return new OndPhaseBlockEnd();
    }

    // Firing the block title animation here means there will be NO OVERLAP with
    // the previous block. This is typically only needed for: resuming and
    // skipping.
    if (context.needsBlockTitleTrigger) {
      triggerBlockTitleAnim(block);
      context.resetBlockTitleTriggered();
    }

    // Sync expected end and current progress of block to all clients. Note that
    // this and any other async actions will contribute desync to the timer.
    await gss.refs.ondState?.update({
      blockProgressSec,
      blockEndingSec,
      blockRemainderMs,
      sessionProgressSec: gameProgressSec,
    });

    logBlockTickInfo(
      this.log,
      playbackPlan,
      blockProgressSec,
      gameProgressSec,
      videoMixer
    );

    const nextItem = context.nextPlaybackItem;

    ensureCurrentHostMediaIsScheduled(
      context.playbackVersion,
      block,
      nextItem?.block,
      gss.ondHostVideoMixerTrackIds,
      context.preloadedMediaCache,
      recording,
      context.blockPlaybackInfo.playbackPlan,
      videoMixer,
      blockProgressSec,
      blockEndingSec,
      blockRemainderMs,
      this.log
    );

    if (
      !SecondQuantizedActionMapUtils.HasMoreWaitableActions(
        actionMap,
        blockProgressSec
      )
    ) {
      const following = nextItem
        ? context.followingPlaybackItem(nextItem.id)?.block ?? null
        : null;

      maybePreloadAndScheduleNextHostVideo(
        context.playbackVersion,
        block,
        nextItem?.block ?? null,
        following,
        blockProgressSec,
        blockEndingSec,
        blockRemainderMs,
        videoMixer,
        gss.ondHostVideoMixerTrackIds,
        this.log
      );

      maybeScheduleOutroHostVideo(
        context.playbackVersion,
        block,
        nextItem?.block ?? null,
        blockProgressSec,
        blockEndingSec,
        blockRemainderMs,
        videoMixer,
        gss.ondHostVideoMixerTrackIds,
        this.log
      );

      // If we have a title block duration, attempt to trigger the next block
      // title so that approximately half of the animation overlaps with the
      // current block. Only half of the `blockTitleAnimationDuration()`
      // duration is allotted during an Ond V3 Recording, with the expectation
      // that the remaining time will be used for overlapping.
      const halfTitleDurationMs = blockTitleAnimationHalfDuration(
        nextItem?.block ?? null
      );
      const nextTitleDurationMs = halfTitleDurationMs * 2;
      const remainingMs = context.blockTimeRemainingMs;

      if (
        nextTitleDurationMs > 0 &&
        remainingMs !== null &&
        remainingMs < halfTitleDurationMs &&
        !this.blockTitleAnimationTriggered
      ) {
        // This is actually _mostly_ unnecessary to track because if all the
        // math works out, calling `triggerBlockTitleAnim` will not cause a new
        // anim to trigger if there is already one running (which should only
        // happen when there is not enough time for the full animation to play
        // again). But in the case that the math is wrong or misconfigured or
        // there is some severe network delay, this is a safety net.
        this.blockTitleAnimationTriggered = true;
        triggerBlockTitleAnim(nextItem?.block ?? null);
      }
    }

    // maybe execute next block action
    const shouldEnterWaitMode = await executeOndAction(
      actionMap,
      blockProgressSec,
      ondState?.waitModeExtSignal,
      block,
      context.fractionalShiftActionsEnabled,
      createActionExecutedObserver(this.log, playbackPlan, videoMixer),
      this.log,
      context.dependencies.townhallAPI,
      async (nextStatus: number) =>
        await context.dependencies.blockLifecycleRulesEvaluator(
          nextStatus,
          context.currentPlaybackItem
        )
    );

    // NOTE: it is only safe to read gss.session.status after executeOndAction,
    // because the system generally doesn't clear out the status until `LOADED`
    // via `loadGameSession()` is written. Reading earlier could result in the
    // previous block's stale data being read instead of what the PhaseRunner
    // considers the current block.

    // Check if we need to preload _before_ moving into wait mode.
    maybePreloadNextVoiceOvers(
      context.preloadedMediaCache,
      block,
      nextItem ?? null,
      context.playbackVersion,
      context.aiHostVoiceId,
      context.ondVoiceOverRegistry,
      context.dependencies.blockRecordingCreator,
      gss.session.status,
      this.log
    );

    if (shouldEnterWaitMode) {
      return new OndPhaseWaitModeTick({
        info: {
          blockActionConfig: shouldEnterWaitMode,
          elapsedSec: 0,
        },
        extSignal: createOndWaitModeExtSignal('wait'),
      });
    }

    context.incrementBlockProgress(1);
    context.incrementSessionProgress(1);

    const map = GameSessionUtil.StatusMapFor(block);
    if (blockProgressSec >= blockEndingSec && map?.end === gss.session.status) {
      return new OndPhaseBlockEnd();
    }

    return this;
  }
}

const logODG = logger.scoped('ond-game');

async function getGSS() {
  // Do not import the store for this entire file, or require a load-time direct
  // reference.
  return (
    await import(
      /* webpackChunkName: "gss" */
      '../store/gameSessionStore'
    )
  ).gameSessionStore;
}

type OndWaitEndOpts = {
  skipHostedTutorial?: boolean;
};

function createOndWaitModeExtSignal(
  mode: OndWaitModeExtSignal['mode']
): OndWaitModeExtSignal {
  return {
    mode,
    // This is meant to be opaque, so as long as the value changes it's fine!
    etag: new Date().toISOString(),
  };
}

/**
 * Helper function to ensure the etag is always updated when writing to the
 * ExtSignal. If specific flags need to be written, create specific wrapper
 * functions for them. This function should not be exposed outside of this file.
 */
async function writeOndWaitModeExtSignal(
  mode: NonNullable<OndWaitModeExtSignal['mode']>,
  change: {
    [K in keyof OndWaitModeExtSignal]?: OndWaitModeExtSignal[K];
  }
) {
  const gss = await getGSS();
  const { refs } = gss;
  const next: OndWaitModeExtSignal = {
    ...gss.ondState?.waitModeExtSignal,
    ...change,
    ...createOndWaitModeExtSignal(mode),
  };
  logODG.info('write extSignal', { waitModeExtSignal: next });
  await refs.ondState?.update({
    waitModeExtSignal: next,
  });
}

/**
 * Call this to signal from outside of the PhaseRunner that wait mode should
 * end.
 */
export async function ondWaitEnd(opts?: OndWaitEndOpts): Promise<void> {
  const gss = await getGSS();
  const { skipHostedTutorial = false } = opts ?? {};

  const mode = gss.ondState?.waitModeExtSignal?.mode;
  if (mode !== 'wait') {
    logODG.info('ondWaitEnd bail, not waiting', {
      waitMode: mode,
    });
    return;
  }

  logODG.info('execute ondWaitEnd');
  await writeOndWaitModeExtSignal('resume', { skipHostedTutorial });
}

/**
 * Normally this is done by the generated recording. A block can optionally
 * control this by setting exitableAfterSec to a large value.
 */
export async function ondWaitReadyForSkip() {
  const gss = await getGSS();
  const mode = gss.ondState?.waitModeExtSignal?.mode;
  if (mode !== 'wait') {
    logODG.info('ondWaitReadyForSkip bail, not waiting', {
      waitMode: mode,
    });
    return;
  }

  await writeOndWaitModeExtSignal(mode, { readyForSkipOverride: true });
}

export async function ondWaitReset(reason: string): Promise<void> {
  const gss = await getGSS();
  const { refs } = gss;
  logODG.info(`ondWaitReset: ${reason}`, {
    waitMode: gss.ondState?.blockEndingSec,
    reason,
  });
  await refs.ondState?.update({
    // Special case: write directly and do not use helper
    waitModeExtSignal: null,
    waitModeInfo: null,
  });
}

export async function ondWaitExtendDuration(
  additionalSeconds: number
): Promise<void> {
  const gss = await getGSS();
  const config = gss.ondState?.waitModeExtSignal;
  const mode = config?.mode;
  if (mode !== 'wait') {
    logODG.info('ondWaitEnd bail, not waiting', {
      waitMode: mode,
    });
    return;
  }

  const extendedDurationSec =
    (config?.extendedDurationSec ?? 0) + additionalSeconds;
  await writeOndWaitModeExtSignal(mode, { extendedDurationSec });
}

export async function configureJump(
  jump: OndPlaybackJump | null
): Promise<void> {
  const gss = await getGSS();
  const { refs } = gss;

  logODG.info('execute configureJump', { jump });
  await refs.ondState?.update({
    jump,
  });
}

export async function configureResumePlaybackItemId(
  resumePlaybackItemId: PlaybackItemId
): Promise<void> {
  const gss = await getGSS();
  const { refs } = gss;

  logODG.info('setting resumePlaybackItemId', { resumePlaybackItemId });
  await refs.ondState?.update({
    resumePlaybackItemId,
  });
}

class OndPhaseWaitModeTick implements Phase {
  readonly name = 'ond-phase-wait-mode-tick';
  readonly log = logger.scoped(this.name);

  private info: GameSessionOndWaitModeInfo;
  private extSignalViaResume:
    | GameSessionOndWaitModeResumeData['extSignal']
    | null = null;
  private lastUpdateExtSignalEtag: string | null = null;

  constructor(resumeData: GameSessionOndWaitModeResumeData) {
    this.info = resumeData.info;
    this.extSignalViaResume = resumeData.extSignal;
  }

  async exit(_: BaseContext, destroying: boolean) {
    // Only clear out firebase data if we (this phase) has purposefully
    // transitioned out of itself. If the runner is being destroyed, it means
    // one of several things:
    //
    // - A. the user chose to pause the game
    // - B. the user chose to skip to gameplay
    // - C. the user is starting a new game, and there was an existing instance
    //   (this is actually impossible, but the design of the store and `reset()`
    //   makes it seem like it's possible)
    // - D. the game has ended and is being destroyed.
    //
    // Possibility A requires that we keep the state, and at this level we can't
    // really tell. So only wipe the data when purposefully exiting, and rely on
    // the existing OND hooks to appropriately call `reset()` when needed.
    if (!destroying) await ondWaitReset('exit OndPhaseWaitModeTick');
  }

  async enter(context: OndPhaseContext) {
    const gss = context.dependencies.gameSessionStore;

    if (!context.blockPlaybackInfo || !gss.ondState) return;

    const { block } = context.blockPlaybackInfo;

    // Blank everything out. This is safe now, since the data has already
    // been read into the resume context during the resume process.
    await ondWaitReset('enter OndPhaseWaitModeTick');

    const { refs } = gss;
    logODG.info('execute ondWaitStart', {
      waitMode: 'wait',
    });

    // Write the "resume" state to the db. It is likely already there, but this
    // keeps data access consistent. We know when it is read (on resume context
    // creation) and we know when it is written (within WaitModeTick).
    await refs.ondState?.update({
      waitModeExtSignal: this.extSignalViaResume,
      waitModeInfo: {
        ...this.info,
        derived: createDerivedWaitModeData(this.info, this.extSignalViaResume),
      },
    });

    // We have written, clear out the ephemeral resume data. extSignal can be
    // written to by external callers, as a way of communicating back to the
    // Runner.
    this.extSignalViaResume = null;

    gss.ondPhaseEmitter.emit(
      'block-enter-wait-mode',
      block,
      context.nextPlaybackItem?.block ?? null,
      this.info.blockActionConfig
    );
  }

  async update(context: OndPhaseContext) {
    const gss = context.dependencies.gameSessionStore;
    if (!gss || !gss.ondState || !gss.ondState.waitModeExtSignal) return this;

    const {
      waitModeExtSignal: { mode: waitMode, etag },
    } = gss.ondState;

    if (waitMode === 'resume') {
      return await this.resumeBlock(context);
    } else if (waitMode === 'wait') {
      if (this.lastUpdateExtSignalEtag !== etag) {
        this.lastUpdateExtSignalEtag = etag;
        // We have a new etag, meaning some data has changed. While the logic will
        // not take effect until fixedUpdate(), write a re-calculated remainingSec
        // (or others) as quickly as possible for the sake of user-feedback.

        const nextInfo: GameSessionOndState['waitModeInfo'] = {
          ...this.info,
          derived: createDerivedWaitModeData(
            this.info,
            gss.ondState.waitModeExtSignal
          ),
        };

        await gss.refs.ondState?.update({
          waitModeInfo: nextInfo,
        });
      }
    }

    return this;
  }

  async fixedUpdate(context: OndPhaseContext): Promise<Phase> {
    const gss = context.dependencies.gameSessionStore;
    if (!gss.ondState?.waitModeExtSignal || !context.blockPlaybackInfo) {
      return new OndPhaseBlockTick();
    }

    const {
      waitModeExtSignal: {
        mode: waitMode,
        extendedDurationSec: extendDurationSec = 0,
      },
    } = gss.ondState;

    if (waitMode === 'wait') {
      this.log.info('block waiting', {
        timer: context.blockProgressSec,
      });

      if (
        this.info.elapsedSec >=
        this.info.blockActionConfig.maxWaitDurationSec + extendDurationSec
      ) {
        this.log.info('block waited max duration', {
          timer: context.blockProgressSec,
          waitElapsedSec: this.info.elapsedSec,
          maxWaitDurationSec: this.info.blockActionConfig.maxWaitDurationSec,
          extendDurationSec,
        });

        // We have passed the maximum wait time, auto-resume without a signal.
        return await this.resumeBlock(context);
      }

      // Increment _after_ checking so that the full duration is used. For
      // example, a QuestionBlock with countdown of 10s actually needs to wait
      // for _11 seconds_ because it starts at `10` and visibly pauses on `0`,
      // aka 11 total seconds.
      this.info.elapsedSec += 1;

      const nextInfo: GameSessionOndState['waitModeInfo'] = {
        ...this.info,
        derived: createDerivedWaitModeData(
          this.info,
          gss.ondState.waitModeExtSignal
        ),
      };

      await gss.refs.ondState?.update({
        waitModeInfo: nextInfo,
      });
    } else if (!waitMode) {
      return new OndPhaseBlockTick();
    } else if (waitMode === 'resume') {
      // let the faster tick handle it
      return this;
    } else {
      assertExhaustive(waitMode);
    }

    context.incrementSessionProgress(1);
    await gss.refs.ondState?.update({
      sessionProgressSec: context.sessionProgressSec,
    });
    return this;
  }

  async resumeBlock(context: OndPhaseContext) {
    this.log.info('block resuming', {
      timer: context.blockProgressSec,
    });

    const gss = context.dependencies.gameSessionStore;
    if (!gss.ondState || !context.blockPlaybackInfo) {
      return new OndPhaseBlockTick();
    }

    const { playbackPlan } = context.blockPlaybackInfo;

    // WaitModeExtSignal takes precedence, but we still may have valid config in
    // blockActionConfig.

    const nextTime =
      SecondQuantizedActionMapUtils.GetTimeForNextActionAfterWaitable(
        playbackPlan.actionMap,
        context.blockProgressSec
      );

    if (nextTime === null) {
      this.log.info('failed to find next action after waitable', {
        actionMap: playbackPlan.actionMap,
        blockProgressSec: context.blockProgressSec,
      });
    }

    context.incrementBlockProgress(
      nextTime === null ? 1 : Math.max(nextTime - context.blockProgressSec, 1)
    );

    // We have decided to transition purposefully, and will not be resuming into
    // wait mode. Cleanup.
    await ondWaitReset('resumeBlock');
    return new OndPhaseBlockTick();
  }
}

function createDerivedWaitModeData(
  info: GameSessionOndWaitModeInfo,
  extSignal?: OndWaitModeExtSignal | null
): GameSessionOndWaitModeDerivedData {
  const { blockActionConfig, elapsedSec } = info;

  // Only if the block action that entered wait mode was configured as exitable
  // and we've waited long enough.
  const readyForSkip =
    extSignal?.readyForSkipOverride ||
    !!(
      blockActionConfig.exitable &&
      elapsedSec >= blockActionConfig.exitableAfterSec
    );
  const maxWaitDurationSec =
    Math.ceil(blockActionConfig.maxWaitDurationSec) +
    (extSignal?.extendedDurationSec ?? 0);
  /**
   * Some blocks must force the user to wait, such as until a video is finished
   * playing.
   * @see {BlockKnifeUtils} makeWaitMode for more details
   */
  const userSkippableMaxDurationSec = Math.floor(
    maxWaitDurationSec - (blockActionConfig.exitableAfterSec ?? 0)
  );
  const remainingSec = Math.floor(maxWaitDurationSec - elapsedSec);

  return {
    readyForSkip,
    userSkippableMaxDurationSec,
    remainingSec,
    lastWaitBeforeEnd: blockActionConfig.lastWaitBeforeEnd,
  };
}

class OndPhaseBlockEnd implements Phase {
  readonly name = 'ond-phase-block-end';
  readonly log = logger.scoped(this.name);

  async update(context: OndPhaseContext) {
    if (context.blockPlaybackInfo) {
      const {
        block,
        playbackPlan: {
          blockRemainderMs,
          extra: { blockTimelineTimeStartMs, correctedRecordingDurationMs },
        },
      } = context.blockPlaybackInfo;
      const vm = context.dependencies.gameSessionStore.videoMixers.ondHostVideo;

      this.log.info('scheduling block end', {
        blockRemainderMs,
        blockId: block.id,
      });
      await sleep(blockRemainderMs);

      const playheadMs = vm?.playheadMs ?? 0;
      const expectedDurationMs = correctedRecordingDurationMs;
      const actualDurationMs = playheadMs - (blockTimelineTimeStartMs ?? 0);
      const blockEndDelayedByMs = actualDurationMs - expectedDurationMs;

      // note: block ids are no longer unique. clear this out.
      context.dependencies.gameSessionStore.ondHostVideoMixerTrackIds.delete(
        asBlockId(block.id)
      );

      this.log.info('block end', {
        blockId: block.id,
        expectedDurationMs,
        actualDurationMs,
        blockEndDelayedByMs,
      });

      // note: we do not await this, since it is not critical to the playback.
      if (context.currentPlaybackItem) {
        context.dependencies.playedBlockTracker.trackPlayedBlock(
          context.currentPlaybackItem,
          context.blockPlayedReportingSummary
        );
      }
    }

    return new OndPhaseNextBlock();
  }
}
