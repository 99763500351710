import { EnumsSharedAssetPurpose } from '@lp-lib/api-service-client/public';

export const PURPOSE_OPTIONS = [
  {
    label: 'General Purpose',
    value: EnumsSharedAssetPurpose.SharedAssetPurposeGeneral,
  },
  {
    label: 'Title/Instruction Card',
    value: EnumsSharedAssetPurpose.SharedAssetPurposeTitleInstructionCard,
  },
  {
    label: 'FAQ Group',
    value: EnumsSharedAssetPurpose.SharedAssetPurposeFaqGroup,
  },
  {
    label: 'Game Pack Showcase / Block UGC',
    value: EnumsSharedAssetPurpose.SharedAssetPurposeGamePackShowcaseCard,
  },
  {
    label: 'Voice',
    value: EnumsSharedAssetPurpose.SharedAssetPurposeVoice,
  },
  {
    label: 'Playlist',
    value: EnumsSharedAssetPurpose.SharedAssetPurposeMusicPlaylist,
  },
];

export function getPurposeLabel(value: EnumsSharedAssetPurpose) {
  return PURPOSE_OPTIONS.find((o) => o.value === value)?.label ?? value;
}
