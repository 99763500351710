import { type EnumsHiddenPicturePenaltyResetStrategy } from '@lp-lib/api-service-client/public';
import { type RTDBServerValueTIMESTAMP } from '@lp-lib/firebase-typesafe';
import { type HiddenPicture } from '@lp-lib/game';

import { type TeamId } from '../../../../types';

export type AdditionalSettings = Pick<
  HiddenPicture,
  'everyoneClicks' | 'sequenced' | 'tool' | 'incorrectAnswerPenalty'
>;

export type AsymmetricSettings = Pick<
  HiddenPicture,
  | 'asymmetricGamePlay'
  | 'asymmetricMedia'
  | 'asymmetricMediaData'
  | 'asymmetricPinDropVisibility'
  | 'asymmetricPinDropAudibility'
>;

export enum GameState {
  None = 1,
  Inited,
  InProgress,
  Ended,
}

export type Game = {
  id: string;
  state: GameState;
  pictures: HiddenPicture[];
  maxPenaltyLimit: number | null;
  maxPenaltyLimitLabel: string | null;
  penaltyResetStrategy: EnumsHiddenPicturePenaltyResetStrategy | null;
  startTime: number | null;
};

export type Pin = {
  x: number;
  y: number;
  clientId: string;
  clientName: string;
  createdAt: number | RTDBServerValueTIMESTAMP;
  // these are all the hotspots that this pin intersects with, regardless of sequence.
  intersectingHotSpotIds: string[] | null;
  // the grade on the pin is influenced by the value of `sequenced`. so although this pin may intersect with
  // hotspots, it may still be graded as a miss.
  grade: 'hit' | 'miss';
  // this is a subset of `intersectingHotSpotIds`. when `sequenced` is false, this will be the same as
  // `intersectingHotSpotIds`.
  foundHotSpotIds: string[] | null;
};

// a kind of user "ping" that players can drop to help with coordination. avoiding the name `ping` since it is so close
// to `pin`.
export type Signal = {
  x: number;
  y: number;
  clientId: string;
  createdAt: number | RTDBServerValueTIMESTAMP;
};

// defines all the pins that have been placed.
// this is keyed automatically by firebase.
export type Pins = {
  [key: string]: Pin;
};

// defines which items have been found, and by which pin.
// an item can only be found once. the pin will be graded as 'hit'.
export type FoundHotSpots = {
  [hotSpotId: string]: Pin;
};

export type Signals = {
  [key: string]: Signal;
};

export type ToolAssignment = {
  tool: HiddenPicture['tool'];
  clientId: string;
};

export type Tools = {
  [tool: string]: { x: number; y: number } | undefined;
};

export type TeamGamePlayData = {
  toolAssignment: ToolAssignment | null;
  tools: Tools | null;
  pins: Pins | null;
  signals: Signals | null;
};

export type TeamProgressData = {
  currentPictureIndex: number | null;
  numPicturesCompleted: number | null;
  numItemsFound: number | null;
  numPenalties: number | null;
  score: number | null;
  found: FoundHotSpots | null;
  completedAllAt: number | RTDBServerValueTIMESTAMP | null;
};

export type AllTeamsProgressData = {
  [key: TeamId]: TeamProgressData | undefined;
};
