import React, {
  type ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  getFeatureQueryParam,
  getFeatureQueryParamArray,
  useFeatureQueryParam,
} from '../../hooks/useFeatureQueryParam';
import { useIsController } from '../../hooks/useMyInstance';
import { profileForCustomVideo } from '../../services/webrtc';
import { releaseMediaStream } from '../../utils/media';
import {
  CanvasProxy,
  DummyProxy,
  type IVideoStreamProxy,
} from '../../utils/videoStreamProxy';
import { CollaborativeDrawing } from '../Drawing';
import { ExperienceScoreBlockEndEmitter } from '../ExperienceScore/ExperienceScoreContext';
import { useIsStreamSessionAlive, useStreamSessionId } from '../Session';
import {
  OndGameAutoSwitchTownhallMode,
  useOndGameAutoToggleTownhall,
} from '../Townhall';
import { useOndTownhallAutoMute } from '../Townhall/hooks/useTownhallAutoMute';
import { useVenueId } from '../Venue';
import {
  useIsCoreChannelJoined,
  usePublishStream,
  useRTCService,
} from '../WebRTC';
import { GameControl } from './Blocks/Common/GameControl/GameControl';
import { GamePlay, GamePlayProvider } from './Blocks/Common/GamePlay';
import { BlockVoteWidget } from './Blocks/Common/GamePlay/BlockVoteWidget';
import { GamePlayBlockTitleTransition } from './Blocks/Common/GamePlay/GamePlayBlockTitleTransition';
import { GamePlayScoreboardManager } from './Blocks/Common/GamePlay/GamePlayScoreboard';
import { GamePlayAllTeamsFinishedTransition } from './Blocks/Common/GamePlay/GamePlayStageFinishedTransition';
import { GameVideoTestPlayback } from './GameVideoTestPlayback';
import { useIsLiveGamePlay } from './hooks';
import { useAutoPreloadBlockVideos } from './hooks/preload';

function buildLoopConfig(isLiveGamePlay: boolean) {
  const loopMethod = getFeatureQueryParamArray('game-play-video-loop-method');
  const loopUseWorker = getFeatureQueryParamArray(
    'game-play-video-loop-use-worker'
  );
  return {
    loopMethod:
      loopMethod === 'auto' ? (isLiveGamePlay ? 'raf' : 'timer') : loopMethod,
    loopUseWorker:
      loopUseWorker === 'auto' ? !isLiveGamePlay : loopUseWorker === 'enabled',
  };
}

function useSetupVideoStreamProxy(
  getLoopConfig = buildLoopConfig
): IVideoStreamProxy {
  const isLiveGamePlay = useIsLiveGamePlay();
  const isController = useIsController();
  const [videoProxy, setVideoProxy] = useState<IVideoStreamProxy>(
    new DummyProxy()
  );
  useEffect(() => {
    if (!isController) return;
    const debug = getFeatureQueryParam('game-play-video-proxy-debug');
    const useVideoPlaceholder = getFeatureQueryParam(
      'game-play-video-proxy-use-video-placeholder'
    );
    const profile = profileForCustomVideo(
      getFeatureQueryParamArray('game-play-video-profile')
    );
    const vProxy = new CanvasProxy({
      ...getLoopConfig(isLiveGamePlay),
      debug,
      useVideoPlaceholder,
      fps: profile.framerate,
      cvsWidth: profile.width,
      cvsHeight: profile.height,
    });
    setVideoProxy(vProxy);
    return () => {
      vProxy.destroy();
    };
  }, [isLiveGamePlay, getLoopConfig, isController]);

  useEffect(() => {
    return () => {
      videoProxy.destroy();
    };
  }, [videoProxy]);
  return videoProxy;
}

interface VideoStreamProxyContext {
  videoStreamProxy: IVideoStreamProxy;
}

const ProxyContext = React.createContext<VideoStreamProxyContext>(
  {} as VideoStreamProxyContext
);

export const useGameVideoStreamProxy = (): IVideoStreamProxy => {
  const ctx = useContext(ProxyContext);
  return ctx.videoStreamProxy;
};

function LocalStreamStub(): JSX.Element {
  const joined = useIsCoreChannelJoined('game');
  const videoStreamProxy = useGameVideoStreamProxy();
  const [mediaStream, setMediaStream] = useState(
    videoStreamProxy.getMediaStream()
  );
  const rtcService = useRTCService('game');
  const { enqueueSynchronize, enqueueOp } = usePublishStream(rtcService);

  useEffect(() => {
    setMediaStream(videoStreamProxy.getMediaStream());
    const off = videoStreamProxy.on(
      'media-stream-updated',
      (stream?: MediaStream) => {
        setMediaStream(stream);
      }
    );
    return () => off();
  }, [videoStreamProxy]);

  useEffect(() => {
    if (!mediaStream || !joined) return;
    const audioTrack = mediaStream.getAudioTracks()[0];
    const videoTrack = mediaStream.getVideoTracks()[0];

    // videoTrack is required for game stream
    if (!videoTrack) {
      rtcService.log.warn('no video track');
      return;
    }

    enqueueOp((rtc) => {
      rtc.switchVideo(videoTrack);
      if (audioTrack) {
        rtc.switchAudio(audioTrack);
      }
    });

    enqueueSynchronize(true);
    return () => {
      enqueueSynchronize(false);
      releaseMediaStream(mediaStream);
    };
  }, [enqueueOp, enqueueSynchronize, joined, mediaStream, rtcService]);
  return <></>;
}

const VideoStreamProxyProvider = (props: {
  children: ReactNode;
}): JSX.Element => {
  const videoStreamProxy = useSetupVideoStreamProxy();

  const ctxValue = useMemo(() => ({ videoStreamProxy }), [videoStreamProxy]);
  return (
    <ProxyContext.Provider value={ctxValue}>
      {props.children}
    </ProxyContext.Provider>
  );
};

const gamePlayMediaEnabled =
  getFeatureQueryParamArray('game-play-media') !== 'disabled';

export function GameSessionBundle(): JSX.Element | null {
  const isController = useIsController();
  const isSessionAlive = useIsStreamSessionAlive();
  const sessionId = useStreamSessionId();
  const venueId = useVenueId();

  const testPlaybackEnabled = useFeatureQueryParam(
    'game-play-video-test-playback'
  );

  useAutoPreloadBlockVideos(isController);
  useOndGameAutoToggleTownhall();
  useOndTownhallAutoMute();

  if (!isSessionAlive) {
    return (
      <>
        <CollaborativeDrawing
          enabled={getFeatureQueryParam('drawing-collaborative-experiment')}
          cursor={getFeatureQueryParam('drawing-collaborative-cursor')}
        />
      </>
    );
  }
  return (
    <VideoStreamProxyProvider>
      <GamePlayProvider>
        <GamePlay />
        <GamePlayScoreboardManager />
        <GamePlayBlockTitleTransition />
        <GamePlayAllTeamsFinishedTransition />
        <BlockVoteWidget />
        <ExperienceScoreBlockEndEmitter />
        {isController && (
          <GameControl venueId={venueId} sessionId={sessionId} />
        )}
        {isController && gamePlayMediaEnabled && <LocalStreamStub />}
        {isController && <OndGameAutoSwitchTownhallMode />}
        {testPlaybackEnabled && <GameVideoTestPlayback />}
      </GamePlayProvider>
    </VideoStreamProxyProvider>
  );
}
