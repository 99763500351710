import { type ReactNode, useEffect, useState } from 'react';

import { createProvider } from '../../utils/createProvider';
import { firebaseService } from '../Firebase';
import { useGameSessionActionsSignalManager } from '../Game/hooks';
import { SubtitlesManager } from './VoiceOverRegistry';

export function SubtitlesManagerProvider(props: {
  children?: ReactNode;
  venueId: string;
}) {
  const [api] = useState(
    () => new SubtitlesManager(props.venueId, firebaseService)
  );

  const signalman = useGameSessionActionsSignalManager();
  useEffect(() => {
    // Cleanup subtitle messages whenever the game resets. COORDINATOR ONLY
    return signalman.connect({
      name: 'reset',
      after: async () => api.cleanupMessages(),
    });
  }, [api, signalman]);

  useEffect(() => {
    api.listen();
    return () => {
      api.destroy();
    };
  }, [api]);

  return <SMPProvider value={api}>{props.children}</SMPProvider>;
}
const {
  Provider: SMPProvider,

  useCreatedContext: useGlobalSubtitlesManager,
} = createProvider<SubtitlesManager>('SubtitlesManagerProvider');

export {
  /**
   * The global subtitles manager is a singleton, per client, that handles both
   * emitting subtitles to the local user, receiving subtitles from a
   * controller, and broadcasting subtitles to all clients.
   */
  useGlobalSubtitlesManager,
};
