import {
  type HiddenPicture,
  type HiddenPictureBlock,
  type HotSpot,
  type Media,
} from '@lp-lib/game';

import logger from '../../../../logger/logger';
import { type TeamId } from '../../../../types';
import { ondTemporaryStageMedia } from '../../ondTemporaryStage';
import { type FoundHotSpots } from './types';

export const log = logger.scoped('hidden-picture');

export const MIN_BOX_RATIO = 0.05;
export const ASPECT_RATIO = 16 / 9;

const minY = MIN_BOX_RATIO;
const maxY = 1 - minY;
const minX = MIN_BOX_RATIO / ASPECT_RATIO;
const maxX = 1 - minX;

export type Point = { x: number; y: number };
export type CoordinateSystem = { maxX: number; maxY: number };

export class HiddenPictureUtils {
  static FromUnitGridHotSpot(
    hotSpot: HotSpot,
    toCoordinateSystem: CoordinateSystem
  ): HotSpot {
    return {
      ...hotSpot,
      top: hotSpot.top * toCoordinateSystem.maxY,
      left: hotSpot.left * toCoordinateSystem.maxX,
      radius: hotSpot.radius * toCoordinateSystem.maxY,
    };
  }

  static ToUnitGridHotSpot(
    hotSpot: HotSpot,
    fromCoordinateSystem: CoordinateSystem
  ): HotSpot {
    return {
      ...hotSpot,
      top: hotSpot.top / fromCoordinateSystem.maxY,
      left: hotSpot.left / fromCoordinateSystem.maxX,
      radius: hotSpot.radius / fromCoordinateSystem.maxY,
    };
  }

  static ToUnitGrid(
    point: Point,
    fromCoordinateSystem: CoordinateSystem
  ): Point {
    return {
      x: point.x / fromCoordinateSystem.maxX,
      y: point.y / fromCoordinateSystem.maxY,
    };
  }

  static Bound(hotSpot: HotSpot): HotSpot {
    const bounded = { ...hotSpot };

    // we need the 16/9 ratio to be maintained when examining the horizontal bounds
    if (
      bounded.left + (bounded.radius / ASPECT_RATIO) * 2 < minX ||
      bounded.top + bounded.radius * 2 < minY
    ) {
      bounded.left = Math.max(
        bounded.left,
        minX - (bounded.radius / ASPECT_RATIO) * 2
      );
      bounded.top = Math.max(bounded.top, minY - bounded.radius * 2);
    }

    if (bounded.left > maxX || bounded.top > maxY) {
      bounded.left = Math.min(bounded.left, maxX);
      bounded.top = Math.min(bounded.top, maxY);
    }
    return bounded;
  }

  static GetBackgroundMedia(
    block: HiddenPictureBlock,
    fallback = ondTemporaryStageMedia
  ): Media {
    return block.fields.backgroundMedia ?? fallback;
  }

  static RootPath(venueId: string): string {
    return `hidden-picture/${venueId}`;
  }

  static GamePath(venueId: string, blockId: string): string {
    return `${this.RootPath(venueId)}/${blockId}/game`;
  }

  static TeamGamePlayPath(
    venueId: string,
    blockId: string,
    teamId: TeamId,
    kind?: 'pins' | 'signals' | 'toolAssignment' | 'tools'
  ): string {
    return `${this.RootPath(venueId)}/${blockId}/team-gameplay/${teamId}${
      kind ? `/${kind}` : ''
    }`;
  }

  static TeamGamePlayToolPath(
    venueId: string,
    blockId: string,
    teamId: TeamId,
    tool: Omit<HiddenPicture['tool'], 'none'>
  ): string {
    return `${this.TeamGamePlayPath(
      venueId,
      blockId,
      teamId,
      'tools'
    )}/${tool}`;
  }

  static TeamProgressPath(
    venueId: string,
    blockId: string,
    teamId?: TeamId
  ): string {
    return `${this.RootPath(venueId)}/${blockId}/team-progress${
      teamId ? `/${teamId}` : ''
    }`;
  }

  static FindIntersectingHotSpots(
    point: Point,
    hotspots: HotSpot[]
  ): HotSpot[] {
    // these are all the hotspots that intersect with the point, in order.
    return hotspots.filter((hotspot) => {
      // this is checking intersection with an ellipse since the circle is skewed when mapped to the unit square.
      const centerX = hotspot.left + hotspot.radius / ASPECT_RATIO;
      const centerY = hotspot.top + hotspot.radius;

      const dx = point.x - centerX;
      const dy = point.y - centerY;
      const normalizedX = dx / (hotspot.radius / ASPECT_RATIO);
      const normalizedY = dy / hotspot.radius;

      const distance = normalizedX * normalizedX + normalizedY * normalizedY;
      return distance <= 1;
    });
  }

  static GradeIntersectingHotSpots(
    intersectingHotSpots: HotSpot[],
    configuredHotSpots: HotSpot[],
    sequenced: boolean,
    foundHotSpotIds: Set<string>
  ): HotSpot[] {
    if (!sequenced) {
      // when not sequenced, we just return the intersecting hotspots.
      return intersectingHotSpots;
    }

    const nextHotspots = [];
    for (const i of intersectingHotSpots) {
      // Get the index of the current hotspot
      const idx = configuredHotSpots.findIndex((h) => h.id === i.id);
      // If it's not the first one and the previous one hasn't been found, break
      if (idx > 0 && !foundHotSpotIds.has(configuredHotSpots[idx - 1].id))
        break;

      nextHotspots.push(i);
      // add the current hotspot to the found list, in case we have multiple intersecting hotspots in a sequence
      foundHotSpotIds.add(i.id);
    }

    return nextHotspots;
  }

  static AllFound(
    configuredHotSpots: HotSpot[],
    foundHotSpots: FoundHotSpots | null | undefined
  ): boolean {
    if (configuredHotSpots.length === 0) return true;
    if (!foundHotSpots || Object.keys(foundHotSpots).length === 0) return false;

    // note: only count hotspots with positive points
    return configuredHotSpots
      .filter((h) => h.points >= 0)
      .every((h) => foundHotSpots[h.id]);
  }

  static GetProgressableHotSpots(
    configuredHotSpots: HotSpot[] | null | undefined
  ): HotSpot[] {
    if (!configuredHotSpots) return [];
    return configuredHotSpots.filter((h) => h.points >= 0);
  }

  static CountFoundProgressableHotSpots(
    configuredHotSpots: HotSpot[] | null | undefined,
    foundHotSpots: FoundHotSpots | null | undefined
  ): number {
    if (!foundHotSpots || !configuredHotSpots) return 0;
    const progressableHotSpotIds = new Set(
      this.GetProgressableHotSpots(configuredHotSpots).map((h) => h.id)
    );

    return Object.keys(foundHotSpots).filter((id) => {
      return progressableHotSpotIds.has(id);
    }).length;
  }
}
