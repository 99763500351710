import { type Logger } from '@lp-lib/logger-base';

import { assertExhaustive } from '../../../utils/common';
import { type IVideoMixerTrack } from '../IVideoMixerTrack';
import { type MESNFactory } from '../MESNFactory';
import { type VideoMixerEmitter } from '../types';
import { fixed4 } from '../utils';

export function audioTickHandler(
  prevTime: number,
  nextTime: number,
  track: IVideoMixerTrack,
  playing: boolean,
  log: Logger,
  emitter: VideoMixerEmitter,
  audioCtx: AudioContext,
  mesn: MESNFactory
): void {
  const [startMs, endMs] = track.computeActiveTimelineTimeMs();

  // Is it time to schedule an audio transition? This should happen before a
  // video begins playing.
  const source = track.getAudioSource();
  if (playing && source) {
    const audioEv = track.nextAudioEvents(
      prevTime,
      nextTime,
      audioCtx.currentTime
    );
    for (const ev of audioEv) {
      switch (ev.param) {
        case 'gain': {
          log.info(`track ${track.id} scheduling audio transition`, {
            playhead: fixed4(prevTime),
            startMs: fixed4(startMs),
            endMs: fixed4(endMs),
            param: ev.param,
            ctxStartSec: ev.ctxStartSec,
            ctxEndSec: ev.ctxEndSec,
            initialValue: ev.initialValue,
            goalValue: ev.goalValue,
            curve: ev.curve,
          });

          emitter.emit('track-audio-transition-start', track.id, prevTime);

          // setValueCurveAtTime sometimes throws a NotSupportedError if the
          // curve overlaps with an existing incompatible scheduled param change
          // (https://stackoverflow.com/q/58204307/169491) and canceling values
          // is hard (see the post). Therefore, ensure the param value is
          // well-defined for any future changes by scheduling a setValueAtTime
          // to just _before_ the transition should begin.
          const TRANSITION_EPSILON_SECONDS = 0.001;
          const [, gain] = mesn.wire(source);

          // NOTE(drew): This try/catch should be unnecessary, but it seems that
          // occasionally a nonsensical error is thrown: "Failed to execute
          // 'setValueCurveAtTime' on 'AudioParam': setValueCurveAtTime(...,
          // 1662.506666666667, 1) overlaps setValueAtTime(1, 1662.512)". This
          // is nonsensical because somehow `ev.ctxStartSec` is different
          // between calls! The only explanation I can think of is that
          // `prevTime` and `nextTime` have not changed between ticks (due to
          // resource contention), and the same events are being scheduled
          // twice. To avoid this exceptional case, wrap it all in a try/catch.
          // Ideally we'd track if the values have been scheduled before but
          // that model doesn't exist in the track code today (it is relatively
          // stateless...) and there are additional complexities around seeking.
          // Also, canceling scheduled value changes requires knowing _when_ the
          // change was scheduled to _start_, not just that changes are taking
          // place: https://stackoverflow.com/q/58204307/169491

          try {
            if (ev.curve === 'none') {
              gain.gain.setValueAtTime(
                ev.initialValue,
                Math.max(ev.ctxStartSec, 0)
              );
              gain.gain.setValueAtTime(ev.goalValue, Math.max(ev.ctxEndSec, 0));
            } else if (typeof ev.curve === 'string') {
              gain.gain.setValueAtTime(
                ev.initialValue,
                Math.max(ev.ctxStartSec, 0)
              );
              gain.gain.linearRampToValueAtTime(ev.goalValue, ev.ctxEndSec);
            } else {
              gain.gain.setValueAtTime(
                ev.initialValue,
                Math.max(ev.ctxStartSec, 0)
              );
              gain.gain.setValueCurveAtTime(
                ev.curve,
                ev.ctxStartSec + TRANSITION_EPSILON_SECONDS,
                ev.ctxEndSec - ev.ctxStartSec - TRANSITION_EPSILON_SECONDS
              );
            }
          } catch (err) {
            log.error(`Failed to schedule audio transition`, err, {
              playhead: fixed4(prevTime),
              startMs: fixed4(startMs),
              endMs: fixed4(endMs),
              param: ev.param,
              ctxStartSec: ev.ctxStartSec,
              ctxEndSec: ev.ctxEndSec,
              initialValue: ev.initialValue,
              goalValue: ev.goalValue,
              curve: ev.curve,
            });
          }

          break;
        }

        default:
          assertExhaustive(ev.param);
      }
    }
  }
}
