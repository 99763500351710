import { ErrorMessage } from '@hookform/error-message';
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import Select from 'react-select';

import {
  type DtoSharedAsset,
  EnumsMediaScene,
  EnumsSharedAssetPurpose,
  type ModelsTTSRenderSettings,
} from '@lp-lib/api-service-client/public';
import { type Media, type MediaData } from '@lp-lib/media';

import { useInstance } from '../../hooks/useInstance';
import { apiService } from '../../services/api-service';
import { type GamePackShowcaseCardData } from '../../types/game';
import { fromMediaDTO } from '../../utils/api-dto';
import { buildReactSelectStyles } from '../../utils/react-select';
import { type Option } from '../common/Utilities';
import { ModalWrapper } from '../ConfirmCancelModalContext/ModalWrapper';
import { type FAQ } from '../FAQ';
import { FAQListEditor } from '../FAQ/FAQEditor';
import { MediaEditor } from '../MediaUploader/MediaEditor';
import { PlaylistEditor } from '../MusicPlayer/MusicPlayerEditor';
import { type Song } from '../MusicPlayer/types';
import { TTSRenderSettingsForm } from '../VoiceOver/TTSLabeledSettingsEditor';
import { PURPOSE_OPTIONS } from './utils';

type SharedAssetData = {
  cardText: string | null;
  faqs: FAQ[] | null;
  showcaseCard: GamePackShowcaseCardData | null;
  ttsRenderSettings: ModelsTTSRenderSettings | null;
  playlist: Song[] | null;
};

type FormData = {
  purpose: EnumsSharedAssetPurpose;
  media: {
    mediaData: MediaData;
    media: Media | null;
  } | null;
  data: SharedAssetData;
  label: string;
};

function SharedAssetPurposeSelect(props: {
  value: EnumsSharedAssetPurpose;
  onChange: (value: EnumsSharedAssetPurpose) => void;
  disabled?: boolean;
}) {
  const { value, onChange, disabled } = props;

  const styles = useInstance(() =>
    buildReactSelectStyles<Option<EnumsSharedAssetPurpose>>({
      override: { control: { height: 52 } },
    })
  );

  return (
    <Select<Option<EnumsSharedAssetPurpose>>
      options={PURPOSE_OPTIONS}
      value={PURPOSE_OPTIONS.find((opt) => opt.value === value)}
      classNamePrefix='select-box-v2'
      className='w-full'
      styles={styles}
      placeholder='Select a category'
      onChange={(option) => {
        if (!option) return;
        onChange(option.value);
      }}
      isDisabled={disabled}
    />
  );
}

function SharedAssetPurposeField(props: { item: DtoSharedAsset | null }) {
  return (
    <div className='w-full'>
      <h3 className='text-base font-bold'>
        Category (Immutable after creation)
      </h3>
      <Controller<FormData, 'purpose'>
        name='purpose'
        rules={{ required: 'category is required' }}
        render={({ field: { onChange, value } }) => (
          <SharedAssetPurposeSelect
            value={value}
            onChange={onChange}
            disabled={!!props.item}
          />
        )}
      />
    </div>
  );
}

function SharedAssetInternalLabelField() {
  const { register, formState } = useFormContext<FormData>();

  return (
    <div className='w-full'>
      <h3 className='text-base font-bold'>Internal Label</h3>
      <input
        {...register('label', {
          maxLength: 75,
          required: true,
          minLength: 1,
        })}
        className={`w-full h-12.5 ${
          formState.errors.label ? 'field-error' : 'field'
        } mb-0 mt-1`}
        placeholder='Max 75 characters'
      />
    </div>
  );
}

function SharedAssetMediaField() {
  const purpose = useWatch<FormData>({
    name: 'purpose',
  });

  if (
    purpose === EnumsSharedAssetPurpose.SharedAssetPurposeFaqGroup ||
    purpose === EnumsSharedAssetPurpose.SharedAssetPurposeVoice ||
    purpose === EnumsSharedAssetPurpose.SharedAssetPurposeMusicPlaylist
  ) {
    return null;
  }

  return (
    <div className='w-full'>
      <h3 className='text-base font-bold'>Media</h3>
      <Controller<FormData, 'media'>
        name='media'
        rules={{ required: 'media is required' }}
        render={({ field: { onChange, value }, formState }) => (
          <>
            <MediaEditor
              video={true}
              scene={EnumsMediaScene.MediaSceneBlockMedia}
              mediaData={value?.mediaData ?? null}
              media={value?.media ?? null}
              onChange={(mediaData, media) => {
                onChange({
                  mediaData,
                  media,
                });
              }}
            />
            <ErrorMessage
              errors={formState.errors}
              name='media'
              as='p'
              className='text-sms text-red-002'
            />
          </>
        )}
      />
    </div>
  );
}

function SharedAssetDataCardTextField() {
  return (
    <div className='w-full'>
      <h3 className='text-base font-bold'>Card Text</h3>
      <Controller<FormData, 'data.cardText'>
        name='data.cardText'
        render={({ field: { onChange, value } }) => (
          <input
            className='w-full h-12.5 field'
            placeholder='Max 150 characters'
            value={value ?? ''}
            onChange={(e) => {
              onChange(e.target.value);
            }}
          />
        )}
      />
    </div>
  );
}

function SharedAssetDataFAQField() {
  const { fields, append, update, remove, move } = useFieldArray<
    FormData,
    'data.faqs',
    'key'
  >({
    name: 'data.faqs',
    keyName: 'key',
  });

  return (
    <FAQListEditor
      faqs={fields}
      onAdd={append}
      onDelete={remove}
      onMove={move}
      onChange={update}
    />
  );
}

function SharedAssetDataPlaylistField() {
  const { fields, append, update, remove, move } = useFieldArray<
    FormData,
    'data.playlist',
    'key'
  >({
    name: 'data.playlist',
    keyName: 'key',
  });

  return (
    <PlaylistEditor
      songs={fields}
      onAdd={append}
      onDelete={remove}
      onMove={move}
      onChange={update}
    />
  );
}

function SharedAssetDataShowcaseCardField() {
  return (
    <>
      <div className='w-full'>
        <h3 className='text-base font-bold'>Primary Text</h3>
        <Controller<FormData, 'data.showcaseCard.primaryText'>
          name='data.showcaseCard.primaryText'
          render={({ field: { onChange, value } }) => (
            <input
              className='w-full h-12.5 field'
              placeholder='Max 150 characters'
              value={value ?? ''}
              onChange={(e) => {
                onChange(e.target.value);
              }}
            />
          )}
        />
      </div>
      <div className='w-full'>
        <h3 className='text-base font-bold'>Secondary Text</h3>
        <Controller<FormData, 'data.showcaseCard.secondaryText'>
          name='data.showcaseCard.secondaryText'
          render={({ field: { onChange, value } }) => (
            <input
              className='w-full h-12.5 field'
              placeholder='Max 150 characters'
              value={value ?? ''}
              onChange={(e) => {
                onChange(e.target.value);
              }}
            />
          )}
        />
      </div>
      <div className='w-full'>
        <h3 className='text-base font-bold'>Hint (UGC - How it works)</h3>
        <Controller<FormData, 'data.showcaseCard.hint'>
          name='data.showcaseCard.hint'
          render={({ field: { onChange, value } }) => (
            <input
              className='w-full h-12.5 field'
              placeholder='Max 300 characters'
              value={value ?? ''}
              onChange={(e) => {
                onChange(e.target.value);
              }}
            />
          )}
        />
      </div>
    </>
  );
}

function SharedAssetDataField() {
  const purpose = useWatch<FormData>({
    name: 'purpose',
  });

  switch (purpose) {
    case EnumsSharedAssetPurpose.SharedAssetPurposeFaqGroup:
      return <SharedAssetDataFAQField />;
    case EnumsSharedAssetPurpose.SharedAssetPurposeTitleInstructionCard:
      return <SharedAssetDataCardTextField />;
    case EnumsSharedAssetPurpose.SharedAssetPurposeGamePackShowcaseCard:
      return <SharedAssetDataShowcaseCardField />;
    case EnumsSharedAssetPurpose.SharedAssetPurposeVoice:
      return (
        <Controller<FormData, 'data.ttsRenderSettings'>
          name='data.ttsRenderSettings'
          render={({ field: { onChange, value } }) => (
            <TTSRenderSettingsForm onChange={onChange} value={value} />
          )}
        />
      );
    case EnumsSharedAssetPurpose.SharedAssetPurposeMusicPlaylist:
      return <SharedAssetDataPlaylistField />;
    default:
      return null;
  }
}

export function SharedAssetEditorModal(props: {
  item: DtoSharedAsset | null;
  onCancel: () => void;
  onSave: (data: DtoSharedAsset) => void;
}) {
  const { item, onCancel, onSave } = props;

  const formReturned = useForm<FormData>({
    defaultValues: {
      purpose:
        item?.purpose ||
        EnumsSharedAssetPurpose.SharedAssetPurposeTitleInstructionCard,
      label: item?.label || '',
      media: item?.media
        ? {
            mediaData: {
              id: item.media.id,
            },
            media: fromMediaDTO(item.media),
          }
        : null,
      data: item?.data || {},
    },
  });

  const handleSave = async (data: FormData) => {
    if (!item) {
      const resp = await apiService.media.createSharedAsset({
        purpose: data.purpose,
        label: data.label,
        mediaId: data.media?.media?.id || null,
        data: {
          ...data.data,
        },
      });
      onSave(resp.data.sharedAsset);
      return;
    }

    const resp = await apiService.media.updateSharedAsset(item.id, {
      purpose: data.purpose,
      label: data.label,
      mediaId: data.media?.media?.id || null,
      data: {
        ...data.data,
      },
    });
    onSave(resp.data.sharedAsset);
  };

  return (
    <ModalWrapper
      containerClassName='w-160'
      borderStyle='gray'
      onClose={onCancel}
    >
      <FormProvider {...formReturned}>
        <form
          className='w-full flex flex-col items-center justify-start gap-3 px-5 py-10'
          onSubmit={formReturned.handleSubmit(handleSave)}
        >
          <header className='text-2xl font-medium'>
            {item?.id ? 'Edit' : 'Create'} Shared Asset
          </header>

          <SharedAssetPurposeField item={item} />

          <SharedAssetMediaField />

          <SharedAssetDataField />

          <SharedAssetInternalLabelField />

          <div className='w-full flex justify-center items-center gap-2'>
            <button
              type='button'
              className='btn-secondary w-35 h-10'
              onClick={onCancel}
            >
              Cancel
            </button>
            <button type='submit' className='btn-primary w-35 h-10'>
              Save
            </button>
          </div>
        </form>
      </FormProvider>
    </ModalWrapper>
  );
}
