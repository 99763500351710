import { apiService } from '../../services/api-service';
import { hashObject } from '../../utils/hash-object';

export type TemplateRenderResult = {
  script: string;
  resolvedCount: number;
  totalCount: number;
  resolved: boolean;
};

export interface TemplateRenderer {
  render(template: string): Promise<TemplateRenderResult>;
}

export class VariableRegistry
  extends Map<string, () => Promise<string>>
  implements TemplateRenderer
{
  static FromRecord(
    record: Record<string, null | undefined | string | (() => Promise<string>)>
  ) {
    const registry = new VariableRegistry();
    Object.entries(record).forEach(([key, value]) => {
      if (value) {
        registry.set(
          key,
          typeof value === 'string' ? async () => value : value
        );
      }
    });
    return registry;
  }

  async intoRecord() {
    const record: Record<string, string> = {};

    for (const [key, value] of this) {
      record[key] = await value();
    }

    return record;
  }

  async render(template: string): Promise<TemplateRenderResult> {
    let resolvedCount = 0;
    const variables = extractVariables(template);
    await Promise.all(
      variables.map(async (variable) => {
        const value = await this.get(variable)?.();
        if (value !== undefined) {
          template = renderVariable(template, variable, value);
          resolvedCount++;
        }
      })
    );

    return {
      script: template,
      resolvedCount,
      totalCount: variables.length,
      resolved: resolvedCount === variables.length,
    };
  }

  destroy() {
    this.clear();
  }
}

export class LLMTemplateRenderer implements TemplateRenderer {
  constructor(
    private readonly vars: VariableRegistry,
    private readonly cache = new Map<string, TemplateRenderResult>()
  ) {}

  async render(template: string): Promise<TemplateRenderResult> {
    let result = await this.vars.render(template);
    if (!result.resolved) return result;

    // fully resolved, so push it to the llm.
    result = await this.resolveLLM(result.script);
    if (result.resolved) return result;

    // there are still variables in the template...
    return this.vars.render(result.script);
  }

  private async resolveLLM(template: string): Promise<TemplateRenderResult> {
    const hash = await hashObject({ template });
    let result = this.cache.get(hash);
    if (result) return result;

    if (hasLLMCodeFences(template)) {
      const { data } = await apiService.tts.resolveCodeFences({
        script: template,
      });
      template = data.script;
    }

    const variables = extractVariables(template);
    result = {
      script: template,
      resolvedCount: 0,
      totalCount: variables.length,
      resolved: variables.length === 0,
    };
    this.cache.set(hash, result);
    return result;
  }
}

export function extractVariables(template: string): string[] {
  const matches = Array.from(template.matchAll(/%([^%]+)%/g));
  return matches.map((match) => match[1]);
}

export function renderVariable(
  template: string,
  variable: string,
  value: string
): string {
  return template.replaceAll(`%${variable}%`, value);
}

export function hasVariables(template: string): boolean {
  return /%([^%]+)%/.test(template);
}

export function hasVariable(script: string, variable: string) {
  return script.indexOf(`%${variable}%`) > -1;
}

/**
 * Idempotent.
 */
export function prependVariable(script: string, variable: string) {
  if (hasVariable(script, variable)) return script;
  return `%${variable}% ${script}`;
}

export function hasLLMCodeFences(template: string): boolean {
  const re = /```([a-zA-Z0-9]+)\s*(\{[\s\S]*?})?\s*\n([\s\S]*?)```/;
  return re.test(template);
}
