import {
  type AnalyticsBrowser,
  type EventProperties,
} from '@segment/analytics-next';

export class UGCAnalytics {
  constructor(private readonly analytics: AnalyticsBrowser) {}

  trackCreateCustomGameClicked(props?: EventProperties) {
    this.analytics.track('Create Custom Game Clicked', props);
  }

  trackMyCustomGamesClicked(props?: EventProperties) {
    this.analytics.track('My Custom Games Clicked', props);
  }

  trackTemplateCustomizeClicked(props?: EventProperties) {
    this.analytics.track('UGC Template Customize Clicked', props);
  }

  trackTeamIntroScriptEdited(props?: EventProperties) {
    this.analytics.track('UGC Editor Team Intro Script Edited', props);
  }

  trackHostCelebrationScriptEdited(props?: EventProperties) {
    this.analytics.track('UGC Editor Host Celebration Script Edited', props);
  }

  trackJeopardyBoardCategoryEdited(props?: EventProperties) {
    this.analytics.track('UGC Editor Jeopardy Board Category Edited', props);
  }

  trackJeopardyBoardClueEdited(props?: EventProperties) {
    this.analytics.track('UGC Editor Jeopardy Board Clue Edited', props);
  }

  trackJeopardyBoardCSVUploaded(props?: EventProperties) {
    this.analytics.track('UGC Editor Jeopardy Board CSV Uploaded', props);
  }

  trackEditorFinishClicked(props?: EventProperties) {
    this.analytics.track('UGC Editor Finish Clicked', props);
  }

  trackEditorNextClicked(props?: EventProperties) {
    this.analytics.track('UGC Editor Next Clicked', props);
  }

  trackEditorSaveClicked(props?: EventProperties) {
    this.analytics.track('UGC Editor Save Clicked', props);
  }

  trackEditorAdvancedClicked(props?: EventProperties) {
    this.analytics.track('UGC Editor Advanced Clicked', props);
  }

  trackCustomGameNameChanged(props?: EventProperties) {
    this.analytics.track('Custom Game Name Changed', props);
  }

  trackEditorActivityClicked(props?: EventProperties) {
    this.analytics.track('UGC Editor Activity Clicked', props);
  }
}
