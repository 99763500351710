import { useState } from 'react';

import { EnumsMediaScene } from '@lp-lib/api-service-client/public';
import { type QuestionBlock, type QuestionBlockMedia } from '@lp-lib/game';

import { BLOCK_TIMER_OPTIONS } from '../Common/Editor';
import {
  AdditionalSettings,
  AdditionalSharedSettingsEditor,
  BlockMediaEditor,
  EditorBody,
  EditorLayout,
  type EditorProps,
  PointsSelect,
  RHFCheckbox,
  RHFSelectField,
  useEditor,
} from '../Common/Editor/Internal';

export function QuestionBlockEditor(
  props: EditorProps<QuestionBlock>
): JSX.Element | null {
  const { block } = props;
  const { updateField } = useEditor(props);

  const [showAdditionField, setShowAdditionField] = useState(false);
  return (
    <EditorLayout
      bottomAccessory={
        <AdditionalSettings>
          <AdditionalSharedSettingsEditor {...props} />
        </AdditionalSettings>
      }
    >
      <EditorBody>
        <h2 className='text-2xl text-white'>Question Editor</h2>
        <div className='w-full my-7.5 flex flex-col text-white'>
          <section className='w-full flex'>
            <div className='w-3/5 flex flex-col'>
              <div>
                <span className='text-white'>Question Text</span>
                <textarea
                  className={`h-20 mt-1 mb-1 py-2 resize-none field`}
                  placeholder='Max 300 characters'
                  maxLength={300}
                  defaultValue={block.fields.question}
                  onBlur={(e) => {
                    updateField('question', e.target.value);
                  }}
                />
              </div>
            </div>

            <div className='w-2/5 flex flex-col items-center'>
              <BlockMediaEditor<QuestionBlockMedia>
                blockId={block.id}
                title='Question Media'
                scene={EnumsMediaScene.MediaSceneBlockMedia}
                volumeSelectable
                mediaData={block.fields?.questionMediaData ?? null}
                media={block.fields?.questionMedia ?? null}
                extraNotice='Media will display to the audience when the question is presented.'
                objectFit='object-contain'
                field='questionMedia'
              />
            </div>
          </section>

          <hr className='w-full my-5 border border-secondary' />

          <section className='w-full flex'>
            <div className='w-3/5 flex flex-col gap-4.5'>
              <div>
                <span className='text-white'>Correct Answer</span>
                <div className='flex items-center gap-5'>
                  <input
                    className={`w-108 h-15 mt-1 mb-1 py-2 field`}
                    placeholder='Max 50 characters'
                    maxLength={50}
                    defaultValue={block.fields.answer}
                    onBlur={(e) => {
                      updateField('answer', e.target.value);
                    }}
                  />
                  {!showAdditionField && (
                    <button
                      type='button'
                      onClick={() => setShowAdditionField(true)}
                      className='btn text-xs text-secondary underline'
                    >
                      Add More
                    </button>
                  )}
                </div>
              </div>

              {showAdditionField && (
                <div>
                  <div className='flex items-center gap-2'>
                    <span className='text-white'>
                      Additional Correct Answers
                    </span>
                    <button
                      type='button'
                      onClick={() => {
                        setShowAdditionField(false);
                      }}
                      className='btn text-secondary text-xs underline'
                    >
                      Hide
                    </button>
                  </div>

                  <input
                    className='w-full h-15 mt-1 mb-1 py-2 field'
                    placeholder='Max 35000 characters'
                    maxLength={35000}
                    defaultValue={block.fields.additionalAnswers}
                    onBlur={(e) => {
                      updateField('additionalAnswers', e.target.value);
                    }}
                  ></input>

                  <div className='mt-3 text-sms text-icon-gray font-normal'>
                    Separate multiple correct answers with a comma (,).
                  </div>
                </div>
              )}
            </div>

            <div className='w-2/5 flex flex-col items-center'>
              <BlockMediaEditor<QuestionBlockMedia>
                blockId={block.id}
                title='Answer Media'
                scene={EnumsMediaScene.MediaSceneBlockMedia}
                volumeSelectable
                mediaData={block.fields.answerMediaData ?? null}
                media={block.fields?.answerMedia ?? null}
                extraNotice='Media will display to the audience when the question is presented.'
                objectFit='object-contain'
                field='answerMedia'
              />
            </div>
          </section>

          <hr className='w-full my-5 border border-secondary' />

          <section className='w-full flex flex-col gap-6 mb-25'>
            <div className='w-full flex gap-5'>
              <div className='w-75'>
                <RHFSelectField<QuestionBlock>
                  className='w-full h-10 text-white mt-1'
                  label='Time Limit'
                  name='time'
                  options={BLOCK_TIMER_OPTIONS}
                  onChange={(_, value) => {
                    if (typeof value !== 'number') return;
                    updateField('time', value);
                  }}
                  value={block.fields.time}
                />
              </div>

              <div className='w-75'>
                <PointsSelect
                  key={`${block.id}-points-select`}
                  label='Points'
                  className='w-full h-10 text-white'
                  defaultValue={{
                    points: block.fields.points,
                    displayPointsMultiplier:
                      block.fields.displayPointsMultiplier,
                  }}
                  onChange={({ points, displayPointsMultiplier }) => {
                    updateField('points', points);
                    updateField(
                      'displayPointsMultiplier',
                      displayPointsMultiplier
                    );
                  }}
                />
              </div>
            </div>

            <div className='w-full flex gap-5'>
              <div className='flex flex-col gap-4'>
                <div className='w-75'>
                  <RHFCheckbox<QuestionBlock>
                    label='Decreasing Points Timer'
                    name='decreasingPointsTimer'
                    value={block.fields.decreasingPointsTimer ?? false}
                    onChange={(_, checked: boolean): void => {
                      updateField('decreasingPointsTimer', checked);
                    }}
                    description={{
                      enabled:
                        'Enabled: Amount of points earned for submitting the correct answer decreases as the Question timer runs out.',
                      disabled:
                        'Disabled: Amount of points earned for submitting the correct answer does not change as the Question timer runs out.',
                    }}
                  />
                </div>

                {block.fields.decreasingPointsTimer && (
                  <div className='w-75'>
                    <RHFCheckbox<QuestionBlock>
                      label='Start Descending Immediately'
                      name='startDescendingImmediately'
                      value={block.fields.startDescendingImmediately ?? false}
                      onChange={(_, checked: boolean): void => {
                        updateField('startDescendingImmediately', checked);
                      }}
                      description={{
                        enabled: 'Enabled: Points start descending immediately',
                        disabled:
                          'Disabled: Points start descending after 25% of time.',
                      }}
                    />
                  </div>
                )}
              </div>

              <div className='w-75'>
                <RHFCheckbox<QuestionBlock>
                  label='Start Video With Timer'
                  name='startVideoWithTimer'
                  value={block.fields.startVideoWithTimer ?? false}
                  onChange={(_, checked: boolean): void => {
                    updateField('startVideoWithTimer', checked);
                  }}
                  description={{
                    enabled:
                      'Enabled: Presenting this Block will show both the Question Text and Video. Video will only play once the timer starts, and it cannot be replayed after.',
                    disabled:
                      'Disabled: Presenting this Block will auto-play the Question Video. Question Text will only show after the video finishes.',
                  }}
                />
              </div>
            </div>
          </section>
        </div>
      </EditorBody>
    </EditorLayout>
  );
}
