import debounce from 'lodash/debounce';
import { useMemo, useState } from 'react';
import { type SingleValue } from 'react-select';
import AsyncSelect from 'react-select/async';

import {
  type DtoSharedAsset,
  type EnumsSharedAssetPurpose,
} from '@lp-lib/api-service-client/public';

import { useLiveCallback } from '../../hooks/useLiveCallback';
import { apiService } from '../../services/api-service';
import { fromMediaDTO } from '../../utils/api-dto';
import { MediaUtils } from '../../utils/media';
import { buildReactSelectStyles } from '../../utils/react-select';
import { useAwaitFullScreenConfirmCancelModal } from '../ConfirmCancelModalContext';
import { ModalWrapper } from '../ConfirmCancelModalContext/ModalWrapper';
import { SharedAssetCover } from './SharedAssetList';

async function search(
  purposes: EnumsSharedAssetPurpose[] | undefined,
  q: string,
  callback: (items: DtoSharedAsset[]) => void
) {
  const items = await apiService.media
    .searchSharedAsset({
      q,
      purposes: purposes ? purposes.join(',') : undefined,
    })
    .next();
  callback(items);
}

const debounceSearch = debounce(search, 250);

function ShareAssetCard(props: { item: DtoSharedAsset }) {
  const { item } = props;
  const media = fromMediaDTO(item.media);
  const url = MediaUtils.PickMediaUrl(media);
  return (
    <div className='flex items-center justify-start gap-1'>
      <SharedAssetCover media={media} className='w-20 h-11.25' />
      <div>{item.label}</div>
      <div className='truncate max-w-60'>
        {item.data ? JSON.stringify(item.data) : ''}
      </div>
      <div className='ml-auto'>
        <a
          href={url ?? ''}
          target='_blank'
          className='text-primary text-sms'
          rel='noreferrer'
        >
          open
        </a>
      </div>
    </div>
  );
}

export function ShareAssetPicker(props: {
  purposes?: EnumsSharedAssetPurpose[];
  onChange: (value: DtoSharedAsset) => void;
}): JSX.Element {
  const styles = useMemo(
    () =>
      buildReactSelectStyles<DtoSharedAsset>({
        override: {
          control: { height: '100%' },
        },
      }),
    []
  );

  const loadOptions = (
    q: string,
    callback: (items: DtoSharedAsset[]) => void
  ): void => {
    debounceSearch(props.purposes, q, callback);
  };

  const handleSingleChange = (option: SingleValue<DtoSharedAsset>) => {
    if (option) {
      props.onChange(option);
    }
  };

  return (
    <AsyncSelect<DtoSharedAsset, false>
      placeholder={`Select from shared media`}
      styles={styles}
      cacheOptions
      defaultOptions
      loadOptions={loadOptions}
      classNamePrefix='select-box-v2'
      noOptionsMessage={(obj) => {
        if (!obj.inputValue) return 'Start typing to search';
        return 'No media matched';
      }}
      getOptionValue={(option) => option.id}
      getOptionLabel={(option) => option.label}
      formatOptionLabel={(option) => <ShareAssetCard item={option} />}
      onChange={handleSingleChange}
    />
  );
}

function ShareAssetPickerModal(props: {
  purposes?: EnumsSharedAssetPurpose[];
  onClose: () => void;
  onSelected: (item: DtoSharedAsset) => void;
}) {
  const [item, setItem] = useState<DtoSharedAsset | null>(null);
  return (
    <ModalWrapper containerClassName='w-160' borderStyle='gray'>
      <section className='flex flex-col items-center justify-center gap-5 p-10'>
        <header className='text-2xl font-medium'>
          Select from Shared Media
        </header>
        <label className='w-full'>
          <div className='font-bold'>Select</div>
          <ShareAssetPicker
            purposes={props.purposes}
            onChange={(item) => setItem(item)}
          />
        </label>
        <footer className='flex justify-center items-center gap-5'>
          <button
            type='button'
            onClick={props.onClose}
            className='btn-secondary w-45 h-10'
          >
            Cancel
          </button>
          <button
            type='submit'
            className='btn-primary w-45 h-10'
            disabled={!item}
            onClick={() => {
              if (!item) return;
              props.onSelected(item);
            }}
          >
            Select
          </button>
        </footer>
      </section>
    </ModalWrapper>
  );
}

export function useOpenShareAssetPickerModal() {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  return useLiveCallback(
    (props: {
      onSelected: (item: DtoSharedAsset) => void;
      purposes?: EnumsSharedAssetPurpose[];
    }) => {
      triggerModal({
        kind: 'custom',
        element: (p) => (
          <ShareAssetPickerModal
            purposes={props.purposes}
            onClose={p.internalOnCancel}
            onSelected={(item) => {
              props.onSelected(item);
              p.internalOnConfirm();
            }}
          />
        ),
      });
    }
  );
}
