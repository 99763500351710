import pluralize from 'pluralize';
import React, { useEffect, useState } from 'react';
import ContentLoader from 'react-content-loader';
import { useLatest } from 'react-use';
import { Waypoint } from 'react-waypoint';

import {
  EnumsGamePackDifficulty,
  EnumsGamePackVersion,
} from '@lp-lib/api-service-client/public';

import {
  GamePackHoverBrandsPreviewMulti,
  GamePackHoverBrandsPreviewSingle,
} from '../../../../app/components/GamePack/GamePackHover';
import {
  FreeGamePackBadge,
  NewGamePackBadge,
  ThumbsUpBadge,
} from '../../../../app/components/GamePack/GamePackShared';
import { getFeatureQueryParamNumber } from '../../../hooks/useFeatureQueryParam';
import { useInstance } from '../../../hooks/useInstance';
import { type GamePack } from '../../../types/game';
import {
  type Classes,
  StagedTailwindTransition,
  type TailwindTransitionStage,
} from '../../common/TailwindTransition';
import { useMyOrganizationFeatureChecker } from '../../Organization';
import { useUser } from '../../UserContext';
import { GamePackCoverPres } from '../GamePackCoverPres';
import { PlayerRangeUtils } from '../PlayerRangeUtils';
import { useGamePackLinkedBrands } from './hooks';
import { useGamePack } from './useGamePack';

export { ThumbsUpBadge, NewGamePackBadge, FreeGamePackBadge };

export function DefaultGamePackCardBadges(props: { gamePack: GamePack }) {
  const features = useMyOrganizationFeatureChecker();
  return (
    <>
      <NewGamePackBadge gamePack={props.gamePack} />
      <FreeGamePackBadge gamePack={props.gamePack} features={features} />
      <ThumbsUpBadge gamePack={props.gamePack} />
    </>
  );
}

export function MyGamePackBadge(props: {
  gamePack: GamePack;
}): JSX.Element | null {
  const user = useUser();
  if (props.gamePack.uid !== user.id) return null;
  return (
    <CustomGamePackCardBadge
      styles={{
        bg: 'bg-gradient-to-br from-[#FFC700] to-[#FFA800]',
      }}
    >
      My Game
    </CustomGamePackCardBadge>
  );
}

export function CustomGamePackCardBadge(props: {
  styles?: Partial<{
    bg: string;
    size: string;
    text: string;
  }>;
  children: React.ReactNode;
}): JSX.Element {
  return (
    <div
      className={`
        ${props.styles?.bg ?? ''} 
        ${props.styles?.size ?? 'px-4 h-6'}
        ${
          props.styles?.text ??
          'text-white text-xs font-bold text-center uppercase tracking-wide truncate'
        }
        rounded-[4px] shadow-gamecard-badge flex items-center justify-center
    `}
    >
      {props.children}
    </div>
  );
}

function GamePackHoverBrandsPreview(props: { pack: GamePack }) {
  const stages = useInstance<TailwindTransitionStage<Classes>[]>(() => {
    return [{ classes: 'opacity-0' }, { classes: 'opacity-100' }];
  });
  const duration = getFeatureQueryParamNumber('game-pack-hover-delayed-ms');

  const { data: brands } = useGamePackLinkedBrands(props.pack.id);
  if (!brands || brands.length === 0) return null;

  return (
    <StagedTailwindTransition stages={stages}>
      {(ref, initial) => (
        <div
          ref={ref}
          className={`absolute left-0 top-0 w-full h-full transform ${initial}`}
          style={{
            transitionDuration: `${duration}ms`,
          }}
        >
          {brands.length === 1 ? (
            <GamePackHoverBrandsPreviewSingle
              play
              brand={brands[0]}
              mediaClassName='rounded-none'
            />
          ) : (
            <GamePackHoverBrandsPreviewMulti
              brands={brands}
              play
              indicator='inner'
              mediaClassName='rounded-none'
            />
          )}
        </div>
      )}
    </StagedTailwindTransition>
  );
}

export type GamePackCardStyles = Partial<{
  bg: string;
  border: string;
  size: string;
  cursor: string;
}>;

function VisibleWatcher(props: { pack: GamePack; onVisible: () => void }) {
  const [horizontalVisible, setHorizontalVisible] = useState(false);
  const [verticalVisible, setVerticalVisible] = useState(false);
  const onVisibleRef = useLatest(props.onVisible);

  useEffect(() => {
    if (horizontalVisible && verticalVisible) {
      onVisibleRef.current();
    }
  }, [horizontalVisible, onVisibleRef, verticalVisible]);

  return (
    <>
      {/* 
        we only care about the first time it's visible.
        remove the waypoint once it is visible.
      */}
      {!verticalVisible && (
        <div className='absolute bottom-0'>
          <Waypoint
            fireOnRapidScroll
            onEnter={() => {
              setVerticalVisible(true);
            }}
          />
        </div>
      )}
      {!horizontalVisible && (
        <div className='absolute right-0'>
          <Waypoint
            fireOnRapidScroll
            horizontal
            onEnter={() => {
              setHorizontalVisible(true);
            }}
          />
        </div>
      )}
    </>
  );
}

export function GamePackCard(props: {
  gamePack: GamePack;
  styles?: GamePackCardStyles;
  badges?: React.ReactNode;
  onClick?: (gamePack: GamePack) => void;
  onVisible?: () => void;
  bottomAccessory?: React.ReactNode;
  hoverAccessory?: React.ReactNode;
  showVersion?: boolean;
}): JSX.Element {
  const durationMin = Math.round(
    props.gamePack.approximateDurationSeconds / 60
  );
  const handleClick = () => {
    props.onClick?.(props.gamePack);
  };
  const gameType = props.gamePack.detailSettings?.gameType;

  const delayedMS = getFeatureQueryParamNumber('game-pack-hover-delayed-ms');

  const [hovered, setHovered] = React.useState(false);
  const [showBrands, setShowBrands] = React.useState(false);

  useEffect(() => {
    if (props.gamePack.version !== EnumsGamePackVersion.GamePackVersionV2)
      return;
    if (!hovered) return;

    const timer = setTimeout(() => {
      setShowBrands(true);
    }, delayedMS);
    return () => {
      clearTimeout(timer);
      setShowBrands(false);
    };
  }, [delayedMS, hovered, props.gamePack.version]);

  return (
    <div
      className={`
        ${
          props.styles?.bg ??
          'bg-lp-gray-009 transition-colors hover:bg-lp-gray-002'
        }
        ${props.styles?.border ?? 'border-none'}
        ${props.styles?.size ?? 'w-62'}
        ${
          props.styles?.cursor ??
          (props.onClick ? 'cursor-pointer' : 'cursor-auto')
        }
        relative rounded-2.5xl group-gamepack-card
      `}
      onClick={handleClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {props.badges && (
        <div className='absolute top-0 left-0 right-0 transform -translate-y-3/4 flex items-center justify-end gap-1 z-10'>
          {props.badges}
        </div>
      )}
      {props.hoverAccessory && hovered && (
        <div className='absolute top-0 left-0 right-0 bottom-0 z-10'>
          {props.hoverAccessory}
        </div>
      )}
      <div className='w-full h-full flex flex-col pt-2 pb-6'>
        <div className='flex-grow flex items-center justify-center px-4.5 py-3.5 font-Montserrat text-sm text-white font-black text-center tracking-wider uppercase truncate'>
          {gameType ? <>{gameType}</> : <>&nbsp;</>}
        </div>
        <div className='flex-grow px-1 relative'>
          <GamePackCoverPres pack={props.gamePack}></GamePackCoverPres>

          {showBrands && <GamePackHoverBrandsPreview pack={props.gamePack} />}

          {props.onVisible && (
            <VisibleWatcher pack={props.gamePack} onVisible={props.onVisible} />
          )}
        </div>
        <div className={`flex-grow flex flex-col pt-2.5 px-2.5`}>
          <div className='text-sm font-bold text-center text-white truncate'>
            {props.gamePack.name}
          </div>

          <div className='text-sms text-center text-icon-gray truncate pt-2.5'>
            <ul className='space-x-1'>
              <li className='inline-block'>
                {PlayerRangeUtils.Format(props.gamePack.playerRange)}
              </li>
              <li className='inline-block'>•</li>
              <li className='inline-block'>{`${durationMin} ${pluralize(
                'Min',
                durationMin
              )}`}</li>
              {props.gamePack.detailSettings?.gameDifficulty ===
                EnumsGamePackDifficulty.GamePackDifficultyHard && (
                <>
                  <li className='inline-block'>•</li>
                  <li className='inline-block text-red-006'>Hard</li>
                </>
              )}
              {props.showVersion &&
                props.gamePack.version ===
                  EnumsGamePackVersion.GamePackVersionV2 && (
                  <>
                    <li className='inline-block'>•</li>
                    <li className='inline-block'>
                      <span className='text-tertiary text-sms'>v2</span>
                    </li>
                  </>
                )}
            </ul>
          </div>
        </div>
      </div>
      {props.bottomAccessory}
    </div>
  );
}

export function LoadingGamePackCard(props: {
  styles?: GamePackCardStyles;
}): JSX.Element {
  return (
    <ContentLoader
      viewBox='0 0 248 250'
      backgroundColor='#101012'
      foregroundColor='#161616'
      className={`
        ${props.styles?.size ?? 'w-62'}
      `}
    >
      <rect width='248' height='250' rx='20' />
    </ContentLoader>
  );
}

export function ConnectedGamePackCard(props: {
  gamePackId: string;
  styles?: GamePackCardStyles;
  badges?: React.ReactNode;
  onClick?: (gamePack: GamePack) => void;
  bottomAccessory?: (gamePack: GamePack) => React.ReactNode;
  hoverAccessory?: (gamePack: GamePack) => React.ReactNode;
}): JSX.Element | null {
  const { data, isLoading } = useGamePack(props.gamePackId);
  if (isLoading) return <LoadingGamePackCard {...props} />;
  if (!data) return null;

  return (
    <GamePackCard
      {...props}
      gamePack={data}
      badges={props.badges ?? <DefaultGamePackCardBadges gamePack={data} />}
      bottomAccessory={props.bottomAccessory?.(data)}
      hoverAccessory={props.hoverAccessory?.(data)}
    />
  );
}
