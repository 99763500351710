import shuffle from 'lodash/shuffle';

import {
  type DtoTTSRenderRequest,
  EnumsTTSCacheControl,
  EnumsTTSGeneratorId,
  EnumsTTSRenderPolicy,
  type ModelsTTSLabeledRenderSettings,
  type ModelsTTSRenderSettings,
  type ModelsTTSScript,
} from '@lp-lib/api-service-client/public';
import {
  type Media,
  type MediaData,
  type VoiceOver,
  type VoiceOverRenderDescription,
  VolumeLevel,
} from '@lp-lib/game';
import { uncheckedIndexAccess_UNSAFE } from '../../utils/uncheckedIndexAccess_UNSAFE';

import { hasLLMCodeFences, hasVariables } from './VariableRegistry';
import type { VoiceOverRegistryPlan } from './VoiceOverRegistry';

export class VoiceOverUtils {
  static HasAtLeastOneConfig(voiceOver: VoiceOver | null | undefined): boolean {
    // TODO(falcon): in fact we might want it to be the case that if there is no fallback, we return false.
    // a runtime without a fallback has no way to play voice over in case of a failure...
    return Boolean(voiceOver && (voiceOver.runtime || voiceOver.fallback));
  }

  static HasRuntimeConfig(voiceOver: VoiceOver | null | undefined): boolean {
    return Boolean(voiceOver && voiceOver.runtime);
  }

  static PickVoiceOverMedia(
    voiceOver: VoiceOver | null | undefined,
    fallbackMedia?: Media | null,
    noFallbacks?: boolean
  ): {
    media: Media | null | undefined;
    mediaData: MediaData | null | undefined;
    delayStartMs: number;
  } {
    if (!voiceOver) {
      return {
        media: undefined,
        mediaData: undefined,
        delayStartMs: 0,
      };
    }

    if (voiceOver.playbackMedia) {
      return {
        media: voiceOver.playbackMedia,
        mediaData: voiceOver.playbackMediaData,
        delayStartMs: voiceOver.runtime?.delayStartMs ?? 0,
      };
    }

    if (noFallbacks) {
      return {
        media: undefined,
        mediaData: undefined,
        delayStartMs: 0,
      };
    }

    return {
      media: voiceOver.fallback?.media ?? fallbackMedia,
      mediaData:
        voiceOver.fallback?.mediaData ??
        (fallbackMedia
          ? {
              id: fallbackMedia.id,
            }
          : undefined),
      delayStartMs: voiceOver.fallback?.renderDescription?.delayStartMs ?? 0,
    };
  }

  static AsTTSRenderRequest(
    renderDescription: VoiceOverRenderDescription | null | undefined
  ): DtoTTSRenderRequest | null | undefined {
    if (!renderDescription) {
      return renderDescription;
    }

    return {
      script: renderDescription.script,
      ttsRenderSettings: {
        generatorId: renderDescription.generatorId,
        generatorSettings: {
          [renderDescription.generatorId]: {
            voiceId: renderDescription.voiceId,
            ...(renderDescription.settings
              ? uncheckedIndexAccess_UNSAFE(renderDescription.settings)[
                  renderDescription.generatorId
                ]
              : {}),
          },
        },
      },
    };
  }

  static AsVoiceOver(
    script: string,
    ttsRenderSettings: ModelsTTSRenderSettings,
    delayStartMs?: number
  ): VoiceOver {
    let voiceId = '';
    switch (ttsRenderSettings.generatorId) {
      case EnumsTTSGeneratorId.TTSGeneratorIdElevenLabs:
        voiceId = ttsRenderSettings.generatorSettings.elevenLabs?.voiceId ?? '';
        break;
      case EnumsTTSGeneratorId.TTSGeneratorIdOpenAI:
        voiceId = ttsRenderSettings.generatorSettings.openAI?.voiceId ?? '';
        break;
    }

    return {
      runtime: {
        script,
        generatorId: ttsRenderSettings.generatorId,
        voiceId: voiceId,
        settings: ttsRenderSettings.generatorSettings,
        delayStartMs: delayStartMs ?? 0,
        volumeLevel: VolumeLevel.Full,
      },
      playbackMedia: null,
      playbackMediaData: null,
    };
  }

  static AsTTSSettings(
    renderDescription: VoiceOverRenderDescription | null | undefined
  ): ModelsTTSRenderSettings | null | undefined {
    if (!renderDescription) return;
    return {
      generatorId: renderDescription.generatorId,
      generatorSettings: {
        elevenLabs: {
          ...renderDescription.settings?.elevenLabs,
          voiceId: renderDescription.voiceId,
        },
      },
    };
  }

  static SamplePreferredFallbackScripts(
    scripts: ModelsTTSScript[] | null | undefined
  ) {
    const shuffled = shuffle(scripts ?? []);
    const preferred = shuffled.find(
      (s) => hasVariables(s.script) || hasLLMCodeFences(s.script)
    );
    const fallback = shuffled.find(
      (s) => !hasVariables(s.script) && !hasLLMCodeFences(s.script)
    );
    return [preferred, fallback];
  }

  static BuildRuntimeFallbackVoiceOverPlansFromLegacy(
    // optionally, the old models.
    voiceOver: VoiceOver | null | undefined,
    // optionally, the new preferred script.
    preferredScript: ModelsTTSScript | null | undefined,
    // optionally, the new fallback script.
    fallbackScript: ModelsTTSScript | null | undefined,
    ttsOptions: ModelsTTSLabeledRenderSettings[] | null | undefined,
    aiHostVoiceId: string | undefined | null,
    tags: string[] = []
  ) {
    if (voiceOver && VoiceOverUtils.HasAtLeastOneConfig(voiceOver)) {
      // we have some legacy configuration, so we'll use that and try to maintain the old semantics.
      const runtime = VoiceOverUtils.AsTTSRenderRequest(voiceOver.runtime);
      const fallback = VoiceOverUtils.AsTTSRenderRequest(
        voiceOver.fallback?.renderDescription
      );
      let fallbackMedia = undefined;
      if (voiceOver.fallback?.media && voiceOver.fallback?.mediaData) {
        fallbackMedia = {
          media: voiceOver.fallback.media,
          mediaData: voiceOver.fallback.mediaData,
        };
      }
      return this.BuildRuntimeFallbackVoiceOverPlans(
        runtime,
        fallback,
        fallbackMedia,
        aiHostVoiceId,
        tags
      );
    }

    // we have no legacy voiceover settings, so we're going to assume we're in the "new world". here we expect some
    // tts options or an aiHostVoiceId, otherwise we cannot render.
    const ttsRenderSettings = ttsOptions?.[0];
    if (
      (!aiHostVoiceId && !ttsRenderSettings) ||
      (!preferredScript && !fallbackScript)
    )
      return [];

    // the block has explicit scripts it wants to use.
    const runtime = preferredScript
      ? { script: preferredScript.script, aiHostVoiceId, ttsRenderSettings }
      : undefined;

    const fallback = fallbackScript
      ? { script: fallbackScript.script, aiHostVoiceId, ttsRenderSettings }
      : undefined;

    return this.BuildRuntimeFallbackVoiceOverPlans(
      runtime,
      fallback,
      undefined, // there is no media in the new world.
      aiHostVoiceId,
      tags
    );
  }

  static BuildRuntimeFallbackVoiceOverPlans(
    runtime: Nullable<DtoTTSRenderRequest>,
    fallback: Nullable<DtoTTSRenderRequest>,
    fallbackMedia: Nullable<{ media: Media; mediaData: MediaData }>,
    aiHostVoiceId: string | undefined | null,
    tags: string[] = []
  ) {
    const plan: VoiceOverRegistryPlan = { entries: [] };

    // priority 1: runtime script, likely with variables
    if (runtime) {
      plan.entries.push({
        ...runtime,
        script: runtime.script,
        voiceId: aiHostVoiceId,
        ttsRenderSettings: aiHostVoiceId
          ? undefined
          : runtime.ttsRenderSettings,
        policy: EnumsTTSRenderPolicy.TTSRenderPolicyReadThrough,
        cacheControl: EnumsTTSCacheControl.TTSCacheControlShortLive,
      });
    }

    // Priority N: The rest are all a form of fallback.

    if (!aiHostVoiceId && fallbackMedia) {
      // priority 2: handcrafted prerendered fallback media (static) if there are
      // no ai voice settings (because otherwise the voices would be different
      // and there would be a mismatch)
      plan.entries.push(fallbackMedia);
    } else if (fallback) {
      // priority 3: handcrafted fallback description, rendered at game start,
      // if there are aivoicesettings/block settings/renderdesc settings
      plan.entries.push({
        ...fallback,
        voiceId: aiHostVoiceId,
        ttsRenderSettings: aiHostVoiceId
          ? undefined
          : fallback.ttsRenderSettings,
        policy: EnumsTTSRenderPolicy.TTSRenderPolicyReadThrough,
        cacheControl: EnumsTTSCacheControl.TTSCacheControlLongLive,
      });
    }
    return plan.entries.length === 0 ? [] : [{ plan, tags }];
  }
}
