import React, {
  type ReactNode,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLatest } from 'react-use';
import { proxy, useSnapshot } from 'valtio';
import { devtools } from 'valtio/utils';

import { useTaskQueue } from '../../hooks/useTaskQueue';
import { getLogger } from '../../logger/logger';
import { type VirtualBackgroundOptions } from '../../services/webrtc/virtual-background';
import { assertExhaustive } from '../../utils/common';
import {
  type Profile,
  profileFor,
  type ProfileIndex,
  releaseMediaStream,
} from '../../utils/media';
import { StorageFactory } from '../../utils/storage';
import { ValtioUtils } from '../../utils/valtio';
import { useUserStates } from '../UserContext';
import {
  DefaultDeviceId,
  type DeviceOption,
  type DeviceUpdatePolicy,
  MediaErrorName,
  MediaKind,
  NoCameraDeviceOption,
  NoMicDeviceOption,
} from './types';
import {
  buildDeviceOption,
  formatError,
  getAlternativeDeviceOption,
  getDeviceOptionsByKind,
  getDevicesByKind,
  getUserMedia,
  resolveUserMedia,
} from './utils';
import {
  type IVideoStreamMixer,
  NoopVideoStreamMixer,
} from './video-stream-mixer';

const log = getLogger().scoped('DeviceContext');

/**
 * Chromium (Chrome, Edge, Opera):
 *  user blocked: NotAllowedError (Permission denied)
 *  system blocked: NotAllowedError (Permission denied by system)
 * Firefox:
 *  user blocked: NotAllowedError (The request is not allowed by the user agent...)
 *  system blocked: NotFoundError (The object can not be found here.)
 * Safari:
 *  user blocked: NotAllowedError (The request is not allowed by the user agent...)
 *  system blocked: N/A (There is no system level block)
 * @param error
 * @returns
 */
export function isBlockedBySystem(error: Error): boolean {
  if (
    error.name === MediaErrorName.NotAllowedError &&
    error.message === 'Permission denied by system'
  )
    return true;
  if (error.name === MediaErrorName.NotFoundError) return true;
  return false;
}

interface State {
  authorized: boolean;
  authorizedAudioError: Error | null;
  authorizedVideoError: Error | null;
  policy: DeviceUpdatePolicy | null;
  activeAudioInputDeviceOption: DeviceOption | null;
  activeVideoInputDeviceOption: DeviceOption | null;
  activeAudioOutputDeviceOption: DeviceOption | null;
  audioInputOptions: DeviceOption[];
  videoInputOptions: DeviceOption[];
  audioOutputOptions: DeviceOption[];
  isGroupedAudioDevices: boolean;
  showUserBlockedPrompt: boolean;
  showSystemBlockedPrompt: boolean;
  deviceOptionsUpdates: number;
  mediaStreamId: string | null;
  audioSupported: boolean;
  videoSupported: boolean;
  inited: boolean;
}

type UpdateSingletonVideoTrackCmd =
  | {
      action: 'remove';
    }
  | {
      action: 'update';
      input: {
        cameraTrack: MediaStreamTrack | null;
        deviceId: string | null;
        // In some cases, we want to clone the input mediaStream, and manage the
        // lifecycle of the copy internally.
        clone?: boolean;
      };
    };

interface IDeviceAPI {
  readonly profile: Profile;
  readonly mixer: IVideoStreamMixer;
  readonly log: ReturnType<typeof getLogger>;

  init(): Promise<void>;
  initUserMedia(config: { audio: boolean; video: boolean }): Promise<{
    audioStream: MediaStream | null;
    videoStream: MediaStream | null;
  }>;
  cloneVideoTrack(): MediaStreamTrack | undefined;
  updateActiveDeviceOption(
    kind: MediaKind,
    option: DeviceOption | null,
    updateStorage?: boolean
  ): void;
  updateDeviceOptions(): Promise<void>;
  watchDeviceChange(): () => void;
  updateSingletonVideoTrack(cmd: UpdateSingletonVideoTrackCmd): Promise<void>;
  toggleBlockedPrompt(error: Error | null, val: boolean): void;
  updateGroupedAudioDevices(): void;
  updateDeviceCheckedInSession(): void;
  checkSkippable(): boolean;
  state: Readonly<State>;
  reset(): void;
}

function initialState(args: {
  audioSupported: boolean;
  videoSupported: boolean;
}): State {
  return {
    authorized: false,
    authorizedAudioError: null,
    authorizedVideoError: null,
    policy: null,
    activeAudioInputDeviceOption: null,
    activeVideoInputDeviceOption: null,
    activeAudioOutputDeviceOption: null,
    audioInputOptions: [],
    videoInputOptions: [],
    audioOutputOptions: [],
    isGroupedAudioDevices: false,
    showUserBlockedPrompt: false,
    showSystemBlockedPrompt: false,
    deviceOptionsUpdates: 0,
    mediaStreamId: null,
    audioSupported: args.audioSupported,
    videoSupported: args.videoSupported,
    inited: false,
  };
}

class NoopDeviceAPI implements IDeviceAPI {
  readonly log = log;
  readonly mixer = new NoopVideoStreamMixer();
  private _state = proxy<State>(
    initialState({
      audioSupported: false,
      videoSupported: false,
    })
  );
  profile: Profile;

  constructor(profileIndex: ProfileIndex) {
    this.profile = profileFor(profileIndex);
  }

  async init() {
    return;
  }
  async initUserMedia(_config: { audio: boolean; video: boolean }) {
    return { audioStream: null, videoStream: null };
  }
  cloneVideoTrack() {
    return undefined;
  }
  updateActiveDeviceOption(
    _kind: MediaKind,
    _option: DeviceOption | null,
    _updateStorage?: boolean | undefined
  ) {
    return;
  }
  async updateDeviceOptions() {
    return;
  }
  watchDeviceChange(): () => void {
    return () => void 0;
  }
  async updateSingletonVideoTrack() {
    return;
  }
  getSingletonMediaStream() {
    return null;
  }
  toggleBlockedPrompt(_error: Error | null, _val: boolean) {
    return;
  }
  updateGroupedAudioDevices() {
    return;
  }
  updateDeviceCheckedInSession() {
    return;
  }
  checkSkippable() {
    return true;
  }
  get state(): Readonly<State> {
    return this._state;
  }

  reset() {
    return;
  }
}

class DeviceAPI implements IDeviceAPI {
  private _state = proxy<State>(
    initialState({
      audioSupported: true,
      videoSupported: true,
    })
  );
  private storage = StorageFactory<MediaKind, DeviceOption>('local');
  readonly mixer: IVideoStreamMixer;
  readonly log = log;
  private cameraTrackInfo: {
    dest: MediaStreamTrack | null;
    deviceId: string | null;
    src: MediaStreamTrack | null;
  };
  readonly profile: Profile;

  constructor(profileIndex: ProfileIndex, mixer: IVideoStreamMixer) {
    this.profile = profileFor(profileIndex);
    this.mixer = mixer;
    this.cameraTrackInfo = {
      src: null,
      dest: null,
      deviceId: null,
    };
    devtools(this._state, { name: 'DeviceAPI' });
  }

  async init() {
    if (this._state.inited) return;
    this.initFromStorage();
    await this.mixer.init();
    this._state.inited = true;
  }

  private initFromStorage() {
    this._state.activeAudioInputDeviceOption = this.storage.get(
      MediaKind.AudioInput
    );
    this._state.activeVideoInputDeviceOption = this.storage.get(
      MediaKind.VideoInput
    );
    this._state.activeAudioOutputDeviceOption = this.storage.get(
      MediaKind.AudioOutput
    );
  }

  /**
   * The caller is responsible for releasing the returned media streams
   * @returns
   */
  async initUserMedia(config: { audio: boolean; video: boolean }) {
    const audioConstraints = this.storage.get(MediaKind.AudioInput)?.value
      ? { deviceId: { exact: this.storage.get(MediaKind.AudioInput)?.value } }
      : true;
    const videoConstraints = this.storage.get(MediaKind.VideoInput)?.value
      ? {
          deviceId: { exact: this.storage.get(MediaKind.VideoInput)?.value },
          width: { ideal: this.profile.width },
          height: { ideal: this.profile.height },
          frameRate: { ideal: this.profile.frameRate },
        }
      : { facingMode: 'user' };
    const result = await resolveUserMedia(
      config.audio ? audioConstraints : false,
      config.video ? videoConstraints : false
    );
    this._state.authorizedAudioError = result.audioError;
    this._state.authorizedVideoError = result.videoError;
    if (this._state.authorizedAudioError) {
      if (
        this._state.authorizedAudioError.name ===
        MediaErrorName.OverconstrainedError
      ) {
        this._state.activeAudioInputDeviceOption = null;
        this._state.activeAudioOutputDeviceOption = null;
      }
      this._state.audioInputOptions = [
        buildDeviceOption({
          label: formatError(
            MediaKind.AudioInput,
            this._state.authorizedAudioError
          ),
        }),
      ];
      this._state.audioOutputOptions = [
        buildDeviceOption({
          label: formatError(
            MediaKind.AudioOutput,
            this._state.authorizedAudioError
          ),
        }),
      ];
    }
    if (this._state.authorizedVideoError) {
      if (
        this._state.authorizedVideoError.name ===
        MediaErrorName.OverconstrainedError
      ) {
        this._state.activeVideoInputDeviceOption = null;
      }
      this._state.videoInputOptions = [
        buildDeviceOption({
          label: formatError(
            MediaKind.VideoInput,
            this._state.authorizedVideoError
          ),
        }),
      ];
    }
    if (
      result.audioDeviceOption &&
      result.audioDeviceOption.value !==
        this._state.activeAudioInputDeviceOption?.value
    ) {
      this._state.activeAudioInputDeviceOption = result.audioDeviceOption;
    }
    if (
      result.videoDeviceOption &&
      result.videoDeviceOption.value !==
        this._state.activeVideoInputDeviceOption?.value
    ) {
      this._state.activeVideoInputDeviceOption = result.videoDeviceOption;
    }
    this._state.policy = {
      audio: !result.audioError,
      video: !result.videoError,
    };
    this._state.authorized = true;
    return {
      audioStream: result.audioStream,
      videoStream: result.videoStream,
    };
  }

  cloneVideoTrack() {
    return this.cameraTrackInfo.dest?.clone();
  }

  updateActiveDeviceOption(
    kind: MediaKind,
    option: DeviceOption | null,
    updateStorage = true
  ) {
    switch (kind) {
      case MediaKind.AudioInput:
        this._state.activeAudioInputDeviceOption = option;
        break;
      case MediaKind.VideoInput:
        this._state.activeVideoInputDeviceOption = option;
        break;
      case MediaKind.AudioOutput:
        this._state.activeAudioOutputDeviceOption = option;
        break;
      default:
        assertExhaustive(kind);
        throw new Error(`unknown MediaKind: ${kind}`);
    }
    if (updateStorage) {
      if (option && option.value !== '') {
        this.storage.set(kind, option);
      } else {
        this.storage.remove(kind);
      }
    }
  }

  async updateDeviceOptions() {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      if (this._state.policy?.audio) {
        this._state.audioInputOptions = getDeviceOptionsByKind(
          devices,
          MediaKind.AudioInput,
          [NoMicDeviceOption]
        );
        this._state.audioOutputOptions = getDeviceOptionsByKind(
          devices,
          MediaKind.AudioOutput,
          []
        );
      }
      if (this._state.policy?.video) {
        this._state.videoInputOptions = getDeviceOptionsByKind(
          devices,
          MediaKind.VideoInput,
          [NoCameraDeviceOption]
        );
      }
      this._state.deviceOptionsUpdates += 1;
    } catch (error) {
      log.error('updateDeviceOptions failed', error);
    }
  }

  watchDeviceChange() {
    const fn = this.updateDeviceOptions.bind(this);
    navigator.mediaDevices.addEventListener('devicechange', fn);
    fn();
    return () => {
      navigator.mediaDevices.removeEventListener('devicechange', fn);
    };
  }

  async updateSingletonVideoTrack(cmd: UpdateSingletonVideoTrackCmd) {
    const nextDeviceId = cmd.action === 'update' ? cmd.input.deviceId : null;
    log.info('update singleton video track', {
      action: cmd.action,
      currDeviceId: this.cameraTrackInfo.deviceId,
      nextDeviceId: nextDeviceId,
    });

    if (cmd.action === 'remove') {
      this.resetCameraTrackInfo();
      return;
    }

    const input = cmd.input;

    if (
      this.cameraTrackInfo.deviceId === input.deviceId &&
      input.deviceId !== null
    ) {
      log.info('same device, skip updating media stream', {
        deviceId: input.deviceId,
      });
      return;
    }

    // We will keep the mixer updated even if the input camera track is null.
    // This will be useful if we just want to turn off the camera, but keep
    // the stage in the mixer.

    this.cameraTrackInfo.src?.stop();
    this.cameraTrackInfo.src = null;
    let sourceCameraTrack = input.cameraTrack;
    if (input.clone && input.cameraTrack) {
      sourceCameraTrack = input.cameraTrack.clone();
      this.cameraTrackInfo.src = sourceCameraTrack;
    }
    await this.mixer.updateCameraTrack(sourceCameraTrack);
    this.cameraTrackInfo.dest = this.mixer.outputVideoTrack;
    this.cameraTrackInfo.deviceId = input.deviceId;
    this._state.mediaStreamId = this.cameraTrackInfo.dest?.id ?? null;
  }

  toggleBlockedPrompt(error: Error | null, val: boolean) {
    if (!error || val === false) {
      this._state.showUserBlockedPrompt = false;
      this._state.showSystemBlockedPrompt = false;
    } else {
      if (isBlockedBySystem(error)) {
        this._state.showUserBlockedPrompt = false;
        this._state.showSystemBlockedPrompt = true;
      } else {
        this._state.showSystemBlockedPrompt = false;
        this._state.showUserBlockedPrompt = true;
      }
    }
  }

  updateGroupedAudioDevices() {
    navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        const audioInputGroupId = getDevicesByKind(
          devices,
          MediaKind.AudioInput
        ).find(
          (d) => d.deviceId === this._state.activeAudioInputDeviceOption?.value
        )?.groupId;
        const audioOutputGroupId = getDevicesByKind(
          devices,
          MediaKind.AudioOutput
        ).find(
          (d) => d.deviceId === this._state.activeAudioOutputDeviceOption?.value
        )?.groupId;
        this._state.isGroupedAudioDevices =
          audioInputGroupId === audioOutputGroupId;
      })
      .catch((err) => log.error('updateGroupedAudioDevices failed', err));
  }

  updateDeviceCheckedInSession() {
    if (this.hasAuthorizedError()) {
      sessionStorage.removeItem('device_checked');
    } else {
      sessionStorage.setItem('device_checked', 'true');
    }
  }

  checkSkippable() {
    if (this.hasAuthorizedError()) return false;
    return sessionStorage.getItem('device_checked') === 'true';
  }

  get state(): Readonly<State> {
    return this._state;
  }

  private resetCameraTrackInfo() {
    this.cameraTrackInfo.src?.stop();
    this.cameraTrackInfo.src = null;
    this.cameraTrackInfo.dest = null;
    this.cameraTrackInfo.deviceId = null;
    this._state.mediaStreamId = null;
  }

  reset() {
    ValtioUtils.reset(
      this._state,
      initialState({
        audioSupported: true,
        videoSupported: true,
      })
    );
    this.resetCameraTrackInfo();
    this.updateSingletonVideoTrack({ action: 'remove' });
    this.mixer.destroy().catch((err) => log.error('dispose mixer failed', err));
  }

  private hasAuthorizedError() {
    return (
      !!this._state.authorizedAudioError || !!this._state.authorizedVideoError
    );
  }
}

interface DeviceContext {
  api: IDeviceAPI;
}

const Context = React.createContext<DeviceContext | null>(null);

export function useDeviceContext(): DeviceContext {
  const ctx = useContext(Context);
  if (!ctx) {
    throw new Error('DeviceContext is not in the tree!');
  }
  return ctx;
}

export function useDeviceAPI() {
  return useDeviceContext().api;
}

export function useDeviceState(): State {
  const api = useDeviceAPI();
  return useSnapshot(api.state) as State;
}

export function useActiveDeviceOption(kind: MediaKind): DeviceOption | null {
  const snapshot = useDeviceState();
  switch (kind) {
    case MediaKind.AudioInput:
      return snapshot.activeAudioInputDeviceOption;
    case MediaKind.VideoInput:
      return snapshot.activeVideoInputDeviceOption;
    case MediaKind.AudioOutput:
      return snapshot.activeAudioOutputDeviceOption;
    default:
      assertExhaustive(kind);
      throw new Error(`unknown MediaKind: ${kind}`);
  }
}

export function useDeviceOptions(kind: MediaKind): DeviceOption[] {
  const snapshot = useDeviceState();
  switch (kind) {
    case MediaKind.AudioInput:
      return snapshot.audioInputOptions;
    case MediaKind.VideoInput:
      return snapshot.videoInputOptions;
    case MediaKind.AudioOutput:
      return snapshot.audioOutputOptions;
    default:
      assertExhaustive(kind);
      throw new Error(`unknown MediaKind: ${kind}`);
  }
}

export function useBlockedPrompt(): [boolean, boolean, (val: boolean) => void] {
  const {
    authorizedAudioError,
    authorizedVideoError,
    showUserBlockedPrompt,
    showSystemBlockedPrompt,
  } = useDeviceState();
  const api = useDeviceAPI();
  const error = authorizedVideoError || authorizedAudioError;

  const toggle = useCallback(
    (val: boolean) => {
      api.toggleBlockedPrompt(error, val);
    },
    [api, error]
  );

  return [showUserBlockedPrompt, showSystemBlockedPrompt, toggle];
}

export function useCloneSingletonMediaStream(): MediaStream | null {
  const api = useDeviceAPI();
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
  const { video } = useUserStates();
  const latestVideo = useLatest(video);
  const srcMediaStreamId = useDeviceState().mediaStreamId;

  useEffect(() => {
    if (!srcMediaStreamId) return;
    let stream: MediaStream | null = null;
    const track = api.cloneVideoTrack();
    if (track) {
      track.enabled = latestVideo.current;
      stream = new MediaStream();
      stream.addTrack(track);
    }
    setMediaStream(stream);
    return () => {
      releaseMediaStream(stream);
      setMediaStream(null);
    };
  }, [api, latestVideo, srcMediaStreamId]);

  useEffect(() => {
    if (!mediaStream) return;
    const track = mediaStream.getVideoTracks()[0];
    if (track.enabled !== video) {
      track.enabled = video;
    }
  }, [mediaStream, video]);

  return mediaStream;
}

function useWatchDeviceChange(inited: boolean) {
  const api = useDeviceAPI();
  const { policy } = useDeviceState();

  useEffect(() => {
    if (policy === null || !inited) return;
    return api.watchDeviceChange();
  }, [policy, inited, api]);

  return inited;
}

function useInitDeviceContext() {
  const api = useDeviceAPI();
  useLayoutEffect(() => {
    api.init();
  }, [api]);
}

function useAutoUpdateActiveDeviceOption(
  kind: MediaKind,
  inited: boolean
): void {
  const { policy } = useDeviceState();
  const activeDeviceOption = useActiveDeviceOption(kind);
  const options = useDeviceOptions(kind);
  const api = useDeviceAPI();

  useEffect(() => {
    if (policy === null || !inited || options.length === 0) return;
    if (!activeDeviceOption) {
      api.updateActiveDeviceOption(kind, options[0]);
    } else if (activeDeviceOption.value === DefaultDeviceId) {
      const defaultOption = options.find((o) => o.value === DefaultDeviceId);
      if (defaultOption) {
        if (defaultOption.label !== activeDeviceOption.label) {
          api.updateActiveDeviceOption(kind, defaultOption);
        }
      } else {
        api.updateActiveDeviceOption(kind, options[0]);
      }
    } else {
      const currentAudioInput = options.find(
        (o) => o.value === activeDeviceOption.value
      );
      if (!currentAudioInput) {
        api.updateActiveDeviceOption(kind, options[0]);
      }
    }
  }, [policy, inited, options, activeDeviceOption, kind, api]);
}

function useUpdateGroupedAudioDevices(inited: boolean): void {
  const { activeAudioInputDeviceOption, activeAudioOutputDeviceOption } =
    useDeviceState();
  const api = useDeviceAPI();
  useEffect(() => {
    if (!inited) return;
    api.updateGroupedAudioDevices();
  }, [
    activeAudioOutputDeviceOption,
    activeAudioInputDeviceOption,
    inited,
    api,
  ]);
}

class SetupLocalStreamError extends Error {
  name = 'SetupLocalStreamError';
}

/**
 * When creating mutiple MediaStream instances from same real camera with different resolutions,
 * Chromium based browsers can not handle it correctly.  Example sandbox is demonstrating here:
 * https://codesandbox.io/s/wrong-video-resolution-on-chromium-d8xs3. The workaround is to create
 * a high resolution stream before other instances, the other smaller isntances created from
 * `getUserMedia` still work well and the cloned stream from `useLocalStream` hook can cover
 * offscreen video streams.
 */
function useSetupSingletonVideoTrack(enabled: boolean): void {
  const { activeVideoInputDeviceOption } = useDeviceState();
  const api = useDeviceAPI();
  const { joined, camOpen } = useUserStates();
  const { addTask } = useTaskQueue({ shouldProcess: enabled });
  const streamRef = useRef<MediaStream | null>(null);
  useEffect(() => {
    if (!joined || !enabled) return;
    if (!activeVideoInputDeviceOption) return;
    addTask(async () => {
      if (!activeVideoInputDeviceOption) return;
      releaseMediaStream(streamRef.current);
      streamRef.current = null;
      try {
        if (camOpen) {
          const alternativeDeviceOption = await getAlternativeDeviceOption(
            activeVideoInputDeviceOption,
            MediaKind.VideoInput
          );
          streamRef.current = await getUserMedia({
            video: {
              deviceId: { exact: alternativeDeviceOption.value },
              width: { ideal: api.profile.width },
              height: { ideal: api.profile.height },
              frameRate: { ideal: api.profile.frameRate },
            },
          });
          await api.updateSingletonVideoTrack({
            action: 'update',
            input: {
              cameraTrack: streamRef.current.getVideoTracks()[0],
              deviceId: alternativeDeviceOption.value,
            },
          });
        } else {
          await api.updateSingletonVideoTrack({
            action: 'update',
            input: {
              cameraTrack: null,
              deviceId: null,
            },
          });
        }
      } catch (error: UnassertedUnknown) {
        const err = new SetupLocalStreamError('setup local stream failed', {
          cause:
            error instanceof Error
              ? error
              : { name: error.name, message: error.message },
        });
        log.error(err.message, err);
      }
    });
  }, [joined, activeVideoInputDeviceOption, api, enabled, addTask, camOpen]);

  useEffect(() => {
    return () => {
      api.updateSingletonVideoTrack({ action: 'remove' });
      releaseMediaStream(streamRef.current);
      streamRef.current = null;
    };
  }, [api]);
}

function Bootstrap(): JSX.Element | null {
  const { inited } = useDeviceState();
  useInitDeviceContext();
  useWatchDeviceChange(inited);
  useSetupSingletonVideoTrack(inited);
  useAutoUpdateActiveDeviceOption(MediaKind.AudioInput, inited);
  useAutoUpdateActiveDeviceOption(MediaKind.VideoInput, inited);
  useAutoUpdateActiveDeviceOption(MediaKind.AudioOutput, inited);
  useUpdateGroupedAudioDevices(inited);
  return null;
}

export type DeviceVirtualBackgroundProps = {
  virtualBackgroundEnabled?: VirtualBackgroundOptions['enabled'];
};

export function DeviceContextProvider(props: {
  profileIndex: ProfileIndex;
  mixer: IVideoStreamMixer;
  noop?: boolean;
  children?: ReactNode;
}): JSX.Element {
  const api = useMemo(
    () =>
      props.noop
        ? new NoopDeviceAPI(props.profileIndex)
        : new DeviceAPI(props.profileIndex, props.mixer),
    [props.noop, props.profileIndex, props.mixer]
  );

  useEffect(() => {
    return () => api.reset();
  }, [api]);

  const ctxValue = useMemo(
    () => ({
      api,
    }),
    [api]
  );

  return (
    <Context.Provider value={ctxValue}>
      <Bootstrap />
      {props.children}
    </Context.Provider>
  );
}
