import {
  AIDenoiserExtension,
  type IAIDenoiserProcessor,
} from 'agora-extension-ai-denoiser';
import VirtualBackgroundExtension, {
  type IVirtualBackgroundProcessor,
} from 'agora-extension-virtual-background';
import type {
  ClientRole,
  CustomVideoTrackInitConfig,
  IAgoraRTCRemoteUser,
  ICameraVideoTrack,
  ILocalAudioTrack,
  ILocalVideoTrack,
  IMicrophoneAudioTrack,
  IRemoteAudioTrack,
  IRemoteVideoTrack,
  LowStreamParameter,
  NetworkQuality,
  RemoteAudioTrackStats,
  RemoteStreamType,
  RemoteVideoTrackStats,
  UID,
  VideoPlayerConfig,
} from 'agora-rtc-sdk-ng';
import type { IBaseProcessor, IProcessorContext } from 'agora-rte-extension';

import { type Logger, type LogLevel } from '@lp-lib/logger-base';

import { type EmitterListener } from '../../utils/emitter';
import { type MicVolumeMeter } from '../../utils/MicVolumeMeter';
import { type AudioBus } from '../audio/audio-bus';
import { type AgoraVideoAutoRecovery } from './video-recovery';

export interface IAgoraException {
  code: number;
  msg: string;
  uid: UID;
}

export type AgoraNumericUID = number;

export type AgoraJoinStatus = {
  channel: string;
  role: ClientRole;
  numericUid: AgoraNumericUID | null;
};

export type GetAgoraToken = (uid: UID, channel: string) => Promise<string>;

export type AudioTrack = ILocalAudioTrack | IRemoteAudioTrack;
export type VideoTrack = ILocalVideoTrack | IRemoteVideoTrack;
export interface IRTCService extends EmitterListener<RTCServiceListenerEvents> {
  readonly name: string;
  readonly uid: UID;
  readonly log: Logger;
  join(channel: string, role: ClientRole): Promise<AgoraNumericUID | null>;
  leave(): Promise<void>;
  setClientRole(role: ClientRole): Promise<void>;
  publish(): Promise<void>;
  publishAudio(): Promise<void>;
  publishVideo(): Promise<void>;
  unpublish(): Promise<void>;
  subscribeEvents(): void;
  unsubscribeEvents(): void;
  getTracksByUid(
    uid: UID | null
  ): [AudioTrack | undefined, VideoTrack | undefined];
  play(
    uid: UID,
    element: string | HTMLElement,
    config?: VideoPlayerConfig
  ): { audio: boolean; video: boolean };
  playAudio(uid: UID): boolean;
  playVideo(
    uid: UID,
    element: string | HTMLElement,
    config?: VideoPlayerConfig
  ): boolean;
  stop(uid: UID): void;
  stopAudio(uid: UID): void;
  stopVideo(uid: UID): void;
  stopAll(): void;
  setRemoteVideoStreamType(
    uid: UID,
    streamType: RemoteStreamType
  ): Promise<void>;
  setRemoteUserVolume(volume: number): void;
  joinStatus(): AgoraJoinStatus | undefined;
  isLocalUser(uid: UID): boolean;
  getVideoTrackStateMap(): Record<UID, TrackState>;
  getVideoTrackStateByUid(uid: UID): TrackState | null;
  updateVideoTrackState(uid: UID, state: TrackState | null): void;
  resubscribe(uid: UID, mediaType: 'audio' | 'video'): Promise<void>;
  subscribeVideo(
    user: UID | IAgoraRTCRemoteUser,
    debug?: string
  ): Promise<void>;
  subscribeAudio(
    user: UID | IAgoraRTCRemoteUser,
    debug?: string
  ): Promise<void>;
  unsubscribeVideo(
    user: UID | IAgoraRTCRemoteUser,
    debug?: string
  ): Promise<void>;
  unsubscribeAudio(
    user: UID | IAgoraRTCRemoteUser,
    debug?: string
  ): Promise<void>;
  denosier(): IAIDenoiserAPI | undefined;
  close(): Promise<void>;
}

export interface IMediaDeviceRTCService extends IRTCService {
  readonly audioBus: AudioBus;
  readonly videoRecovery?: AgoraVideoAutoRecovery;
  toggleAudio(val: boolean): Promise<void>;
  muteAudio(val: boolean): void;
  toggleVideo(val: boolean): Promise<void>;
  toggleMicDeviceSetting(
    setting: keyof MediaDeviceSettings,
    val: boolean
  ): Promise<void>;
  getMicDeviceSettings(): Readonly<MediaDeviceSettings>;
  switchAudioInput(deviceId: string): Promise<void>;
  switchAudioOuptut(deviceId: string): Promise<void>;
  switchVideo(deviceId: string | MediaStreamTrack): Promise<void>;
  setEncoderConfiguration(
    config: CustomVideoQualityPreset | 'default',
    options?: {
      suppressError?: boolean;
      persistConfig?: boolean;
    }
  ): Promise<boolean>;
  getVolumeMeter(): MicVolumeMeter | undefined;
  attemptMusicShare(): Promise<void>;
  stopMusicShare(): Promise<void>;
}

export interface ICustomRTCService extends IRTCService {
  switchAudio(track: MediaStreamTrack): void;
  switchVideo(track: MediaStreamTrack): void;
}

export interface IBaseAgoraLocalUser<
  A extends ILocalAudioTrack,
  V extends ILocalVideoTrack
> {
  uid: UID;
  audioTrack?: A;
  videoTrack?: V;
  hasAudio: boolean;
  hasVideo: boolean;
}

export interface IMediaDeviceAgoraLocalUser<
  A extends ILocalAudioTrack,
  V extends ILocalVideoTrack
> extends IBaseAgoraLocalUser<A, V> {
  inputAudioTrack?: IMicrophoneAudioTrack;
  inputVideoTrack?: ICameraVideoTrack | ILocalVideoTrack;
  musicShare?: MediaStream;
  isScreenSharing: boolean;
  isMusicSharing: boolean;
}

export enum TrackState {
  Unknown = 'Unknown',
  Subscribed = 'Subscribed',
  Live = 'Live',
  Disconnected = 'Disconnected',
}

export interface NetworkQualityState {
  uplinkNetworkQuality?: number;
  downlinkNetworkQuality?: number;
}

export const NetworkQualityBad = 3;

type NoArgListener = () => void;
type ValueToggledListener = (value: boolean) => void;

type RemoteUserStreamStateChangedListener = (
  uid: UID,
  mediaType: 'audio' | 'video'
) => void;

type TrackStateChangedListener = (
  uid: UID,
  oldState: TrackState | null,
  newState: TrackState | null
) => void;

type UserVolumeChangedListener = (volumeMap: Record<UID, number>) => void;
type NetworkQualityListener = (stats: NetworkQuality) => void;
type StreamTypeChangedListener = (
  uid: UID,
  streamType: RemoteStreamType
) => void;

export interface MediaDeviceSettings {
  autoGainControl: boolean;
  noiseSuppression: boolean;
}

export type RTCServiceListenerEvents = {
  'remote-user-published': RemoteUserStreamStateChangedListener;
  'remote-user-unpublished': RemoteUserStreamStateChangedListener;
  'audio-toggled': ValueToggledListener;
  'video-toggled': ValueToggledListener;
  'audio-switched': NoArgListener;
  'video-switched': NoArgListener;
  'audio-mic-device-setting-toggled': NoArgListener;
  'video-track-state-changed': TrackStateChangedListener;
  'user-volume-changed': UserVolumeChangedListener;
  'network-quality': NetworkQualityListener;
  'music-share-changed': ValueToggledListener;
  'stream-type-changed': StreamTypeChangedListener;
  'remote-user-subscribed': RemoteUserStreamStateChangedListener;
  'remote-user-unsubscribed': RemoteUserStreamStateChangedListener;
};

export type CustomVideoLowQualityPreset =
  | '120p'
  | '240p'
  | '360p'
  | '480p'
  | '540p'
  | '720p';
const CustomVideoLowQualityProfileParameters: {
  [key in CustomVideoLowQualityPreset]: LowStreamParameter;
} = {
  '120p': { width: 160, height: 120, framerate: 15, bitrate: 50 },
  '240p': { width: 320, height: 240, framerate: 15, bitrate: 200 },
  '360p': { width: 480, height: 360, framerate: 15, bitrate: 400 },
  '480p': { width: 640, height: 480, framerate: 15, bitrate: 600 },
  '540p': { width: 960, height: 540, framerate: 15, bitrate: 800 },
  '720p': { width: 1280, height: 720, framerate: 15, bitrate: 1000 },
};

type CustomVideoProfileConfig = {
  width: number;
  height: number;
  framerate: number;
  bitrateMin: number;
  bitrateMax: number;
  optimizationMode?: CustomVideoTrackInitConfig['optimizationMode'];
};

const CUSTOM_VIDEO_PROFILE_PRESETS = [
  '120p',
  '240p',
  '360p',
  '480p',
  '540p',
  '720p',
  '720p_1',
  '1080p',
] as const;
export type CustomVideoQualityPreset =
  (typeof CUSTOM_VIDEO_PROFILE_PRESETS)[number];

const CustomVideoProfileParameters: {
  [key in CustomVideoQualityPreset]: CustomVideoProfileConfig;
} = {
  '120p': {
    width: 160,
    height: 120,
    framerate: 10,
    bitrateMin: 50,
    bitrateMax: 100,
  },
  '240p': {
    width: 320,
    height: 240,
    framerate: 15,
    bitrateMin: 100,
    bitrateMax: 200,
  },
  '360p': {
    width: 480,
    height: 360,
    framerate: 15,
    bitrateMin: 200,
    bitrateMax: 400,
  },
  '480p': {
    width: 640,
    height: 480,
    framerate: 15,
    bitrateMin: 400,
    bitrateMax: 600,
  },
  '540p': {
    width: 960,
    height: 540,
    framerate: 15,
    bitrateMin: 600,
    bitrateMax: 800,
  },
  '720p': {
    width: 1280,
    height: 720,
    framerate: 24,
    bitrateMin: 800,
    bitrateMax: 1800,
  },
  '720p_1': {
    width: 1280,
    height: 720,
    framerate: 24,
    bitrateMin: 400,
    bitrateMax: 1200,
  },
  '1080p': {
    width: 1920,
    height: 1080,
    framerate: 15,
    bitrateMin: 120,
    bitrateMax: 2080,
  },
};

export function profileForCustomVideo(
  profileIndex: CustomVideoQualityPreset
): CustomVideoProfileConfig {
  const profile = CustomVideoProfileParameters[profileIndex];
  if (!profile) throw new Error(`invalid profileIndex: ${profileIndex}`);
  return profile;
}

export function profileForCustomVideoLowQuality(
  profileIndex: CustomVideoLowQualityPreset
): LowStreamParameter {
  const profile = CustomVideoLowQualityProfileParameters[profileIndex];
  if (!profile) throw new Error(`invalid profileIndex: ${profileIndex}`);
  return profile;
}

export type LogEntry = {
  timestamp: string;
  level: LogLevel;
  message: string;
  data?: unknown[];
};

export type WebRTCTestResult = {
  id: string;
  succeeded: boolean;
  logs: LogEntry[];
  data: RemoteVideoTrackStats[] | RemoteAudioTrackStats[];
  channelName: string;
  startedAt: string;
  endedAt: string;
};

// Note(jialin): there is really nothing we can do of those errors,
// and from the logs, they usually auto recovered after a while.
// https://docs.agora.io/en/Video/API%20Reference/web_ng/interfaces/iagorartcclient.html?platform=Web#event_exception
export const INGORED_AGORA_EXCEPTIONS = new Set([
  'AUDIO_OUTPUT_LEVEL_TOO_LOW',
  'AUDIO_OUTPUT_LEVEL_TOO_LOW_RECOVER',
  'AUDIO_INPUT_LEVEL_TOO_LOW',
  'AUDIO_INPUT_LEVEL_TOO_LOW_RECOVER',
  'SEND_VIDEO_BITRATE_TOO_LOW',
  'SEND_VIDEO_BITRATE_TOO_LOW_RECOVER',
  'SEND_AUDIO_BITRATE_TOO_LOW',
  'SEND_AUDIO_BITRATE_TOO_LOW_RECOVER',
  'FRAMERATE_INPUT_TOO_LOW',
  'FRAMERATE_INPUT_TOO_LOW_RECOVER',
  'FRAMERATE_SENT_TOO_LOW',
  'FRAMERATE_SENT_TOO_LOW_RECOVER',
]);

export type RemoteUser = {
  user: IAgoraRTCRemoteUser;
  audioSubscribed: boolean;
  videoSubscribed: boolean;
  videoTrackState: TrackState | null;
};

export type RemoteStreamState = {
  audioReady?: boolean;
  videoReady?: boolean;
  volume?: number;
  trackState?: TrackState | null;
};

export type RemoteStreamStateV2 = {
  audioPublished?: boolean;
  videoPublished?: boolean;
  audioSubscribed?: boolean;
  videoSubscribed?: boolean;
  volume?: number;
  trackState?: TrackState | null;
};

export interface IAIDenoiserProcessorPatched extends IAIDenoiserProcessor {
  updateInput(inputOptions: {
    track?: MediaStreamTrack;
    node?: AudioNode;
    context: IProcessorContext;
  }): void;
  reset(): void;
  // eslint-disable-next-line @typescript-eslint/ban-types
  off(event: string, listener: Function): void;
}

export type IAIDenoiserAPI = Omit<IAIDenoiserProcessor, 'pipe' | 'unpipe'>;

export declare class AIDenoiserExtensionPatched extends AIDenoiserExtension {
  createProcessor(): IAIDenoiserProcessorPatched;
}

export interface IVirtualBackgroundProcessorPatched
  extends IVirtualBackgroundProcessor,
    IBaseProcessor {}

export declare class VirtualBackgroundExtensionPatched extends VirtualBackgroundExtension {
  createProcessor(): IVirtualBackgroundProcessorPatched;
}
