import { Link } from '@remix-run/react';
import isEqual from 'lodash/isEqual';
import { useMemo } from 'react';

import {
  EnumsBrandPredefinedBlockScenario,
  type ModelsTTSScript,
} from '@lp-lib/api-service-client/public';
import {
  type BlockFields,
  type ScoreboardBlock,
  ScoreboardMode,
  type VoiceOver,
} from '@lp-lib/game';

import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { uuidv4 } from '../../../../utils/common';
import { type TagQuery } from '../../../../utils/TagQuery';
import { useAwaitFullScreenConfirmCancelModal } from '../../../ConfirmCancelModalContext';
import {
  defaultTTSOptions,
  TTSOptionEditor,
} from '../../../VoiceOver/TTSOptionEditor';
import {
  type ScriptScenarioOption,
  TTSScriptsEditor,
} from '../../../VoiceOver/TTSScriptsEditor';
import { VoiceOverUtils } from '../../../VoiceOver/utils';
import {
  hasLLMCodeFences,
  hasVariables,
  LLMTemplateRenderer,
} from '../../../VoiceOver/VariableRegistry';
import { VoiceOverEditor } from '../../../VoiceOver/VoiceOverEditor';
import { makeExampleVariableRegistrySnapshot } from '../../hooks/useCreateGameInfoSnapshot';
import {
  AdditionalSettings,
  AdditionalSharedSettingsEditor,
  type Option,
} from '../Common/Editor/EditorUtilities';
import {
  FieldContainer,
  FieldDescription,
  FieldPanel,
  FieldTitle,
  SimpleFieldEditor,
} from '../Common/Editor/FieldEditorUtilities';
import {
  type EditorProps,
  RHFSelectField,
  useEditor,
} from '../Common/Editor/Internal';

export const SCOREBOARD_MODE_OPTIONS: (Option & { description: string })[] = [
  {
    value: ScoreboardMode.GlobalTeams,
    label: 'Global Teams',
    description:
      'Displays scores for teams across all organizations on Luna Park.',
  },
  {
    value: ScoreboardMode.OrgTeams,
    label: 'Company Teams',
    description: "Displays scores for teams across the user's organization.",
  },
  {
    value: ScoreboardMode.VenueTeams,
    label: 'Venue Teams',
    description: 'Displays scores for teams in this session.',
  },
  {
    value: ScoreboardMode.VenueGlobalTeams,
    label: 'Venue/Global Teams',
    description:
      'Displays a two panel scoreboard with the venue scoreboard on top and the global scoreboard below.',
  },
];

const exampleVariableRegistry = makeExampleVariableRegistrySnapshot();
const scoreboardScriptScenarioOptions: ScriptScenarioOption[] = [
  {
    label: 'Preferred',
    description:
      'The preferred scripts to use during playback. A script is chosen at random and played.',
    tags: ['runtime'],
    supportedVariables: exampleVariableRegistry,
    templateRenderer: new LLMTemplateRenderer(exampleVariableRegistry),
  },
  {
    label: 'Fallback',
    description:
      'Used in case a preferred script cannot be rendered in time. These scripts should not use variables.',
    tags: ['fallback'],
  },
];

export function ScoreboardBlockEditor(
  props: EditorProps<ScoreboardBlock>
): JSX.Element {
  const { updateField } = useEditor(props);
  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  const { id, fields } = props.block;
  const { mode, voiceOver } = fields;

  const voiceOverScriptSubtitle = useMemo(() => {
    if (voiceOver?.runtime?.settings?.noInjectedScripts) return null;
    switch (props.blockScenario) {
      case EnumsBrandPredefinedBlockScenario.BrandPredefinedBlockScenarioScoreboard:
        return (
          <span className='text-sms font-normal text-icon-gray'>
            Note that <strong>Scoreboard</strong> voice overs are prepended with{' '}
            <em>%positionAwareScoreboardScript%</em>
          </span>
        );
    }
    return null;
  }, [props.blockScenario, voiceOver?.runtime?.settings?.noInjectedScripts]);

  const selectOnChange = (
    name: keyof BlockFields<ScoreboardBlock>,
    value: Nullable<Option['value'], false>
  ): void => {
    if (!value) return;

    updateField(name, value as ScoreboardMode);
  };

  const handleVoiceOverChange = useLiveCallback(
    async (updated: VoiceOver | null) => {
      await updateField('voiceOver', { ...voiceOver, ...updated });
    }
  );

  const handleTTSOptionsChange = (
    value: BlockFields<ScoreboardBlock>['ttsOptions']
  ) => {
    updateField('ttsOptions', value);
  };

  const handleAddTTSScript = useLiveCallback(async (value: ModelsTTSScript) => {
    await updateField('ttsScripts', [
      ...(props.block.fields.ttsScripts ?? []),
      value,
    ]);
  });

  const handleRemoveTTSScript = useLiveCallback(
    async (value: ModelsTTSScript) => {
      const nextTTSScripts = (props.block.fields.ttsScripts ?? []).filter(
        (s) => s.id !== value.id
      );
      await updateField('ttsScripts', nextTTSScripts);
    }
  );

  const handleChangeTTSScript = useLiveCallback(
    async (prev: ModelsTTSScript, next: ModelsTTSScript) => {
      const nextTTSScripts = [...(props.block.fields.ttsScripts ?? [])];
      const index = nextTTSScripts.findIndex((s) => s.id === prev.id);
      if (index === -1) return;

      const hasChanged = !isEqual(prev, next);
      if (hasChanged) {
        // only update the field if there's a change.
        nextTTSScripts[index] = next;
        await updateField('ttsScripts', nextTTSScripts);
      }
    }
  );

  const handleUploadTTSScripts = useLiveCallback(
    async (nextTTSScripts: ModelsTTSScript[]) => {
      await updateField('ttsScripts', nextTTSScripts);
    }
  );

  const handleMigrate = useLiveCallback(async () => {
    const voiceOver = props.block.fields.voiceOver;
    if (!voiceOver) return;

    if (voiceOver.fallback?.media) {
      const hasFallbackDescription = !!voiceOver.fallback.renderDescription;
      if (hasFallbackDescription) {
        const resp = await triggerModal({
          kind: 'confirm-cancel',
          prompt: (
            <div className='p-5 text-white text-center'>
              <p className='text-2xl font-medium'>Are you sure?</p>
              <p className='mt-4 text-sms'>
                The current voice over configuration has fallback media.
                However, there is a script that was used to generate the media
                that can be ported to the new voice over settings.
              </p>
              <p className='mt-4 text-sms text-tertiary'>
                {voiceOver.fallback.renderDescription?.script}
              </p>
              <p className='mt-4 text-sms'>
                We will no longer use the media, but can copy over the script.
              </p>
            </div>
          ),
          confirmBtnLabel: 'Continue',
          cancelBtnLabel: 'Cancel',
        });
        if (resp.result === 'canceled') return;
      } else {
        await triggerModal({
          kind: 'confirm-cancel',
          prompt: (
            <div className='p-5 text-white text-center'>
              <p className='text-2xl font-medium'>Are you sure?</p>
              <p className='mt-4 text-sms'>
                The current voice over configuration has fallback media, but
                there is no script that describes how the media was generated.
                We no longer support this moving forward. Please either remove
                the media or provide a fallback script.
              </p>
            </div>
          ),
          confirmBtnLabel: 'Ok',
          confirmOnly: true,
        });
        return;
      }
    }

    const nextTTSScripts: ModelsTTSScript[] = [];
    if (voiceOver.runtime) {
      const prependPositionScenarioVariable =
        !voiceOver?.runtime?.settings?.noInjectedScripts &&
        props.blockScenario ===
          EnumsBrandPredefinedBlockScenario.BrandPredefinedBlockScenarioScoreboard &&
        voiceOver.runtime.script.indexOf('%positionAwareScoreboardScript%') ===
          -1;

      const script = prependPositionScenarioVariable
        ? `%positionAwareScoreboardScript% ${voiceOver.runtime.script}`
        : voiceOver.runtime.script;

      nextTTSScripts.push({
        id: uuidv4(),
        script,
        tags: ['runtime'],
      });
    }
    if (voiceOver.fallback?.renderDescription) {
      nextTTSScripts.push({
        id: uuidv4(),
        script: voiceOver.fallback.renderDescription.script,
        tags: ['fallback'],
      });
    }

    await updateField('ttsScripts', nextTTSScripts);
    await updateField('voiceOver', {});

    if (
      !props.block.fields.ttsOptions ||
      props.block.fields.ttsOptions.length === 0
    ) {
      await updateField('ttsOptions', defaultTTSOptions);
    }
  });

  const handleAddCustomizedScripts = useLiveCallback(async () => {
    await updateField('ttsScripts', [
      {
        id: uuidv4(),
        script: '',
        tags: ['runtime'],
      },
    ]);
  });

  const checkForFallbacks = useLiveCallback(
    (query: TagQuery<ModelsTTSScript>) => {
      if (
        props.blockScenario ===
        EnumsBrandPredefinedBlockScenario.BrandPredefinedBlockScenarioScoreboard
      ) {
        // scoreboard scenario blocks always get a fallback...
        return { ok: true };
      }

      const needsFallbacks = query
        .select(['runtime'])
        .some((s) => hasVariables(s.script) || hasLLMCodeFences(s.script));

      const ok = !needsFallbacks || query.select(['fallback']).length > 0;
      if (ok) return { ok };
      return {
        ok,
        message:
          'At least one of your preferred scripts uses variables. Define a fallback script to be used in case of failure.',
      };
    }
  );

  const currentModeOption = SCOREBOARD_MODE_OPTIONS.find(
    (option) => option.value === mode
  );

  const hasLegacyVoiceOvers = VoiceOverUtils.HasAtLeastOneConfig(
    props.block.fields.voiceOver
  );

  const useSharedScenarioScripts =
    !hasLegacyVoiceOvers &&
    props.blockScenario ===
      EnumsBrandPredefinedBlockScenario.BrandPredefinedBlockScenarioScoreboard &&
    (props.block.fields.ttsScripts ?? []).length === 0;

  return (
    <div className='w-full'>
      <h2 className='text-2xl text-white mb-7'>Scoreboard Block</h2>
      <div className='space-y-3'>
        <SimpleFieldEditor
          name='Scoreboard Mode'
          description={
            <>
              Determines what kind of scoreboard is shown.
              {currentModeOption && (
                <div className='pt-2'>
                  <strong>{currentModeOption.label}:</strong>{' '}
                  {currentModeOption.description}
                </div>
              )}
            </>
          }
        >
          <RHFSelectField<ScoreboardBlock>
            className='w-full text-white'
            name='mode'
            options={SCOREBOARD_MODE_OPTIONS}
            onChange={selectOnChange}
            value={mode}
          />
        </SimpleFieldEditor>
        {hasLegacyVoiceOvers ? (
          <FieldContainer styles={{ layout: 'flex flex-col gap-4' }}>
            <FieldPanel>
              <FieldTitle>Host Scripts</FieldTitle>
              <FieldDescription>
                <div className='w-full flex gap-4 justify-between items-center'>
                  <div className='text-tertiary'>
                    The current configuration of this block is using deprecated
                    voice over settings. Please migrate to the new settings.
                  </div>
                  <button
                    type='button'
                    className='w-40 h-10 btn-secondary'
                    onClick={handleMigrate}
                  >
                    Migrate
                  </button>
                </div>
              </FieldDescription>
            </FieldPanel>
            <div className='w-full'>
              <VoiceOverEditor
                id={id}
                voiceOver={voiceOver}
                onChange={handleVoiceOverChange}
                bottomAccessory={
                  <div className='text-icon-gray text-sms'>
                    Voice over audio. If uploaded, audio will start immediately
                    with the background media.
                  </div>
                }
                runtimeScriptSubtitle={voiceOverScriptSubtitle}
                onGenerateExampleRuntimeState={(state) => {
                  return {
                    ...state,
                    blockScenario: props.blockScenario ?? undefined,
                  };
                }}
              />
            </div>
          </FieldContainer>
        ) : useSharedScenarioScripts ? (
          <SimpleFieldEditor
            name='Host Scripts'
            description={
              <>
                This block is used as a{' '}
                <span className='text-white'>scoreboard brand scenario</span>,
                and it will default to using the shared scenario scripts. You
                may customize the scripts for this block, but they may not use
                the shared scripts.
              </>
            }
          >
            <div className='w-full space-y-2'>
              <Link to='/admin/toolkit/shared-scripts' target='_blank'>
                <button type='button' className='w-full h-10 btn-secondary'>
                  View Shared Scripts
                </button>
              </Link>
              <button
                type='button'
                className='w-full h-10 btn-secondary'
                onClick={handleAddCustomizedScripts}
              >
                Customize
              </button>
            </div>
          </SimpleFieldEditor>
        ) : (
          <TTSScriptsEditor
            title='Host Scripts'
            description={
              <>
                When specified, the AI host will read one of these scripts when
                the scoreboard is presented.
              </>
            }
            value={props.block.fields.ttsScripts}
            scenarioOptions={scoreboardScriptScenarioOptions}
            onAdd={handleAddTTSScript}
            onRemove={handleRemoveTTSScript}
            onChange={handleChangeTTSScript}
            onUpload={handleUploadTTSScripts}
            ttsRenderSettings={props.block.fields.ttsOptions?.[0]}
            csvDownloadName={`scoreboard-host-scripts-${props.block.id}.csv`}
            checkForFallbacks={checkForFallbacks}
          />
        )}
      </div>
      <AdditionalSettings>
        <AdditionalSharedSettingsEditor {...props} />
        <TTSOptionEditor
          value={props.block.fields.ttsOptions}
          onChange={handleTTSOptionsChange}
        />
      </AdditionalSettings>
    </div>
  );
}
