import { getAudioContext } from '../../services/audio/audio-context';

type MESNGainTuple = readonly [MediaElementAudioSourceNode, GainNode];

export class MESNFactory {
  protected readonly mesnMap = new Map<HTMLMediaElement, MESNGainTuple>();

  constructor(
    private destination: AudioNode,
    private audioCtx = getAudioContext()
  ) {}

  wire(video: HTMLMediaElement): MESNGainTuple {
    let pair = this.mesnMap.get(video);
    if (!pair) {
      const mesn = this.audioCtx.createMediaElementSource(video);
      const gain = this.audioCtx.createGain();
      mesn.connect(gain);
      gain.connect(this.destination);
      pair = [mesn, gain];
      this.mesnMap.set(video, pair);
    }
    return pair;
  }

  unwire(video: HTMLMediaElement): void {
    const pair = this.mesnMap.get(video);
    if (!pair) return;
    const [mesn, gain] = pair;
    mesn.disconnect();
    gain.disconnect();
    this.mesnMap.delete(video);
  }
}
