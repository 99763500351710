import { useEffect, useRef } from 'react';
import { useEffectOnce } from 'react-use';

import {
  type SpotlightBlock,
  SpotlightBlockGameSessionStatus,
} from '@lp-lib/game';

import { assertExhaustive } from '../../../../utils/common';
import { useOndVoiceOverRegistry } from '../../../VoiceOver/OndVoiceOverRegistryProvider';
import { VariableRegistry } from '../../../VoiceOver/VariableRegistry';
import {
  useGameSessionStatus,
  useGetOndGameCurrentPlaybackItem,
  useIsLiveGamePlay,
} from '../../hooks';
import { ondWaitReadyForSkip } from '../../OndPhaseRunner/OndPhaseRunner';
import { type GameControlProps } from '../Common/GameControl/types';
import { useStableBlock } from '../Common/hooks';
import { useSpotlightControlAPI } from './SpotlightBlockProvider';

type SharedProps = GameControlProps<SpotlightBlock>;

function Loaded(props: SharedProps) {
  const api = useSpotlightControlAPI();
  useEffectOnce(() => {
    api?.initBlock(props.block.id);
  });
  return null;
}

function Ended(props: SharedProps) {
  const api = useSpotlightControlAPI();
  useEffectOnce(() => {
    api?.resetBlock(props.block.id);
  });
  return null;
}

function Spotlight(props: SharedProps) {
  const isLive = useIsLiveGamePlay();
  if (isLive) return null;
  return <OndSpotlight {...props} />;
}

function OndSpotlight(props: SharedProps) {
  const api = useSpotlightControlAPI();

  useVoiceOverPlayback();
  useEffectOnce(() => {
    api?.spotlight(props.block);
  });

  return null;
}

function useVoiceOverPlayback() {
  const gss = useGameSessionStatus<SpotlightBlockGameSessionStatus>();
  const getCurrentPlaybackItem = useGetOndGameCurrentPlaybackItem();
  const voiceOverRegistry = useOndVoiceOverRegistry();
  const hasPlayedVoiceOver = useRef(false);

  useEffect(() => {
    async function run() {
      if (
        gss !== SpotlightBlockGameSessionStatus.CELEBRATING ||
        hasPlayedVoiceOver.current
      )
        return;

      hasPlayedVoiceOver.current = true;
      try {
        const playbackItem = getCurrentPlaybackItem();
        const voiceOverPlans = playbackItem?.voiceOverPlans ?? [];
        if (voiceOverPlans.length === 0) return;
        const vo = voiceOverPlans[0];
        const group = await voiceOverRegistry.getOrCreateGroup(vo.plan);

        const info = await group.play(new VariableRegistry(), { noLoad: true });
        await info?.trackEnded;
      } finally {
        await ondWaitReadyForSkip();
      }
    }
    run();
  });
}

export function SpotlightBlockControl(props: SharedProps): JSX.Element | null {
  const block = useStableBlock(props.block);
  const gss = useGameSessionStatus<SpotlightBlockGameSessionStatus>();
  const api = useSpotlightControlAPI();

  useEffect(() => {
    return () => {
      api?.resetBlock(block.id);
    };
  }, [block.id, api]);

  switch (gss) {
    case SpotlightBlockGameSessionStatus.LOADED:
      return <Loaded {...props} />;
    case SpotlightBlockGameSessionStatus.PRESENTING:
    case SpotlightBlockGameSessionStatus.CELEBRATING:
      return <Spotlight {...props} />;
    case SpotlightBlockGameSessionStatus.END:
      return <Ended {...props} />;
    case null:
    case undefined:
      break;
    default:
      assertExhaustive(gss);
      break;
  }

  return null;
}
