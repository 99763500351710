import { type DtoTTSRenderRequest } from '@lp-lib/api-service-client/public';
import { asFBReference } from '@lp-lib/firebase-typesafe';
import { type Media, type MediaData, VolumeLevel } from '@lp-lib/media';

import { apiService } from '../../services/api-service';
import { Chan } from '../../utils/Chan';
import { assertDefinedFatal, once, sleep } from '../../utils/common';
import { Emitter } from '../../utils/emitter';
import { hashObject } from '../../utils/hash-object';
import { MediaUtils } from '../../utils/media';
import { UnplayableBytes, type UnplayableMedia } from '../../utils/unplayable';
import { type FirebaseService } from '../Firebase';
import {
  type FBMsgPassStorage,
  recv,
  send,
} from '../Firebase/FirebaseMessagePassing';
import {
  type TrackId,
  TrackInitConfigBuilder,
  type VideoMixer,
} from '../VideoMixer';
import {
  createTrackEndAwaiter,
  createTrackRemoveAwaiter,
  createTrackStartAwaiter,
} from '../VideoMixer/createTrackAwaiters';
import { extractVariables, type TemplateRenderer } from './VariableRegistry';

export type VoiceOverRegistryId = string & {
  __brand: 'VoiceOverRegistryId';
};

// stages of an entry:
// - not loaded
// - intoLPMedia (media: basically noop, tts: resolve script, then request bytes)
// - intoPlayable (aka prepared)
// - recheck if intoLPMedia still valid due to variable values, or if intoLPMedia needed again

export type VoiceOverRegistryPlayInfo = {
  media: Media;
  approximateDurationMs: number;
  trackEnded: Promise<void>;
  trackStarted: Promise<void>;
  trackRemoved: Promise<void>;
};

export abstract class VoiceOverEntry {
  // abstract req: VoiceOverGroupPlanEntry;

  trackIds: TrackId[] = [];

  media: Media | null = null;
  mediaData: MediaData | null = null;
  unplayable: UnplayableMedia | null = null;

  abstract willFullyResolve(
    templateRenderer: TemplateRenderer
  ): Promise<boolean>;

  abstract requiresIntoMedia(
    templateRenderer: TemplateRenderer
  ): Promise<boolean>;

  abstract intoMedia(
    templateRenderer: TemplateRenderer,
    concurrencyChannel: Chan<true>,
    signal: AbortSignal
  ): Promise<void>;

  async prepare(
    templateRenderer: TemplateRenderer,
    concurrencyChannel: Chan<true>,
    signal: AbortSignal
  ) {
    if (!(await this.willFullyResolve(templateRenderer))) return;

    if (await this.requiresIntoMedia(templateRenderer)) {
      await this.intoMedia(templateRenderer, concurrencyChannel, signal);
    }

    if (!this.media) return;

    const unplayable = await MediaUtils.IntoUnplayable(this.media);
    if (signal.aborted) return;
    this.unplayable = unplayable;
    await unplayable.intoPlayable();
  }

  async consume() {
    // "consume" the media element and require a new one to be created the next
    // time we want to prepare or play.

    try {
      const { unplayable, media } = this;
      return { unplayable, media };
    } finally {
      this.unplayable = null;
    }
  }
}

class ScriptBasedVoiceOver extends VoiceOverEntry {
  latestResolvedScript: string | null = null;
  latestResolvedScriptPercentage: number | null = null;

  constructor(public req: DtoTTSRenderRequest) {
    super();
  }

  async willFullyResolve(templateRenderer: TemplateRenderer) {
    const { resolved } = await templateRenderer.render(this.req.script);
    return resolved;
  }

  async requiresIntoMedia(templateRenderer: TemplateRenderer) {
    const { script: resolvedScript, resolved } = await templateRenderer.render(
      this.req.script
    );

    return resolved && resolvedScript !== this.latestResolvedScript;
  }

  async intoMedia(
    templateRenderer: TemplateRenderer,
    concurrencyChannel: Chan<true>,
    signal: AbortSignal
  ) {
    try {
      await concurrencyChannel.take();
      const { script: resolvedScript } = await templateRenderer.render(
        this.req.script
      );

      this.latestResolvedScript = resolvedScript;

      const resolvedReq = {
        ...this.req,
        script: resolvedScript,
      };

      const bytes = (await apiService.tts.render(resolvedReq)).data;
      if (signal.aborted) return;

      const unplayable = new UnplayableBytes(bytes);
      unplayable.intoPlayable();
      await once(unplayable.media, 'loadedmetadata');
      const lengthSec = unplayable.media.duration;
      const m = MediaUtils.IntoFakeAudioMediaFromBytes(
        unplayable.media.src,
        bytes,
        lengthSec * 1000
      );
      assertDefinedFatal(m);
      this.media = m;
      this.mediaData = {
        id: m.id,
        volumeLevel: VolumeLevel.Full,
      };
    } finally {
      concurrencyChannel.put(true);
    }
  }
}

function isScriptBasedVoiceOver(
  entry: VoiceOverEntry
): entry is ScriptBasedVoiceOver {
  return entry instanceof ScriptBasedVoiceOver;
}

class MediaBasedVoiceOver extends VoiceOverEntry {
  constructor(
    public req: {
      media: Media;
      mediaData: MediaData;
    },
    public aborter = new AbortController(),
    public signal = aborter.signal
  ) {
    super();
  }

  async willFullyResolve() {
    return true;
  }

  async requiresIntoMedia() {
    return true;
  }

  async intoMedia() {
    this.media = this.req.media;
    this.mediaData = this.req.mediaData;
  }
}

type VoiceOverGroupPlanEntry =
  | DtoTTSRenderRequest
  | { media: Media; mediaData: MediaData };

function basedVoiceOverFactory(
  planEntry: VoiceOverGroupPlanEntry
): ScriptBasedVoiceOver | MediaBasedVoiceOver {
  let entry: ScriptBasedVoiceOver | MediaBasedVoiceOver;

  if ('media' in planEntry) {
    entry = new MediaBasedVoiceOver(planEntry);
  } else {
    entry = new ScriptBasedVoiceOver(planEntry);
  }

  return entry;
}

export class VoiceOverGroup {
  private planEntryToEntry = new Map<VoiceOverGroupPlanEntry, VoiceOverEntry>();

  constructor(
    public readonly id: VoiceOverRegistryId,
    public readonly plan: {
      entries: VoiceOverGroupPlanEntry[];
    },
    private readonly emitter: Emitter<{
      'all-loaded': (id: VoiceOverRegistryId) => void;
      'some-loaded': (id: VoiceOverRegistryId) => void;
    }>,
    private getVideoMixer: () => Nullable<VideoMixer>,
    private getSubtitleManaager: () => Nullable<{
      kind: 'local' | 'broadcast';
      sub: SubtitlesManager;
    }>,
    private concurrencyChannel: Chan<true>
  ) {
    for (const entry of this.plan.entries) {
      const vo = basedVoiceOverFactory(entry);
      this.planEntryToEntry.set(entry, vo);
    }
  }

  atLeastOneLoaded() {
    return this.plan.entries.some((entry) => {
      const vo = this.planEntryToEntry.get(entry);
      if (!vo) return false;
      return vo.media !== null;
    });
  }

  private loadWithoutAwait(
    templateRenderer: TemplateRenderer,
    signal: AbortSignal
  ) {
    const loads = [];

    for (const entry of this.plan.entries) {
      const vo = this.planEntryToEntry.get(entry);
      if (!vo) continue;
      // calls .load() within
      const loading = vo.prepare(
        templateRenderer,
        this.concurrencyChannel,
        signal
      );
      loads.push(loading);
    }

    Promise.all(loads).then(() => this.emitter.emit('all-loaded', this.id));
    Promise.race(loads).then(() => this.emitter.emit('some-loaded', this.id));
    return loads;
  }

  async load(
    templateRenderer: TemplateRenderer,
    aborter = new AbortController()
  ) {
    // TODO: check if already loading, and call aborter.abort() on passed in signal.

    const loads = this.loadWithoutAwait(templateRenderer, aborter.signal);
    return await Promise.all(loads);
  }

  /**
   * This only exists for legacy voiceover backwards compatibility, and is not a
   * recommended public API.
   */
  private async consume(
    templateRenderer: TemplateRenderer,
    signal: AbortSignal,
    options?: {
      renderDeadlineMs?: number;
      // Set to true if you have already called `load()` and do not want to
      // wait.
      noLoad?: boolean;
    }
  ) {
    // need to log which was chosen?
    // this.log.info('playing voice over', { id: this.id });

    if (!options?.noLoad) {
      // Request all to load, but only grab the first.
      const all = this.loadWithoutAwait(templateRenderer, signal);
      const priority = all.at(0);

      // Wait for the _first_ to load or timeout
      await Promise.race([
        priority?.then(() => true) ?? Promise.resolve(false),
        sleep(options?.renderDeadlineMs ?? 5000),
      ]);

      // silently ensure we catch load errors
      Promise.all(all).catch(() => void 0);

      // We then know that at least something had a chance to load because we
      // requested that they _all_ load.
    }

    // Find the first planEntry!
    const firstLoaded = this.plan.entries.find((entry) => {
      const vo = this.planEntryToEntry.get(entry);
      if (!vo) return false;
      return vo.media !== null;
    });

    // Grab the actual entry
    const entry = firstLoaded ? this.planEntryToEntry.get(firstLoaded) : null;

    if (!firstLoaded || !entry) {
      return null;
    }

    const { unplayable, media } = await entry.consume();
    return { unplayable, media, planEntry: firstLoaded, entry };
  }

  async play(
    templateRenderer: TemplateRenderer,
    options?: {
      delayStartMs?: number;
      renderDeadlineMs?: number;
      // Set to true if you have already called `load()` and do not want to
      // wait.
      noLoad?: boolean;
    },
    aborter = new AbortController()
  ): Promise<null | VoiceOverRegistryPlayInfo> {
    const consumed = await this.consume(
      templateRenderer,
      aborter.signal,
      options
    );

    if (!consumed) return null;

    const { unplayable, media, entry, planEntry } = consumed;

    const vm = this.getVideoMixer();
    const managerInfo = this.getSubtitleManaager();

    if (aborter.signal.aborted || !vm || !unplayable) return null;

    const format = MediaUtils.PickMediaFormat(media);

    if (!format || !media) return null;

    const cfg = new TrackInitConfigBuilder()
      .setTimelineTimeStartMs(
        (vm.playheadMs ?? 0) + (options?.delayStartMs ?? 0)
      )
      .setDurationMs(format.length)
      .build();

    const trackId = vm.pushTrack(unplayable.media, cfg);
    entry.trackIds.push(trackId);
    const startAwaiter = createTrackStartAwaiter(vm, trackId);
    const endAwaiter = createTrackEndAwaiter(vm, trackId);
    const removedAwaiter = createTrackRemoveAwaiter(vm, trackId);

    const ret = {
      media,
      approximateDurationMs: format.length,
      trackEnded: endAwaiter,
      trackStarted: startAwaiter,
      trackRemoved: removedAwaiter,
    };

    const unresolvedScript = 'script' in planEntry ? planEntry.script : '';

    const resolvedScript = isScriptBasedVoiceOver(entry)
      ? entry.latestResolvedScript ?? unresolvedScript
      : unresolvedScript;

    if (resolvedScript) {
      managerInfo?.sub?.notify(managerInfo.kind, resolvedScript, ret);
    }

    return ret;
  }

  async stop() {
    const vm = this.getVideoMixer();
    if (!vm) return;
    for (const [, vo] of this.planEntryToEntry) {
      if (!vo) continue;
      for (const trackId of vo.trackIds) {
        vm.removeTrack(trackId);
      }
    }
  }

  destroy() {
    this.stop();
  }
}

export type VoiceOverRegistryPlan = {
  entries: (DtoTTSRenderRequest | { media: Media; mediaData: MediaData })[];
};

export function makeVoiceOverRegistryPlan(): VoiceOverRegistryPlan {
  return {
    entries: [],
  };
}

type SubtitlesMessage = { kind: 'script-now'; script: string };

export class SubtitlesManager {
  private emitter = new Emitter<{
    'script-now': (script: string) => void;
  }>();

  msgRef;

  constructor(venueId: string, svc: FirebaseService) {
    this.msgRef = asFBReference<FBMsgPassStorage>(
      svc.prefixedSafeRef(`subtitles-manager/${venueId}`)
    );
  }

  on = this.emitter.on.bind(this.emitter);

  recvAborter = new AbortController();

  listen() {
    this.recvAborter.abort();
    this.recvAborter = new AbortController();

    recv(
      this.msgRef,
      async (msg: SubtitlesMessage) => {
        if (msg.kind === 'script-now') {
          this.emitter.emit('script-now', msg.script);
        }
      },
      {
        signal: this.recvAborter.signal,
      }
    );
  }

  /**
   * This is best called from a single client, such as the controller. Calling
   * from multiple during the wrong lifecycle could result in a disconnecting
   * user removing all subtitles for all users in the venue.
   */
  async cleanupMessages() {
    await this.msgRef.remove();
  }

  /**
   * - `local`: this is for the current user only
   * - `broadcast`: this is for all users in the venue
   *
   * It is expected that `local` is from a local-only voiceover, such as the H2H
   * block. `broadcast` is likely only ever specified on the controller so that
   * all users receive the subtitles.
   */
  async notify(
    kind: 'local' | 'broadcast',
    script: string,
    info: VoiceOverRegistryPlayInfo
  ) {
    await info.trackStarted;

    if (kind === 'broadcast') {
      await send<SubtitlesMessage>(
        this.msgRef,
        {
          kind: 'script-now',
          script: script,
        },
        {
          cleanupAfterMs: 30_000,
        }
      );
    } else {
      this.emitter.emit('script-now', script);
    }
  }

  destroy() {
    this.emitter.clear();
  }
}

export class VoiceOverRegistry {
  private vm: Nullable<VideoMixer> = null;
  private subManagerInfo: Nullable<{
    sub: SubtitlesManager;
    kind: 'local' | 'broadcast';
  }> = null;
  private groups = new Map<VoiceOverRegistryId, VoiceOverGroup>();

  concurrencyChannel: Chan<true> = new Chan({ multicast: false });

  private emitter = new Emitter<{
    registered: (id: VoiceOverRegistryId) => void;
    forgotten: (id: VoiceOverRegistryId) => void;
    'some-loaded': (id: VoiceOverRegistryId) => void;
    'all-loaded': (id: VoiceOverRegistryId) => void;
  }>();

  on = this.emitter.on.bind(this.emitter);
  off = this.emitter.off.bind(this.emitter);

  constructor(requestConcurrency = 4) {
    // Concurrency equals the number of intial `put`s. These are "tokens" and
    // are eventually put back into the channel.
    for (let i = 0; i < requestConcurrency; i++) {
      this.concurrencyChannel.put(true);
    }
  }

  setVideoMixer(vm: Nullable<VideoMixer>) {
    this.vm = vm;
  }

  setSubtitlesManager(
    kind: 'local' | 'broadcast',
    sub: Nullable<SubtitlesManager>
  ) {
    if (!sub) return;
    this.subManagerInfo = { sub, kind };
  }

  getGroups() {
    return this.groups;
  }

  getVariableNames() {
    const names = new Set<string>();
    for (const [, group] of this.groups) {
      for (const entry of group.plan.entries) {
        if ('script' in entry) {
          const variables = extractVariables(entry.script);
          for (const variable of variables) {
            names.add(variable);
          }
        }
      }
    }
    return Array.from(names);
  }

  async getOrCreateGroup(plan: VoiceOverRegistryPlan): Promise<VoiceOverGroup> {
    const hash = (await hashObject(plan)) as VoiceOverRegistryId;
    const existing = this.groups.get(hash);
    if (existing) return existing;
    const id = hash;
    const group = new VoiceOverGroup(
      id,
      plan,
      this.emitter,
      () => this.vm,
      () => this.subManagerInfo,
      this.concurrencyChannel
    );
    this.groups.set(id, group);
    this.emitter.emit('registered', group.id);
    return group;
  }

  get(id: Nullable<VoiceOverRegistryId>): VoiceOverGroup | undefined {
    if (!id) return;
    return this.groups.get(id);
  }

  forget(id: VoiceOverRegistryId) {
    this.groups.delete(id);
    this.emitter.emit('forgotten', id);
  }

  destroy() {
    this.groups.forEach((group) => group.destroy());
    this.groups.clear();
    this.vm = null;
  }
}
