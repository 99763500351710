import { useMemo } from 'react';

import { isServerValue } from '@lp-lib/firebase-typesafe';
import {
  aggregateAnswers,
  type AggregatedAnswers,
  calculateScore,
  Delimited,
  type GameSessionPlayerData,
  type QuestionBlock,
  QuestionBlockAnswerGrade,
  type QuestionBlockAnswerGradeModel,
  type QuestionBlockDetailScore,
  type TeamDataList,
} from '@lp-lib/game';
import { type Logger } from '@lp-lib/logger-base';

import {
  getFeatureQueryParam,
  getFeatureQueryParamNumber,
} from '../../../../hooks/useFeatureQueryParam';
import logger from '../../../../logger/logger';
import { apiService } from '../../../../services/api-service';
import { type AIGradeAPI } from '../../../../services/api-service/aiGrade.api';
import { type User } from '../../../../types';
import { Emitter, type EmitterListener } from '../../../../utils/emitter';
import { type FirebaseService, useFirebaseContext } from '../../../Firebase';
import { useVenueId } from '../../../Venue/VenueProvider';
import { useIsLiveGamePlay } from '../../hooks';
import { type GradeEvents, type GradeResult } from '../Common/grader';
import { QuestionBlockAnswerGradeConverter } from '../Common/grader';

export class QuestionGradeAPI implements EmitterListener<GradeEvents> {
  private correctAnswers: string[];
  private emitter = new Emitter<GradeEvents>();
  on = this.emitter.on.bind(this.emitter);
  off = this.emitter.off.bind(this.emitter);

  constructor(
    svc: FirebaseService,
    readonly venueId: string,
    readonly block: QuestionBlock,
    readonly aiGrade:
      | {
          enabled: true;
          temperature: number;
          grader: AIGradeAPI['gradeSubmission'];
        }
      | { enabled: false },
    readonly gameaudit?: Logger,
    private userSubmissionsRef = svc.prefixedSafeRef<{
      [key: User['id']]: GameSessionPlayerData;
    }>(`game-session-player-data/${venueId}/${block.id}`),
    private teamScoresRef = svc.prefixedRef<
      TeamDataList<QuestionBlockDetailScore>
    >(`game-session-scores/${venueId}/${block.id}`)
  ) {
    this.correctAnswers = [
      block.fields.answer,
      ...new Delimited().parse(block.fields.additionalAnswers || ''),
    ]
      .map((a) => a?.trim().toLowerCase())
      .filter(Boolean);
  }

  start(): void {
    this.userSubmissionsRef.on('child_added', (snapshot) => {
      const userId = snapshot.key;
      const data = snapshot.val();
      if (!userId || !data) {
        this.gameaudit?.warn(`recv empty answer / userId`, {
          blockId: this.block.id,
          venueId: this.venueId,
          userId,
          data,
        });
        return;
      }
      this.grade(userId, data);
    });
  }

  stop(): void {
    this.userSubmissionsRef.off();
  }

  private async gradeWithPreset(answer: string): Promise<GradeResult> {
    const trimmedAnswer = answer.trim().toLowerCase();

    if (this.correctAnswers.some((correct) => correct === trimmedAnswer)) {
      return {
        model: 'Preset',
        grade: QuestionBlockAnswerGrade.CORRECT,
        timeMs: 0,
      };
    } else {
      // Auto-grading with graded answer
      const allTeamScoresSnap = await this.teamScoresRef.get();
      const aggregatedAnswers: AggregatedAnswers = aggregateAnswers(
        allTeamScoresSnap.val(),
        true
      );

      const matchedAnswer = aggregatedAnswers[
        trimmedAnswer
      ] as AggregatedAnswers[string];

      if (
        matchedAnswer &&
        matchedAnswer.grade !== QuestionBlockAnswerGrade.NONE
      ) {
        return {
          model: matchedAnswer.model,
          grade: matchedAnswer.grade,
          timeMs: 0,
          cached: true,
        };
      }
    }

    return {
      model: 'Preset',
      grade: QuestionBlockAnswerGrade.NONE,
      timeMs: 0,
    };
  }

  private gradeWithGPT3 = this.makeGradeWithGPT('GPTv3');
  private gradeWithGPT4 = this.makeGradeWithGPT('GPTv4');

  private async grade(userId: string, data: GameSessionPlayerData) {
    this.audit(`answer retrieved session block`, {
      userId,
      retrievedBlockId: this.block.id,
      retrievedBlockType: this.block.type,
      retrievedBlockAnswer: this.block.fields.answer,
      retrievedBlockAdditionalAnswers: this.block.fields.additionalAnswers,
      ...data,
    });

    const { answer, teamId, timerWhenSubmitted, submittedAt } = data;

    if (!answer || !teamId) return;

    const trimmedAnswer = answer.trim().toLowerCase();

    const graders = [this.gradeWithPreset.bind(this)];
    if (this.aiGrade.enabled) {
      graders.push(this.gradeWithGPT3.bind(this));
      graders.push(this.gradeWithGPT4.bind(this));
    }

    const results: GradeResult[] = [];
    for (const grader of graders) {
      const r = await grader(trimmedAnswer);
      results.push(r);
      if (r.grade !== QuestionBlockAnswerGrade.NONE) {
        break;
      }
    }
    const finalResult = results[results.length - 1] ?? {
      model: 'Preset',
      grade: QuestionBlockAnswerGrade.NONE,
    };

    const score = calculateScore(
      finalResult.grade,
      this.block.fields.decreasingPointsTimer ?? true,
      timerWhenSubmitted || 0,
      this.block.fields.time,
      this.block.fields.points,
      this.block.fields.startDescendingImmediately
    );

    this.audit(
      `answer graded by ${
        finalResult.model
      } as ${QuestionBlockAnswerGradeConverter.From(finalResult.grade)}`,
      {
        userId,
        grade: finalResult.grade,
        score,
        trimmedAnswer,
        ...data,
      }
    );

    const detail: Partial<QuestionBlockDetailScore> = {
      answer,
      score,
      model: finalResult?.model,
      grade: finalResult?.grade,
      submitterUid: userId,
      submittedAt: isServerValue(submittedAt) ? undefined : submittedAt,
      timerWhenSubmitted,
    };

    await this.teamScoresRef.child(teamId).update(detail);

    this.audit(`wrote team detail score`, {
      userId,
      teamId,
      ...detail,
    });

    this.emitter.emit('submission-graded', {
      question: this.block.fields.question,
      results,
      userSubmission: answer,
      submitterUid: userId,
      correctAnswers: this.firstNorrectAnswers(),
    });
  }

  private firstNorrectAnswers(n = 3) {
    return this.correctAnswers.slice(0, n);
  }

  private audit(message: string, meta?: Record<string, unknown>) {
    this.gameaudit?.info(message, {
      blockId: this.block.id,
      venueId: this.venueId,
      ...meta,
    });
  }

  private makeGradeWithGPT(
    model: Exclude<QuestionBlockAnswerGradeModel, 'Preset'>
  ) {
    return async (userSubmission: string): Promise<GradeResult> => {
      if (!this.aiGrade.enabled) {
        return { model, grade: QuestionBlockAnswerGrade.NONE, timeMs: 0 };
      }
      const req = {
        model,
        temperature: this.aiGrade.temperature,
        correctAnswers: this.firstNorrectAnswers(),
        userSubmission,
      };
      const t1 = Date.now();
      try {
        const resp = await this.aiGrade.grader(req);
        return {
          model: model,
          grade: resp.data.evaluation
            ? QuestionBlockAnswerGrade.CORRECT
            : QuestionBlockAnswerGrade.NONE,
          timeMs: Date.now() - t1,
          error: null,
        };
      } catch (error) {
        this.gameaudit?.error('failed to grade by GPT', error, {
          blockId: this.block.id,
          venueId: this.venueId,
          req,
        });
        return {
          model: model,
          grade: QuestionBlockAnswerGrade.NONE,
          timeMs: Date.now() - t1,
          error,
        };
      }
    };
  }
}

export function useGradeAPI(block: QuestionBlock) {
  const venueId = useVenueId();
  const { svc } = useFirebaseContext();
  const isLiveGame = useIsLiveGamePlay();
  return useMemo(
    () =>
      new QuestionGradeAPI(
        svc,
        venueId,
        block,
        {
          enabled: isLiveGame
            ? false
            : getFeatureQueryParam('question-block-ai-grade'),
          temperature: getFeatureQueryParamNumber(
            'question-block-ai-grade-temperature',
            true
          ),
          grader: apiService.aiGrade.gradeSubmission.bind(apiService.aiGrade),
        },
        logger.scoped('game-system-audit')
      ),
    [block, isLiveGame, svc, venueId]
  );
}
