import { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { useGameTeamVolumeBalancerValue } from '../Venue';
import { type VideoMixer } from '../VideoMixer';
import { makeLocalAudioOnlyVideoMixer } from './makeLocalAudioOnlyVideoMixer';

export function useLocalAudioMixer() {
  const [instance, setInstance] = useState<null | VideoMixer>(null);
  const [output, setOutput] = useState<null | HTMLAudioElement>(null);
  useLayoutEffect(() => {
    const [vm, media] = makeLocalAudioOnlyVideoMixer();
    vm.play();
    setInstance(vm);
    setOutput(media);
    return () => {
      vm.pause();
      vm.destroy();
    };
  }, []);

  // Consider this output part of the "game" audio.
  const [gameTeamVolumeBalancerValue] = useGameTeamVolumeBalancerValue();
  const volume = useRef<number>(
    convertVolumeBalancerValue(gameTeamVolumeBalancerValue)
  );

  useEffect(() => {
    if (!output) return;
    output.volume = volume.current;
  }, [output]);

  return instance;
}
function convertVolumeBalancerValue(vol: number): number {
  return vol / 100;
}
