import {
  BoundingBoxProcessor,
  ChromakeyProcessor,
  OpacityProcessor,
} from '../VideoFrameProcessing';

export class ProcessorRegistry {
  private injected: InjectedProcessors;
  private processors: VisualEffectsProcessors = {
    chromakey: null,
    opacity: null,
    boundingBox: null,
  };

  constructor(injected?: Partial<InjectedProcessors>) {
    this.injected = injected
      ? { ...ErrorfulProcessors, ...injected }
      : { ...DefaultProcessors };
  }

  async destroy(): Promise<void> {
    await Object.keys(this.processors).map((k) =>
      this.delete(k as keyof typeof this.processors)
    );
    return;
  }

  async delete(name: keyof typeof this.processors): Promise<void> {
    const processor = this.processors[name];
    this.processors[name] = null;
    await processor?.destroy();
  }

  // NOTE(drew): I could not get the types right to do this via polymorphism or
  // named lookups easily. So brute force it is.
  get chromakey(): ChromakeyProcessor {
    const processor = this.processors.chromakey ?? this.injected.chromakey();
    this.processors.chromakey = processor;
    return processor;
  }

  get opacity(): OpacityProcessor {
    const processor = this.processors.opacity ?? this.injected.opacity();
    this.processors.opacity = processor;
    return processor;
  }

  get boundingBox(): BoundingBoxProcessor {
    const processor =
      this.processors.boundingBox ?? this.injected.boundingBox();
    this.processors.boundingBox = processor;
    return processor;
  }
}

export interface InjectedProcessors {
  chromakey: () => ChromakeyProcessor;
  opacity: () => OpacityProcessor;
  boundingBox: () => BoundingBoxProcessor;
}

const DefaultProcessors: InjectedProcessors = {
  chromakey: () => new ChromakeyProcessor(),
  opacity: () => new OpacityProcessor(),
  boundingBox: () => new BoundingBoxProcessor(),
} as const;

const ErrorfulProcessors: InjectedProcessors = {
  chromakey: () => {
    throw new Error('no chromakey processor implementation');
  },
  opacity: () => {
    throw new Error('no opacity processor implementation');
  },
  boundingBox: () => {
    throw new Error('no boundingBox processor implementation');
  },
} as const;

type VisualEffectsProcessors = {
  chromakey: ChromakeyProcessor | null;
  opacity: OpacityProcessor | null;
  boundingBox: BoundingBoxProcessor | null;
};
