import uniqBy from 'lodash/uniqBy';
import React, { useContext, useEffect, useMemo } from 'react';
import { usePrevious } from 'react-use';
import { proxy } from 'valtio';

import { type BlockPreconfig } from '@lp-lib/game';

import { useInstance } from '../../../../../hooks/useInstance';
import { useLiveCallback } from '../../../../../hooks/useLiveCallback';
import { useIsController } from '../../../../../hooks/useMyInstance';
import { type Participant } from '../../../../../types';
import {
  markSnapshottable,
  useSnapshot,
  type ValtioSnapshottable,
  ValtioUtils,
} from '../../../../../utils/valtio';
import {
  type FirebaseService,
  FirebaseValueHandle,
  useFirebaseContext,
  useIsFirebaseConnected,
} from '../../../../Firebase';
import { useParticipantByUserIds } from '../../../../Player';
import { useIsStreamSessionEnded } from '../../../../Session';
import { useVenueId } from '../../../../Venue/VenueProvider';
import { useIsGameSessionInited } from '../../../hooks';

interface GameSessionPreconfigState {
  vipUserIds: string[];
  blockMap: Record<string, BlockPreconfig>;
}

function initialState(): GameSessionPreconfigState {
  return {
    vipUserIds: [],
    blockMap: {},
  };
}

class GameSessionPreconfigAPI {
  constructor(
    venueId: string,
    firebaseService: FirebaseService,
    private state: GameSessionPreconfigState,
    private handle = new FirebaseValueHandle<GameSessionPreconfigState>(
      firebaseService.prefixedSafeRef(`game-session-preconfig/${venueId}`)
    )
  ) {}

  reset() {
    this.handle.remove();
  }

  on() {
    this.handle.on((val) => {
      ValtioUtils.update(this.state, val ?? initialState());
    });
  }

  off() {
    this.handle.off();
  }

  setVIP(vipUserIds: string[]) {
    this.handle.ref.update({
      vipUserIds,
    });
  }

  setBlock<T extends BlockPreconfig>(blockId: string, config: T) {
    this.handle.ref.child('blockMap').update({
      [blockId]: config,
    });
  }
}

type Context = {
  state: ValtioSnapshottable<GameSessionPreconfigState>;
  api: GameSessionPreconfigAPI;
};

const context = React.createContext<Context | null>(null);

function useGameSessionPreconfigContext(): Context {
  const ctx = useContext(context);
  if (!ctx) throw new Error('GameSessionPreconfig is not in the tree!');
  return ctx;
}

export function useGameSessionPreconfigAPI(): Context['api'] {
  const { api } = useGameSessionPreconfigContext();
  return api;
}

export function useGameSessionPreconfigVIPUserIds(): string[] {
  const { state } = useGameSessionPreconfigContext();
  return useSnapshot(state).vipUserIds as string[];
}

export function useGameSessionPreconfigVIPUserIdsGetter(): () => string[] {
  const { state } = useGameSessionPreconfigContext();
  return useLiveCallback(() => {
    return state.vipUserIds;
  });
}

export function useGameSessionPreconfigVIPParticipants(): Participant[] {
  const vipUserIds = useGameSessionPreconfigVIPUserIds();
  const participants = useParticipantByUserIds(vipUserIds, false);
  return useMemo(() => {
    const sorted = participants.sort((a, b) => b.joinedAt - a.joinedAt);
    return uniqBy(sorted, (p) => p.id);
  }, [participants]);
}

export function useGameSessionPreconfigByBlockId<T extends BlockPreconfig>(
  blockId: string
): Nullable<T> {
  const { state } = useGameSessionPreconfigContext();
  const blockMap = useSnapshot(state).blockMap;
  return blockMap[blockId] as Nullable<T>;
}

export function useGameSessionPreconfigByBlockIdGetter<
  T extends BlockPreconfig
>() {
  const { state } = useGameSessionPreconfigContext();
  const blockMap = useSnapshot(state).blockMap;
  return useLiveCallback((blockId: string) => {
    return blockMap[blockId] as Nullable<T>;
  });
}

export function GameSessionPreconfigProvider(props: {
  children?: React.ReactNode;
}): JSX.Element | null {
  const venueId = useVenueId();
  const { svc } = useFirebaseContext();
  const isController = useIsController();
  const firebaseConnected = useIsFirebaseConnected();
  const isSessionInited = useIsGameSessionInited();
  const [isSessionEnded] = useIsStreamSessionEnded();
  const isSessionEndedPrev = usePrevious(isSessionEnded);

  const state = useInstance(() =>
    markSnapshottable(proxy<GameSessionPreconfigState>(initialState()))
  );

  const ctxValue = useMemo(
    () => ({
      state,
      api: new GameSessionPreconfigAPI(venueId, svc, state),
    }),
    [svc, venueId, state]
  );

  useEffect(() => {
    if (!firebaseConnected) return;

    ctxValue.api.on();
    return () => ctxValue.api.off();
  }, [ctxValue.api, firebaseConnected]);

  useEffect(() => {
    if (!isSessionInited) return;
    if (isSessionEndedPrev || !isSessionEnded) return;
    if (!isController) return;

    ctxValue.api.reset();
  }, [
    ctxValue.api,
    isController,
    isSessionEnded,
    isSessionEndedPrev,
    isSessionInited,
  ]);

  return <context.Provider value={ctxValue}>{props.children}</context.Provider>;
}
