import AgoraRTC, {
  type AudioEncoderConfigurationPreset,
  type ClientRole,
  type CustomAudioTrackInitConfig,
  type CustomVideoTrackInitConfig,
  type IAgoraRTCClient,
  type IAgoraRTCRemoteUser,
  type ICameraVideoTrack,
  type ILocalAudioTrack,
  type ILocalTrack,
  type ILocalVideoTrack,
  type IRemoteVideoTrack,
  type NetworkQuality,
  type RemoteStreamType,
  type SDK_CODEC,
  type UID,
  type VideoPlayerConfig,
} from 'agora-rtc-sdk-ng';

import { type Logger } from '@lp-lib/logger-base';

import config from '../../config';
import { getFeatureQueryParam } from '../../hooks/useFeatureQueryParam';
import logger from '../../logger/logger';
import { assertExhaustive, err2s, uuidv4 } from '../../utils/common';
import { Emitter } from '../../utils/emitter';
import { getDeviceIdFromTrack, releaseMediaStream } from '../../utils/media';
import {
  type MicVolumeMeter,
  MicVolumeMeterFromTrack,
} from '../../utils/MicVolumeMeter';
import { AudioBus, type AudioBusOptions } from '../audio/audio-bus';
import { AIDenoiserProcessorMode, DenoiserExtension } from './denoiser';
import { createHighQualityMusicShare } from './music-share';
import {
  type AgoraJoinStatus,
  type AgoraNumericUID,
  type AudioTrack,
  type CustomVideoLowQualityPreset,
  type CustomVideoQualityPreset,
  type GetAgoraToken,
  type IAgoraException,
  type IAIDenoiserAPI,
  type IAIDenoiserProcessorPatched,
  type IBaseAgoraLocalUser,
  type ICustomRTCService,
  type IMediaDeviceAgoraLocalUser,
  type IMediaDeviceRTCService,
  INGORED_AGORA_EXCEPTIONS,
  type IRTCService,
  type MediaDeviceSettings,
  profileForCustomVideoLowQuality,
  type RemoteUser,
  type RTCServiceListenerEvents,
  TrackState,
  type VideoTrack,
} from './types';
import { defaultGetToken, WebRTCUtils } from './utils';
import { AgoraVideoAutoRecovery } from './video-recovery';
import { AudioVolumeDetector } from './volume';

const verbose = getFeatureQueryParam('verbose-local-logging');
AgoraRTC.setLogLevel(verbose ? 0 : config.agora.logLevel);

if (config.agora.enableLogUpload) {
  AgoraRTC.enableLogUpload();
}

const enableCloudProxy = getFeatureQueryParam('stream-cloud-proxy');

export interface BaseRTCServiceOptions {
  uid: UID;
  name: string;
  codec?: string | null;
  getToken?: GetAgoraToken;
  useDualStream?: boolean;
  useAudioVolumeDetector?: boolean;
  autoSubscribe?: {
    video?: boolean;
    audio?: boolean;
  };
  denoiser?: {
    enabled: boolean;
    assetsPath: string;
  };
}

abstract class BaseRTCService<
  A extends ILocalAudioTrack,
  V extends ILocalVideoTrack,
  T extends IBaseAgoraLocalUser<A, V>
> implements IRTCService
{
  protected id: string;
  readonly name: string;
  readonly uid: UID;
  protected joined?: AgoraJoinStatus;
  protected client: IAgoraRTCClient;
  protected localUser: T;
  protected readonly remoteUserMapping: Record<UID, RemoteUser>;
  private remoteUserVolume?: number;
  private lastNetworkQuality?: NetworkQuality;
  protected readonly emitter = new Emitter<RTCServiceListenerEvents>();
  private getToken: GetAgoraToken;
  private useDualStream: boolean;
  protected audioVolumeDetector?: AudioVolumeDetector;
  private autoSubscribe: {
    video: boolean;
    audio: boolean;
    datachannel?: boolean;
  };
  protected denoiser: {
    enabled: boolean;
    processor?: IAIDenoiserProcessorPatched;
  };
  readonly log: Logger;
  on = this.emitter.on.bind(this.emitter);
  off = this.emitter.off.bind(this.emitter);

  protected abstract initLocalUser(uid: UID): T;

  constructor(options: BaseRTCServiceOptions) {
    this.name = options.name;
    this.id = `${options.name || 'agora'}:${uuidv4()}`;
    this.uid = options.uid;
    this.getToken = options.getToken || defaultGetToken;
    this.useDualStream = options.useDualStream ?? true;
    this.autoSubscribe = {
      video: options.autoSubscribe?.video ?? true,
      audio: options.autoSubscribe?.audio ?? true,
    };
    this.remoteUserMapping = {};
    this.localUser = this.initLocalUser(options.uid);
    this.client = this.initClient(options.codec);
    this.log = logger.scoped(`rtcService#${this.name}`);
    this.log.updateMeta({ channelType: this.name });
    if (options.useAudioVolumeDetector) {
      this.createAudioVolumeDetector();
    }
    if (options.denoiser?.enabled) {
      this.initDenoiser(options.denoiser.assetsPath);
    }
    this.denoiser = { enabled: !!options.denoiser?.enabled };
  }

  private initClient(codec?: string | null): IAgoraRTCClient {
    const normalizedCodec =
      codec === 'vp8' || codec === 'h264' || codec === 'vp9'
        ? codec
        : config.agora.codec;
    const client = AgoraRTC.createClient({
      mode: 'live',
      codec: normalizedCodec as SDK_CODEC,
    });
    if (this.useDualStream) {
      client
        .enableDualStream()
        .then(() => {
          this.log.info('dual stream enabled');
        })
        .catch((error) => {
          this.log.error('dual stream failed', error);
        });
    }
    return client;
  }

  async join(
    channel: string,
    role: ClientRole
  ): Promise<AgoraNumericUID | null> {
    this.log.info('attempt to join channel', { channel, role });
    WebRTCUtils.AssertAgoraChannelLength(channel);
    const token = await this.getToken(this.localUser.uid.toString(), channel);
    await this.client.setClientRole(role);
    if (enableCloudProxy) this.client.startProxyServer(3);
    await this.client.join(
      config.agora.appId,
      channel,
      token,
      this.localUser.uid
    );
    const numericUid = this.fetchNumericUID();
    this.joined = { channel, role, numericUid };
    this.log.updateMeta({ channel });
    this.log.info('join channel done', {
      channel,
      role,
      numericUid,
    });
    return numericUid;
  }

  /**
   * This is a hack solution to get the number uid from the client object
   */
  private fetchNumericUID() {
    if (
      '_joinInfo' in this.client &&
      !!this.client['_joinInfo'] &&
      typeof this.client['_joinInfo'] === 'object'
    ) {
      if (
        'uid' in this.client['_joinInfo'] &&
        !!this.client['_joinInfo']['uid'] &&
        typeof this.client['_joinInfo']['uid'] === 'number'
      ) {
        return this.client['_joinInfo']['uid'];
      }
    }
    return null;
  }

  async setClientRole(role: ClientRole): Promise<void> {
    await this.client.setClientRole(role);
  }

  async leave(): Promise<void> {
    this.log.info('attempt to leave channel');
    await this.client.leave();
    this.joined = undefined;
    this.log.info('leave channel done');
  }

  async publish(): Promise<void> {
    const tracks: ILocalTrack[] = [];

    if (this.localUser.videoTrack && this.localUser.hasVideo) {
      tracks.push(this.localUser.videoTrack);
    }
    if (this.localUser.audioTrack && this.localUser.hasAudio) {
      tracks.push(this.localUser.audioTrack);
    }
    if (tracks.length === 0) {
      this.log.warn('no tracks to publish');
      return;
    }
    await this.client.publish(tracks);
  }

  async publishAudio(): Promise<void> {
    if (this.localUser.audioTrack && this.localUser.hasAudio) {
      await this.client.publish(this.localUser.audioTrack);
    }
  }

  async publishVideo(): Promise<void> {
    if (this.localUser.videoTrack && this.localUser.hasVideo) {
      await this.client.publish(this.localUser.videoTrack);
    }
  }

  async unpublish(): Promise<void> {
    await this.client.unpublish();
  }

  isLocalUser(uid: UID): boolean {
    return !!this.localUser && uid === this.localUser.uid;
  }

  private userJoined(user: RemoteUser) {
    const old = this.remoteUserMapping[user.user.uid];
    if (old) {
      const { audioTrack: oAudioTrack, videoTrack: oVideoTrack } = old.user;
      const { audioTrack: nAudioTrack, videoTrack: nVideoTrack } = user.user;
      if (
        oAudioTrack &&
        oAudioTrack.getTrackId() !== nAudioTrack?.getTrackId() &&
        oAudioTrack.isPlaying
      ) {
        this.log.info('remote auto track changed, auto stop');
        oAudioTrack.stop();
      }
      if (
        oVideoTrack &&
        oVideoTrack.getTrackId() !== nVideoTrack?.getTrackId() &&
        oVideoTrack.isPlaying
      ) {
        this.log.info('remote video track changed, auto stop');
        oVideoTrack.stop();
      }
    }
    this.remoteUserMapping[user.user.uid] = user;
  }

  private userLeft(uid: UID) {
    this.stopAudio(uid);
    this.stopVideo(uid);
    delete this.remoteUserMapping[uid];
  }

  private getRemoteUser(
    user: UID | IAgoraRTCRemoteUser
  ): RemoteUser | undefined {
    if (typeof user === 'number' || typeof user === 'string') {
      return this.remoteUserMapping[user];
    } else {
      return this.remoteUserMapping[user.uid];
    }
  }

  async subscribeVideo(key: UID | IAgoraRTCRemoteUser, debug?: string) {
    const user = this.getRemoteUser(key);
    if (!user) {
      this.log.warn('remote user not found', {
        key,
        op: 'subscribeVideo',
        debug,
      });
      return;
    }
    this.log.info('subscribe video', { targetAgoraUid: user.user.uid, debug });
    await this.client.subscribe(user.user, 'video');
    user.videoSubscribed = true;
    this.updateVideoTrackState(user.user.uid, TrackState.Subscribed);
    this.emitter.emit('remote-user-subscribed', user.user.uid, 'video');
  }

  async unsubscribeVideo(key: UID | IAgoraRTCRemoteUser, debug?: string) {
    const user = this.getRemoteUser(key);
    if (!user) {
      this.log.warn('remote user not found', {
        key,
        op: 'unsubscribeVideo',
        debug,
      });
      return;
    }
    this.log.info('unsubscribe video', {
      targetAgoraUid: user.user.uid,
      debug,
    });
    this.stopVideo(user.user.uid);
    await this.client.unsubscribe(user.user, 'video');
    user.videoSubscribed = false;
    this.updateVideoTrackState(user.user.uid, null);
    this.emitter.emit('remote-user-unsubscribed', user.user.uid, 'video');
  }

  async subscribeAudio(key: UID | IAgoraRTCRemoteUser, debug?: string) {
    const user = this.getRemoteUser(key);
    if (!user) {
      this.log.warn('remote user not found', {
        key,
        op: 'subscribeAudio',
        debug,
      });
      return;
    }
    this.log.info('subscribe audio', { targetAgoraUid: user.user.uid, debug });
    await this.client.subscribe(user.user, 'audio');
    if (!user.user.audioTrack) return;
    this.audioVolumeDetector?.addAudioTrack(
      user.user.uid,
      user.user.audioTrack
    );
    if (this.remoteUserVolume !== undefined) {
      user.user.audioTrack.setVolume(this.remoteUserVolume);
    }
    user.audioSubscribed = true;
    this.emitter.emit('remote-user-subscribed', user.user.uid, 'audio');
  }

  async unsubscribeAudio(key: UID | IAgoraRTCRemoteUser, debug?: string) {
    const user = this.getRemoteUser(key);
    if (!user) {
      this.log.warn('remote user not found', {
        key,
        op: 'unsubscribeAudio',
        debug,
      });
      return;
    }
    this.log.info('unsubscribe audio', {
      targetAgoraUid: user.user.uid,
      debug,
    });
    this.stopAudio(user.user.uid);
    this.audioVolumeDetector?.removeAudioTrack(user.user.uid);
    await this.client.unsubscribe(user.user, 'audio');
    user.audioSubscribed = false;
    this.emitter.emit('remote-user-unsubscribed', user.user.uid, 'audio');
  }

  subscribeEvents(): void {
    this.log.info('subscribe agora events');
    this.client.on('connection-state-change', (curState, revState, reason) => {
      this.log.info('connection-state-change', { curState, revState, reason });
    });
    this.client.on('user-joined', async (user) => {
      if (this.isLocalUser(user.uid)) return;
      this.userJoined({
        user,
        audioSubscribed: false,
        videoSubscribed: false,
        videoTrackState: null,
      });
      this.log.info('user-joined', {
        remoteUid: user.uid,
        meJoined: this.joined,
      });
    });
    this.client.on('user-left', async (user, reason: string) => {
      this.log.info('user-left', {
        remoteUid: user.uid,
        reason: reason,
        meJoined: this.joined,
      });
      this.userLeft(user.uid);
    });
    this.client.on('user-published', async (user, mediaType) => {
      if (this.isLocalUser(user.uid)) return;
      this.log.info('user-published', {
        remoteUid: user.uid,
        mediaType: mediaType,
        meJoined: this.joined,
      });
      if (this.autoSubscribe[mediaType]) {
        try {
          switch (mediaType) {
            case 'audio':
              await this.subscribeAudio(user, 'internal');
              break;
            case 'video':
              await this.subscribeVideo(user, 'internal');
              break;
            case 'datachannel':
              break;
            default:
              assertExhaustive(mediaType);
              break;
          }
        } catch (error) {
          this.log.error('subscribe user error', err2s(error), {
            uid: user.uid,
            mediaType,
          });
          return;
        }
      } else {
        this.log.debug(`${mediaType} auto subscribed disabled`);
      }
      if (mediaType === 'audio' || mediaType === 'video') {
        this.emitter.emit('remote-user-published', user.uid, mediaType);
      }
    });
    this.client.on('user-unpublished', async (user, mediaType) => {
      if (this.isLocalUser(user.uid)) return;
      this.log.info('user-unpublished', {
        remoteUid: user.uid,
        mediaType: mediaType,
        meJoined: this.joined,
      });
      switch (mediaType) {
        case 'audio':
          await this.unsubscribeAudio(user, 'internal');
          break;
        case 'video':
          await this.unsubscribeVideo(user, 'internal');
          break;
        case 'datachannel':
          break;
        default:
          assertExhaustive(mediaType);
          break;
      }
      if (mediaType === 'audio' || mediaType === 'video') {
        this.emitter.emit('remote-user-unpublished', user.uid, mediaType);
      }
    });
    this.client.on('network-quality', (stats) => {
      if (
        !this.lastNetworkQuality ||
        JSON.stringify(stats) !== JSON.stringify(this.lastNetworkQuality)
      ) {
        this.log.debug('network quality', { stats: stats });
        this.emitter.emit('network-quality', stats);
        this.lastNetworkQuality = stats;
      }
    });
    this.client.on('stream-fallback', (uid, isFallbackOrRecover) => {
      this.log.info('stream-fallback', { remoteUid: uid, isFallbackOrRecover });
    });
    this.client.on('stream-type-changed', (uid, streamType) => {
      this.log.debug('stream-type-changed', { remoteUid: uid, streamType });
      this.emitter.emit('stream-type-changed', uid, streamType);
    });
    this.client.on('exception', async (e: IAgoraException) => {
      if (INGORED_AGORA_EXCEPTIONS.has(e.msg)) return;
      this.log.error('agora exception', `${e.msg} - ${e.code} - ${e.uid}`);
      if (e.msg === 'RECV_VIDEO_DECODE_FAILED') {
        this.updateVideoTrackState(e.uid, TrackState.Disconnected);
      } else if (e.msg === 'RECV_VIDEO_DECODE_FAILED_RECOVER') {
        this.updateVideoTrackState(e.uid, TrackState.Live);
      }
    });
    this.client.on('is-using-cloud-proxy', (isUsingProxy: boolean) => {
      this.log.info('is-using-cloud-proxy', { isUsingProxy });
    });
  }

  unsubscribeEvents(): void {
    this.log.info('unsubscribe agora events');
    this.client.removeAllListeners();
  }

  getTracksByUid(
    uid: UID | null
  ): [AudioTrack | undefined, VideoTrack | undefined] {
    let audioTrack: AudioTrack | undefined;
    let videoTrack: VideoTrack | undefined;
    if (uid && this.localUser && this.isLocalUser(uid)) {
      audioTrack = this.localUser.audioTrack as AudioTrack;
      videoTrack = this.localUser.videoTrack as VideoTrack;
    }
    const remoteUser = uid ? this.remoteUserMapping[uid] : null;
    if (remoteUser) {
      audioTrack = remoteUser.user.audioTrack as AudioTrack;
      videoTrack = remoteUser.user.videoTrack as VideoTrack;
    }
    return [audioTrack, videoTrack];
  }

  play(uid: UID, element: string | HTMLElement, config?: VideoPlayerConfig) {
    const audioPlayed = this.playAudio(uid);
    const videoPlayed = this.playVideo(uid, element, config);
    return { audio: audioPlayed, video: videoPlayed };
  }

  playAudio(uid: UID) {
    const [audioTrack] = this.getTracksByUid(uid);
    const isLocalUser = this.isLocalUser(uid);
    const hasAudio = this.isLocalUser(uid)
      ? this.localUser.hasAudio
      : Boolean(audioTrack);
    if (audioTrack && hasAudio && !audioTrack.isPlaying) {
      audioTrack.play();
      if (!isLocalUser && this.remoteUserVolume !== undefined) {
        audioTrack.setVolume(this.remoteUserVolume);
      }
      return true;
    } else {
      if (audioTrack?.isPlaying) {
        this.log.warn('client tried to play a playing audio', {
          agoraUid: uid,
        });
      }
      return false;
    }
  }

  playVideo(
    uid: UID,
    element: string | HTMLElement,
    config?: VideoPlayerConfig
  ) {
    const [, videoTrack] = this.getTracksByUid(uid);
    const hasVideo = this.isLocalUser(uid)
      ? this.localUser.hasVideo
      : Boolean(videoTrack);
    if (videoTrack && hasVideo && !videoTrack.isPlaying) {
      videoTrack.play(element, config);
      if (!this.isLocalUser(uid)) {
        this.startFirstVideoFrameDetector(uid, videoTrack);
      }
      return true;
    } else {
      if (videoTrack?.isPlaying) {
        this.log.warn('client tried to play a playing video', {
          agoraUid: uid,
        });
      }
      return false;
    }
  }

  stop(uid: UID): void {
    this.stopAudio(uid);
    this.stopVideo(uid);
  }

  stopAudio(uid: UID): void {
    const [audioTrack] = this.getTracksByUid(uid);
    if (audioTrack && audioTrack.isPlaying) {
      audioTrack.stop();
    }
  }

  stopVideo(uid: UID): void {
    const [, videoTrack] = this.getTracksByUid(uid);
    if (videoTrack && videoTrack.isPlaying) {
      videoTrack.stop();
    }
  }

  stopAll(): void {
    const uids = Object.keys(this.remoteUserMapping);
    for (const uid of uids) {
      this.stop(uid);
    }
  }

  async close(): Promise<void> {
    this.log.info('start to dispose rtc service', { id: this.id });
    this.emitter.clear();
    this.localUser?.audioTrack?.close();
    this.localUser?.videoTrack?.close();
    try {
      await this.client.unpublish();
      await this.client.leave();
    } catch (error) {
      this.log.error('close agora error', error);
    }
    this.unsubscribeEvents();
    this.audioVolumeDetector?.stop();
    this.releaseDenoiserProcessor();
    this.log.info('dispose rtc service done', { id: this.id });
  }

  private startFirstVideoFrameDetector(uid: UID, videoTrack: VideoTrack): void {
    const track = videoTrack as IRemoteVideoTrack;
    track.removeAllListeners('first-frame-decoded');
    track.on('first-frame-decoded', () => {
      this.updateVideoTrackState(uid, TrackState.Live);
    });
  }

  updateVideoTrackState(uid: UID, state: TrackState | null): void {
    const user = this.remoteUserMapping[uid];
    if (!user) return;
    const oldState = user.videoTrackState;
    user.videoTrackState = state;
    this.emitter.emit('video-track-state-changed', uid, oldState, state);
  }

  async setRemoteVideoStreamType(
    uid: UID,
    streamType: RemoteStreamType
  ): Promise<void> {
    try {
      await this.client.setRemoteVideoStreamType(uid, streamType);
    } catch (error) {
      this.log.error('setRemoteVideoStreamType failed', error);
    }
  }

  setRemoteUserVolume(volume: number): void {
    if (volume < 0 || volume > 100) {
      throw new Error('Invalid volume, range [0, 100]');
    }
    this.remoteUserVolume = volume;
    const uids = Object.keys(this.remoteUserMapping);
    for (const uid of uids) {
      const [audioTrack] = this.getTracksByUid(uid);
      if (audioTrack) {
        audioTrack.setVolume(volume);
      }
    }
  }

  joinStatus(): AgoraJoinStatus | undefined {
    return this.joined;
  }

  getVideoTrackStateMap(): Record<UID, TrackState> {
    const stateMap: Record<UID, TrackState> = {};
    for (const [uid, user] of Object.entries(this.remoteUserMapping)) {
      if (user.videoTrackState !== null) {
        stateMap[uid] = user.videoTrackState;
      }
    }
    return stateMap;
  }

  getVideoTrackStateByUid(uid: UID): TrackState | null {
    const user = this.remoteUserMapping[uid];
    if (!user) return null;
    return user.videoTrackState;
  }

  async resubscribe(uid: UID, mediaType: 'audio' | 'video'): Promise<void> {
    if (mediaType === 'audio') return;
    const user = this.remoteUserMapping[uid];
    if (!user) {
      this.log.warn('remote user not found', {
        key: uid,
        op: 'resubscribe',
      });
    }
    try {
      await this.unsubscribeVideo(uid, 'internal-resubscribe');
      await this.subscribeVideo(uid, 'internal-resubscribe');
    } catch (error) {
      this.log.error('resubscribe failed', error, { remoteUid: uid });
    }
  }

  private createAudioVolumeDetector(): void {
    this.audioVolumeDetector = new AudioVolumeDetector();
    this.audioVolumeDetector.start((volumeMap) => {
      this.emitter.emit('user-volume-changed', volumeMap);
    });
  }

  private initDenoiser(assetsPath: string): void {
    DenoiserExtension.Init(assetsPath);
  }

  protected releaseDenoiserProcessor(): void {
    if (this.denoiser.processor) {
      this.denoiser.processor.unpipe();
      this.denoiser.processor.reset();
    }
  }

  protected createDenoiserProcessor(): IAIDenoiserProcessorPatched {
    this.releaseDenoiserProcessor();
    this.denoiser.processor = DenoiserExtension.Instance().createProcessor();
    this.denoiser.processor.on('loaderror', (err) => {
      this.log.error('load denoiser error', err);
    });
    this.denoiser.processor.on('overload', async (elapsedTime) => {
      this.log.warn('denoiser overload', { elapsedTime });
      // Switch from AI noise suppression to stationary noise suppression
      await this.denoiser.processor?.setMode(
        AIDenoiserProcessorMode.STATIONARY_NS
      );
      // Disable AI noise suppression
      await this.denoiser.processor?.disable();
    });
    return this.denoiser.processor;
  }

  denosier(): IAIDenoiserAPI | undefined {
    return this.denoiser.processor;
  }
}

export interface MediaDeviceRTCServiceOptions extends BaseRTCServiceOptions {
  audioBusOptions?: AudioBusOptions;
  micDeviceSettings?: MediaDeviceSettings;
  videoEncoderConfig?: CustomVideoQualityPreset;
  micEncoderConfig?: AudioEncoderConfigurationPreset;
  micVolumeMeterEnabled?: boolean;
  videoEncoderLowQualityConfig?: CustomVideoLowQualityPreset;
  useVideoRecovery?: boolean;
}

export class MediaDeviceRTCService
  extends BaseRTCService<
    ILocalAudioTrack,
    ICameraVideoTrack | ILocalVideoTrack,
    IMediaDeviceAgoraLocalUser<
      ILocalAudioTrack,
      ICameraVideoTrack | ILocalVideoTrack
    >
  >
  implements IMediaDeviceRTCService
{
  private micEncoderConfig: AudioEncoderConfigurationPreset;
  private micVolumeMeterEnabled: boolean;
  private micVolumeMeter?: MicVolumeMeterFromTrack;
  protected videoEncoderConfig: CustomVideoQualityPreset;
  readonly videoEncoderLowQualityConfig: CustomVideoLowQualityPreset;
  private audioAutoMuted?: boolean;
  readonly audioBus: AudioBus;
  private audioOutputDeviceId?: string;
  private micDeviceSettings: MediaDeviceSettings;
  readonly videoRecovery?: AgoraVideoAutoRecovery;

  constructor(options: MediaDeviceRTCServiceOptions) {
    super(options);
    this.micEncoderConfig = options.micEncoderConfig || 'music_standard';
    this.micVolumeMeterEnabled = options.micVolumeMeterEnabled || false;
    this.videoEncoderConfig = options.videoEncoderConfig || '240p';
    this.micDeviceSettings = {
      autoGainControl: getFeatureQueryParam('use-automatic-gain-control'),
      noiseSuppression: getFeatureQueryParam('use-automatic-noise-suppression'),
      ...options.micDeviceSettings,
    };
    this.videoEncoderLowQualityConfig =
      options.videoEncoderLowQualityConfig || '120p';
    this.client.setLowStreamParameter(
      profileForCustomVideoLowQuality(this.videoEncoderLowQualityConfig)
    );
    this.audioBus = new AudioBus(options.audioBusOptions);
    this.localUser.audioTrack = this.audioBus.outputTrack;
    if (options.useVideoRecovery) {
      this.videoRecovery = new AgoraVideoAutoRecovery(this, this.log, 5000, 10);
      this.videoRecovery.start();
    }
  }

  protected initLocalUser(uid: UID): typeof this.localUser {
    return {
      uid: uid,
      hasVideo: false,
      hasAudio: false,
      isScreenSharing: false,
      isMusicSharing: false,
    };
  }

  async toggleAudio(val: boolean): Promise<void> {
    this.localUser.hasAudio = val;
    if (this.localUser.inputAudioTrack) {
      if (!val) {
        this.stopAudio(this.localUser.uid);
      }
      if (!this.audioAutoMuted) {
        // setEnabled will cause the unstable remote audio tracks
        // await this.localUser.audioTrack.setEnabled(val);
        this.audioBus.toggleMicrophone(val);
      }
      if (val && this.micVolumeMeterEnabled) {
        this.createVolumeMeter();
      }
    }
    this.emitter.emit('audio-toggled', val);
  }

  muteAudio(val: boolean): void {
    if (this.localUser.inputAudioTrack) {
      this.audioAutoMuted = val;
      this.audioBus.toggleMicrophone(val ? false : this.localUser.hasAudio);
    }
  }

  async toggleVideo(val: boolean): Promise<void> {
    await this.toggleVideoInternal(val, val);
  }

  protected async toggleVideoInternal(
    hasVideo: boolean,
    enabled: boolean
  ): Promise<void> {
    this.localUser.hasVideo = hasVideo;

    if (this.localUser.inputVideoTrack) {
      if (!enabled) {
        this.stopVideo(this.localUser.uid);
      }
      await this.localUser.inputVideoTrack.setEnabled(enabled);
      // NOTE: calling .setEnabled() will destroy/create a new underlying
      // MediaStreamTrack, use this to notify dependents.
      this.setInputVideoTrack(this.localUser.inputVideoTrack);
    }

    this.emitter.emit('video-toggled', enabled);
  }

  async switchAudioInput(
    deviceId: string,
    forceRecreate = false
  ): Promise<void> {
    let deviceChanged = false;
    if (this.localUser.inputAudioTrack && !forceRecreate) {
      const currentDeviceId = getDeviceIdFromTrack(
        this.localUser.inputAudioTrack.getMediaStreamTrack()
      );
      if (currentDeviceId === deviceId) {
        this.log.debug('switchAudio - same deviceId detected');
      } else {
        this.localUser.inputAudioTrack.stop();
        await this.localUser.inputAudioTrack.setDevice(deviceId);
        deviceChanged = true;
      }
    } else {
      this.localUser.inputAudioTrack =
        await AgoraRTC.createMicrophoneAudioTrack({
          microphoneId: deviceId,
          encoderConfig: this.micEncoderConfig,
          AGC: this.micDeviceSettings.autoGainControl,
          ANS: this.micDeviceSettings.noiseSuppression,
        });
      deviceChanged = true;
    }
    if (deviceChanged) {
      if (this.micVolumeMeterEnabled) {
        this.createVolumeMeter();
      }

      this.audioVolumeDetector?.addAudioTrack(
        this.localUser.uid,
        this.localUser.inputAudioTrack
      );

      this.audioBus?.setMicrophoneTrack(
        this.localUser.inputAudioTrack.getMediaStreamTrack()
      );

      if (this.denoiser.enabled) {
        const processor = this.createDenoiserProcessor();
        this.audioBus?.outputTrack
          .pipe(processor)
          .pipe(this.audioBus?.outputTrack.processorDestination);
        await processor.enable();
      }
      // Output track will not change during lifetime of audioBus, but still
      // needs to be present as `audioTrack` for getTracksByUuid queries.
      this.localUser.audioTrack = this.audioBus?.outputTrack;

      this.emitter.emit('audio-switched');
    }
  }

  async toggleMicDeviceSetting(
    setting: keyof MediaDeviceSettings,
    val: boolean
  ): Promise<void> {
    if (!this.localUser.inputAudioTrack) return;

    const currentDeviceId = getDeviceIdFromTrack(
      this.localUser.inputAudioTrack.getMediaStreamTrack()
    );

    if (!currentDeviceId) return;

    this.micDeviceSettings[setting] = val;
    await this.switchAudioInput(currentDeviceId, true);
    this.emitter.emit('audio-mic-device-setting-toggled');
  }

  getMicDeviceSettings(): Readonly<MediaDeviceSettings> {
    return this.micDeviceSettings;
  }

  async switchAudioOuptut(deviceId: string): Promise<void> {
    this.audioOutputDeviceId = deviceId;
    const uids = Object.keys(this.remoteUserMapping);
    for (const uid of uids) {
      const [audioTrack] = this.getTracksByUid(uid);
      if (audioTrack) {
        audioTrack.setPlaybackDevice(deviceId);
      }
    }
  }

  async switchVideo(input: string | MediaStreamTrack): Promise<void> {
    await this.switchVideoInternal(input);
    this.setOutputVideoTrack(this.localUser.inputVideoTrack);
  }

  protected async switchVideoInternal(input: string | MediaStreamTrack) {
    if (typeof input === 'string') {
      await this.switchVideoDeviceId(input);
    } else {
      await this.switchVideoTrack(input);
    }
  }

  private async switchVideoTrack(track: MediaStreamTrack): Promise<void> {
    if (track.kind !== 'video') {
      throw new Error(`invalid track type: ${track.kind}`);
    }

    // _track.stop()_ doesn't release the track, so the cam light is still on
    this.localUser.inputVideoTrack?.close();
    const nextTrack = AgoraRTC.createCustomVideoTrack({
      mediaStreamTrack: track,
    });

    this.setInputVideoTrack(nextTrack);
  }

  private async switchVideoDeviceId(deviceId: string): Promise<void> {
    const currentDeviceId = getDeviceIdFromTrack(
      this.localUser.inputVideoTrack?.getMediaStreamTrack()
    );

    if (this.localUser.inputVideoTrack && currentDeviceId === deviceId) {
      this.log.debug('switchVideo - same deviceId detected');
    } else {
      this.localUser.inputVideoTrack?.close();

      // NOTE(drew): previous code used ICameraTrack.setDevice() to change the
      // underlying deviceId without changing the track reference. But
      // enable/disable, changing the device, etc changes the underlying
      // MediaStreamTrack, and requires any parts of the app that use
      // MediaStreamTrack(s) directly (Sentiment, CrowdFrames, CameraVideoMixer
      // etc) to behave as if the entire reference were destroyed. It's easier
      // to just recreate the entire MediaStream. The downside is that it
      // requires a (possible) republish, but that is handled by
      // `setOutputVideoTrack`.

      // initialization, create track
      this.setInputVideoTrack(
        await AgoraRTC.createCameraVideoTrack({
          cameraId: deviceId,
          encoderConfig: this.videoEncoderConfig,
        })
      );
    }
  }

  protected setInputVideoTrack(
    track: ICameraVideoTrack | ILocalVideoTrack | undefined
  ) {
    if (track !== this.localUser.inputVideoTrack) {
      this.localUser.inputVideoTrack = track;
    }
  }

  protected async setOutputVideoTrack(
    nextTrack: ICameraVideoTrack | ILocalVideoTrack | undefined
  ) {
    if (nextTrack !== this.localUser.videoTrack) {
      let needsVideoPublish = false;

      // Internally, Agora only adds a track to the list of localTracks once
      // published. If there is already a published track, and we're going to
      // change it, then we need to unpublish the old track and publish the new
      // track as quickly as possible.
      const publishedTrack = this.client.localTracks.find(
        (t) => t.trackMediaType === 'video'
      );
      if (publishedTrack) {
        needsVideoPublish = true;
        await this.client.unpublish(publishedTrack);
      }

      // Stop local playback of the rtcService-controlled video
      this.stopVideo(this.localUser.uid);

      // Set the output track
      this.localUser.videoTrack = nextTrack;

      const nextMST = nextTrack?.getMediaStreamTrack();

      // Republish the new track, but only if it is not ended (which can happen
      // if the camera was muted when the source changed).
      if (needsVideoPublish && nextMST?.readyState !== 'ended') {
        await this.publishVideo();
      }

      // Notify of a new output track.
      this.emitter.emit('video-switched');
    }
  }

  async setEncoderConfiguration(
    config: CustomVideoQualityPreset | 'default',
    options?: {
      suppressError?: boolean;
      persistConfig?: boolean;
    }
  ): Promise<boolean> {
    const opts = { suppressError: true, persistConfig: false, ...options };
    if (opts.persistConfig && config !== 'default') {
      this.videoEncoderConfig = config;
    }
    // TODO: This will only work if cameraVideoMixer is disabled, since setting
    // the encoder config can only be done on a Camera Track.
    const videoTrack = this.localUser?.inputVideoTrack ?? null;
    if (!videoTrack) return false;
    const readyState = videoTrack.getMediaStreamTrack()?.readyState;
    if (readyState !== 'live') return false;

    if (config === 'default') {
      try {
        await videoTrack.setEncoderConfiguration(this.videoEncoderConfig);
        return true;
      } catch (err) {
        if (!opts.suppressError) throw err;
        this.log.error('setEncoderConfiguration error', err2s(err));
        return false;
      }
    } else {
      try {
        await videoTrack.setEncoderConfiguration(config);
        return true;
      } catch (err) {
        if (!opts.suppressError) throw err;
        this.log.error('setEncoderConfiguration error', err2s(err));
        return false;
      }
    }
  }

  getVolumeMeter(): MicVolumeMeter | undefined {
    return this.micVolumeMeter;
  }

  private createVolumeMeter(): void {
    if (!this.localUser?.inputAudioTrack) {
      this.log.warn('audioTrack not found');
      return;
    }
    if (this.micVolumeMeter) {
      this.log.debug('close existing meter');
      this.micVolumeMeter.close();
    }
    this.micVolumeMeter = new MicVolumeMeterFromTrack(
      this.localUser.inputAudioTrack.getMediaStreamTrack()
    );
    const currentDeviceId = getDeviceIdFromTrack(
      this.localUser.inputAudioTrack.getMediaStreamTrack()
    );
    this.log.debug('create new meter', {
      deviceId: currentDeviceId,
    });
  }

  async attemptMusicShare(): Promise<void> {
    if (!this.localUser) return;
    const stream = await createHighQualityMusicShare();
    const audio = stream.getAudioTracks()[0];
    const video = stream.getVideoTracks()[0];

    if (!audio.label.match(/tab audio/i))
      throw new Error('OnlyTabAudioSupported to prevent feedback');

    this.localUser.musicShare = stream;
    this.localUser.isMusicSharing = true;
    this.audioBus?.setMusicTrack(audio);
    audio.onended = () => this.stopMusicShare();
    video.onended = () => this.stopMusicShare();
    this.emitter.emit('music-share-changed', true);
  }

  async stopMusicShare(): Promise<void> {
    if (
      !this.localUser ||
      !this.localUser.isMusicSharing ||
      !this.localUser.musicShare
    )
      return;

    this.audioBus?.setMusicTrack(null);
    releaseMediaStream(this.localUser.musicShare);
    this.localUser.musicShare = undefined;
    this.localUser.isMusicSharing = false;
    this.emitter.emit('music-share-changed', false);
  }

  async unpublish(): Promise<void> {
    await this.stopMusicShare();
    await super.unpublish();
  }

  subscribeEvents(): void {
    super.subscribeEvents();
    this.client.on('user-published', async (user, mediaType) => {
      if (this.isLocalUser(user.uid)) return;
      if (mediaType === 'audio' && user.audioTrack) {
        if (this.audioOutputDeviceId !== undefined) {
          user.audioTrack.setPlaybackDevice(this.audioOutputDeviceId);
        }
      }
    });
  }

  async close(): Promise<void> {
    this.audioBus?.dispose();
    this.micVolumeMeter?.close();
    this.localUser?.inputAudioTrack?.close();
    this.localUser?.musicShare?.getTracks().forEach((t) => t.stop());
    this.videoRecovery?.reset();
    super.close();
  }
}

export interface CustomRTCServiceOptions extends BaseRTCServiceOptions {
  audioEncoderConfig?: CustomAudioTrackInitConfig['encoderConfig'];
  videoEncoderConfig?: Omit<CustomVideoTrackInitConfig, 'mediaStreamTrack'>;
  videoEncoderLowQualityConfig?: CustomVideoLowQualityPreset;
}

export class CustomRTCService
  extends BaseRTCService<
    ILocalAudioTrack,
    ILocalVideoTrack,
    IBaseAgoraLocalUser<ILocalAudioTrack, ILocalVideoTrack>
  >
  implements ICustomRTCService
{
  private audioEncoderConfig?: CustomAudioTrackInitConfig['encoderConfig'];
  private videoEncoderConfig?: Omit<
    CustomVideoTrackInitConfig,
    'mediaStreamTrack'
  >;
  private videoEncoderLowQualityConfig: CustomVideoLowQualityPreset;
  constructor(options: CustomRTCServiceOptions) {
    super(options);
    this.audioEncoderConfig = options.audioEncoderConfig;
    this.videoEncoderConfig = options.videoEncoderConfig;
    this.videoEncoderLowQualityConfig =
      options.videoEncoderLowQualityConfig || '360p';
    this.client.setLowStreamParameter(
      profileForCustomVideoLowQuality(this.videoEncoderLowQualityConfig)
    );
  }

  protected initLocalUser(uid: UID): typeof this.localUser {
    return {
      uid: uid,
      hasVideo: false,
      hasAudio: false,
    };
  }
  switchAudio(track: MediaStreamTrack): void {
    if (track.kind !== 'audio') {
      throw new Error(`invalid track type: ${track.kind}`);
    }
    if (this.localUser.audioTrack) {
      this.localUser.audioTrack.stop();
    }
    this.localUser.audioTrack = AgoraRTC.createCustomAudioTrack({
      mediaStreamTrack: track,
      encoderConfig: this.audioEncoderConfig,
    });
    this.localUser.hasAudio = true;
    this.emitter.emit('audio-switched');
  }
  switchVideo(track: MediaStreamTrack): void {
    if (track.kind !== 'video') {
      throw new Error(`invalid track type: ${track.kind}`);
    }
    if (this.localUser.videoTrack) {
      this.localUser.videoTrack.stop();
    }
    this.localUser.videoTrack = AgoraRTC.createCustomVideoTrack({
      mediaStreamTrack: track,
      ...this.videoEncoderConfig,
    });
    this.localUser.hasVideo = true;
    this.emitter.emit('video-switched');
  }
}
