import debounce from 'lodash/debounce';
import { useMemo, useState } from 'react';
import { type SingleValue } from 'react-select';
import AsyncSelect from 'react-select/async';

import { type DtoPromptTemplate } from '@lp-lib/api-service-client/public';

import { useLiveCallback } from '../../hooks/useLiveCallback';
import { apiService } from '../../services/api-service';
import { buildReactSelectStyles } from '../../utils/react-select';
import { useAwaitFullScreenConfirmCancelModal } from '../ConfirmCancelModalContext';
import { ModalWrapper } from '../ConfirmCancelModalContext/ModalWrapper';

async function search(
  q: string,
  callback: (items: DtoPromptTemplate[]) => void
) {
  const resp = await apiService.promptTemplate.getTemplates();
  const items = resp.data.promptTemplates.filter((item) =>
    item.name.toLowerCase().includes(q.toLowerCase())
  );
  callback(items);
}

const debounceSearch = debounce(search, 250);

export function PromptTemplatePicker(props: {
  onChange: (value: DtoPromptTemplate) => void;
}): JSX.Element {
  const styles = useMemo(
    () =>
      buildReactSelectStyles({
        override: {
          control: { height: '100%' },
        },
      }),
    []
  );

  const loadOptions = (
    q: string,
    callback: (items: DtoPromptTemplate[]) => void
  ): void => {
    debounceSearch(q, callback);
  };

  const handleSingleChange = (option: SingleValue<DtoPromptTemplate>) => {
    if (option) {
      props.onChange(option);
    }
  };

  return (
    <AsyncSelect<DtoPromptTemplate, false>
      placeholder={`Select prompt template`}
      styles={styles}
      cacheOptions
      defaultOptions
      loadOptions={loadOptions}
      classNamePrefix='select-box-v2'
      noOptionsMessage={(obj) => {
        if (!obj.inputValue) return 'Start typing to search';
        return 'No prompt template matched';
      }}
      getOptionValue={(option) => option.id}
      getOptionLabel={(option) => option.name}
      onChange={handleSingleChange}
    />
  );
}

function PromptTemplatePickerModal(props: {
  onClose: () => void;
  onSelected: (item: DtoPromptTemplate) => void;
}) {
  const [item, setItem] = useState<DtoPromptTemplate | null>(null);
  return (
    <ModalWrapper containerClassName='w-160' borderStyle='gray'>
      <section className='flex flex-col items-center justify-center gap-5 p-10'>
        <header className='text-2xl font-medium'>Select Prompt Template</header>
        <label className='w-full'>
          <div className='font-bold'>Select</div>
          <PromptTemplatePicker onChange={(item) => setItem(item)} />
        </label>
        <footer className='flex justify-center items-center gap-5'>
          <button
            type='button'
            onClick={props.onClose}
            className='btn-secondary w-45 h-10'
          >
            Cancel
          </button>
          <button
            type='submit'
            className='btn-primary w-45 h-10'
            disabled={!item}
            onClick={() => {
              if (!item) return;
              props.onSelected(item);
            }}
          >
            Select
          </button>
        </footer>
      </section>
    </ModalWrapper>
  );
}

export function useOpenPromptTemplatePickerModal() {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  return useLiveCallback(
    (props: { onSelected: (item: DtoPromptTemplate) => void }) => {
      triggerModal({
        kind: 'custom',
        element: (p) => (
          <PromptTemplatePickerModal
            onClose={p.internalOnCancel}
            onSelected={(item) => {
              props.onSelected(item);
              p.internalOnConfirm();
            }}
          />
        ),
      });
    }
  );
}
