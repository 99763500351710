import { MediaType } from '@lp-lib/media';

import { UnplayableMediaFactory } from '../../utils/unplayable';
import { VideoMixer } from '../VideoMixer';

export function makeLocalAudioOnlyVideoMixer() {
  const vm = new VideoMixer(
    {
      renderHeight: 100,
      renderWidth: 100,
      audioRenderingOnly: true,
    },
    false,
    'timer-accumulated'
  );

  const unplayable = UnplayableMediaFactory.From(
    vm.getOutputMediaStream(),
    MediaType.Audio
  );
  unplayable.intoPlayable();

  vm.on('playstate', (playing) => {
    if (playing) unplayable.media.play();
    else unplayable.media.pause();
  });

  vm.on('before-destroy', () => {
    unplayable.media.pause();
    unplayable.media.remove();
  });

  return [vm, unplayable.media] as const;
}
