import { Link } from '@remix-run/react';
import { useNavigate } from '@remix-run/react';
import pluralize from 'pluralize';
import { useMemo } from 'react';
import { $path } from 'remix-routes';

import { EnumsEventType } from '@lp-lib/api-service-client/public';

import { isGamePackLaunched } from '../../../../app/components/GamePack/utils';
import { useAppAnalytics } from '../../../analytics/app/identifiable';
import { useEventSchedulerAnalytics } from '../../../analytics/eventScheduler';
import { useMakeHostViewAutoloadHandler } from '../../../hooks/useLoadGame';
import { useMetaKeyHeld } from '../../../hooks/useModKeyHeld';
import { useGamePackContext } from '../../../pages/GamePack/Context';
import { type GamePack } from '../../../types/game';
import { CalendarIcon } from '../../icons/CalendarIcon';
import { EditIcon } from '../../icons/EditIcon';
import { HostIcon } from '../../icons/HostIcon';
import { PlayIcon } from '../../icons/PlayIcon';
import { ToolsIcon } from '../../icons/ToolsIcon';
import { useMyOrganizationFeatureChecker } from '../../Organization';
import { useGameLikeActionSheetSettings } from '../GameCenter';
import { GamePackActionSheet } from '../GamePackActionSheet';
import { useOpenRoutedGamePackEditor } from './hooks';
import { GamePackUtils } from './utils';

type GamePackButtonContext =
  | 'featuredGamePack'
  | 'gamePackDetail'
  | 'gamePackHovered';

type GamePackButtonSharedProps = {
  pack: GamePack;
  context: GamePackButtonContext;
  onClick?: () => void;
  className?: string;
  iconClassName?: string;
};

export function GamePackButtonSchedule(props: GamePackButtonSharedProps) {
  const eventSchedulerAnalytics = useEventSchedulerAnalytics();
  const appAnalytics = useAppAnalytics();
  const navigate = useNavigate();

  const handleClick = () => {
    eventSchedulerAnalytics.trackScheduleGameClicked(
      props.context,
      props.pack.id
    );
    appAnalytics.trackLibraryGameDetailsClicked(
      props.pack.id,
      props.pack.name,
      'Schedule'
    );

    if (props.onClick) {
      props.onClick();
      return;
    }
    if (GamePackUtils.IsLive(props.pack)) {
      navigate(
        $path(
          '/events/schedule/:type?',
          { type: EnumsEventType.EventTypeLive },
          { 'game-pack-id': props.pack.id }
        )
      );
      return;
    }
    navigate(`/events/create?game-pack-id=${props.pack.id}`);
  };

  return (
    <button
      type='button'
      onClick={handleClick}
      className={
        props.className ??
        'btn-secondary rounded h-10 flex items-center justify-center px-4 text-sms'
      }
    >
      <CalendarIcon
        className={props.iconClassName ?? 'w-5 h-5 fill-current mr-2.5'}
      />
      <p>
        {isGamePackLaunched(props.pack)
          ? 'Schedule Experience'
          : 'Preschedule Today'}
      </p>
    </button>
  );
}

export function GamePackButtonPlay(
  props: GamePackButtonSharedProps & {
    disabled?: boolean;
  }
) {
  const appAnalytics = useAppAnalytics();
  const navigate = useNavigate();
  const featureChecker = useMyOrganizationFeatureChecker();
  const venueCap = useMemo(
    () => featureChecker.getVenueCap(props.pack),
    [featureChecker, props.pack]
  );

  const handleClick = () => {
    appAnalytics.trackPlayGameClicked(props.context, props.pack.id);
    appAnalytics.trackLibraryGameDetailsClicked(
      props.pack.id,
      props.pack.name,
      'Play'
    );

    if (props.onClick) {
      props.onClick();
      return;
    }
    navigate(`/my-venue/${props.pack.id}`);
  };

  return (
    <div className='flex flex-col items-center justify-center gap-1'>
      <button
        type='button'
        className={
          props.className ??
          'btn-delete rounded h-10 flex items-center justify-center px-4 text-sms'
        }
        disabled={!isGamePackLaunched(props.pack)}
        onClick={handleClick}
      >
        <PlayIcon
          className={props.iconClassName ?? 'w-5 h-5 fill-current mr-2.5'}
        />
        <p>Start Now</p>
      </button>
      {venueCap && (
        <div className='text-icon-gray text-xs'>{`(${venueCap} ${pluralize(
          'player',
          venueCap
        )} max)`}</div>
      )}
    </div>
  );
}

export function GamePackButtonHostLive(props: GamePackButtonSharedProps) {
  const handleLoadGame = useMakeHostViewAutoloadHandler(props.pack);

  const handleClick = () => {
    if (props.onClick) {
      props.onClick();
      return;
    }
    handleLoadGame();
  };

  return (
    <button
      type='button'
      className={
        props.className ??
        'btn-secondary h-10 flex items-center justify-center px-4 text-sms'
      }
      onClick={handleClick}
    >
      <HostIcon className={props.iconClassName ?? 'w-3.5 h-3.5 fill-current'} />
      <p>Host Live</p>
    </button>
  );
}

export function GamePackButtonPlaytest(props: GamePackButtonSharedProps) {
  const held = useMetaKeyHeld();
  const url = GamePackUtils.GetPlaytestUrl(props.pack, held);

  return (
    <Link to={url}>
      <button
        type='button'
        className={
          props.className ??
          'btn-primary h-10 flex items-center justify-center gap-2 px-4 text-sms whitespace-nowrap'
        }
      >
        {held ? (
          <ToolsIcon
            className={props.iconClassName ?? 'w-3.5 h-3.5 fill-current'}
          />
        ) : (
          <PlayIcon
            className={props.iconClassName ?? 'w-3.5 h-3.5 fill-current'}
          />
        )}
        Playtest
      </button>
    </Link>
  );
}

export function GamePackButtonCohost(props: GamePackButtonSharedProps) {
  const held = useMetaKeyHeld();
  const url = GamePackUtils.GetCohostUrl(props.pack, held);

  return (
    <Link to={url}>
      <button
        type='button'
        className={
          props.className ??
          'btn-secondary h-10 flex items-center justify-center gap-2 px-4 text-sms whitespace-nowrap'
        }
      >
        {held ? (
          <ToolsIcon
            className={props.iconClassName ?? 'w-3.5 h-3.5 fill-current'}
          />
        ) : (
          <HostIcon
            className={props.iconClassName ?? 'w-3.5 h-3.5 fill-current'}
          />
        )}
        Cohost
      </button>
    </Link>
  );
}

export function GamePackButtonEdit(props: GamePackButtonSharedProps) {
  const openGamePackEditor = useOpenRoutedGamePackEditor();

  const handleClick = () => {
    if (props.onClick) {
      props.onClick();
      return;
    }
    openGamePackEditor({ type: 'edit', packId: props.pack.id });
  };

  return (
    <button
      type='button'
      className={
        props.className ??
        'btn-secondary h-10 px-4 flex items-center justify-center gap-2'
      }
      onClick={handleClick}
    >
      <EditIcon className={props.iconClassName ?? 'w-3.5 h-3.5 fill-current'} />
      <p>Edit</p>
    </button>
  );
}

export function UGCGamePackButtonEdit(props: GamePackButtonSharedProps) {
  const navigate = useNavigate();

  const handleClick = () => {
    if (props.onClick) {
      props.onClick();
      return;
    }
    navigate(`/custom-games/${props.pack.id}/edit`);
  };

  return (
    <button
      type='button'
      className={
        props.className ??
        'btn-secondary h-10 px-4 flex items-center justify-center gap-2'
      }
      onClick={handleClick}
    >
      <EditIcon className={props.iconClassName ?? 'w-3.5 h-3.5 fill-current'} />
      <p>Edit</p>
    </button>
  );
}

export function GamePackButtonActions(
  props: GamePackButtonSharedProps
): JSX.Element | null {
  const ctx = useGamePackContext();
  const actionSheetSettings = useGameLikeActionSheetSettings(
    props.pack,
    'detail-card',
    ctx.pageType
  );

  return (
    <GamePackActionSheet
      pack={props.pack}
      buttonClassName={props.className ?? 'w-7.5 h-7.5'}
      settings={actionSheetSettings}
    />
  );
}

export function GamePackOneTimePurchaseButton(props: {
  pack: GamePack;
  btnClassName?: string;
}) {
  const { pack, btnClassName } = props;
  const navigate = useNavigate();

  return (
    <div className='flex flex-col items-center justify-center gap-1'>
      <button
        type='button'
        className={btnClassName ?? 'btn-delete h-10 px-4 rounded text-sms'}
        onClick={() => navigate(GamePackUtils.GetOneTimePurchaseUrl(pack))}
      >
        Book For Your Group
      </button>
    </div>
  );
}
