import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useEffectOnce } from 'react-use';

import {
  assertExhaustive,
  type IcebreakerBlock,
  IcebreakerBlockGameSessionStatus,
  IcebreakerMode,
} from '@lp-lib/game';

import { useMusicPlayerContext } from '../../../MusicPlayer/Context';
import { useCohostClientId, useHostClientId } from '../../../Player';
import { StageMode, useStageControlAPI } from '../../../Stage';
import { useTownhallAPI, useTownhallEnabled } from '../../../Townhall';
import {
  useGameSessionLocalTimer,
  useGameSessionStatus,
  useIsLiveGamePlay,
} from '../../hooks';
import { ondWaitEnd } from '../../OndPhaseRunner';
import { next } from '../../store';
import { type GameControlProps } from '../Common/GameControl/types';
import { useStableBlock } from '../Common/hooks';
import {
  useCurrentCard,
  useCurrentPlayer,
  useIcebreakerGameControlAPI,
  useIcebreakerSharedAPI,
  useIsAllCardsPlayed,
} from './IcebreakerBlockProvider';
import { type IcebreakerGamePlayCard } from './types';
import { IcebreakerUtils } from './utils';

type CardControlProps = {
  block: IcebreakerBlock;
  card: IcebreakerGamePlayCard;
};

function CardControlReveal(props: CardControlProps) {
  const api = useIcebreakerSharedAPI();
  const shouldAutoProgress = IcebreakerUtils.ShouldRevealAutoProgress(
    props.card,
    props.block
  );

  useEffect(() => {
    if (!shouldAutoProgress) return;

    const timer = setTimeout(() => {
      api.completeCard();
    }, 3000);
    return () => {
      clearInterval(timer);
    };
  }, [api, props.card.revealIndex, shouldAutoProgress]);

  return null;
}

function CardControlCompleted(props: CardControlProps) {
  const api = useIcebreakerSharedAPI();

  useEffectOnce(() => {
    if (
      !IcebreakerUtils.ShouldSelectOnStagePlayer(props.block) ||
      props.block.fields.mode === IcebreakerMode.HeadsUp
    ) {
      api.nextCard();
      return;
    }

    const nextPlayerUid = api.pickNextPlayerUid(
      props.block.fields.onStageSelection,
      props.block.fields.selectNextStrategy
    );
    // let the on stage player to pick the next player
    if (!nextPlayerUid) {
      return;
    }
    api.nextCardAndPlayer(nextPlayerUid);
  });

  return null;
}

function CardPhaseControl(props: SharedProps) {
  const currentCard = useCurrentCard();
  if (!currentCard) return null;

  const phase = currentCard.phase;
  switch (phase) {
    case 'active':
      return null;
    case 'reveal':
      return <CardControlReveal block={props.block} card={currentCard} />;
    case 'completed':
      return <CardControlCompleted block={props.block} card={currentCard} />;
    default:
      assertExhaustive(phase);
      return null;
  }
}

function HeadsUpTimeControl(props: SharedProps) {
  if (props.block.fields.mode !== IcebreakerMode.HeadsUp) return null;
  return <HeadsUpTimeControlInternal {...props} />;
}

function HeadsUpTimeControlInternal(props: SharedProps) {
  const api = useIcebreakerSharedAPI();
  const time = useGameSessionLocalTimer();
  const isRunning = useRef(false);

  useEffect(() => {
    if (time !== 0 || isRunning.current) return;
    isRunning.current = true;
    try {
      const nextPlayerUid = api.pickNextPlayerUid(
        props.block.fields.onStageSelection,
        props.block.fields.selectNextStrategy
      );
      // let the on stage player to pick the next player
      if (!nextPlayerUid) {
        return;
      }
      api.nextCardAndPlayer(nextPlayerUid);
    } finally {
      isRunning.current = false;
    }
  }, [
    api,
    props.block.fields.onStageSelection,
    props.block.fields.selectNextStrategy,
    time,
  ]);

  return null;
}

type SharedProps = GameControlProps<IcebreakerBlock>;

function Loaded(_props: SharedProps) {
  const api = useIcebreakerGameControlAPI();

  useEffectOnce(() => {
    api.resetGame();
  });

  return null;
}

function Init(props: SharedProps) {
  const api = useIcebreakerGameControlAPI();

  useEffectOnce(() => {
    api.initGame(props.block);
  });

  return null;
}

function InProgress(props: SharedProps) {
  const isLive = useIsLiveGamePlay();
  const isAllCardsPlayed = useIsAllCardsPlayed();

  useEffect(() => {
    if (!isAllCardsPlayed) return;

    if (isLive) next();
    else ondWaitEnd();
  }, [isAllCardsPlayed, isLive]);

  return (
    <>
      <CardPhaseControl {...props} />
      <HeadsUpTimeControl {...props} />
    </>
  );
}

function useSyncTownhall() {
  const gss = useGameSessionStatus<IcebreakerBlockGameSessionStatus>();
  const townhallEnabled = useTownhallEnabled();
  const isLive = useIsLiveGamePlay();
  const api = useTownhallAPI();

  const townhall =
    !!gss &&
    IcebreakerBlockGameSessionStatus.GAME_INIT <= gss &&
    gss < IcebreakerBlockGameSessionStatus.END;
  useLayoutEffect(() => {
    if (!townhallEnabled || !isLive) return;
    if (!townhall) return;

    api.setNext({
      mode: 'crowd',
      countdownSec: 0,
      type: 'global',
      source: 'LiveIcebreakerBlockStart',
    });

    return () => {
      api.setNext({
        mode: 'team',
        countdownSec: 0,
        type: 'global',
        source: 'LiveIcebreakerBlockEnd',
      });
    };
  }, [api, isLive, townhall, townhallEnabled]);
}

function useSyncStage(gss: Nullable<IcebreakerBlockGameSessionStatus>) {
  const stageControl = useStageControlAPI();
  const hostClientId = useHostClientId();
  const cohostClientId = useCohostClientId();
  const currentPlayer = useCurrentPlayer();

  useEffect(() => {
    stageControl.updateStageMode(StageMode.BLOCK_CONTROLLED);
    return () => {
      stageControl.leaveAll([hostClientId, cohostClientId]);
      stageControl.updateStageMode(StageMode.BOS);
    };
  }, [cohostClientId, hostClientId, stageControl]);

  const shouldBringOnStage =
    !!gss &&
    IcebreakerBlockGameSessionStatus.GAME_INIT <= gss &&
    gss <= IcebreakerBlockGameSessionStatus.GAME_START;
  // bring current player on stage
  useEffect(() => {
    if (!shouldBringOnStage) return;
    if (!currentPlayer?.clientId) return;

    stageControl.join(currentPlayer.clientId, StageMode.BLOCK_CONTROLLED);
    return () => {
      stageControl.leave(currentPlayer.clientId);
    };
  }, [currentPlayer?.clientId, shouldBringOnStage, stageControl]);
}

function useSyncMusicPlayer(
  block: IcebreakerBlock,
  gss: Nullable<IcebreakerBlockGameSessionStatus>
) {
  const { play, pause, isPlaying } = useMusicPlayerContext();

  const [resumable, setResumable] = useState(false);

  useEffect(() => {
    if (!block.fields.muteBackgroundMusic) return;
    if (!isPlaying) return;
    if (!gss) return;
    if (
      gss < IcebreakerBlockGameSessionStatus.GAME_INIT ||
      gss >= IcebreakerBlockGameSessionStatus.END
    )
      return;

    pause('icebreaker block pause');
    setResumable(true);
  }, [isPlaying, pause, play, block.fields.muteBackgroundMusic, gss]);

  useEffect(() => {
    if (!block.fields.muteBackgroundMusic) return;
    if (gss !== IcebreakerBlockGameSessionStatus.END) return;
    if (!resumable) return;

    play('icebreaker block resume');
  }, [block.fields.muteBackgroundMusic, gss, play, resumable]);
}

export function IcebreakerBlockGameControl(
  props: SharedProps
): JSX.Element | null {
  const block = useStableBlock(props.block);
  const gss = useGameSessionStatus<IcebreakerBlockGameSessionStatus>();

  useSyncTownhall();
  useSyncStage(gss);
  useSyncMusicPlayer(props.block, gss);

  const api = useIcebreakerGameControlAPI();
  useEffect(() => {
    return () => {
      api.resetGame();
    };
  }, [api]);

  switch (gss) {
    case IcebreakerBlockGameSessionStatus.LOADED:
      return <Loaded block={block} />;
    case IcebreakerBlockGameSessionStatus.GAME_INIT:
      return <Init block={block} />;
    case IcebreakerBlockGameSessionStatus.GAME_START:
      return <InProgress block={block} />;
    case IcebreakerBlockGameSessionStatus.RESULTS:
      return null;
    case IcebreakerBlockGameSessionStatus.END:
      return null;
    case null:
    case undefined:
      break;
    default:
      assertExhaustive(gss);
      break;
  }

  return null;
}
