import { type Logger } from '@lp-lib/logger-base';

import { type IVideoMixerTrack } from './IVideoMixerTrack';
import { type TrackId, type VideoMixerEvents } from './types';
import { type VideoMixer } from './VideoMixer';

export function fixed4(n: number | null | undefined): string | undefined {
  return n?.toFixed(4);
}

// Only warn if head and tail events are spaced out by this amount of time,
// to prevent needing to poll. Logging gets expensive when it's potentially
// per frame.
export function reportLongDraws(
  vm: VideoMixer,
  log: Logger,
  longDrawWarnDurationMs = 1000
) {
  const longDraws: {
    playheadMs: number;
    limitMs: number;
    deltaMs: number;
  }[] = [];
  vm.on('long-draw', (playheadMs, limitMs, deltaMs) => {
    longDraws.push({ playheadMs, limitMs, deltaMs });

    const first = longDraws[0];
    const last = longDraws[longDraws.length - 1];

    if (
      first &&
      last &&
      longDraws.length > 1 &&
      last.playheadMs - first.playheadMs > longDrawWarnDurationMs
    ) {
      let total = 0;
      for (const ev of longDraws) total += ev.deltaMs;

      log.warn(
        `long-draws count:${longDraws.length}, totalMs:${fixed4(
          total
        )}ms, avg:${fixed4(total / longDraws.length)}ms`
      );

      longDraws.length = 0;
    }
  });
}

export function reportTrackDesyncs(
  vm: VideoMixer,
  trackGetter: () => IVideoMixerTrack[],
  log: Logger
) {
  const trackDesyncInfo = new Map<
    TrackId,
    Parameters<VideoMixerEvents['track-corrected-desync']>[]
  >();
  vm.on('track-corrected-desync', (...args) => {
    const [trackId] = args;
    let desyncs = trackDesyncInfo.get(trackId);
    if (!desyncs) {
      desyncs = [];
      trackDesyncInfo.set(trackId, desyncs);
    }
    desyncs.push(args);
  });
  vm.on('track-remove', (trackId) => {
    const desyncs = trackDesyncInfo.get(trackId);
    if (!desyncs) return;

    const track = trackGetter().find((t) => t.id === trackId);
    const desc = track?.sourceDescription();

    const count = desyncs.length;
    let totalMs = 0;
    let foundStartMs = -1;
    let foundEndMs = -1;
    for (const d of desyncs) {
      const [, , startMs, endMs, expectedLocalTimeMs, actualLocalTimeMs] = d;
      foundStartMs = startMs;
      foundEndMs = endMs;
      totalMs += Math.abs(actualLocalTimeMs - expectedLocalTimeMs);
    }

    if (count > 0 && totalMs > 0) {
      log.info(
        `desync-summary ${trackId} startMs:${fixed4(
          foundStartMs
        )}, endMs:${fixed4(foundEndMs)}, corrections:${count}, totalMs:${fixed4(
          totalMs
        )}ms`,
        {
          desc,
        }
      );
    }

    trackDesyncInfo.delete(trackId);
  });
}
