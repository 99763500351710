import pluralize from 'pluralize';
import { useCallback } from 'react';
import { useEffectOnce } from 'react-use';
import { match, P } from 'ts-pattern';
import { proxy } from 'valtio';
import { devtools, watch } from 'valtio/utils';

import { ProfileIndex } from '@lp-lib/crowd-frames-schema';
import { BlockType } from '@lp-lib/game';

import { type usePostGameAnalytics } from '../../analytics/postGame';
import { useOnDGameAnalytics } from '../../analytics/useOndGameAnalytics';
import { useVenueAnalytics } from '../../analytics/venue';
import {
  getFeatureQueryParam,
  getFeatureQueryParamArray,
} from '../../hooks/useFeatureQueryParam';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import { type useLoadGame } from '../../hooks/useLoadGame';
import logger, { safeWindowReload } from '../../logger/logger';
import { type Participant, SessionMode } from '../../types';
import { type GamePack } from '../../types/game';
import {
  AbortableRunner,
  YieldableAbortableRunner,
} from '../../utils/AbortSignalableRunner';
import { Chan } from '../../utils/Chan';
import { assertDefinedFatal } from '../../utils/common';
import { throws } from '../../utils/throws';
import { markSnapshottable, ValtioUtils } from '../../utils/valtio';
import { CopyVenueLinkBanner } from '../common/CopyVenueLink';
import {
  ConfirmCancelModalHeading,
  ConfirmCancelModalText,
  type useAwaitFullScreenConfirmCancelModal,
} from '../ConfirmCancelModalContext';
import { ModalWrapper } from '../ConfirmCancelModalContext/ModalWrapper';
import { CrowdFramesAvatar } from '../CrowdFrames';
import {
  type GameLibraryType,
  type useCloseGameLibrary,
  type useOpenGameLibrary,
} from '../Game/GameLibrary';
import { GamePlayStore } from '../Game/GamePlayStore';
import {
  type useGameSessionActionsSignalManager,
  type useGameSessionBlockId,
  type useOndGameState,
  type useOndPreparedContextReady,
} from '../Game/hooks';
import {
  CommandQueueFullError,
  CommandTimeoutError,
  type useOnDGameControllerSemaphore,
} from '../Game/OndGameControl';
import { type useOndWrappedControlAPI } from '../Game/OndGameControl/hooks';
import { type OndGameCommand } from '../Game/OndGameControl/types';
import { configureResumePlaybackItemId } from '../Game/OndPhaseRunner/OndPhaseRunner';
import {
  createPlaybackEtagIsh,
  type OndPlaybackGenConfigGamePack2,
  type PlaybackDesc,
  type PlaybackEtagIsh,
  type PlaybackItemId,
  stillValidPlaybackEtagIsh,
} from '../Game/Playback/intoPlayback';
import { PlayerRangeUtils } from '../Game/PlayerRangeUtils';
import { type usePostGameControlAPI } from '../Game/PostGame/Provider';
import { type usePreGameControlAPI } from '../Game/PreGame/Provider';
import { isOndGameCoordinatable } from '../Game/store';
import { setOndPreparedPlayback } from '../Game/store/gameSessionActions/ond-state-writers';
import {
  glAppend,
  type useRequestGameLogSessionSync,
} from '../GameLog/GameLogComponents';
import {
  type useApplyControllerWithErrorHandler,
  type useOnDGameCommandDispatcher,
} from '../OnDGameHosting';
import { type useTryReleaseController } from '../OnDGameHosting/OnDGameHostingManager';
import {
  useNumSeatOccupyingParticipants,
  type useNumSeatOccupyingParticipantsGetter,
  useSeatOccupyingParticipants,
} from '../Player';
import { useTriggerBookNowWithGameSessionGamePack } from '../Product/BookNow';
import { considerVenueCapAsDemo } from '../Product/FeatureChecker';
import { useRemoveFromVenue, type useTeamsGetter } from '../TeamAPI/TeamV1';
import { type useOndTeamRandomizerAPI } from '../TeamRandomizer/Context';
import { type useTownhallAPI } from '../Townhall';
import {
  useMyClientId,
  type useVenueDerivedSettings,
  useVenueId,
} from '../Venue';
import { type useIsCoreChannelJoined } from '../WebRTC';
import { PreLaunchChecklist } from './PreLaunchChecklist';
import { TransferCloudHostModal } from './TransferCloudHostModal';

export class GamePackLoader {
  private action: AbortableRunner<
    { pack: GamePack; playback: PlaybackDesc } | null | undefined,
    undefined
  > | null = new AbortableRunner(async () => null);
  private actionEtag: PlaybackEtagIsh | null = null;

  constructor(private log = logger.scoped('ond-game-ui-control-gp-loader')) {
    // Initialize to await-able no-op
    this.action?.start();
  }

  async peek() {
    try {
      const result = await this.action?.finished;
      return result;
    } catch (err) {
      // Something else is likely handling the error, we just want to ignore it
      // when "peeking".
      return null;
    }
  }

  async load(
    id: GamePack['id'],
    playbackConfig: Nullable<OndPlaybackGenConfigGamePack2>,
    usePlayHistory = getFeatureQueryParamArray(
      'game-on-demand-use-play-history'
    ),
    forPlayTest = getFeatureQueryParamArray('game-on-demand-play-test') !==
      'disabled'
  ) {
    const log = this.log;

    if (usePlayHistory === 'disabled' && playbackConfig) {
      log.warn(
        `usePlayHistory: ${usePlayHistory}. playHistoryTargetId is being cleared!`
      );
      playbackConfig.playHistoryTargetId = '';
    }

    if (forPlayTest && playbackConfig) {
      playbackConfig.startUnitIndex = 0;
      playbackConfig.requestedUnitCount = Number.MAX_SAFE_INTEGER;
    }

    const nextEtag = createPlaybackEtagIsh(id, playbackConfig);

    if (
      this.actionEtag &&
      stillValidPlaybackEtagIsh(this.actionEtag, nextEtag)
    ) {
      log.info(`not loading ${id}. etagishs match and not expired`, {
        actionEtag: this.actionEtag,
        nextEtag,
      });
      return await this.action?.finished;
    }

    await this.action?.destroy();
    this.actionEtag = nextEtag;
    this.action = YieldableAbortableRunner(async function* () {
      const store = new GamePlayStore(null);

      yield store.load({ pack: id, playbackConfig });
      const playback = store.getResolvedPlayback(SessionMode.OnDemand);
      if (!playback) return null;

      const gameLike = ValtioUtils.detachCopy(store.getLoadedGameLike());
      if (gameLike?.type !== 'gamePack') return null;

      return {
        pack: gameLike,
        playback: ValtioUtils.detachCopy(playback),
      };
    });
    return await this.action.start();
  }

  /**
   * `load` can be called any number of times. This waits until `load` has _not_
   * been called while another load was in progress.
   */
  async read() {
    let target;
    while (this.action !== target) {
      target = this.action;
      // Make sure this promise clears before we check again, and to prevent an
      // infinite loop.
      await this.action?.finished;
    }

    return await this.action?.finished;
  }
}

type TrackedDependencies = {
  packId: GamePack['id'] | null;
  ondState: ReturnType<typeof useOndGameState>;
  ondPreparedContextReady: ReturnType<typeof useOndPreparedContextReady>;
  coreChannelJoined: ReturnType<typeof useIsCoreChannelJoined>;
  coordinatorUid: Nullable<string>;
  derivedVenueSettings: ReturnType<typeof useVenueDerivedSettings>;
};

type UntrackedDependencies = {
  signalman: ReturnType<typeof useGameSessionActionsSignalManager>;

  blockId: ReturnType<typeof useGameSessionBlockId>;
  getPlayerCount: ReturnType<typeof useNumSeatOccupyingParticipantsGetter>;

  wrappedOndGameCtrl: ReturnType<typeof useOndWrappedControlAPI>;
  ondGameCommandDispatcher: ReturnType<typeof useOnDGameCommandDispatcher>;
  tryReleaseController: ReturnType<typeof useTryReleaseController>;

  triggerFullScreenModal: ReturnType<
    typeof useAwaitFullScreenConfirmCancelModal
  >;

  openGameLibrary: ReturnType<typeof useOpenGameLibrary>;
  closeGameLibrary: ReturnType<typeof useCloseGameLibrary>;

  requestGameLogSessionSync: ReturnType<typeof useRequestGameLogSessionSync>;

  applyController: ReturnType<typeof useApplyControllerWithErrorHandler>;
  controllerSemaphore: ReturnType<typeof useOnDGameControllerSemaphore>;
  loadGame: ReturnType<typeof useLoadGame>;
  postGameControlAPI: ReturnType<typeof usePostGameControlAPI>;
  preGameControlAPI: ReturnType<typeof usePreGameControlAPI>;
  randomizerAPI: ReturnType<typeof useOndTeamRandomizerAPI>;
  postGameAnalytics: ReturnType<typeof usePostGameAnalytics>;
  townhallAPI: ReturnType<typeof useTownhallAPI>;
  getTeams: ReturnType<typeof useTeamsGetter>;

  gameLibraryType?: GameLibraryType;
};

type Dependencies = TrackedDependencies & UntrackedDependencies;

type ExternalState = {
  resetDisabled: boolean;
  readonly actionDisabled: boolean;
  pausingDisabled: boolean;
  showReRandomizeButton: boolean;

  commandChannelError: null | Error;
  gamePackLoadError: null | Error;
};

export function initialExternalState(): ExternalState {
  return {
    resetDisabled: false,
    actionDisabled: true,
    pausingDisabled: false,
    showReRandomizeButton: false,
    commandChannelError: null,
    gamePackLoadError: null,
  };
}

type InternalState = {
  booting: boolean;
  isHardResetting: boolean;
  isSoftResetting: boolean;
  applyingController: boolean;
  commandChannelLoading: boolean;
  gamePackLoading: boolean;
  pack: GamePack | null;
};

function initialInternalState(): InternalState {
  return {
    booting: false,
    isHardResetting: false,
    isSoftResetting: false,
    applyingController: false,
    commandChannelLoading: false,
    gamePackLoading: false,
    pack: null,
  };
}

type State = {
  internal: InternalState;
  external: ExternalState;
  trackedDeps: Partial<TrackedDependencies>;
};

const AbortWithNoReset = 'AbortWithNoReset' as const;
const AbortWithHardReset = 'AbortWithHardReset' as const;
const AbortWithSoftReset = 'AbortWithSoftReset' as const;
const Completed = 'Completed' as const;

// These types help add nice logging when aborting or finishing the process of
// starting a game.

type RunnerReturns = {
  name:
    | typeof AbortWithHardReset
    | typeof AbortWithSoftReset
    | typeof AbortWithNoReset
    | typeof Completed;
  desc: string;
  meta?: Record<string, unknown>;
};
type RunnerDestroys = {
  name:
    | typeof AbortWithHardReset
    | typeof AbortWithSoftReset
    | typeof AbortWithNoReset;
  desc: string;
  info?: Record<string, unknown>;
};

export class OndGameUIControl {
  private markDepsReceivedAtLeastOnce: (() => void) | null = null;
  private receivedDepsAtLeastOnce = new Promise<void>((resolve) => {
    this.markDepsReceivedAtLeastOnce = resolve;
  });

  private _state: State;
  private deps: UntrackedDependencies | null;

  private contextPreparedChan = new Chan<true>();
  private everyoneReadyChan = new Chan<{ skipHostedTutorial: boolean }>();

  private runnerCtrl: AbortableRunner<
    // Return Values, which must include Destroy Values since they are also,
    // potentially, returned
    RunnerReturns | RunnerDestroys | undefined,
    // Destroy Values
    RunnerDestroys
  > | null = null;

  private gpLoader = new GamePackLoader();
  private isRecoveringFromPreparing = false;

  private undevtools;

  constructor(private log = logger.scoped('ond-game-ui-control')) {
    this._state = proxy<State>({
      internal: proxy(initialInternalState()),
      external: proxy(initialExternalState()),
      trackedDeps: proxy({}),
    });
    this.deps = null;

    watch((get) => {
      const trackedDeps = get(this._state.trackedDeps);
      const internal = get(this._state.internal);

      (this._state.external.actionDisabled as boolean) =
        !isOndGameCoordinatable(trackedDeps?.ondState ?? null) ||
        internal.commandChannelLoading ||
        !trackedDeps.coreChannelJoined ||
        internal.applyingController ||
        // NOTE(drew):these can cause the button to flicker if these states
        // transition quickly, such as no need for a context or the gamePack is
        // being reloaded.
        (trackedDeps.ondState === 'preparing' &&
          !trackedDeps.ondPreparedContextReady) ||
        !trackedDeps.packId ||
        internal.gamePackLoading ||
        internal.booting ||
        internal.isSoftResetting ||
        internal.isHardResetting ||
        trackedDeps.derivedVenueSettings === undefined;
    });

    this.undevtools = devtools(this._state, { name: 'OndGameUIControl' });
  }

  async destroy() {
    await this.doSoftReset('destroy');
    this.undevtools?.();
  }

  hasRunner(): boolean {
    return Boolean(this.runnerCtrl) && !this.runnerCtrl?.aborted;
  }

  async syncHookedDeps(deps: Dependencies) {
    const {
      ondState,
      coreChannelJoined,
      ondPreparedContextReady,
      packId,
      coordinatorUid,
      derivedVenueSettings,
      ...untrackedDeps
    } = deps;

    this.deps = untrackedDeps;

    const trackedDeps: TrackedDependencies = {
      packId,
      ondState,
      coreChannelJoined,
      ondPreparedContextReady,
      coordinatorUid: coordinatorUid,
      derivedVenueSettings,
    };
    Object.assign(this._state.trackedDeps, trackedDeps);

    if (deps.ondPreparedContextReady) {
      this.contextPreparedChan.put(true);
    }

    // note(falcon): this used to be set during the prolonged randomization steps. since randomization is now
    // "instant", this is set to false. keeping this here in case we need it in the future.
    this._state.external.resetDisabled = false;

    // Pausing is not allowed unless there is an active block. This prevents
    // pausing during the intro video.
    this._state.external.pausingDisabled = !deps.blockId;

    this.markDepsReceivedAtLeastOnce?.();
  }

  get state() {
    return markSnapshottable(this._state.external);
  }

  private async dispatchCC(command: OndGameCommand) {
    assertDefinedFatal(this.deps, 'dispatchControllerCommand');
    try {
      this._state.external.commandChannelError = null;
      this._state.internal.commandChannelLoading = true;
      // Ensure we still have a controller. Otherwise, the command channel will
      // be clogged without a controller to consume it.
      await this.deps.controllerSemaphore.ensureInstance({
        getTimeoutMs: 500,
      });
      await this.deps.ondGameCommandDispatcher.dispatch({
        command,
      });
      return null;
    } catch (err) {
      this._state.external.commandChannelError = match(err)
        // If we want to do something specific if the controller cannot be
        // found, this is how:
        // - .with(P.instanceOf(TimeoutError), (err) => err)
        .with(P.instanceOf(Error), (err) => err)
        .otherwise(() => new Error('Unknown Hosting Error'));
      this.log.error(
        `failed to dispatch controller command: ${command}`,
        this._state.external.commandChannelError
      );
      return this._state.external.commandChannelError;
    } finally {
      this._state.internal.commandChannelLoading = false;
    }
  }

  /**
   * Reset only the internal state of this class.
   */
  private async doSoftReset(
    reason: string,
    extra?: RunnerReturns | RunnerDestroys | undefined
  ) {
    const ogHard = this._state.internal.isHardResetting;
    try {
      if (this._state.internal.isSoftResetting) return;
      this._state.internal.isSoftResetting = true;
      this.log.info(`soft reset: start. ${reason}`, { reason: extra });
      await this.runnerCtrl?.destroy({
        name: AbortWithNoReset,
        desc: 'soft reset',
      });
      this.contextPreparedChan.clear();
      this.everyoneReadyChan.clear();
      ValtioUtils.reset(this._state.external, initialExternalState());
      ValtioUtils.reset(this._state.internal, initialInternalState());
      // Special case: we know that softReset can be called from hardReset, and
      // that each cannot be independently concurrent. So if we're
      // hardResetting, retain the flag even after reverting internal state to
      // initialState().
      this._state.internal.isHardResetting = ogHard;
      this._state.internal.isSoftResetting = true; // unnecessary but just for clarity
      this.log.info('soft reset: end');
    } finally {
      this._state.internal.isSoftResetting = false;
    }
  }

  /**
   * Reset the internal state as well as the possible cloud/game state via
   * the core `reset()` and `ondReset()`.
   */
  private async doHardReset(
    reason: string,
    extra?: RunnerReturns | RunnerDestroys | undefined
  ) {
    try {
      if (this._state.internal.isHardResetting) return;
      this._state.internal.isHardResetting = true;

      this.log.info(`hard reset: start. ${reason}`, { reason: extra });
      await this.receivedDepsAtLeastOnce;
      assertDefinedFatal(this.deps);

      await this.doSoftReset(reason, extra);

      // Only destroy on a hard reset because there is no need to reload the
      // gamepack once it starts.
      await this.gpLoader.read();
      this.gpLoader = new GamePackLoader();

      // Note(jialin): Ensure the resetGame available with best effort of checking
      // if the controller instance exists. If it exists, we send the command to
      // the controller. This could fail if it's no longer alive (heartbeat
      // timeout). If it does not exist, we reset the game locally. Since the game
      // can only be reset by controller, we add force flag here to bypass the
      // check.

      const controller = await this.deps.controllerSemaphore.instance();
      if (!controller) {
        this.deps.wrappedOndGameCtrl.reset({
          force: true,
          retainGamePack: true,
        });
      } else {
        // reset() handles its own signal firing, which will only happen on the
        // controller. Ensure it also happens on the coordinator by wrapping
        // sendCommand here.
        await this.deps.signalman.fire('reset', 'before');
        await this.dispatchCC('resetGame');
        if (
          this._state.external.commandChannelError &&
          (this._state.external.commandChannelError instanceof
            CommandQueueFullError ||
            this._state.external.commandChannelError instanceof
              CommandTimeoutError)
        ) {
          // Something has gone horribly wrong, such as the coordinator thinking
          // it still has a controller, but the controller has reset its consumer
          // and somehow left the command queue full. Since we can't get the reset
          // command to the controller, try to release the controller, and do a
          // local reset and hope the cloud host follows suit. This has typically
          // only been needed in dev when a controller is cleaned up.
          await this.deps.tryReleaseController();
          await this.deps.wrappedOndGameCtrl.reset({
            force: true,
            retainGamePack: true,
          });
        }
        await this.deps.signalman.fire('reset', 'after');
      }
      this.log.info('hard reset: end');
    } finally {
      this._state.internal.isHardResetting = false;
    }
  }

  onNeedsRecoveryFromPreparing = async (isCoordinator: boolean) => {
    if (this.isRecoveringFromPreparing) return;
    try {
      this.isRecoveringFromPreparing = true;
      if (isCoordinator) {
        // You may have just become the coordinator, so do a full reset to start
        // from a known good point.
        await this.doHardReset('RecoveryFromPreparingAsCoordintor');
      } else {
        // You may have been the coordinator, but you lost control. So reset
        // internals but assume the next coordinator will perform a full reset
        // if necessary.
        await this.doSoftReset('RecoveryFromPreparingAsNonCoordinator');
      }
    } finally {
      this.isRecoveringFromPreparing = false;
    }
  };

  onWillOverrideCurrentActiveGame = async () => {
    await this.doHardReset('WillOverrideCurrentActiveGame');
  };

  onClickPauseResume = async (
    expectedNextCommand: 'pauseGame' | 'resumeGame',
    needCoordinate = true
  ) => {
    assertDefinedFatal(this.deps);
    if (needCoordinate) {
      if (!(await this.deps.applyController())) {
        this.log.info(
          [
            `user requested coordinate/applyController with`,
            `[${expectedNextCommand}] but coordination failed`,
          ].join(' ')
        );
        return;
      }
    }

    await this.dispatchCC(expectedNextCommand);
  };

  onClickStartGamePlay = async (
    opts: Partial<{
      skipHostedTutorial: boolean;
      useAnimatedRandomization: boolean;
    }> = {
      skipHostedTutorial: false,
      useAnimatedRandomization: false,
    }
  ) => {
    if (this._state.internal.booting) {
      this.log.info('onClickStartGamePlay, but already booting');
      return;
    }

    // NOTE: this is set to `false` by `hardReset`->`softReset` so it's not
    // necessary to explicitly revert the value or wrap in try/catch.
    this._state.internal.booting = true;
    this.log.info('onClickStartGamePlay');
    glAppend('ond-user-clicked-start-game', {});

    const getPlayerCount = () => {
      assertDefinedFatal(this.deps);
      return this.deps.getPlayerCount();
    };

    // Note: For this runner, any promise you want to `await`, instead `yield`.
    // The runner will not continue beyond the promise if the runner is canceled
    // during the async operation.
    const runner: OndGameUIControl['runnerCtrl'] = YieldableAbortableRunner(
      async function* (this: OndGameUIControl) {
        assertDefinedFatal(this.deps);

        // Hopefully, something else has attempted to load a gamepack (and
        // playbackConfig) before we get here. But if not, fallback to the
        // currently loaded packId and coordinator (playHistoryTargetId).
        const preselected = await this.gpLoader.read();

        let results: Nullable<{
          pack: GamePack;
          playback: PlaybackDesc;
        }>;

        if (
          preselected &&
          Boolean(preselected.playback.genConfig.v2?.skipHostedTutorial) ===
            Boolean(opts.skipHostedTutorial)
        ) {
          this.log.info('using preselected loaded gamepack', {
            name: preselected.pack.name,
            packId: preselected.pack.id,
          });
          results = preselected;
        } else {
          const { coordinatorUid, packId } = this._state.trackedDeps;
          if (!coordinatorUid)
            return {
              name: AbortWithHardReset,
              desc: 'No coordinatorUid',
            };
          if (!packId)
            return { name: AbortWithHardReset, desc: 'Missing PackId' };
          this.log.info('loading external gamepack', { packId });
          results = await this.gpLoader.load(packId, {
            playHistoryTargetId: coordinatorUid,
            subscriberId: coordinatorUid,
            skipHostedTutorial: opts.skipHostedTutorial,
          });
        }

        if (!results || !results?.playback || !results.playback.items.length)
          return {
            name: AbortWithHardReset,
            desc: 'No pack or playable items!',
          };

        const derivedVenueSettings =
          this._state.trackedDeps.derivedVenueSettings;
        if (!derivedVenueSettings) {
          return {
            name: AbortWithHardReset,
            desc: 'Missing derivedVenueSettings',
          };
        }

        const pack = results.pack;

        this.log.info('using gploader result', { ...results });

        // Now we have confirmed this is the pack we are going to play, expose
        // it for the randomizer settings..
        this._state.internal.pack = pack;

        const skipChecklist = getFeatureQueryParam(
          'game-on-demand-skip-checklist'
        );
        const playerCount = getPlayerCount();
        const inRange = PlayerRangeUtils.InRange(
          results.pack.playerRange,
          playerCount
        );

        const venueSeatCap = derivedVenueSettings.seatCap ?? null;
        if (venueSeatCap !== null && playerCount > venueSeatCap) {
          const confirmCancel = await this.deps.triggerFullScreenModal({
            kind: 'custom',
            element: (p) => (
              <SeatCapConfirmModal
                seatCap={venueSeatCap}
                onCancel={p.internalOnCancel}
                onConfirm={p.internalOnConfirm}
              />
            ),
          });
          if (confirmCancel.result === 'canceled')
            return {
              name: AbortWithSoftReset,
              desc: 'user cancel: seat cap prompt',
            };
        }

        const skipBecauseOfCap = considerVenueCapAsDemo(venueSeatCap);

        if (!skipChecklist && !skipBecauseOfCap && inRange !== 0) {
          const confirmCancel = await this.deps.triggerFullScreenModal({
            kind: 'custom',
            element: (p) => (
              <PlayerRangeConfirmModal
                pack={pack}
                playerCount={playerCount}
                onCancel={p.internalOnCancel}
                onConfirm={p.internalOnConfirm}
              />
            ),
          });
          if (confirmCancel.result === 'canceled')
            return {
              name: AbortWithSoftReset,
              desc: 'user cancel: team size prompt',
            };
        }

        const checklistResult = await this.deps?.triggerFullScreenModal({
          kind: 'custom',
          element: (p) => {
            assertDefinedFatal(results, 'checklist playback result');
            return (
              <PreLaunchChecklist
                blocks={results.playback.items.map((item) => item.block)}
                recordingSeconds={pack.approximateDurationSeconds}
                onComplete={p.internalOnConfirm}
                onClose={p.internalOnCancel}
                skip={skipChecklist}
              />
            );
          },
        });

        if (checklistResult.result === 'canceled')
          return {
            name: AbortWithSoftReset,
            desc: 'user cancel: pre launch checklist',
          };

        const randomizeConfig =
          this.deps.randomizerAPI.selectRecommendedRandomizerSettings(
            pack.teamRandomizationSettings
          );

        // TODO(falcon): where should this live really?
        const hasTeamIntroEnabled = results.playback.items.some(
          (item) =>
            item.block.type === BlockType.TITLE_V2 &&
            (item.block.fields.cards ?? []).some((c) => c.teamIntroEnabled)
        );

        // when team size is 0, it means we don't need to randomize teams, and therefore we should hide the button.
        this._state.external.showReRandomizeButton =
          randomizeConfig.teamSize !== 0 &&
          !hasTeamIntroEnabled &&
          !pack.teamRandomizationSettings?.oneTeam;

        if (pack.cohostSettings?.enabled && opts.useAnimatedRandomization) {
          // Use animiated team randomization for cohosted games. The cohosted
          // game should be unlikely to have single player teams, so it doesn't
          // support _preferSinglePlayerTeams_ for now.
          yield this.deps.preGameControlAPI.bootstrap();
          if (pack.teamRandomizationSettings?.oneTeam) {
            yield this.deps.randomizerAPI.animatedRandomize(
              9999,
              Number.POSITIVE_INFINITY
            );
          } else {
            yield this.deps.randomizerAPI.animatedRandomize(
              randomizeConfig.teamSize,
              randomizeConfig.maxTeamSize
            );
          }
        } else {
          if (pack.teamRandomizationSettings?.oneTeam) {
            yield this.deps.randomizerAPI.instantRandomize(
              9999,
              Number.POSITIVE_INFINITY,
              false
            );
          } else {
            yield this.deps.randomizerAPI.instantRandomize(
              randomizeConfig.teamSize,
              randomizeConfig.maxTeamSize,
              // if we want single player teams, then use the player name for the team.
              derivedVenueSettings.preferSinglePlayerTeams
            );
          }
        }

        const townhallForceMode = match(
          this.deps.townhallAPI.deps.getForceModeFeatureFlag()
        )
          .with('auto', () => {
            if (pack.teamRandomizationSettings?.oneTeam) {
              return 'crowd';
            }
            // force townhall mode if it's single player mode
            // (all teams have only one player)
            const teams =
              this.deps?.getTeams({
                excludeStaffTeam: true,
              }) ?? [];
            const forceCrowdMode =
              teams.length > 0 && teams.every((t) => t.membersCount === 1);
            this.log.info('force crowd mode', {
              teams,
              enabled: forceCrowdMode,
            });
            return forceCrowdMode ? 'crowd' : undefined;
          })
          .otherwise((v) => v);
        if (townhallForceMode) {
          yield this.deps.townhallAPI.setForceMode(townhallForceMode);
        } else {
          yield this.deps.townhallAPI.disableForceMode();
        }

        // NOTE: if the user clicks "Cancel" on the pregame instruction block
        // after already finishing a game, they will return to the Lobby, NOT
        // the post-game screen, because we have already reset/dismissed
        // postGame in order to show preGame.

        const maybePreGameInstructions = results.playback.preGame?.instructions;
        yield this.deps.preGameControlAPI.present({
          brandName: maybePreGameInstructions?.brand?.name,
          blockId: maybePreGameInstructions?.block?.id,
          hasHostedTutorial: Boolean(maybePreGameInstructions?.hostedTutorial),
        });
        yield this.deps.postGameControlAPI.reset();

        // We have already resolved playback, so use the existing playback when
        // loading instead of recomputing!
        yield this.deps.loadGame({ playback: results.playback });

        {
          this._state.internal.applyingController = true;
          const res = unwrapAC(yield this.deps.applyController());
          if (res) return res;
          this._state.internal.applyingController = false;
        }

        this.log.info('acquired controller');

        const pending_preparedContextIsReady = this.contextPreparedChan.take();
        const pending_everyoneIsReady = this.everyoneReadyChan.take();
        yield setOndPreparedPlayback(results.playback);

        // TODO(drew gp2): should this return a user-facing error modal? What
        // should it even say? What action would be appropriate?
        {
          const res = unwrapCC(yield this.dispatchCC('prepareGame'));
          if (res) return res;
        }

        this.log.info('waiting for everyone ready and prepared context');

        // NOTE: the prepared context must finish, which unlocks the button,
        // which allows everyoneIsReady to have a value. `booting` is
        // temporarily reset to allow the button to unlock temporarily.
        this._state.internal.booting = false;
        yield Promise.all([
          pending_everyoneIsReady,
          pending_preparedContextIsReady,
        ]);
        this._state.internal.booting = true;

        this.log.info('everyone and prepared context ready');

        const everyoneIsReady = await pending_everyoneIsReady;
        await glAppend('ond-user-clicked-everybodys-ready', {});
        const skipHostedTutorial = everyoneIsReady?.skipHostedTutorial ?? true;
        if (skipHostedTutorial) {
          const res = unwrapCC(
            yield this.dispatchCC('skipPreGameHostedTutorial')
          );
          if (res) return res;
        }

        yield this.deps.preGameControlAPI.unpresent();

        {
          const res = unwrapCC(yield this.dispatchCC('startGame'));
          if (res) return res;
        }

        return {
          name: Completed,
          desc: 'handed off control to controller',
        };
      }.bind(this)
    );

    this.log.info('defined next runner');

    try {
      await this.receivedDepsAtLeastOnce;
      assertDefinedFatal(this.deps);

      if (this.runnerCtrl)
        await this.runnerCtrl.destroy({
          name: AbortWithNoReset,
          desc: 'runner exists during onClickStartGamePlay',
        });

      this.runnerCtrl = runner;

      this.log.info('runner starting');
      const reason = await this.runnerCtrl.start();
      this.log.info('runner finished', { ...reason });
      await match(reason)
        // This is mainly used to avoid a reset cycle:
        // - reset() -> destroy() -> reset().
        .with({ name: AbortWithNoReset }, async () => void 0)
        .with({ name: AbortWithHardReset }, async (reason) =>
          this.doHardReset(AbortWithHardReset, reason)
        )
        .with(
          { name: AbortWithSoftReset },
          { name: Completed },
          undefined,
          async (reason) => await this.doSoftReset(reason?.name ?? '', reason)
        )
        .exhaustive();
    } catch (err) {
      // The runner died due to an uncaught Error/rejection.
      this.log.error('runner died', err);
      await this.doHardReset('RunnerDied');
    }

    return;
  };

  onClickPostGameEndSession = async () => {
    // Close library in case it is open and the user decides to instead End
    // Session.
    await this.receivedDepsAtLeastOnce;
    assertDefinedFatal(this.deps);
    this.deps?.closeGameLibrary();
    await glAppend('ond-user-clicked-end-session', {});
    // await this.deps.requestGameLogSessionSync();
    await this.doHardReset('ClickedEndSession');
  };

  onClickReset = async (force = false) => {
    await this.receivedDepsAtLeastOnce;
    assertDefinedFatal(this.deps);
    if (!force) {
      const response = await this.deps.triggerFullScreenModal({
        kind: 'confirm-cancel',
        prompt: (
          <ConfirmCancelModalHeading>
            Are you sure you want to reset the Game?
          </ConfirmCancelModalHeading>
        ),
        confirmBtnLabel: 'Confirm',
        cancelBtnLabel: 'Cancel',
      });

      if (response.result !== 'confirmed') return;
    }
    await this.doHardReset('ClickedConfirmedReset');
  };

  onClickTransferCloudHost = async () => {
    await this.receivedDepsAtLeastOnce;
    assertDefinedFatal(this.deps);

    {
      glAppend('ond-user-clicked-cloud-host-transfer', {});
      this.log.info('cloud-host-xfer: user request');

      const response = await this.deps.triggerFullScreenModal({
        kind: 'confirm-cancel',
        prompt: (
          <>
            <ConfirmCancelModalHeading>Game frozen?</ConfirmCancelModalHeading>
            <ConfirmCancelModalText>
              Don't sweat it! We've got a trick up our sleeve to get things
              moving again. Only try this if you've already turned off your VPN,
              refreshed your browser, and it's still not working.
            </ConfirmCancelModalText>
          </>
        ),
        confirmBtnLabel: 'Confirm',
        cancelBtnLabel: 'Cancel',
      });

      if (response.result !== 'confirmed') {
        this.log.info('cloud-host-xfer: user canceled');
        return;
      }

      glAppend('ond-user-clicked-confirm-cloud-host-transfer', {});
      this.log.info('cloud-host-xfer: user confirmed');
    }
    {
      const response = await this.deps.triggerFullScreenModal({
        kind: 'custom',
        element: (p) => (
          <TransferCloudHostModal
            exec={async () => {
              if (!this.deps) return p.internalOnCancel();
              this.log.info('cloud-host-xfer: attempting controller release');

              if (!this.state.pausingDisabled) {
                await this.onClickPauseResume('pauseGame', false);
              }

              await this.deps.tryReleaseController(true);

              this.log.info('cloud-host-xfer: applying new controller');
              this._state.internal.applyingController = true;
              const res = await this.deps.applyController();
              this._state.internal.applyingController = false;

              if (!res) p.internalOnCancel();
              else {
                p.internalOnConfirm();
                if (!this.state.pausingDisabled) {
                  await this.onClickPauseResume('resumeGame', false);
                }
              }
            }}
          />
        ),
      });

      if (response.result === 'confirmed') {
        this.log.info('cloud-host-xfer: apply new controller completed');
      } else {
        this.log.info(
          'cloud-host-xfer: apply new controller failed, notifying user'
        );

        const response = await this.deps.triggerFullScreenModal({
          kind: 'confirm-cancel',
          prompt: (
            <ConfirmCancelModalText>
              Could not acquire remote game server. Please refresh and try
              again.
            </ConfirmCancelModalText>
          ),
          confirmBtnLabel: 'Refresh',
          cancelBtnLabel: 'Cancel',
        });

        if (response.result === 'confirmed') {
          await safeWindowReload();
        }

        return;
      }
    }
  };

  onClickGameExplore = async () => {
    this.deps?.openGameLibrary({
      type: this.deps.gameLibraryType ?? 'bottomPublicLibrary',
      canLoad: async () => {
        if (this.deps && this._state?.trackedDeps?.ondState) {
          const response = await this.deps.triggerFullScreenModal({
            kind: 'confirm-cancel',
            prompt: (
              <ConfirmCancelModalText>
                The running game will be reset, do you want to continue?
              </ConfirmCancelModalText>
            ),
            confirmBtnLabel: 'Continue',
            cancelBtnLabel: 'Cancel',
          });

          if (response.result !== 'confirmed') return false;
          return true;
        }
        return true;
      },
      onGamePackClick: async (pack) => {
        assertDefinedFatal(this.deps);
        const { coordinatorUid } = this._state.trackedDeps;
        assertDefinedFatal(coordinatorUid, 'coordinatorUid');

        // NOTE(drew): unfortunately there is no feedback to the user that we
        // are loading :/, especially when reset() or loadGame() takes a while.

        // Immediately close the library to prevent concurrent clicks.
        this.deps.closeGameLibrary();

        // We have to manually load the game so it appears in the Lobby. We know
        // this is safe because we just hardReset, so the user experience will
        // be flickering regardless.
        await this.doHardReset('ClickedGamePackFromExplore');
        await Promise.all([
          this.deps.loadGame(pack),
          this.onClickChooseNextGamePack(pack.id, {
            playHistoryTargetId: coordinatorUid,
            subscriberId: coordinatorUid,
          }),
        ]);

        return true;
      },
    });
  };

  /**
   * This "enqueues" the gamepack to be loaded and played by the system at
   * playback start. It specifically does not call `loadGame`! If the game must
   * be immediately loaded by the system, use `loadGame` directly afterwards.
   */
  onClickChooseNextGamePack = async (
    id: string,
    info?: OndPlaybackGenConfigGamePack2 | null
  ) => {
    try {
      this._state.internal.gamePackLoading = true;
      await this.gpLoader.load(id, info);
    } catch (err) {
      this.log.error('failed to preload game pack', err, { gamePackId: id });
      this._state.external.gamePackLoadError = err as Error;
    } finally {
      this._state.internal.gamePackLoading = false;
    }
  };

  onClickChooseShadowGamePackLevel = async (gamePack: GamePack) => {
    assertDefinedFatal(this.deps);
    // Shadow game packs are V1 only?
    const { coordinatorUid } = this._state.trackedDeps;
    if (!coordinatorUid) {
      const err = new Error(
        'UICtrlError: No coordinatorUid for shadow pack playback context'
      );
      this.log.error(err.message, err);
      return;
    }
    // We have to manually load the game so it appears in the Lobby. We know
    // this overall action (shadow game pack level selection) can only
    // happen outside of the pre/post game.
    await Promise.all([
      this.deps.loadGame(gamePack),
      this.onClickChooseNextGamePack(gamePack.id, {
        playHistoryTargetId: coordinatorUid,
        subscriberId: coordinatorUid,
      }),
    ]);
  };

  onClickPostGameExploreAll = async () => {
    this.deps?.openGameLibrary({
      type: this.deps.gameLibraryType ?? 'bottomPublicLibrary',
      canLoad: async () => true,
      onGamePackClick: async (pack) => {
        assertDefinedFatal(this.deps);
        const { coordinatorUid } = this._state.trackedDeps;
        assertDefinedFatal(coordinatorUid, 'playHistoryTargetId');
        const swapped =
          await this.deps.postGameControlAPI.prependRecommendation(pack);

        if (swapped) {
          this.deps.postGameAnalytics.trackRecommendationSelected({
            gamePackId: pack.id,
            gamePackName: pack.name,
            gamePackType: pack.detailSettings?.gameType ?? null,
            source: 'library',
          });
          // NOTE(drew): we do not call loadGame or `hardReset()` here because
          // that could cause the system to reset, and we're still technically
          // "playing" the previous game. The start game process will manage
          // calling load game when necessary.
          await Promise.all([
            this.deps.postGameControlAPI.selectRecommendedPack(pack),
            this.onClickChooseNextGamePack(pack.id, {
              playHistoryTargetId: coordinatorUid,
              subscriberId: coordinatorUid,
            }),
          ]);
          this.deps.closeGameLibrary();
        } else {
          await this.deps.triggerFullScreenModal({
            kind: 'confirm-cancel',
            prompt: (
              <ConfirmCancelModalText>
                Error: could not prepare selected game, please try another.
              </ConfirmCancelModalText>
            ),
            confirmBtnLabel: 'Ok',
          });
          return false;
        }

        return true;
      },
    });
  };

  onClickReRandomizeTeams = async () => {
    await this.deps?.randomizerAPI.instantRandomizeWithSettings(
      this._state.internal.pack?.teamRandomizationSettings,
      this._state.trackedDeps.derivedVenueSettings?.preferSinglePlayerTeams
    );
  };

  onClickCancelPreGame = async () => {
    if (!this.runnerCtrl) {
      // No runner? must have refreshed. Just reset.
      await this.doHardReset('ClickCancelPreGameNoRunner');
      return;
    }
    await this.runnerCtrl.destroy({
      name: AbortWithHardReset,
      desc: 'user cancel: pregame cancel button',
    });
  };

  onClickEveryoneIsReady = async (skipHostedTutorial: boolean) => {
    this.log.info('onClickEveryoneIsReady');
    if (!this.runnerCtrl) {
      return await this.doHardReset('ClickEveryoneIsReadyPreGameNoRunner');
    }
    this.everyoneReadyChan.put({
      skipHostedTutorial,
    });
  };

  onClickPlayTestPlaybackItem = async (
    playbackItemId: PlaybackItemId,
    needCoordinate = true
  ) => {
    assertDefinedFatal(this.deps);
    if (needCoordinate) {
      if (!(await this.deps.applyController())) {
        this.log.info('coordination failed for playtest playback item');
        return;
      }
    }

    await configureResumePlaybackItemId(playbackItemId);
    await this.dispatchCC('pauseGame');
    await this.dispatchCC('resumeGame');
  };
}

function unwrapAC(yielded: unknown) {
  // yield types are hard in TS, so it's best to assert.
  const applied = match(yielded)
    .with(P.boolean, (v) => v)
    .otherwise(() => throws('UnexpectedYieldNextValue'));
  if (!applied)
    return {
      name: AbortWithHardReset,
      desc: 'Could not acquire controller',
    };
}

function unwrapCC(yielded: unknown) {
  return match(yielded)
    .with(
      P.instanceOf(CommandQueueFullError),
      P.instanceOf(CommandTimeoutError),
      P.instanceOf(Error),
      (err) => {
        return {
          name: AbortWithHardReset,
          desc: err.name,
          info: {
            ...err,
          },
        };
      }
    )
    .otherwise(() => void 0);
}

function PlayerRangeConfirmModal(props: {
  pack: GamePack;
  playerCount: number;
  onCancel: () => void;
  onConfirm: () => void;
}) {
  const venueId = useVenueId();
  const analytics = useVenueAnalytics();
  useEffectOnce(() => {
    analytics.trackPlayerRangeConfirmationModalViewed({
      venueId,
      playerCount: props.playerCount,
      minPlayers: props.pack.playerRange.min,
      maxPlayers: props.pack.playerRange.max,
    });
  });
  const handleConfirm = useLiveCallback(() => {
    analytics.trackPlayerRangeConfirmationModalConfirmed({ venueId });
    props.onConfirm();
  });
  const handleCancel = useLiveCallback(() => {
    analytics.trackPlayerRangeConfirmationModalDismissed({ venueId });
    props.onCancel();
  });
  return (
    <ModalWrapper containerClassName='w-160' borderStyle='gray'>
      <div className='w-full pt-8 pb-16 flex flex-col items-center'>
        <div className='text-2xl font-medium'>Are you sure?</div>
        <div className='mt-1.5 w-98 text-sms font-bold text-center'>
          This game requires {PlayerRangeUtils.Format(props.pack.playerRange)}.
          We suggest inviting some colleagues to join you to ensure a smooth
          experience!
        </div>
        <div className='mt-9 w-full'>
          <CopyVenueLinkBanner />
        </div>
        <div className='mt-13 flex justify-center items-center gap-3'>
          <button
            type='button'
            onClick={handleCancel}
            className='w-40 h-10 btn-secondary'
          >
            Cancel
          </button>
          <button
            type='button'
            onClick={handleConfirm}
            className='w-40 h-10 btn-primary'
          >
            Play Anyway
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
}

function RemovePlayer(props: { participant: Participant }) {
  const { participant } = props;
  const venueId = useVenueId();
  const myClientId = useMyClientId();
  const removeFromVenue = useRemoveFromVenue();

  const handleRemove = useCallback(async () => {
    if (!participant.teamId) return;
    await removeFromVenue(
      participant.clientId,
      participant.teamId,
      myClientId,
      {
        message: 'You were removed from the venue by the organizer.',
        redirectTo: `/venue/${venueId}/at-capacity`,
      }
    );
  }, [
    myClientId,
    participant.clientId,
    participant.teamId,
    removeFromVenue,
    venueId,
  ]);

  const you = myClientId === participant.clientId;
  return (
    <div className='flex items-center gap-3.5'>
      <div className='flex-none relative w-9 h-9 rounded-full bg-gray-300'>
        <CrowdFramesAvatar
          participant={participant}
          profileIndex={ProfileIndex.wh100x100fps8}
          enablePointerEvents={false}
        />
      </div>

      <div
        className={`flex-1 ${
          you ? 'text-tertiary' : 'text-white'
        } font-bold truncate`}
      >
        {you ? <>You</> : <>{participant.firstName ?? participant.username}</>}
      </div>

      <div className='flex-none'>
        <button
          type='button'
          className='h-7 px-2 btn btn-secondary text-xs font-bold'
          disabled={participant.clientId === myClientId}
          onClick={handleRemove}
        >
          Remove
        </button>
      </div>
    </div>
  );
}

function RemovePlayers() {
  const participants = useSeatOccupyingParticipants();
  return (
    <div className='grid grid-cols-2 gap-x-4 gap-y-2'>
      {participants.map((participant) => (
        <RemovePlayer key={participant.clientId} participant={participant} />
      ))}
    </div>
  );
}

function SeatCapConfirmModal(props: {
  seatCap: number;
  onCancel: () => void;
  onConfirm: () => void;
}) {
  const { seatCap } = props;
  const analytics = useOnDGameAnalytics();
  const numParticipants = useNumSeatOccupyingParticipants();
  const triggerBookNow = useTriggerBookNowWithGameSessionGamePack();
  const playerExcess = numParticipants - seatCap;

  const handleUpgradeNow = useLiveCallback(() => {
    triggerBookNow('too-many-people-modal');
  });

  useEffectOnce(() => {
    analytics.trackVenueAtCapacityModalViewed({
      seatCap,
      numParticipants,
    });
  });

  return (
    <ModalWrapper containerClassName='w-160' borderStyle='gray'>
      <div className='w-full flex flex-col items-center gap-4 py-10 px-7.5'>
        <div className='text-2xl font-medium'>Whoops! Too Many People!</div>
        <div className='text-sms font-medium text-center'>
          <p>You have too many people in your venue for this experience.</p>
          <p>
            Upgrade your account or remove players to continue the experience.
          </p>
        </div>
        <div>
          <button
            type='button'
            className='text-tertiary font-bold text-sms hover:underline'
            onClick={handleUpgradeNow}
          >
            Upgrade Account
          </button>
        </div>
        <div className='bg-main-layer rounded-md px-2 py-1 font-bold'>
          Using{' '}
          <span
            className={`${playerExcess > 0 ? 'text-red-006' : 'text-white'}`}
          >
            {numParticipants}
          </span>{' '}
          of {seatCap} {pluralize('seat', seatCap)}
        </div>
        <div className='h-[250px] w-full bg-main-layer border-secondary rounded-xl overflow-y-scroll scrollbar p-4'>
          <RemovePlayers />
        </div>

        <div className='mt-7.5 flex justify-center items-center gap-3'>
          <button
            type='button'
            onClick={props.onCancel}
            className='w-40 h-10 btn-secondary'
          >
            Cancel
          </button>
          <button
            type='button'
            onClick={props.onConfirm}
            className='w-40 h-10 btn-primary'
            disabled={playerExcess > 0}
          >
            Continue
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
}
