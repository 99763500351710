import React, {
  type ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Select from 'react-select';
import { useEffectOnce, usePromise } from 'react-use';

import { useVenueAnalytics } from '../../analytics/venue';
import logo from '../../assets/img/logo-text.png';
import { getFeatureQueryParam } from '../../hooks/useFeatureQueryParam';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import logger, { safeWindowReload } from '../../logger/logger';
import { useCanJoinGetter } from '../../pages/Audience/VenueCapacityCheck';
import { type ParticipantFull } from '../../types';
import { getStaticAssetPath } from '../../utils/assets';
import { type Profile, releaseMediaStream } from '../../utils/media';
import { Modal } from '../common/Modal';
import { useAwaitFullScreenConfirmCancelModal } from '../ConfirmCancelModalContext';
import { CameraIcon } from '../icons/CameraIcon';
import { CameraOffIcon } from '../icons/CameraOffIcon';
import { ErrorIcon } from '../icons/ErrorIcon';
import { GreenScreenIcon } from '../icons/GreenScreenIcon';
import { HostIcon } from '../icons/HostIcon';
import { MicrophoneIcon } from '../icons/MicrophoneIcon';
import { MicrophoneOffIcon } from '../icons/MicrophoneOffIcon';
import { SpeakerIcon } from '../icons/SpeakerIcon';
import { StadiumViewIcon } from '../icons/StadiumViewIcon';
import {
  useLiteModeDisablesCamera,
  useLiteModeEnabled,
  useLiteModeEnabledRecently,
} from '../LiteMode';
import { PlaceholderPres } from '../Participant';
import {
  type AudioMutedBy,
  isGuest,
  useUser,
  useUserContext,
  useUserStates,
} from '../UserContext';
import { useNavigateToVenueAtCapacity, useVenueEvent } from '../Venue';
import { VideoEffectsSettingsDeviceCheckPanel } from '../VideoEffectsSettingsPanels/DeviceCheck';
import { AudioPreview } from './AudioPreview';
import { useDeviceAPI, useDeviceState } from './Context';
import { MicVolumeMeterVisualizer } from './MicVolumeMeterVisualizer';
import { ModeratorToggle } from './ModeratorToggle';
import {
  DefaultDeviceId,
  DefaultSpeakerDeviceOption,
  type DeviceOption,
  MediaKind,
} from './types';
import { getAlternativeDeviceOption, getUserMedia } from './utils';
import {
  useMaybeVirtualBackgroundMixer,
  useMustVirtualBackgroundMixer,
} from './video-stream-mixer';
import { VideoPreview, VideoPreviewMini } from './VideoPreview';
import {
  VirtualBackgroudOptions,
  VirtualBackgroundSettingsModal,
} from './VirtualBackgroundSettings';

const log = logger.scoped('DeviceCheck');

async function getTrackByDeviceId(
  deviceId: string,
  kind: MediaKind.AudioInput | MediaKind.VideoInput,
  profile: Profile
): Promise<MediaStreamTrack> {
  if (kind === MediaKind.AudioInput) {
    const constraints = {
      audio: { deviceId: { exact: deviceId } },
      video: false,
    };
    const stream = await getUserMedia(constraints);
    return stream.getAudioTracks()[0];
  } else {
    const constraints = {
      audio: false,
      video: {
        deviceId: { exact: deviceId },
        width: { ideal: profile.width },
        height: { ideal: profile.height },
        frameRate: { ideal: profile.frameRate },
      },
    };
    const stream = await getUserMedia(constraints);
    return stream.getVideoTracks()[0];
  }
}

async function switchDevice(
  kind: MediaKind.AudioInput | MediaKind.VideoInput,
  activeDeviceOption: DeviceOption | null,
  stream: MediaStream | null,
  profile: Profile
): Promise<MediaStream | undefined> {
  if (!activeDeviceOption?.value) return;
  if (stream !== null) {
    const track =
      kind === MediaKind.AudioInput
        ? stream.getAudioTracks()[0]
        : stream.getVideoTracks()[0];
    const currDeviceId = track.getSettings().deviceId;
    const currDeviceLabel = track.label;
    const sameDefaultDevice =
      currDeviceId === DefaultDeviceId &&
      currDeviceLabel === activeDeviceOption.label;
    const newDeviceId = activeDeviceOption.value;
    if (
      (currDeviceId === newDeviceId && currDeviceId !== DefaultDeviceId) ||
      sameDefaultDevice
    ) {
      return;
    }
    releaseMediaStream(stream);
  }
  const mediaStream = new MediaStream();
  const alternativeDeviceOption = await getAlternativeDeviceOption(
    activeDeviceOption,
    kind
  );
  const newTrack = await getTrackByDeviceId(
    alternativeDeviceOption.value,
    kind,
    profile
  );
  mediaStream.addTrack(newTrack);
  return mediaStream;
}

interface Props {
  layout?: 'row' | 'col';
  enableVideoPreview?: boolean;
  enableCameraSelect?: boolean;
  onLoaded?: () => void;
  venueId?: string;
  skippable?: boolean;
  updateCameraTrack?: (track: MediaStreamTrack | null) => void;
}

interface DeviceCheckContext {
  loaded: boolean;
  audioCanSwitch: boolean;
  videoCanSwitch: boolean;
  toggleCamera: () => void;
  toggleAudio: (val: boolean, mutedBy?: AudioMutedBy) => void;
  toggleMicrophone: () => void;
  toggleMirror: () => void;
  changeAudioInput: (option: DeviceOption | null) => void;
  changeAudioOutput: (option: DeviceOption | null) => void;
  changeVideoInput: (option: DeviceOption | null) => void;
  videoMediaStream: MediaStream | null;
  audioMediaStream: MediaStream | null;

  /**
   * we don't use userStates.audio here because toggling that value triggers
   * _initUserMedia_, which creates both audio and video streams. We will see
   * the flicker on the video preview when toggling the microphone.
   */
  audio: boolean;
}

const Context = React.createContext<DeviceCheckContext | null>(null);

export function useDeviceCheckContext(): DeviceCheckContext {
  const ctx = useContext(Context);
  if (!ctx) {
    throw new Error('DeviceCheckContext is not in the tree!');
  }
  return ctx;
}

export function DeviceCheckContextProvider(props: {
  children?: ReactNode;
}): JSX.Element {
  const mounted = usePromise();

  // NOTE(drew): these refs and ext* states should be kept in sync. A ref is
  // needed to prevent an infinite loop on init, but a React state variable is
  // needed in order for the context to re-render / invalidate its useMemo for
  // child consumers of the streams. I don't like this fragility, but am not
  // sure of a better solution, currently.
  const audioStreamRef = useRef<MediaStream | null>(null);
  const videoStreamRef = useRef<MediaStream | null>(null);
  const [extVideoStream, setExtVideoStream] = useState<MediaStream | null>(
    null
  );
  const [extAudioStream, setExtAudioStream] = useState<MediaStream | null>(
    null
  );

  const { toggleVideo, toggleAudio, updateMirror } = useUserContext();
  const userStates = useUserStates();
  const { activeAudioInputDeviceOption, activeVideoInputDeviceOption } =
    useDeviceState();

  const [loaded, setLoaded] = useState(false);
  const [audioCanSwitch, setAudioCanSwitch] = useState(false);
  const [videoCanSwitch, setVideoCanSwitch] = useState(false);

  const deviceAPI = useDeviceAPI();
  const attemptsRef = useRef<number>(0);

  useEffect(() => {
    async function init() {
      const attempts = attemptsRef.current + 1;
      attemptsRef.current = attempts;
      releaseMediaStream(audioStreamRef.current);
      releaseMediaStream(videoStreamRef.current);
      const ret = await deviceAPI.initUserMedia({
        audio: userStates.audio,
        video: userStates.video,
      });
      if (attemptsRef.current !== attempts) {
        releaseMediaStream(ret.audioStream);
        releaseMediaStream(ret.videoStream);
        return;
      }
      audioStreamRef.current = ret.audioStream;
      videoStreamRef.current = ret.videoStream;
      setExtAudioStream(ret.audioStream);
      setExtVideoStream(ret.videoStream);
      setLoaded(true);
      setAudioCanSwitch(userStates.audio);
      setVideoCanSwitch(userStates.video);
    }
    init();
    return () => {
      setAudioCanSwitch(false);
      setVideoCanSwitch(false);
    };
  }, [deviceAPI, userStates.audio, userStates.video]);

  useEffect(() => {
    return () => {
      releaseMediaStream(audioStreamRef.current);
      releaseMediaStream(videoStreamRef.current);
    };
  }, []);

  useEffect(() => {
    if (!audioCanSwitch) return;
    const switchAudioInput = async () => {
      try {
        const newStream = await mounted(
          switchDevice(
            MediaKind.AudioInput,
            activeAudioInputDeviceOption,
            audioStreamRef.current,
            deviceAPI.profile
          )
        );
        if (newStream) {
          audioStreamRef.current = newStream;
          setExtAudioStream(newStream);
        }
      } catch (error: UnassertedUnknown) {
        log.error('switchAudioInput failed', error);
      }
    };
    switchAudioInput();
  }, [activeAudioInputDeviceOption, mounted, audioCanSwitch, deviceAPI]);

  useEffect(() => {
    if (!videoCanSwitch) return;
    const switchVideoInput = async () => {
      try {
        const newStream = await mounted(
          switchDevice(
            MediaKind.VideoInput,
            activeVideoInputDeviceOption,
            videoStreamRef.current,
            deviceAPI.profile
          )
        );
        if (newStream) {
          videoStreamRef.current = newStream;
          setExtVideoStream(newStream);
        }
      } catch (error: UnassertedUnknown) {
        log.error('switchVideoInput failed', error);
      }
    };
    switchVideoInput();
  }, [activeVideoInputDeviceOption, mounted, videoCanSwitch, deviceAPI]);

  const [audio, setAudio] = useState(userStates.audio);

  const value = useMemo(() => {
    const changeAudioInput = (option: DeviceOption | null) => {
      if (option === null) return;
      deviceAPI.updateActiveDeviceOption(MediaKind.AudioInput, option);
    };

    const changeVideoInput = (option: DeviceOption | null) => {
      if (option === null) return;
      deviceAPI.updateActiveDeviceOption(MediaKind.VideoInput, option);
    };

    const changeAudioOutput = (option: DeviceOption | null) => {
      if (option === null) return;
      deviceAPI.updateActiveDeviceOption(MediaKind.AudioOutput, option);
    };

    const toggleCamera = () => {
      toggleVideo(!userStates.video);
    };

    const toggleMicrophone = () => {
      setAudio((prev) => !prev);
    };

    const toggleMirror = () => {
      updateMirror(!userStates.mirror);
    };

    return {
      loaded,
      audioCanSwitch,
      videoCanSwitch,
      videoMediaStream: extVideoStream,
      audioMediaStream: extAudioStream,
      changeAudioInput,
      changeAudioOutput,
      changeVideoInput,
      toggleCamera,
      toggleAudio,
      toggleMicrophone,
      toggleMirror,
      audio,
    };
  }, [
    loaded,
    audioCanSwitch,
    videoCanSwitch,
    extVideoStream,
    extAudioStream,
    toggleAudio,
    audio,
    deviceAPI,
    toggleVideo,
    userStates.video,
    userStates.mirror,
    updateMirror,
  ]);

  return <Context.Provider value={value}>{props.children}</Context.Provider>;
}

export function DeviceCheck({
  layout = 'row',
  onLoaded,
  venueId,
  skippable,
  updateCameraTrack,
}: Props): JSX.Element {
  const [localParticipant, setLocalParticipant] = useState<Omit<
    ParticipantFull,
    'clientId' | 'clientType'
  > | null>(null);
  const analytics = useVenueAnalytics();

  const {
    videoMediaStream: inputVideoMediaStream,
    toggleCamera,
    toggleMicrophone,
    toggleMirror,
    audio,
  } = useDeviceCheckContext();
  useDeviceLoaded(onLoaded);

  useEffect(() => {
    const cameraTrack = inputVideoMediaStream?.getVideoTracks()[0];
    updateCameraTrack?.(cameraTrack ?? null);
  }, [inputVideoMediaStream, updateCameraTrack]);

  const [outputVideoMediaStream, setOutputVideoMediaStream] = useState(
    inputVideoMediaStream
  );

  const user = useUser();
  const userStates = useUserStates();
  const deviceAPI = useDeviceAPI();
  const mixer = useMaybeVirtualBackgroundMixer(deviceAPI.mixer);

  const { activeVideoInputDeviceOption, inited: deviceContextInited } =
    useDeviceState();

  useEffectOnce(() => {
    analytics.trackDeviceCheckViewed({ venueId });
  });

  useEffect(() => {
    if (!user.id) return;
    setLocalParticipant({
      joinedAt: 0,
      username: user.username,
      id: user.id,
      audio: userStates.audio,
      video: userStates.video,
      lite: userStates.lite,
    });
  }, [user, userStates]);

  const liteMode = useLiteModeEnabled();
  useLiteModeDisablesCamera();

  const showVideo = userStates.video && localParticipant?.hasCamera !== false;

  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  const placeholder = localParticipant ? (
    <PlaceholderPres
      participantUsername={localParticipant.username}
      hasCamera={localParticipant.hasCamera}
      hasMicrophone={localParticipant.hasMicrophone}
      lite={localParticipant.lite}
      showLiteMode={true}
    />
  ) : null;

  const vbgCustomizable = !isGuest(user);

  const openVirtualBackgroundSettings = () => {
    triggerModal({
      kind: 'custom',
      containerClassName: 'bg-transparent',
      element: (p) => (
        <Modal
          borderStyle='gray'
          bgStyle='transparent'
          modalBgStyle='transparent'
        >
          <div
            className={`
            flex flex-col items-center gap-4 bg-modal rounded-xl
            text-white relative p-5 w-174 h-full min-h-136
          `}
          >
            <VirtualBackgroundSettingsModal
              onCancel={p.internalOnCancel}
              onConfirm={p.internalOnConfirm}
              customizable={vbgCustomizable}
            />
          </div>
        </Modal>
      ),
    });
  };

  return (
    <div className='flex gap-7.5'>
      <div
        className={`flex flex-${layout} gap-10 ${
          layout === 'row' ? 'w-full' : ''
        }`}
      >
        <div className='flex flex-col flex-grow'>
          <div
            className={`${
              layout === 'row' ? 'w-48 h-48' : 'w-100 h-60'
            } bg-black rounded-xl relative flex flex-row justify-center 
              mb-2.5 overflow-hidden`}
          >
            <VideoPreview
              mirror={userStates.mirror}
              show={showVideo}
              mediaStream={inputVideoMediaStream}
              deviceId={activeVideoInputDeviceOption?.value}
              virtualBackground={mixer?.virtualBackgroundAvailable}
              renderable={!skippable && deviceContextInited}
              onOutputMediaStreamChanged={(ms) => setOutputVideoMediaStream(ms)}
              joined={userStates.joined}
            />

            {placeholder}

            <div
              className={`z-15 absolute right-1 bottom-1 p-px cursor-pointer 
                  icon-btn w-6 h-6 rounded-md ${
                    userStates.mirror ? 'text-white' : 'text-white opacity-80'
                  }`}
              onClick={toggleMirror}
            >
              <StadiumViewIcon />
            </div>
          </div>

          {layout === 'row' && (
            <div className='flex items-center justify-center gap-2.5'>
              <button
                type='button'
                className={`btn w-10 h-10 flex justify-center items-center ring-1 ring-secondary text-white`}
                onClick={toggleCamera}
                disabled={liteMode}
              >
                {userStates.video ? (
                  <CameraIcon className='w-4.5 h-4.5 fill-current' />
                ) : (
                  <CameraOffIcon className='w-4.5 h-4.5 fill-current' />
                )}
              </button>
              <button
                type='button'
                className={`btn w-10 h-10 flex justify-center items-center ring-1 ring-secondary text-white`}
                onClick={toggleMicrophone}
              >
                {audio ? (
                  <MicrophoneIcon className='w-4.5 h-4.5 fill-current' />
                ) : (
                  <MicrophoneOffIcon className='w-4.5 h-4.5 fill-current' />
                )}
              </button>
              {mixer?.virtualBackgroundEnabled && (
                <button
                  type='button'
                  className={`btn w-10 h-10 flex justify-center items-center ring-1 ring-secondary text-white`}
                  onClick={openVirtualBackgroundSettings}
                  disabled={liteMode || !mixer.virtualBackgroundAvailable}
                >
                  <GreenScreenIcon className='w-5 h-5 fill-current' />
                </button>
              )}
            </div>
          )}
        </div>
        <DeviceSelector
          width='w-100'
          cameraSelectVisible
          cameraSelectEnabled={!liteMode}
        />
      </div>
      {mixer?.virtualBackgroundAvailable && layout === 'col' && (
        <AsideVirtualBackgroundSettings
          mediaStream={outputVideoMediaStream}
          placeholder={placeholder}
          customizable={vbgCustomizable}
        />
      )}
    </div>
  );
}

export function useDeviceLoaded(onLoaded?: () => void) {
  const { loaded } = useDeviceCheckContext();
  useEffect(() => {
    if (loaded && onLoaded) onLoaded();
  }, [onLoaded, loaded]);
}

export function DeviceSelector(props: {
  width: `w-${string | number}`;
  cameraSelectVisible: boolean;
  cameraSelectEnabled: boolean;
}) {
  const {
    audioCanSwitch,
    audioMediaStream,
    changeAudioInput,
    changeAudioOutput,
    changeVideoInput,
    audio,
  } = useDeviceCheckContext();

  const {
    activeAudioInputDeviceOption,
    activeAudioOutputDeviceOption,
    activeVideoInputDeviceOption,
    audioInputOptions,
    videoInputOptions,
    audioOutputOptions,
    isGroupedAudioDevices,
  } = useDeviceState();

  return (
    <div className={`flex flex-col ${props.width} text-white`}>
      {props.cameraSelectVisible && (
        <div className='flex flex-row pb-6 items-center'>
          <CameraIcon className='w-5 h-5 fill-current' />
          <Select
            options={videoInputOptions}
            onChange={changeVideoInput}
            value={activeVideoInputDeviceOption}
            isSearchable={false}
            className='select-box w-full ml-3'
            classNamePrefix='select-box'
            isDisabled={!props.cameraSelectEnabled}
          />
        </div>
      )}
      <div className='flex flex-col pb-6'>
        <div className='flex flex-row items-center'>
          <MicrophoneIcon className='w-5 h-5 fill-current' />
          <Select
            options={audioInputOptions}
            onChange={changeAudioInput}
            value={activeAudioInputDeviceOption}
            isSearchable={false}
            className='select-box w-full ml-3'
            classNamePrefix='select-box'
          />
        </div>
        <MicVolumeMeterVisualizer
          stream={audio ? audioMediaStream : null}
          className='ml-8 mt-5'
        />
      </div>
      <div className='flex flex-col'>
        <div className='flex flex-row items-center'>
          <SpeakerIcon className='w-5 h-5 fill-current' />
          <Select
            options={audioOutputOptions}
            onChange={changeAudioOutput}
            value={activeAudioOutputDeviceOption}
            placeholder={DefaultSpeakerDeviceOption.label}
            isDisabled={audioOutputOptions.length === 0}
            isSearchable={false}
            className='select-box w-full ml-3'
            classNamePrefix='select-box'
          />
        </div>
        <div className='flex flex-row ml-8 mt-3.5 items-center'>
          <AudioPreview
            src={getStaticAssetPath('audios/test.mp3')}
            switchable={audioCanSwitch}
            deviceId={activeAudioOutputDeviceOption?.value}
          />
        </div>
        <div className='ml-8 mt-2 h-4 text-2xs text-gray-400 flex flex-row items-center invisible'>
          {!isGroupedAudioDevices && audioOutputOptions.length > 0 && (
            <>
              <ErrorIcon />
              <span className='ml-1'>
                Using different devices as your mic and speaker can cause echo.
              </span>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

function AsideVirtualBackgroundSettings(props: {
  mediaStream: MediaStream | null;
  placeholder?: ReactNode;
  customizable?: boolean;
}) {
  const { mediaStream, placeholder } = props;
  const { mirror, virtualBackgroundEffects } = useUserStates();
  const { updateVirtualBackgroundEffects } = useUserContext();
  const vbgMixer = useMustVirtualBackgroundMixer(useDeviceAPI().mixer);

  return (
    <div className={`flex flex-col gap-10`}>
      <div className='w-[fit-content] h-60 flex items-center justify-center'>
        <VideoPreviewMini
          mirror={mirror}
          mediaStream={mediaStream}
          placeholder={placeholder}
        />
      </div>
      <div className='w-62 -mt-6'>
        <VirtualBackgroudOptions
          value={virtualBackgroundEffects}
          onChange={(val) => {
            updateVirtualBackgroundEffects(val ?? null);
            vbgMixer.setVirtualBackgroundEffects(val ?? null);
          }}
          customizable={props.customizable}
        />
      </div>
    </div>
  );
}

function HostDeviceCheckModalInternal(props: { handleContinue: () => void }) {
  const { videoMediaStream } = useDeviceCheckContext();
  return (
    <VideoEffectsSettingsDeviceCheckPanel
      onContinue={props.handleContinue}
      introOutroSettings
      stagePodiumSettings
      settingsEditable={true}
      mediaStream={videoMediaStream}
    />
  );
}

export function HostDeviceCheckModal(props: {
  loaded?: boolean;
  handleClose?: () => void;
}) {
  const { updateUserStates } = useUserContext();

  const handleContinue = useLiveCallback(() => {
    updateUserStates({ joined: true });
    props.handleClose && props.handleClose();
  });

  return (
    <DeviceCheckContextProvider>
      <Modal className='w-11/12 h-5/6'>
        <div className={`w-full h-full p-8 overflow-auto scrollbar`}>
          <HostDeviceCheckModalInternal handleContinue={handleContinue} />
        </div>
      </Modal>
    </DeviceCheckContextProvider>
  );
}

function ContinueButton(props: {
  loaded: boolean | undefined;
  handleContinue: () => void;
}) {
  const { loaded, handleContinue } = props;
  const { audio } = useDeviceCheckContext();
  const { toggleAudio } = useUserContext();

  const onContinue = () => {
    toggleAudio(audio);
    handleContinue();
  };
  return (
    <button
      type='button'
      className='btn-primary w-40 h-12 text-base'
      onClick={onContinue}
      data-testid='device-check-continue'
      disabled={!loaded}
    >
      {loaded ? 'Continue' : 'Loading'}
    </button>
  );
}

type DeviceCheckProps = {
  loaded?: boolean;
  handleClose?: () => void;
  venueId?: string;
  maybeCohost?: boolean;
};

export function DeviceCheckModal(props: DeviceCheckProps): JSX.Element {
  const [loaded, setLoaded] = useState(props.loaded);
  const { updateUserStates } = useUserContext();
  const liteModeEnabledRecently = useLiteModeEnabledRecently();
  const deviceAPI = useDeviceAPI();
  const getCanJoin = useCanJoinGetter();
  const navigateToVenueAtCapacity = useNavigateToVenueAtCapacity();
  const analytics = useVenueAnalytics();
  const event = useVenueEvent();
  const { activeVideoInputDeviceOption: did } = useDeviceState();
  const [cameraTrack, setCameraTrack] = useState<MediaStreamTrack | null>(null);

  const handleContinue = useLiveCallback(async (skipped?: boolean) => {
    analytics.trackDeviceCheckCompleted({
      venueId: props.venueId,
      skipped: skipped ?? false,
    });

    if (cameraTrack && did?.value) {
      await deviceAPI.updateSingletonVideoTrack({
        action: 'update',
        input: {
          cameraTrack,
          deviceId: did.value,
          clone: true,
        },
      });
    }

    // venue capacity check – check that we can actually join.
    const canJoin = await getCanJoin();
    if (!canJoin) {
      navigateToVenueAtCapacity();
      return;
    }
    updateUserStates({ joined: true });
    if (getFeatureQueryParam('skip-device-check-in-session')) {
      deviceAPI.updateDeviceCheckedInSession();
    }
    props.handleClose && props.handleClose();
  });

  const handleReload = () => {
    safeWindowReload();
  };

  const handleLoaded = useCallback(() => {
    setLoaded(true);
  }, []);

  useEffect(() => {
    if (!loaded) return;
    if (deviceAPI.checkSkippable() || liteModeEnabledRecently()) {
      handleContinue(true);
    }
  }, [deviceAPI, handleContinue, liteModeEnabledRecently, loaded]);

  const formatter = new Intl.DateTimeFormat(
    Intl.DateTimeFormat().resolvedOptions().locale,
    {
      month: 'long',
      day: 'numeric',
      weekday: 'long',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    }
  );

  const organizationName = event?.orgName;
  const dateAndTime = event?.startAt
    ? formatter.format(new Date(event.startAt))
    : '';

  return (
    <DeviceCheckContextProvider>
      <Modal borderStyle='gray'>
        <div
          className={`
            flex flex-col items-center gap-4
            bg-modal rounded-xl
            text-white relative
            pt-5 pb-8
            w-full h-full
          `}
        >
          <img src={logo} className='w-35 ' alt='Luna Park Logo' />
          <div className='text-xl font-bold'>
            {organizationName ? `Welcome ${organizationName}!` : `Welcome!`}
          </div>
          {dateAndTime ? (
            <div className='flex text-sms'>
              <span>
                <span className='font-bold'>Date & Time: </span>
                <span className=''>{dateAndTime}</span>
              </span>
            </div>
          ) : null}
          {props.maybeCohost ? (
            <div
              className='
              w-full h-10
              bg-lp-red-002
              font-base font-bold
              flex items-center justify-center
            '
            >
              You are entering this venue as a{' '}
              <HostIcon className='w-3 h-3 fill-current inline-block mx-1' />{' '}
              Cohost.
            </div>
          ) : null}
          <div className='px-8'>
            <DeviceCheck
              onLoaded={handleLoaded}
              venueId={props.venueId}
              skippable={deviceAPI.checkSkippable()}
              updateCameraTrack={setCameraTrack}
            />
          </div>
          <div className='flex flex-col gap-3'>
            <div className='text-sm'>
              Please use headphones for the best experience.{' '}
              <button
                type='button'
                className='text-primary outline-none focus:outline-none'
                onClick={handleReload}
              >
                Troubleshoot
              </button>
              .
            </div>
            <div className='text-sms text-tertiary text-center'>
              Using a VPN may adversely affect your experience.
            </div>
          </div>
          <ContinueButton
            loaded={loaded}
            handleContinue={() => handleContinue(false)}
          />
          <ModeratorToggle />
        </div>
      </Modal>
    </DeviceCheckContextProvider>
  );
}
