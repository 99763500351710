import isEqual from 'lodash/isEqual';
import { type ReactNode, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import {
  compareBoundingBoxSettings,
  defaultBoundingBoxSettings,
  GreenScreenUIValuesRange,
} from '@lp-lib/game';

import type { CustomVideoQualityPreset } from '../../services/webrtc';
import { getAgentInfo } from '../../utils/user-agent';
import { type Option } from '../common/Utilities';
import { DeviceSelector } from '../Device/Check';
import { DirectRangeInput } from '../Form/DirectRangeInput';
import { GreenScreenIcon } from '../icons/GreenScreenIcon';
import { SwitcherControlled, SwitcherHookForm } from '../Switcher';
import { type FitOperationKind } from '../VideoFrameProcessing';
import { Cameras, IntroMedias, OutroMedias, Podiums, Stages } from './content';
import { MaskRectEditor } from './MaskRectEditor';
import { usePreviewVideoEffectsSettingsStore } from './Provider';
import { SelectController } from './SelectController';
import { type VideoEffectsFormData, type VideoEffectsSettings } from './types';
import { VideoEffectsSettingsUtils } from './VideoEffectsSettingsUtils';

const noneOption: Option<string> = {
  label: 'None',
  value: 'none',
};

const stageOptions: Option<string>[] = [
  noneOption,
  ...Stages.dump().map((stage) => ({
    label: stage.name,
    value: stage.id,
  })),
];

const podiumOptions: Option<string>[] = [
  noneOption,
  ...Podiums.dump().map((podium) => ({
    label: podium.name,
    value: podium.id,
  })),
];

const introOptions: Option<string>[] = [
  ...IntroMedias.dump().map((m) => ({
    label: m.name,
    value: m.id,
  })),
];

const outroOptions: Option<string>[] = [
  ...OutroMedias.dump().map((m) => ({
    label: m.name,
    value: m.id,
  })),
];

const cameraOptions: Option<string>[] = [
  ...Cameras.dump().map((c) => ({
    label: c.name,
    value: c.id,
  })),
];

const cameraFitOptions: Option<FitOperationKind>[] = [
  { label: 'Contain', value: 'contain' as FitOperationKind },
  { label: 'Cover', value: 'cover' as FitOperationKind },
];

export function VideoEffectsSettingsCore(props: {
  streamOrSrc: MediaStream | string | null;
  targetVideoProfile: CustomVideoQualityPreset;
  muted?: boolean;
  autoplay?: boolean;
  controls?: boolean;
  initialEditingDisabled: boolean;
  initialSettings: VideoEffectsSettings;
  onSave: (values: VideoEffectsSettings) => Promise<void>;
  onCancel?: () => void;
  deviceSelect?: boolean;
  introOutroSettings?: boolean;
  title?: ReactNode;
  headerAccessory?: React.ReactNode;
  stagePodiumSettings?: boolean | 'stage' | 'podium';
  settingsEditable?: boolean;
}): JSX.Element {
  const [settingsEditingDisabled, setSettingsEditingDisabled] = useState(
    props.initialEditingDisabled
  );

  const [agentInfo] = useState(() => getAgentInfo());

  const [customCameraEnabled, setCustomCameraEnabled] = useState(
    !isEqual(props.initialSettings.boundingBox, defaultBoundingBoxSettings())
  );
  const [guidelinesEnabled, setGuidelinesEnabled] = useState(true);

  const form = useForm<VideoEffectsFormData>({
    defaultValues: {
      ...props.initialSettings,
    },
  });

  const { register, watch, reset, control } = form;

  const resetMaskBox = useRef<null | (() => void)>(null);

  const greenScreenEnabled = watch(
    'greenScreen.enabled',
    props.initialSettings.greenScreen.enabled
  );
  const greenScreenControlsDisabled =
    !greenScreenEnabled || settingsEditingDisabled;

  const previewStore = usePreviewVideoEffectsSettingsStore();

  // Sync form changes back to the store. This avoids the form needing to be
  // fully react-controlled. The values are needed by the Greenscreen Video
  // Preview.
  useEffect(() => {
    const sub = watch((values, { name }) => {
      previewStore.updateOthers(values, name);
    });
    return () => sub.unsubscribe();
  }, [previewStore, watch]);

  const noStreamPlaceholder = (
    <div className='aspect-w-16 aspect-h-9'>
      <div className='absolute w-full h-full flex justify-center items-center bg-lp-gray-002 text-white text-sm'>
        Unmute Camera to Preview Green Screen
      </div>
    </div>
  );

  return (
    <FormProvider<VideoEffectsFormData> {...form}>
      <div className='w-full h-full flex flex-col'>
        <div className='flex justify-between items-center mb-6'>
          <header className='flex gap-2 items-center text-white text-2xl'>
            <GreenScreenIcon className='w-5 h-5 fill-current' />
            {props.title ?? 'Host Configuration Settings'}
          </header>

          <div className='flex gap-5'>
            {settingsEditingDisabled ? (
              <>
                <button
                  type='button'
                  className={`btn-secondary min-w-36 min-h-10 ${
                    props.settingsEditable ? 'block' : 'hidden'
                  }`}
                  onClick={() => setSettingsEditingDisabled(false)}
                >
                  Edit
                </button>
                {props.headerAccessory}
              </>
            ) : (
              <>
                <button
                  type='button'
                  className='btn-secondary min-w-36 min-h-10'
                  onClick={() => {
                    if (props.initialEditingDisabled) {
                      setSettingsEditingDisabled(true);
                    }

                    previewStore.resetToInitial();
                    reset(props.initialSettings);
                    props.onCancel?.();
                  }}
                >
                  Cancel
                </button>
                <button
                  type='button'
                  className='btn-primary min-w-36 min-h-10 px-4'
                  onClick={async () => {
                    const gsv = previewStore.toJS();
                    await props.onSave(gsv);
                    if (props.initialEditingDisabled)
                      setSettingsEditingDisabled(true);
                  }}
                >
                  Save
                </button>
              </>
            )}
          </div>
        </div>

        <div className='flex-1 flex gap-6 overflow-hidden'>
          <div className='w-2/3'>
            {!props.streamOrSrc ? (
              noStreamPlaceholder
            ) : (
              <MaskRectEditor
                disabled={greenScreenControlsDisabled}
                streamOrSrc={props.streamOrSrc}
                targetVideoProfile={props.targetVideoProfile}
                // Note(drew): This is for testing, it's easier to access the venue settings
                // than a block's settings to test a static video.
                // streamOrSrc={
                //   // casey
                //   // 'https://assets.gadder.live/videos/19187/10af0c3c421d4b0d90703c827c4d164a.webm'
                //   // test announcer woman
                //   // 'https://assets.gadder.live/videos/19186/f6206bdf73cf438dbd57d1f94fd9e3cd.mp4'
                // }
                muted={props.muted}
                autoplay={props.autoplay}
                controls={props.controls}
                resetMaskBox={resetMaskBox}
                boundingBox={customCameraEnabled}
                guidelines={guidelinesEnabled}
              />
            )}
          </div>
          <div className='flex-1 px-2 py-4 scrollbar overflow-y-scroll w-1/3 h-full text-white text-sms'>
            {props.deviceSelect && (
              <section>
                <div className='font-bold text-center mb-4'>Cam & Mic</div>
                <DeviceSelector
                  width='w-full'
                  cameraSelectEnabled
                  cameraSelectVisible
                />
              </section>
            )}

            {props.introOutroSettings && (
              <section className='mb-6'>
                <div className='font-bold text-center mb-px'>Intro & Outro</div>
                <div className='text-xs text-center mb-4 text-secondary'>
                  Live Hosted Games Only
                </div>
                <fieldset
                  disabled={settingsEditingDisabled}
                  className='w-full flex flex-col gap-5'
                >
                  <div className='flex items-center gap-2'>
                    <div className='w-1/6 flex-none'>Intro</div>
                    <SelectController
                      control={control}
                      name='intro'
                      options={introOptions}
                      toOption={(value) => {
                        return value === null || !value.enabled
                          ? null
                          : introOptions.find(
                              (s) => s.value === value.config.id
                            ) ?? null;
                      }}
                      fromOption={(opt) => {
                        const intro = IntroMedias.select(opt?.value);
                        return intro === null
                          ? null
                          : {
                              enabled: true,
                              config: intro,
                            };
                      }}
                      disabled={settingsEditingDisabled}
                    />
                  </div>

                  <div className='flex items-center gap-2'>
                    <div className='w-1/6 flex-none'>Outro</div>
                    <SelectController
                      control={control}
                      name='outro'
                      options={outroOptions}
                      toOption={(value) => {
                        return value === null || !value.enabled
                          ? null
                          : outroOptions.find(
                              (s) => s.value === value.config.id
                            ) ?? null;
                      }}
                      fromOption={(opt) => {
                        const outro = OutroMedias.select(opt?.value);
                        return outro === null
                          ? null
                          : {
                              enabled: true,
                              config: outro,
                            };
                      }}
                      disabled={settingsEditingDisabled}
                    />
                  </div>
                </fieldset>
              </section>
            )}

            {props.stagePodiumSettings && (
              <section>
                <div className='font-bold text-center mb-4'>Stage & Podium</div>
                <fieldset
                  disabled={settingsEditingDisabled}
                  className='w-full flex flex-col gap-5'
                >
                  <div className='flex items-center gap-2'>
                    <label className='w-1/6 flex-none' htmlFor='stage'>
                      Stage
                    </label>
                    <SelectController
                      control={control}
                      name='stage'
                      options={stageOptions}
                      toOption={(value) => {
                        return value === null || !value.enabled
                          ? noneOption
                          : stageOptions.find(
                              (s) => s.value === value.config.id
                            ) ?? noneOption;
                      }}
                      fromOption={(opt) => {
                        const stage = Stages.select(opt?.value);
                        return stage === null
                          ? null
                          : {
                              enabled: true,
                              config: stage,
                            };
                      }}
                      disabled={
                        settingsEditingDisabled ||
                        props.stagePodiumSettings === 'podium'
                      }
                    />
                  </div>

                  <div className='flex items-center gap-2'>
                    <label className='w-1/6 flex-none' htmlFor='podium'>
                      Podium
                    </label>
                    <SelectController
                      control={control}
                      name='podium'
                      options={podiumOptions}
                      toOption={(value) => {
                        return value === null || !value.enabled
                          ? noneOption
                          : podiumOptions.find(
                              (s) => s.value === value.config.id
                            ) ?? noneOption;
                      }}
                      fromOption={(opt) => {
                        const podium = Podiums.select(opt?.value);
                        return podium === null
                          ? null
                          : {
                              enabled: true,
                              config: podium,
                            };
                      }}
                      disabled={
                        settingsEditingDisabled ||
                        props.stagePodiumSettings === 'stage'
                      }
                    />
                  </div>
                </fieldset>
              </section>
            )}

            <section className='mt-6'>
              <div className='font-bold text-center mb-4 relative'>
                Camera Zoom
                <div className='absolute right-0 top-0 h-full flex justify-center items-center'>
                  <button
                    type='button'
                    className='btn text-primary'
                    onClick={() => resetMaskBox.current?.()}
                  >
                    Reset Crop
                  </button>
                </div>
              </div>
              <fieldset
                disabled={settingsEditingDisabled}
                className='w-full flex flex-col gap-5'
              >
                <div className='w-full flex justify-between items-center gap-2'>
                  <span>Show Guidelines</span>
                  <SwitcherControlled
                    name='guidelinesEnabled'
                    checked={guidelinesEnabled}
                    onChange={(checked) => setGuidelinesEnabled(checked)}
                  />
                </div>

                <div className='w-full flex justify-between items-center gap-2'>
                  <span>Enable Custom Camera</span>
                  <SwitcherControlled
                    name='customCameraEnabled'
                    checked={customCameraEnabled}
                    onChange={(checked) => setCustomCameraEnabled(checked)}
                  />
                </div>

                {!customCameraEnabled ? (
                  <SelectController
                    control={control}
                    name={'boundingBox'}
                    options={cameraOptions}
                    toOption={(value) => {
                      const maybeCamera = Cameras.query((a) =>
                        compareBoundingBoxSettings(a, value)
                      );
                      return (
                        cameraOptions.find(
                          (c) => c.value === maybeCamera?.id
                        ) ?? null
                      );
                    }}
                    fromOption={(opt) => {
                      return (
                        Cameras.select(opt?.value) ??
                        defaultBoundingBoxSettings()
                      );
                    }}
                    disabled={settingsEditingDisabled}
                  />
                ) : (
                  <>
                    <div className='w-full flex flex-col'>
                      <label htmlFor={'boundingBox.box.x'}>Camera Box X</label>
                      <DirectRangeInput<VideoEffectsFormData>
                        className='flex justify-between items-center w-full gap-2'
                        name='boundingBox.box.x'
                        min={-2}
                        max={2}
                        step={0.001}
                        initialValue={props.initialSettings.boundingBox.box.x}
                        disabled={settingsEditingDisabled}
                      />
                    </div>

                    <div className='w-full flex flex-col'>
                      <label htmlFor={'boundingBox.box.y'}>Camera Box Y</label>
                      <DirectRangeInput<VideoEffectsFormData>
                        className='flex justify-between items-center w-full gap-2'
                        name='boundingBox.box.y'
                        min={-2}
                        max={2}
                        step={0.001}
                        initialValue={props.initialSettings.boundingBox.box.y}
                        disabled={settingsEditingDisabled}
                      />
                    </div>

                    <div className='w-full flex flex-col'>
                      <label htmlFor={'boundingBox.box.width'}>
                        Camera Box Width
                      </label>
                      <DirectRangeInput<VideoEffectsFormData>
                        className='flex justify-between items-center w-full gap-2'
                        name='boundingBox.box.width'
                        min={-2}
                        max={2}
                        step={0.001}
                        initialValue={
                          props.initialSettings.boundingBox.box.width
                        }
                        disabled={settingsEditingDisabled}
                      />
                    </div>

                    <div className='w-full flex flex-col'>
                      <label htmlFor={'boundingBox.box.height'}>
                        Camera Box Height
                      </label>
                      <DirectRangeInput<VideoEffectsFormData>
                        className='flex justify-between items-center w-full gap-2'
                        name='boundingBox.box.height'
                        min={-2}
                        max={2}
                        step={0.001}
                        initialValue={
                          props.initialSettings.boundingBox.box.height
                        }
                        disabled={settingsEditingDisabled}
                      />
                    </div>

                    <div className='flex items-center'>
                      <label
                        className='w-1/6 flex-none'
                        htmlFor='boundingBox.fit'
                      >
                        Camera Box Fit
                      </label>
                      <SelectController
                        control={control}
                        name={'boundingBox.fit'}
                        options={cameraFitOptions}
                        toOption={(value) =>
                          cameraFitOptions.find((c) => c.value === value) ??
                          null
                        }
                        fromOption={(opt) =>
                          opt?.value ?? defaultBoundingBoxSettings().fit
                        }
                        disabled={settingsEditingDisabled}
                      />
                    </div>
                  </>
                )}
              </fieldset>
            </section>

            <section className='mt-6'>
              <div className='font-bold text-center mb-4'>Green Screen</div>
              {agentInfo.browser.isFirefox && (
                <div className='text-xs text-center mb-4 text-red-005'>
                  <p>
                    <a
                      href='https://bugzilla.mozilla.org/show_bug.cgi?id=1163426'
                      target={'_blank'}
                      rel='noreferrer'
                      className='underline'
                    >
                      Firefox has a bug that results in poor Green Screen
                      Performance.{' '}
                    </a>
                  </p>
                  <p
                    className={
                      greenScreenControlsDisabled ? 'invisible' : 'visible'
                    }
                  >
                    Proceed with Green Screen enabled at your own risk or use
                    Google Chrome instead.
                  </p>
                </div>
              )}
              <div className='flex flex-col gap-4'>
                <fieldset
                  disabled={settingsEditingDisabled}
                  className='w-full flex flex-col gap-4'
                >
                  <div className='w-full flex justify-between items-center gap-1'>
                    <span>Enable Green Screen</span>
                    <SwitcherHookForm
                      {...register('greenScreen.enabled')}
                      defaultChecked={props.initialSettings.greenScreen.enabled}
                    />
                  </div>

                  <div className='w-full flex justify-between items-center gap-1'>
                    <span>Key Color</span>
                    <input
                      type='color'
                      className='
                      w-17 h-12 p-2
                      rounded-xl
                      border border-secondary border-solid
                      hover:border-primary focus:border-primary
                      disabled:opacity-50 disabled:cursor-not-allowed disabled:border-secondary
                      bg-black
                    '
                      defaultValue={
                        props.initialSettings.greenScreen.sharpPrefixedKeyColor
                      }
                      {...register('greenScreen.sharpPrefixedKeyColor', {
                        onChange() {
                          previewStore.changeProcessorEnabled(
                            'greenScreen',
                            true
                          );
                        },
                      })}
                      // NOTE(drew): I tried using onFocus for this but it only
                      // activates on the first mousedown, then is removed.
                      // Might be a browser quirk (chrome), or I'm missing
                      // something.
                      onClick={() => {
                        previewStore.changeProcessorEnabled(
                          'greenScreen',
                          false
                        );
                      }}
                    />
                  </div>
                </fieldset>

                <fieldset
                  disabled={greenScreenControlsDisabled}
                  className='w-full flex flex-col gap-4'
                >
                  <div className='w-full flex flex-col gap-1'>
                    <label htmlFor={'similarity'}>Similarity</label>
                    <DirectRangeInput<VideoEffectsFormData>
                      className='flex justify-between items-center w-full gap-2'
                      name='greenScreen.similarity'
                      min={GreenScreenUIValuesRange.min}
                      max={GreenScreenUIValuesRange.max}
                      step={1}
                      initialValue={
                        props.initialSettings.greenScreen.similarity
                      }
                    />
                  </div>

                  <div className='w-full flex flex-col gap-1'>
                    <label htmlFor={'smoothness'}>Smoothness</label>
                    <DirectRangeInput<VideoEffectsFormData>
                      className='flex justify-between items-center w-full gap-2'
                      name='greenScreen.smoothness'
                      min={GreenScreenUIValuesRange.min}
                      max={GreenScreenUIValuesRange.max}
                      step={1}
                      initialValue={
                        props.initialSettings.greenScreen.smoothness
                      }
                    />
                  </div>

                  <div className='w-full flex flex-col gap-1'>
                    <label htmlFor={'spill'}>Spill</label>
                    <DirectRangeInput<VideoEffectsFormData>
                      className='flex justify-between items-center w-full gap-2'
                      name='greenScreen.spill'
                      min={GreenScreenUIValuesRange.min}
                      max={GreenScreenUIValuesRange.max}
                      step={1}
                      initialValue={props.initialSettings.greenScreen.spill}
                    />
                  </div>

                  <div className='w-full flex flex-col gap-1'>
                    <button
                      type='button'
                      name={'reset-to-defaults'}
                      className='btn-secondary min-h-10 text-base'
                      onClick={() => {
                        const values = VideoEffectsSettingsUtils.WithDefaults();
                        values.greenScreen.enabled = greenScreenEnabled;
                        reset(values);
                        previewStore.resetToValues(values);
                      }}
                    >
                      Reset to Defaults
                    </button>
                  </div>
                </fieldset>
              </div>
            </section>
          </div>
        </div>
      </div>
    </FormProvider>
  );
}
