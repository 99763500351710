import capitalize from 'lodash/capitalize';
import { match } from 'ts-pattern';

import {
  type DtoOrganizer,
  type DtoOrgSubscription,
  type EnumsExConnectType,
  type ModelsOrgPurchase,
  type ModelsOrgSettings,
} from '@lp-lib/api-service-client/public';
import { type Media } from '@lp-lib/media';

import { assertExhaustive } from '../utils/common';
import { RoleUtils, type User } from './user';

export const ALL_ORG_SUBSCRIPTION_PLANS = [
  'free',
  'company',
  'company_plus',
  'paid', // old, deprecated plan.
] as const;

export type OrgSubscriptionPlan = (typeof ALL_ORG_SUBSCRIPTION_PLANS)[number];

export interface Organization {
  id: string;
  name: string;
  maxSize: number | null;
  subscription: DtoOrgSubscription;
  organizersCount: number;
  pairingDisabledParticipantsCount: number;
  canTrial: boolean;
  usesSlack: boolean;
  createdAt: string;
  updatedAt: string;
  logo: Media | null;
  connection: OrgConnectionBase | null;
  settings: Nullable<ModelsOrgSettings>;
  purchase: Nullable<ModelsOrgPurchase>;
}

export class OrgSubscriptionUtils {
  static Compare(a: OrgSubscriptionPlan, b: OrgSubscriptionPlan): number {
    return (
      ALL_ORG_SUBSCRIPTION_PLANS.indexOf(a) -
      ALL_ORG_SUBSCRIPTION_PLANS.indexOf(b)
    );
  }
  static GetSubscriptionPlanName(
    item:
      | { subscription: DtoOrgSubscription }
      | DtoOrgSubscription
      | null
      | undefined
  ): string | null {
    if (!item) return 'N/A';
    const subscription = 'subscription' in item ? item.subscription : item;
    return subscription
      ? subscription.productName ??
          OrgSubscriptionUtils.FormatPlanDisplayName(subscription.plan)
      : 'N/A';
  }
  /**
   * @deprecated Use `GetSubscriptionPlanName` instead.
   */
  private static FormatPlanDisplayName(plan: OrgSubscriptionPlan): string {
    return match(plan)
      .with('free', () => 'Free Pass')
      .with('paid', () => 'Premium Legacy')
      .with('company', () => 'Company')
      .with('company_plus', () => 'Company Plus')
      .exhaustive();
  }
}

export enum OrganizerRole {
  Default = 0,
  Owner,
  Admin,
}

export interface Organizer {
  uid: string;
  orgId: string;
  firstName: string;
  lastName: string;
  role: OrganizerRole;
  email: string;
  venueId: string;
  venueName: string | null;
  createdAt: string;
  updatedAt: string;
  invitedAt: string | null;
  activatedAt: string | null;
  activated: boolean;
  pairingDisabled: boolean;
  exUserId: string | null;
  exConnectType: string | null;
  icon: string | null;

  organization: Organization | null;
}

export type OrganizerSortBy = 'name' | 'email' | 'time';

export class OrganizerUtils {
  static GetDisplayName(organizer: Organizer | null): string {
    if (!organizer) return 'N/A';

    return this.GetFullName(organizer) || organizer.email;
  }

  static GetFirstName(organizer: DtoOrganizer | Organizer | null): string {
    if (!organizer) return 'N/A';
    if (organizer.firstName) return organizer.firstName;

    return capitalize(organizer.email.split('@')[0]);
  }

  static GetFullName(organizer: Organizer): string {
    if (!!organizer.lastName)
      return `${organizer.firstName} ${organizer.lastName}`;
    return organizer.firstName;
  }

  static GetRoleName(role: OrganizerRole): string {
    switch (role) {
      case OrganizerRole.Default:
        return 'Member';
      case OrganizerRole.Admin:
        return 'Admin';
      case OrganizerRole.Owner:
        return 'Owner';
      default:
        assertExhaustive(role);
        return 'Unknown';
    }
  }

  static Compare(a: Organizer, b: Organizer, sortBy: OrganizerSortBy): number {
    switch (sortBy) {
      case 'name':
        return a.activated && b.activated
          ? this.GetFullName(a).localeCompare(this.GetFullName(b))
          : Number(b.activated) - Number(a.activated);
      case 'email':
        return a.email.localeCompare(b.email);
      case 'time':
        const ta = (!a.activated ? a.invitedAt : a.updatedAt) || '';
        const tb = (!b.activated ? b.invitedAt : b.updatedAt) || '';
        return tb.localeCompare(ta);
      default:
        assertExhaustive(sortBy);
        return 0;
    }
  }

  static CanIManage(
    me: User,
    target: Organizer,
    selfManageable?: boolean
  ): boolean {
    const isAdmin = RoleUtils.isAdmin(me);
    if (me.id === target.uid) return !!selfManageable;
    if (OrganizerRoleUtils.isOwner(target)) return isAdmin;
    return isAdmin || OrganizerRoleUtils.isOwnerOrAdmin(me.organizer);
  }
}

export interface OrganizationDTO {
  organization: string | null;
}

type RoleMatchable = Organizer | OrganizerRole | undefined | null;

function buildRoleMatchFn(
  target: OrganizerRole | OrganizerRole[]
): (m: RoleMatchable) => boolean {
  return (m: RoleMatchable): boolean => {
    if (!m) return false;
    const role = typeof m === 'number' ? m : m.role;
    if (Array.isArray(target)) {
      return target.includes(role);
    } else {
      return role === target;
    }
  };
}

export const OrganizerRoleUtils = {
  isOwner: buildRoleMatchFn(OrganizerRole.Owner),
  isAdmin: buildRoleMatchFn(OrganizerRole.Admin),
  isOwnerOrAdmin: buildRoleMatchFn([OrganizerRole.Owner, OrganizerRole.Admin]),
};

export type OrgConnectionType = 'slack';

export type OrgConnectionStatus = 'active' | 'inactive';

export type OrgConnectionBase = {
  id: string;
  orgId: string;
  exConnId: string;
  type: EnumsExConnectType;
  status: OrgConnectionStatus;
  asSourceOfTruth: boolean;
};

export type OrgConnection = OrgConnectionBase & {
  name: string;
  icon: string | null;
};

export type OrgConnectionTypeInfo = {
  label: string;
  icon: React.FC<{ className?: string }>;
};
