import { useEffect, useState } from 'react';
import { ref } from 'valtio';

import { type OnDGameAnalytics } from '../../../../analytics/game';
import { useOnDGameAnalytics } from '../../../../analytics/useOndGameAnalytics';
import { getFeatureQueryParam } from '../../../../hooks/useFeatureQueryParam';
import { useInstance } from '../../../../hooks/useInstance';
import logger from '../../../../logger/logger';
import { SessionMode } from '../../../../types';
import { uuidv4 } from '../../../../utils/common';
import { ValtioUtils } from '../../../../utils/valtio';
import { firebaseService } from '../../../Firebase';
import {
  useGetStreamSessionId,
  useIsStreamSessionAborted,
  useStreamSessionControlAPI,
} from '../../../Session';
import { useTownhallAPI } from '../../../Townhall';
import { useOndVoiceOverRegistry } from '../../../VoiceOver/OndVoiceOverRegistryProvider';
import { type VoiceOverRegistry } from '../../../VoiceOver/VoiceOverRegistry';
import { useBlockLifecycleRulesEvaluator } from '../../Blocks/Common/LifecycleRules/BlockLifecycleRulesEvaluator';
import { getLocalGamePlayStore } from '../../GamePlayStore';
import { useOndGameState } from '../../hooks';
import { useCreateGameInfoSnapshot } from '../../hooks/useCreateGameInfoSnapshot';
import {
  BlockRecordingCreator,
  OndPhaseBoot,
  OndPhaseContext,
  OndPhaseResume,
  OndPhaseRunner,
} from '../../OndPhaseRunner';
import { BlockToVideoMixerTrackMap } from '../../OndPhaseRunner/BlockToVideoMixerTrackMap';
import { OndPhaseEmitterUtils } from '../../OndPhaseRunner/ond-phase-emitter';
import { PlayedBlockTracker } from '../../OndPhaseRunner/OndPhaseRunner';
import {
  nextPlaybackIsSameEtagIsh,
  type PlaybackDesc,
  type PlaybackItemId,
} from '../../Playback/intoPlayback';
import { usePlaybackInfoWriteExtra } from '../../Playback/PlaybackInfoProvider';
import {
  type GameSessionOndState,
  gameSessionStore,
  type OndGamePlayCoordinatableState,
  type OndGamePlayState,
} from '../gameSessionStore';
import {
  getDataPath,
  loadGameSession,
  pauseVideoMixerAndGameRunner,
  reset,
} from './core';
import {
  setOndGamePlayEnded,
  setOndGamePlayPaused,
  setOndGamePlayPreparing,
} from './ond-state-writers';

const logODG = logger.scoped('ond-game');

const endOndGamePlay = async () => {
  logODG.info('ending game');
  await setOndGamePlayEnded();
  await gameSessionStore.terminateSession?.();
  logODG.info('ended game');
};

/**
 * If the game state is "running" by the time this function is called, assume it
 * is ending naturally and thus the state should be thrown away and the game
 * truly "ended".
 */
async function endOndGameIfRunning() {
  if (gameSessionStore.ondState?.state === 'running') {
    gameSessionStore.ondGameRunner = null;
    await endOndGamePlay();
  }
}

/**
 * We need to ensure the local store has the playback loaded/resolved during an
 * OND game, because various commands/components read from it. Previously this
 * was done by a hook called "useAutoloadGameFromSession()" in the CloudHost
 * client root, which watched for the prepared playback and called `.load()`.
 * But this was detatched from the actual processes that needed it.
 * Additionally, the acquireOndGameControl was checking whether the gamepack was
 * loaded or not, which is now irrelevant in a PlaybackDesc world. Moving this
 * process here and removing the check speeds up cloud-host responsiveness! We
 * already pass in the Playback via the wrappedControlAPI, and `playback` is a
 * required parameter of api.* methods that need it.
 */
async function maybeLoadPlaybackIntoLocalStore(playback: PlaybackDesc) {
  const store = getLocalGamePlayStore();
  // Compute etags for existing playback and the incoming, ignoring the
  // expiry (since we're making them both now). This allows for easy
  // comparison to know if a `load` actually needs to happen.
  const existing = store.getResolvedPlayback(SessionMode.OnDemand);
  if (nextPlaybackIsSameEtagIsh(existing, playback)) return;

  // Detatch to aid logging
  const detached = ValtioUtils.detachCopy(playback);
  logODG.info('load game playback', { playback: detached });
  await store.load({ playback: detached });
  logODG.info('load game playback done', { playback: detached });
}

async function prepareOndGamePlay(
  playback: Nullable<PlaybackDesc>,
  createGameInfoSnapshot: ReturnType<typeof useCreateGameInfoSnapshot>,
  getSessionId: ReturnType<typeof useGetStreamSessionId>,
  writeExtra: ReturnType<typeof usePlaybackInfoWriteExtra>,
  analytics: OnDGameAnalytics,
  townhallAPI: ReturnType<typeof useTownhallAPI>,
  ondVoiceOverRegistry: VoiceOverRegistry,
  blockLifecycleRulesEvaluator: ReturnType<
    typeof useBlockLifecycleRulesEvaluator
  >,
  startItemId?: PlaybackItemId
) {
  const { ondState, isController, session } = gameSessionStore;

  if (!playback || playback.items.length === 0) {
    logODG.info('prepare on-demand game BAIL no playback');
    return;
  }

  logODG.info('prepare on-demand game', {
    numOfBlocks: playback.items.length,
    gamePackId: session.gamePackId,
  });

  logODG.debug('prepare check stage 1', {
    ondState: ondState?.state,
    isController,
  });

  if (ondState?.state === 'preparing' || !isController) return;

  await setOndGamePlayPreparing();
  await maybeLoadPlaybackIntoLocalStore(playback);

  // Reset block session
  await loadGameSession();
  logODG.debug('game session loaded during prepare');

  const { context, prepared } = OndPhaseContext.FromPrepareForStartVRefed(
    playback,
    {
      blockRecordingCreator: new BlockRecordingCreator(
        createGameInfoSnapshot,
        getSessionId,
        writeExtra,
        analytics
      ),
      gameSessionStore,
      playedBlockTracker: new PlayedBlockTracker(createGameInfoSnapshot),
      townhallAPI,
      ondVoiceOverRegistry,
      blockLifecycleRulesEvaluator,
    },
    startItemId
  );

  // Note: already valtio ref()-ed
  gameSessionStore.ondPreparedContext = context;
  // Set initial 'ready' to false
  await gameSessionStore.refs.ondState?.update({ preparedContextReady: false });

  return async () => {
    // Wait until prepared is generated (audio host, etc), then mark as ready to
    // allow the coordinator to proceed.
    await prepared;
    await gameSessionStore.refs.ondState?.update({
      preparedContextReady: true,
    });
    return context;
  };
}

const startOndGamePlay = async (
  context: OndPhaseContext,
  playIntro = getFeatureQueryParam('game-on-demand-intro')
): Promise<(() => Promise<unknown>) | void> => {
  const { ondState, isController, session } = gameSessionStore;

  logODG.info('start on-demand game', {
    numOfGames: context.playbackItemsLength,
    gamePackId: session.gamePackId,
  });

  if (context.playbackItemsLength === 0) return;

  logODG.debug('start check stage 1', {
    ondState: ondState?.state,
    isController,
  });

  if (ondState?.state === 'running' || !isController) return;

  // Reset block session
  await loadGameSession();

  logODG.debug('game session loaded');

  if (gameSessionStore.ondGameRunner) {
    await gameSessionStore.ondGameRunner.destroy();
    gameSessionStore.ondGameRunner = null;
  }

  return async () => {
    const runner = (gameSessionStore.ondGameRunner =
      OndPhaseRunner.CreatedVRefed(context, new OndPhaseBoot(playIntro)));

    await runner.awake();
    await runner.start();
    runner.finished.finally(endOndGameIfRunning);

    // NOTE(drew): This function is called by the OndGameController, which expects
    // any call to resolve quickly (within the heartbeat). DO NOT AWAIT
    // `runner.finished` here, as it will cause all ond control messages to fail
    // after the first pause/resume: the command queue becomes filled with this
    // promise that will never resolve.
  };
};

const resetOndGamePlay = async (
  params: { force: boolean; retainGamePack?: boolean } = {
    force: false,
    retainGamePack: false,
  },
  fbSvc = firebaseService
): Promise<void> => {
  // BEGIN ond-scoped gameSessionStore RESET: this would be replaced by some
  // sort of ond-scoped store instance.
  {
    // Re-init glue mapping of on-demand blocks -> VideoMixer TrackIds
    gameSessionStore.ondHostVideoMixerTrackIds.destroy();
    gameSessionStore.ondHostVideoMixerTrackIds =
      BlockToVideoMixerTrackMap.CreateVRefed();

    if (gameSessionStore.videoMixers.ondHostVideo) {
      await gameSessionStore.videoMixers.ondHostVideo.destroy();
      gameSessionStore.videoMixers.ondHostVideo = null;
    }

    await gameSessionStore.ondGameRunner?.destroy();
    gameSessionStore.ondPreparedContext = null;
    gameSessionStore.ondPhaseEmitter = OndPhaseEmitterUtils.CreateVRefed();

    const { refs } = gameSessionStore;

    // Note(falcon): this is legacy code. the Object.keys(ref).forEach block in
    // core.ts#reset() will attempt to remove all the values for all the
    // references. HOWEVER! it's possible the ondState reference was not
    // initialized because it's not initialized in the `init` function (see
    // gameSessionHooks.ts:useSubscribeOndGame) in that case, this block of code
    // will remove the ondState.
    if (!refs.ondState) {
      const ondRef = fbSvc.prefixedRef<GameSessionOndState>(
        getDataPath('ondState')
      );
      await ondRef.remove();
    }
  }

  await reset(params);
};

const resumeOndGamePlay = async (
  playback: Nullable<PlaybackDesc>,
  createGameInfoSnapshot: ReturnType<typeof useCreateGameInfoSnapshot>,
  getSessionId: ReturnType<typeof useGetStreamSessionId>,
  writeExtra: ReturnType<typeof usePlaybackInfoWriteExtra>,
  analytics: OnDGameAnalytics,
  townhallAPI: ReturnType<typeof useTownhallAPI>,
  ondVoiceOverRegistry: VoiceOverRegistry,
  blockLifecycleRulesEvaluator: ReturnType<
    typeof useBlockLifecycleRulesEvaluator
  >,
  startItemId?: PlaybackItemId
): Promise<unknown> => {
  const {
    session: { blockSession },
  } = gameSessionStore;

  if (gameSessionStore.ondState?.state !== 'paused' || !playback) return;

  logODG.info('resuming');
  await gameSessionStore.ondGameRunner?.destroy();
  await maybeLoadPlaybackIntoLocalStore(playback);

  const runner = (gameSessionStore.ondGameRunner = OndPhaseRunner.CreatedVRefed(
    OndPhaseContext.FromResume(
      playback,
      {
        blockRecordingCreator: new BlockRecordingCreator(
          createGameInfoSnapshot,
          getSessionId,
          writeExtra,
          analytics
        ),
        gameSessionStore,
        playedBlockTracker: new PlayedBlockTracker(createGameInfoSnapshot),
        townhallAPI,
        ondVoiceOverRegistry,
        blockLifecycleRulesEvaluator,
      },
      blockSession,
      startItemId
    ),
    new OndPhaseResume()
  ));

  await runner.awake();
  await runner.start();
  runner.finished.finally(endOndGameIfRunning);

  // NOTE(drew): This function is called by the OndGameController, which expects
  // any call to resolve quickly (within the heartbeat). DO NOT AWAIT
  // `runner.finished` here, as it will cause all ond control messages to fail
  // after the first pause/resume: the command queue becomes filled with this
  // promise that will never resolve.
};

const pauseOndGamePlay = async (): Promise<void> => {
  const { refs } = gameSessionStore;
  if (!refs.ondState) {
    logODG.error(
      `GameSession.pauseOndGamePlay.refs`,
      new Error('Missing ondState ref')
    );
    return;
  }

  await setOndGamePlayPaused();
  await pauseVideoMixerAndGameRunner();

  logODG.info('paused');
};

const skipOndPreGameHostedTutorial = async (
  context: OndPhaseContext
): Promise<void> => {
  const { ondState, isController, refs } = gameSessionStore;

  if (ondState?.state === 'running' || !isController) {
    logODG.info('cannot skip on-demand pregame hosted tutorial', {
      ondState: ondState?.state,
      isController,
    });
    return;
  }

  const nextContext = OndPhaseContext.SkipPreGameHostedTutorial(context);
  if (context.currentPlaybackItem === nextContext.currentPlaybackItem) {
    logODG.info(
      'cannot skip on-demand pregame hosted tutorial; no item to skip to'
    );
    return;
  }

  logODG.info('skip on-demand pregame hosted tutorial');
  gameSessionStore.ondPreparedContext = ref(nextContext);
  await refs.ondState?.update({
    currentPlaybackItemId: nextContext.currentPlaybackItem?.id,
    jump: nextContext.jump,
  });
};

// NOTE(drew): apparently this is used to be able to resume/recover gameplay
// video progress if the controller refreshes. Ideally it would be more obvious
// as to when this needs to be set, reset, or read.
export const setOndGamePlayVideoProgress = async (
  progress: number | null
): Promise<void> => {
  const {
    refs,
    session: { isLive },
    ondState,
  } = gameSessionStore;

  if (isLive || !ondState) return;

  if (!refs.ondState) {
    logODG.error(
      `GameSession.setOndGamePlayVideoProgress.refs`,
      new Error('Missing ondState ref')
    );
    return;
  }

  try {
    await refs.ondState.update({
      gamePlayVideoProgress: progress,
    });
  } catch (err) {
    logODG.error('GameSession.setOndGamePlayVideoProgress.update', err);
  }
};

export function isOndGameCoordinatable(
  ondState: OndGamePlayState | null
): ondState is OndGamePlayCoordinatableState | null {
  return (
    ondState === null ||
    ondState === 'running' ||
    ondState === 'paused' ||
    ondState === 'preparing' ||
    ondState === 'ended'
  );
}

/**
 * This is used from the Host Controller to play an OND game.
 */
export function useOndGameControlHandlersStateRoot(): {
  playPause: (
    playback: PlaybackDesc | null,
    startPlaybackItemId?: PlaybackItemId
  ) => Promise<void>;
  reset: () => Promise<void>;
  willResume: boolean;
  coordinatable: boolean;
} {
  const ondState = useOndGameState();
  const streamSessionAborted = useIsStreamSessionAborted();
  const streamSessionControl = useStreamSessionControlAPI();
  const [willResume, setWillResume] = useState(false);
  const createGameInfoSnapshot = useCreateGameInfoSnapshot();
  const getStreamSessionId = useGetStreamSessionId();
  const writeExtra = usePlaybackInfoWriteExtra();
  const analytics = useOnDGameAnalytics();
  const townhallAPI = useTownhallAPI();
  const voiceOverRegistry = useOndVoiceOverRegistry();
  const blockLifecycleRulesEvaluator = useBlockLifecycleRulesEvaluator();

  const playPause = async (
    playback: PlaybackDesc | null,
    startPlaybackItemId?: PlaybackItemId
  ) => {
    if (!playback) return;

    const startItemId = playback.items.find(
      (item) => item.id === startPlaybackItemId
    )?.id;

    if (ondState === 'running') {
      await pauseOndGamePlay();
      setWillResume(true);
    } else {
      if (willResume) {
        if (streamSessionAborted) {
          await streamSessionControl.resume();
        }
        await resumeOndGamePlay(
          playback,
          createGameInfoSnapshot,
          getStreamSessionId,
          writeExtra,
          analytics,
          townhallAPI,
          voiceOverRegistry,
          blockLifecycleRulesEvaluator,
          startItemId
        );
        setWillResume(false);
      } else {
        await streamSessionControl.prepare(uuidv4());
        const executor = await prepareOndGamePlay(
          playback,
          createGameInfoSnapshot,
          getStreamSessionId,
          writeExtra,
          analytics,
          townhallAPI,
          voiceOverRegistry,
          blockLifecycleRulesEvaluator,
          startItemId
        );

        // Something has gone wrong.
        if (!executor) return;

        const context = await executor();
        await streamSessionControl.start(SessionMode.OnDemand);
        const firstBlockPromise = await startOndGamePlay(context);
        if (firstBlockPromise) await firstBlockPromise();
      }
    }
  };

  const reset = async () => {
    setWillResume(false);
    endOndGamePlay();
  };

  useEffect(() => {
    if (streamSessionAborted && ondState) {
      pauseOndGamePlay();
      setWillResume(true);
    }
  }, [ondState, streamSessionAborted]);

  useEffect(() => {
    if (willResume === false && ondState === 'paused') {
      setWillResume(true);
    }
    if (willResume === true && ondState !== 'paused') {
      setWillResume(false);
    }
  }, [ondState, willResume]);

  return {
    playPause,
    reset,
    willResume,
    coordinatable: isOndGameCoordinatable(ondState),
  };
}

type OndGameControlRawAPI = {
  prepare: typeof prepareOndGamePlay;
  start: typeof startOndGamePlay;
  pause: typeof pauseOndGamePlay;
  resume: typeof resumeOndGamePlay;
  reset: typeof resetOndGamePlay;
  skipPreGameHostedTutorial: typeof skipOndPreGameHostedTutorial;
};

export function useOndGameControlRawAPI(): OndGameControlRawAPI {
  return useInstance(() => ({
    prepare: prepareOndGamePlay,
    start: startOndGamePlay,
    pause: pauseOndGamePlay,
    resume: resumeOndGamePlay,
    reset: resetOndGamePlay,
    skipPreGameHostedTutorial: skipOndPreGameHostedTutorial,
  }));
}
