import React, { type ReactNode, useContext, useEffect } from 'react';

import { type Media } from '@lp-lib/media';

import { useInstance } from '../../../../../hooks/useInstance';
import { Emitter } from '../../../../../utils/emitter';
import { type GamePlayEndedState } from './Internal';

export type GamePlayEndedEventFinishOptions = {
  animationMedia?: Media | null;
  muteAnimationMedia?: boolean;
  primaryTextNode?: ReactNode;
  secondaryTextNode?: ReactNode;
};

export type GamePlayEvents = {
  'ended-awaiting-goal-media': (
    blockId: string,
    state: 'finished',
    options: GamePlayEndedEventFinishOptions
  ) => void;
  ended: (
    blockId: string,
    state: GamePlayEndedState,
    options?: GamePlayEndedEventFinishOptions
  ) => void;
  'title-card-game-play-media-ended': (blockId: string) => void;
  'h2h-intro-media-ended': (blockId: string) => void;
};

type Context = {
  emitter: Emitter<GamePlayEvents>;
};

const context = React.createContext<Nullable<Context, false>>(null);

function useGamePlayContext(): Context {
  const ctx = useContext(context);
  if (!ctx) throw new Error('GamePlayContext is not in the tree!');
  return ctx;
}

export function useGamePlayEmitter(): Context['emitter'] {
  return useGamePlayContext().emitter;
}

export function GamePlayProvider(props: {
  children?: ReactNode;
}): JSX.Element | null {
  const ctxValue = useInstance(() => ({
    emitter: new Emitter<GamePlayEvents>(),
  }));

  useEffect(() => {
    return () => {
      ctxValue.emitter.clear();
    };
  }, [ctxValue.emitter]);

  return <context.Provider value={ctxValue}>{props.children}</context.Provider>;
}
