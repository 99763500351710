import { Link } from '@remix-run/react';
import React, { useCallback, useEffect, useState } from 'react';
import {
  type Config,
  type PopperOptions,
  usePopperTooltip,
} from 'react-popper-tooltip';

import { useOutsideClick } from '../hooks/useOutsideClick';
import { assertExhaustive } from '../utils/common';
import { OptionsIcon } from './icons/OptionsIcon';

export type ActionButton<T extends string> = {
  key: T;
  kind: 'button';
  icon?: JSX.Element;
  text: string;
  className?: string;
  disabled?: boolean;
  onClick: (event: React.MouseEvent) => void;
};

export type ActionLink<T extends string> = {
  key: T;
  kind: 'link';
  icon?: JSX.Element;
  text: string;
  className?: string;
  href: string;
};

export type ActionDelimiter = {
  kind: 'delimiter';
  className?: string;
};

export type ActionNoop = {
  kind: 'noop';
  className?: string;
};

export type Action<T extends string> =
  | ActionButton<T>
  | ActionLink<T>
  | ActionDelimiter
  | ActionNoop;

type ActionSheetProps<T extends string> = {
  optionsChildren?: React.ReactNode;
  actions: Action<T>[];
  placement?: PopperOptions['placement'];
  btnSizingClassName?: string;
  trigger?: HTMLElement;
  dropdownClassName?: string;
  offset?: Config['offset'];
  icon?: JSX.Element;
  // Note(guoqiang): Do not use that property!
  // It's not really support arrow, just for narrow down the scope of refactor.
  // Will go back here in the near future.
  arrow?: boolean;
};

function ActionItem<T extends string>(props: {
  action: Action<T>;
  setShowItemMenu: (show: boolean) => void;
}) {
  const { action, setShowItemMenu } = props;

  const className = `
    btn
    w-full h-8 px-2
    hover:bg-dark-gray
    border-0 rounded-lg
    flex flex-row justify-start items-center
    text-3xs
    ${action.className}
  `;

  switch (action.kind) {
    case 'button':
      return (
        <button
          type='button'
          key={`${action.key}`}
          onClick={(event) => {
            event.stopPropagation();
            action.onClick(event);
            setShowItemMenu(false);
          }}
          className={`${className}`}
          disabled={action.disabled}
        >
          {action.icon}
          <div className='ml-2'>{action.text}</div>
        </button>
      );
    case 'link':
      return (
        <Link key={`${action.key}`} to={action.href} className={`${className}`}>
          {action.icon}
          <div className='ml-2'>{action.text}</div>
        </Link>
      );
    case 'delimiter':
      return <div className={`w-full h-px bg-black ${className} my-0.5`}></div>;
    case 'noop':
      return null;
    default:
      assertExhaustive(action);
      return null;
  }
}

export function ActionSheet<T extends string>(
  props: ActionSheetProps<T>
): JSX.Element {
  const { actions, optionsChildren, trigger, arrow, icon } = props;
  const [showItemMenu, setShowItemMenu] = useState(false);

  const {
    getTooltipProps,
    getArrowProps,
    setTooltipRef,
    setTriggerRef,
    tooltipRef,
    triggerRef,
    visible,
  } = usePopperTooltip({
    trigger: 'click',
    placement: props.placement || 'left',
    visible: showItemMenu,
    offset: props.offset,
  });

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement> | MouseEvent) => {
      e.stopPropagation();
      setShowItemMenu(!showItemMenu);
    },
    [showItemMenu]
  );

  useEffect(() => {
    if (!trigger) return;
    trigger.addEventListener('click', handleClick);
    setTriggerRef(trigger);
    return () => trigger.removeEventListener('click', handleClick);
  }, [handleClick, setTriggerRef, showItemMenu, trigger]);

  useOutsideClick(tooltipRef, () => setShowItemMenu(false), triggerRef);

  const sizing = `${props.btnSizingClassName ?? 'w-6 h-6'}`;
  const outline = `ring-1 ring-secondary ring-opacity-40`;

  return (
    <div>
      {!trigger && (
        <button onClick={handleClick} ref={setTriggerRef} type='button'>
          {optionsChildren || (
            <div
              className={`${sizing} ${outline} btn flex flex-row justify-center items-center text-white bg-black rounded-lg hover:bg-light-gray pointer-events-on`}
            >
              {icon || <OptionsIcon />}
            </div>
          )}
        </button>
      )}

      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className: `w-auto h-auto rounded-lg text-white flex flex-col p-1 z-50 transition-opacity bg-black whitespace-nowrap border border-secondary ${props.dropdownClassName}`,
          })}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          {arrow && (
            <div
              {...getArrowProps({
                className:
                  'h-4 absolute w-4 pointer-events-off left-0 top-0 mt-n1 before:tooltipArrowBefore after:tooltipArrowAfter',
              })}
            />
          )}

          {actions.map((action, index) => (
            <ActionItem
              key={
                action.kind === 'delimiter' || action.kind === 'noop'
                  ? `${action.kind}_${index}`
                  : action.key
              }
              setShowItemMenu={setShowItemMenu}
              action={action}
            />
          ))}
        </div>
      )}
    </div>
  );
}
