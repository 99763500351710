import React, { type ReactNode, useContext, useEffect, useMemo } from 'react';

import { EnumsSpotlightBlockStageLayout } from '@lp-lib/api-service-client/public';
import { RTDBServerValueTIMESTAMP } from '@lp-lib/firebase-typesafe';
import { type SpotlightBlock } from '@lp-lib/game';
import { type Logger } from '@lp-lib/logger-base';

import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { useIsController } from '../../../../hooks/useMyInstance';
import { getLogger } from '../../../../logger/logger';
import { type TeamId } from '../../../../types';
import { err2s } from '../../../../utils/common';
import { useCohostPositionManager } from '../../../Cohost/CohostPositionManagerProvider';
import {
  type FirebaseService,
  FirebaseValueHandle,
  useFirebaseContext,
} from '../../../Firebase';
import { type BlockId } from '../../../GameRecorder/types';
import {
  createParticipantListFromUids,
  useAllHostClientIdsGetter,
  useCohostClientIdGetter,
  useParticipantsGetter,
} from '../../../Player';
import { StageMode, useStageControlAPI } from '../../../Stage';
import { useTeamMembersGetter, useTeamsGetter } from '../../../TeamAPI/TeamV1';
import { useVenueId } from '../../../Venue';
import { useGameHostingCoordinatorGetter } from '../../GameHostingProvider';
import { useGameSessionActionsSignalManager } from '../../hooks';
import { useGameSessionPreconfigVIPUserIdsGetter } from '../Common/GamePlay/GameSessionPreconfigProvider';
import { SpotlightPreSelectedTeamOrder } from './types';
import { usePreselectedTeamGetter } from './utils';

function makeRootHandle(
  svc: FirebaseService,
  venueId: string
): FirebaseValueHandle<TeamSpotlights> {
  return new FirebaseValueHandle(
    svc.prefixedSafeRef(`spotlight/${venueId}/teamSpotlights`)
  );
}

type TeamSpotlight = {
  teamId: TeamId;
  blockId: BlockId;
  spotlightedAt: number | RTDBServerValueTIMESTAMP;
};

type TeamSpotlights = {
  [teamId: string]: Nullable<TeamSpotlight>;
};

export type SpotlightConfig = {
  team?: Nullable<TeamId>;
  users?: Nullable<string[]>;
  coordinator?: Nullable<boolean>;
};

type Deps = {
  stageControl: ReturnType<typeof useStageControlAPI>;
  copman: ReturnType<typeof useCohostPositionManager>;
  getAllHostClientIds: ReturnType<typeof useAllHostClientIdsGetter>;
  getCohostClientId: ReturnType<typeof useCohostClientIdGetter>;
  getCoordinator: ReturnType<typeof useGameHostingCoordinatorGetter>;
  getVIPUserIds: ReturnType<typeof useGameSessionPreconfigVIPUserIdsGetter>;
  getPreselectedTeam: ReturnType<typeof usePreselectedTeamGetter>;
  getTeams: ReturnType<typeof useTeamsGetter>;
  getTeamMembers: ReturnType<typeof useTeamMembersGetter>;
  getParticipants: ReturnType<typeof useParticipantsGetter>;
};

export class SpotlightControlAPI {
  constructor(
    venueId: string,
    svc: FirebaseService,
    private deps: Deps,
    private log: Logger,
    private rootHandle = makeRootHandle(svc, venueId)
  ) {}

  async reset() {
    this.log.info('reset');
    await this.rootHandle.remove();
  }

  async resetBlock(blockId: BlockId) {
    const hostClientIds = this.deps.getAllHostClientIds();
    const cohostClientId = this.deps.getCohostClientId();
    this.log.info('resetting spotlight block', {
      cohostClientId,
      hostClientIds,
      blockId,
    });
    await this.deps.stageControl.leaveAll([...hostClientIds, cohostClientId]);
    await this.deps.stageControl.updateStageMode(StageMode.BOS);
    await this.deps.copman.setNamedPosition('default');
  }

  async initBlock(blockId: BlockId) {
    this.log.info('initializing spotlight block', { blockId });
    await this.deps.stageControl.updateStageMode(StageMode.SPOTLIGHT_BLOCK);
  }

  async spotlight(block: SpotlightBlock) {
    this.log.info('start spotlight', {
      blockId: block.id,
      preselectedTeamOrder: block.fields.preselectedTeamOrder,
    });

    const clientIds = await this.computeSpotlightConfig(block);
    if (clientIds.length === 0) {
      this.log.info('no spotlighted users');
      return;
    }

    // check if we should position the cohost...
    const cohostClientId = this.deps.getCohostClientId();
    if (clientIds.length === 1 && clientIds[0] === cohostClientId) {
      this.log.info('selected cohost to be on stage, repositioning');
      if (cohostClientId) {
        try {
          await this.deps.copman.setVisibility('hidden');
          await this.deps.copman.setNamedPositionOr(
            'fullscreen-solo',
            'center',
            true
          );
          await this.deps.copman.setVisibility('visible');
        } catch (error) {
          this.log.error('setNamedPosition failed', error);
        }
      }
    } else {
      if (cohostClientId) {
        if (
          block.fields.stageLayout ===
          EnumsSpotlightBlockStageLayout.SpotlightBlockStageLayoutInterview
        ) {
          try {
            await this.deps.copman.setVisibility('hidden');
            await this.deps.copman.setNamedPositionOr(
              'fullscreen-interview',
              null,
              true
            );
            await this.deps.copman.setVisibility('visible');
          } catch (error) {
            this.log.error('setNamedPosition failed', error);
          }
        } else {
          // hide the cohost...they should only be visible for "interviews".
          await this.deps.copman.setVisibility('hidden');
        }
      }
    }

    // bring everyone else on stage.
    const ids = clientIds.filter((id) => id !== cohostClientId);
    const promises = ids.map((clientId) => {
      return this.deps.stageControl
        .join(clientId, StageMode.SPOTLIGHT_BLOCK)
        .catch((err) => {
          this.log.error('bringing on stage failed', err2s(err));
        });
    });
    await Promise.all(promises);
  }

  private async computeSpotlightConfig(
    block: SpotlightBlock
  ): Promise<string[]> {
    // 1. check for a preconfig. if we have a preconfig that's what we should use.
    if (block.fields.preselectedTeamOrder === 0) {
      const vipUserIds = this.deps.getVIPUserIds();
      this.log.info('spotlighting vips users', { vipUserIds });

      const participants = this.deps.getParticipants();
      return createParticipantListFromUids(participants, vipUserIds).map(
        (p) => p.clientId
      );
    }

    // 2. no preconfig, so use the block configuration.
    const team = this.deps.getPreselectedTeam(
      block.fields.preselectedTeamOrder
    );
    if (team) {
      this.log.info('spotlighting team', {
        team,
        preselectedTeamOrder: block.fields.preselectedTeamOrder,
      });
      await this.markTeamSpotlighted(team.id, block.id);
      return this.getTeamMemberClientIds(team.id);
    }

    // 3. no preselected team, try the "special" orders.
    const order = block.fields.preselectedTeamOrder;
    switch (order) {
      case SpotlightPreSelectedTeamOrder.Coordinator: {
        this.log.info('spotlighting coordinator');
        const coordinatorClientId = this.deps.getCoordinator()?.clientId;
        return coordinatorClientId ? [coordinatorClientId] : [];
      }
      case SpotlightPreSelectedTeamOrder.SystemSelected: {
        this.log.info('spotlighting system selected');
        const teamSpotlights = (await this.rootHandle.get()) ?? {};

        // has this blockId spotlighted a team, before? in case there's a refresh, we don't want to pick a new team.
        const previouslySelected = Object.values(teamSpotlights).find(
          (s) => s?.blockId === block.id
        );
        if (previouslySelected) {
          this.log.info('reusing previously spotlighted team', {
            teamId: previouslySelected.teamId,
          });
          return this.getTeamMemberClientIds(previouslySelected.teamId);
        }

        // we haven't spotlighted a team, yet. let's pick one.
        const allTeams = this.deps.getTeams({
          active: true,
          updateStaffTeam: true,
          excludeStaffTeam: true,
          excludeCohostTeam: true,
        });
        const seenTeams = new Set(Object.keys(teamSpotlights));
        let nextTeam = allTeams.find((t) => !seenTeams.has(t.id));
        if (!nextTeam) {
          // we've seen all teams, so find the least recently spotlighted team.
          const sorted = allTeams.sort((a, b) => {
            let aSpotlightedAt = teamSpotlights[a.id]?.spotlightedAt;
            let bSpotlightedAt = teamSpotlights[b.id]?.spotlightedAt;
            if (!aSpotlightedAt || typeof aSpotlightedAt !== 'number') {
              aSpotlightedAt = 0;
            }
            if (!bSpotlightedAt || typeof bSpotlightedAt !== 'number') {
              bSpotlightedAt = 0;
            }
            return aSpotlightedAt - bSpotlightedAt;
          });
          nextTeam = sorted[0];
          this.log.info(
            'all teams have been spotlighted, picking the least recently spotlighted team'
          );
        }
        if (nextTeam) {
          this.log.info('spotlighting team', { teamId: nextTeam.id });
          await this.markTeamSpotlighted(nextTeam.id, block.id);
          return this.getTeamMemberClientIds(nextTeam.id);
        }
        break;
      }
    }
    return [];
  }

  private getTeamMemberClientIds(teamId: TeamId): string[] {
    const members = this.deps.getTeamMembers(teamId);
    return members?.map((t) => t.id) ?? [];
  }

  private async markTeamSpotlighted(teamId: TeamId, blockId: BlockId) {
    await this.rootHandle.update({
      [teamId]: {
        teamId,
        blockId,
        spotlightedAt: RTDBServerValueTIMESTAMP,
      },
    });
  }
}

type Context = {
  controlAPI?: SpotlightControlAPI;
};

const context = React.createContext<Context | null>(null);

function useSpotlightContext(): Context {
  const ctx = useContext(context);
  if (!ctx) throw new Error('SpotlightContext is not in the tree!');
  return ctx;
}

export function useSpotlightControlAPI(): Context['controlAPI'] {
  return useSpotlightContext().controlAPI;
}

export function SpotlightBlockProvider(props: {
  children?: ReactNode;
}): JSX.Element | null {
  const venueId = useVenueId();
  const { svc } = useFirebaseContext();

  const isController = useIsController();

  const stageControl = useStageControlAPI();
  const copman = useCohostPositionManager();
  const getAllHostClientIds = useAllHostClientIdsGetter();
  const getCohostClientId = useCohostClientIdGetter();
  const getVIPUserIds = useGameSessionPreconfigVIPUserIdsGetter();
  const getPreselectedTeam = usePreselectedTeamGetter();
  const getTeams = useTeamsGetter();
  const getTeamMembers = useTeamMembersGetter();
  const getParticipants = useParticipantsGetter();
  const getCoordinator = useGameHostingCoordinatorGetter();

  const deps: Deps = useMemo(() => {
    return {
      stageControl,
      copman,
      getAllHostClientIds,
      getCohostClientId,
      getCoordinator,
      getVIPUserIds,
      getPreselectedTeam,
      getTeams,
      getTeamMembers,
      getParticipants,
    };
  }, [
    copman,
    getAllHostClientIds,
    getCohostClientId,
    getCoordinator,
    getParticipants,
    getPreselectedTeam,
    getTeamMembers,
    getTeams,
    getVIPUserIds,
    stageControl,
  ]);

  const ctx = useMemo(() => {
    return {
      controlAPI: isController
        ? new SpotlightControlAPI(
            venueId,
            svc,
            deps,
            getLogger().scoped('spotlight')
          )
        : undefined,
    };
  }, [isController, venueId, svc, deps]);

  const reset = useLiveCallback(async () => {
    await ctx.controlAPI?.reset();
  });

  const sigman = useGameSessionActionsSignalManager();
  useEffect(() => {
    return sigman.connect({
      name: 'reset',
      before: async () => {
        await reset();
      },
    });
  }, [sigman, reset]);

  return <context.Provider value={ctx}>{props.children}</context.Provider>;
}
