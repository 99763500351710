import { type BoundingBox, type RelativeRect } from '@lp-lib/game';

import { HiddenCanvas } from '../../../utils/canvas';
import { DrawableCanvasVideoFrameBuffer } from '../DrawableCanvasVideoFrameBuffer';
import { FitOperation, type FitOperationKind } from '../FitOperation';
import { matchDimensionsIfNeeded } from '../utils/matchDimensionsIfNeeded';
import type VideoFrameBuffer from '../vendor/amazon-chime-sdk-js/VideoFrameBuffer';
import type VideoFrameProcessor from '../vendor/amazon-chime-sdk-js/VideoFrameProcessor';

export class BoundingBoxProcessor implements VideoFrameProcessor {
  private canvasVideoFrameBuffer = new DrawableCanvasVideoFrameBuffer(
    new HiddenCanvas('bounding-box-processor')
  );

  public enabled = true;
  public maskRectEnabled = true;
  public boundingBoxDrawEnabled = false;

  constructor(
    private maskRect: RelativeRect = { top: 0, right: 0, bottom: 0, left: 0 },
    private box: BoundingBox = { x: 0, y: 0, width: 1, height: 1 },
    private field: BoundingBox = {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    },
    private boxFit: FitOperationKind = 'cover'
  ) {}

  setFieldSize(widthPx: number, heightPx: number): void {
    // pixel dimensions of the "world", aka the final output size of all the processors.
    // 0,0 is upper left.
    this.field.width = widthPx;
    this.field.height = heightPx;
  }

  setBB(box: BoundingBox): void {
    this.box = box;
  }

  setBBPosition(xPct: number, yPct: number): void {
    // x/y offset (upper left corner) from the scene
    // 0,0 is upper left corner
    // 1,1 is lower right corner of the scene
    this.box.x = xPct;
    this.box.y = yPct;
  }

  setBBSize(wPct: number, hPct: number): void {
    // width/height as a percentage of the Scene
    this.box.width = wPct;
    this.box.height = hPct;
  }

  setBBFit(fit: FitOperationKind): void {
    // given the position and size, that creates a bounding box
    // Then the choice is:
    // - squish the incoming signal into the box
    // - cover the box
    // - be contained by the box
    // - natural center
    this.boxFit = fit;
  }

  setMaskRect(rect: RelativeRect): void {
    // top/right/bottom/left pct from the source (incoming) buffer
    this.maskRect = rect;
  }

  process(buffers: VideoFrameBuffer[]): VideoFrameBuffer[] {
    const ctx = this.canvasVideoFrameBuffer.ctx;

    if (
      !this.field.width ||
      !this.field.height ||
      !this.box.width ||
      !this.box.height ||
      !this.enabled ||
      !ctx
    )
      return buffers;

    ctx.clearRect(
      0,
      0,
      this.canvasVideoFrameBuffer.width,
      this.canvasVideoFrameBuffer.height
    );

    matchDimensionsIfNeeded(this.field, this.canvasVideoFrameBuffer);

    for (const b of buffers) {
      const sourceWidth = b.width;
      const sourceHeight = b.height;

      // In order to avoid affine/non-linear transformations and a double pixel
      // copy, the crop rect pct will be relative to the _video_ source, not the
      // bounding box. If it ends up relative to the bounding box, two pixel
      // operations must happen: 1) copy the object-fitted source into the
      // bounding box to perform scaling, translation, 2) copy the cropped box
      // onto the final output canvas. Performing this without a two-draw
      // operation is pretty difficult mathematically to transform between the
      // two coordinate spaces (source space, "field"/output space).

      // 1) compute field-space bounding box
      // 2) compute field-space destination draw box using object fit.
      // 3) compute source-space crop box source
      // 4) compute field-space destination crop box from field-space destination draw box
      // 5) draw pixels from source to destination

      // Field Space: Convert pct bounding box into pixels using the latest field dimensions.
      const boundingBoxPx: BoundingBox = {
        x: this.box.x * this.field.width,
        y: this.box.y * this.field.height,
        width: this.box.width * this.field.width,
        height: this.box.height * this.field.height,
      };

      // Relative Space: Compute the target draw coordinates to fit the source image accordingly
      const drawFitBox = FitOperation[this.boxFit](
        boundingBoxPx.width,
        boundingBoxPx.height,
        sourceWidth,
        sourceHeight
      );

      // Convert to Field Space
      drawFitBox.x += boundingBoxPx.x;
      drawFitBox.y += boundingBoxPx.y;

      const maskRectTop = this.maskRectEnabled ? this.maskRect.top : 0;
      const maskRectRight = this.maskRectEnabled ? this.maskRect.right : 0;
      const maskRectBottom = this.maskRectEnabled ? this.maskRect.bottom : 0;
      const maskRectLeft = this.maskRectEnabled ? this.maskRect.left : 0;

      // Source Space: crop box
      // Floor the X,Y to avoid subpixel rendering (slow), but use round(width +
      // remainder) to ensure a pixel is not lost or accidentally added (and the
      // width/height is still an integer).
      const rawX = maskRectLeft * sourceWidth;
      const rawY = maskRectTop * sourceHeight;
      const sx = Math.floor(rawX);
      const sy = Math.floor(rawY);
      const remainderX = rawX - sx;
      const remainderY = rawY - sy;
      const sw = Math.round(
        (1 - (maskRectLeft + maskRectRight)) * sourceWidth + remainderX
      );
      const sh = Math.round(
        (1 - (maskRectBottom + maskRectTop)) * sourceHeight + remainderY
      );

      // Field Space: destination crop box
      const ratioX = drawFitBox.width / sourceWidth;
      const ratioY = drawFitBox.height / sourceHeight;
      const rawDestX = drawFitBox.x + sx * ratioX;
      const rawDestY = drawFitBox.y + sy * ratioY;
      const destX = Math.floor(rawDestX);
      const destY = Math.floor(rawDestY);
      const remainderDestX = rawDestX - destX;
      const remainderDestY = rawDestY - destY;
      const destW = Math.round(sw * ratioX + remainderDestX);
      const destH = Math.round(sh * ratioY + remainderDestY);

      // Draw from crop to crop!
      const source = b.asCanvasImageSource();
      if (!source) continue;

      ctx.clearRect(0, 0, this.field.width, this.field.height);
      ctx.drawImage(source, sx, sy, sw, sh, destX, destY, destW, destH);

      if (this.boundingBoxDrawEnabled) {
        ctx.strokeStyle = '#01ACC4';
        ctx.lineWidth = 2;
        ctx.strokeRect(
          boundingBoxPx.x,
          boundingBoxPx.y,
          boundingBoxPx.width,
          boundingBoxPx.height
        );
      }
    }

    buffers[0] = this.canvasVideoFrameBuffer;

    return buffers;
  }

  async destroy(): Promise<void> {
    this.canvasVideoFrameBuffer.destroy();
  }
}
