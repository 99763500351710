import { proxy } from 'valtio';
import { devtools } from 'valtio/utils';

import { type ISO8601String } from '../../types';
import { assertDefinedFatal, assertExhaustive } from '../../utils/common';
import { StorageFactory } from '../../utils/storage';
import { uncheckedIndexAccess_UNSAFE } from '../../utils/uncheckedIndexAccess_UNSAFE';
import {
  markSnapshottable,
  type ValtioSnapshottable,
  ValtioUtils,
} from '../../utils/valtio';
import {
  FirebaseReconnectTracker,
  type FirebaseReconnectTrackerStorage,
} from './FirebaseReconnectTracker';

const gameLatencyBuckets = [
  // - Count: number of measures that fell into the bucket.
  // - MsLT: total duration of all measures that fell into the bucket. LTXXX
  //   means a value was "less than XXX" to qualify for the bucket.
  [100, 'xpGameLatencyCountLT0100', 'xpGameLatencyMsLT0100'],
  [200, 'xpGameLatencyCountLT0200', 'xpGameLatencyMsLT0200'],
  [300, 'xpGameLatencyCountLT0300', 'xpGameLatencyMsLT0300'],
  [400, 'xpGameLatencyCountLT0400', 'xpGameLatencyMsLT0400'],
  [500, 'xpGameLatencyCountLT0500', 'xpGameLatencyMsLT0500'],
  [600, 'xpGameLatencyCountLT0600', 'xpGameLatencyMsLT0600'],
  [700, 'xpGameLatencyCountLT0700', 'xpGameLatencyMsLT0700'],
  [800, 'xpGameLatencyCountLT0800', 'xpGameLatencyMsLT0800'],
  [900, 'xpGameLatencyCountLT0900', 'xpGameLatencyMsLT0900'],
  [1000, 'xpGameLatencyCountLT1000', 'xpGameLatencyMsLT1000'],
  [2000, 'xpGameLatencyCountLT2000', 'xpGameLatencyMsLT2000'],
] as const;

const frameTimeBuckets = [
  // - Count: number of measures that fell into the bucket.
  // - MsLT: total duration of all measures that fell into the bucket. LTXXX
  //   means a value was "less than XXX" to qualify for the bucket.
  [10, 'xpFrameCountLT0010', 'xpFrameMsLT0010'],
  [20, 'xpFrameCountLT0020', 'xpFrameMsLT0020'],
  [30, 'xpFrameCountLT0030', 'xpFrameMsLT0030'],
  [40, 'xpFrameCountLT0040', 'xpFrameMsLT0040'],
  [50, 'xpFrameCountLT0050', 'xpFrameMsLT0050'],
  [60, 'xpFrameCountLT0060', 'xpFrameMsLT0060'],
  [70, 'xpFrameCountLT0070', 'xpFrameMsLT0070'],
  [80, 'xpFrameCountLT0080', 'xpFrameMsLT0080'],
  [90, 'xpFrameCountLT0090', 'xpFrameMsLT0090'],
  [100, 'xpFrameCountLT0100', 'xpFrameMsLT0100'],
  [200, 'xpFrameCountLT0200', 'xpFrameMsLT0200'],
  [300, 'xpFrameCountLT0300', 'xpFrameMsLT0300'],
  [400, 'xpFrameCountLT0400', 'xpFrameMsLT0400'],
  [500, 'xpFrameCountLT0500', 'xpFrameMsLT0500'],
  [1000, 'xpFrameCountLT1000', 'xpFrameMsLT1000'],
] as const;

/**
 * The simple names used to mark detracting events. These probably don't need to
 * be exposed outside.
 */
type Detractors = 'long-frame' | 'long-latency' | 'reconnect-game' | 'refresh';

// Pick a bucket that defines the lower boundary of a "long lotency" detracting event.
const LONG_GAME_LATENCY_BUCKET = gameLatencyBuckets.find((b) => b[0] === 500);
assertDefinedFatal(LONG_GAME_LATENCY_BUCKET, 'LongGameLatencyBucket');

// Pick a bucket that defines the lower boundary of a "long frame" detracting event.
// NOTE: purposefully diverging from ExperienceScore / LiteMode. This is more
// fine grained and provides more visibility into improvements.
const LONG_FRAME_BUCKET = frameTimeBuckets.find((b) => b[0] === 50);
assertDefinedFatal(LONG_FRAME_BUCKET, 'LongFrameBucket');

// BucketLists: Please forgive this small "dsl", it's hard to make the logic
// maintainable otherwise. We need the bucket numeric value to be tied to the
// bucket names, with as little duplication as possible.

type BucketLists = typeof gameLatencyBuckets | typeof frameTimeBuckets;

class BucketListUtil {
  static ToObject<T extends BucketLists>(list: T) {
    const obj = {} as { [K in T[number][1 | 2]]: 0 };
    for (const b of list) {
      // initialize to 0, just like all the others
      uncheckedIndexAccess_UNSAFE(obj)[b[1]] = 0;
      uncheckedIndexAccess_UNSAFE(obj)[b[2]] = 0;
    }
    return obj;
  }

  static Find<T extends BucketLists>(buckets: T, durationMs: number) {
    for (let i = 0; i < buckets.length; i++) {
      const [bucket] = buckets[i];
      // Look for a greater bucket, unless this is the last bucket.
      if (durationMs >= bucket && i !== buckets.length - 1) continue;
      return buckets[i];
    }
  }
}

const detractorSpec = [
  LONG_FRAME_BUCKET[1],
  LONG_GAME_LATENCY_BUCKET[1],
  'xpGameReconnectCount',
  'xpRefreshCount',
];

/**
 * The structure that is sent to Mixpanel. This defines a "sample window", aka a
 * batch of metrics that occured between `start` and `end` (added during `flush`).
 */
const InitialStats = {
  // `xpDetractorSpec` defines which detractors are added to create count/ms.
  // This helps make it more obvious in mixpanel when we change the thresholds,
  // or add/remove detractors.
  xpDetractorSpec: detractorSpec,

  xpLongFrameCount: 0,
  xpLongFrameMs: 0,

  xpLongGameLatencyCount: 0,
  xpLongGameLatencyMs: 0,

  xpGameReconnectCount: 0,
  xpGameReconnectMs: 0,

  xpRefreshCount: 0,
  xpRefreshMs: 0,

  // This property computes the total detractors according to the definition of
  // detractors at the time of event emission. Historical or Future analysis may
  // ignore this value should the definition change without a corresponding
  // client change. This is OK because the total detracting event count or
  // duration can also be derived from adding up the raw buckets for long-frame
  // and long-latency.
  xpDetractorCount: 0,
  xpDetractorMs: 0,

  // The sampling window duration for these stats. This can be arbitrary, and
  // will be longer than the emission rate in the event of a refresh.
  xpWindowMs: 0,
  // What percentage of this sample window was taken up by detracting events?
  // Defined as `detractorMs / windowMs`. This could potentially be greater than
  // 100% (>1) since detracting event durations are not exclusive/sequential and
  // can overlap. Think of it similar to a CPU report where >100% indicates more
  // than one core. IMPORTANT: This percentage is only valid for this sample
  // window, and is present for informational purposes more than statistical
  // (summing all of these up is meaningless, for example).
  xpDetractorPct: 0,

  ...BucketListUtil.ToObject(gameLatencyBuckets),
  xpGameLatencyCount: 0, // total samples present in the buckets

  ...BucketListUtil.ToObject(frameTimeBuckets),
  xpFrameCount: 0, // total samples present in the buckets
};

export type ExperienceMetrics = typeof InitialStats;

type Ext = {
  start: ISO8601String;
  persistedAt: ISO8601String[];
  clientId: null | string;
  firebaseTracker: FirebaseReconnectTrackerStorage | null;
  stats: { [K in keyof ExperienceMetrics]: ExperienceMetrics[K] };
  sessionStart: ISO8601String;
  sessionStats: { [K in keyof ExperienceMetrics]: ExperienceMetrics[K] };
};

function makeStorage() {
  return StorageFactory<'xp-metrics', Ext>('local');
}

/**
 * Limit external callers outside of this folder.
 */
export interface ExperienceMetricsManagerExt {
  listenable: ExperienceMetricsManager['listenable'];
  markFrame: ExperienceMetricsManager['markFrame'];
  markGameLatency: ExperienceMetricsManager['markGameLatency'];
}

// Throw away any persisted data older than this age. Persisted data is used to
// keep continuity after a quick refresh and mark refreshes.
const MAX_VALID_PERSIST_AGE_MS = 1000 * 60 * 2;

export class ExperienceMetricsManager {
  // For testing.
  static DateImpl = Date;

  /**
   * Construct an ExperienceMetricsManager with continuity across refreshes.
   */
  static FromStorage(): ExperienceMetricsManager {
    const storage = makeStorage();
    let ext = storage.get('xp-metrics');
    // Leave the data here for now. React StrictMode will double-invoke, so it's
    // better to delete outside of initialization, which happens in React
    // Lifecycles.

    if (ext) {
      // false lint positive
      // eslint-disable-next-line valtio/avoid-this-in-proxy
      const start = new this.DateImpl(ext.start).getTime();
      const now = this.DateImpl.now();
      // Do not rehydrate with "old" data.
      if (now - start > MAX_VALID_PERSIST_AGE_MS) {
        ext = null;
      }
    }

    return new ExperienceMetricsManager(ext);
  }

  // Pass static impl to instance
  private DateImpl = ExperienceMetricsManager.DateImpl;
  private storage = makeStorage();
  private firebaseTracker: FirebaseReconnectTracker;

  private s = markSnapshottable(
    proxy<Ext>({
      start: new this.DateImpl().toISOString(),
      persistedAt: [],
      clientId: null,
      firebaseTracker: null,
      stats: { ...InitialStats },
      sessionStart: new this.DateImpl().toISOString(),
      sessionStats: { ...InitialStats },
    })
  );

  private undevtools = devtools(this.s, { name: 'ExperienceMetricsManager' });

  constructor(hydrateWith: Ext | null) {
    if (hydrateWith) {
      ValtioUtils.update(this.s, hydrateWith);
    }

    this.firebaseTracker = new FirebaseReconnectTracker(
      hydrateWith?.firebaseTracker ?? null
    );

    // Propagate timing implementation.
    this.firebaseTracker.DateNowImpl = () => this.DateImpl.now();
  }

  /**
   *
   * @param connected
   * @param overrideDurationMs Should only used during testing
   */
  trackReconnect(connected: boolean, overrideDurationMs = 0): void {
    const durationMs = this.firebaseTracker.trackConnection(
      connected,
      overrideDurationMs
    );
    if (durationMs > 0) this.markDetractor('reconnect-game', durationMs);
  }

  /**
   *
   * @param clientId
   * @param overrideDurationMs Should only used during testing
   */
  trackRefresh(
    clientId: string,
    overrideDurationMs: number | null = null
  ): void {
    if (this.s.clientId !== null && this.s.clientId !== clientId) {
      this.markRefreshDetractor(overrideDurationMs);
    }
    this.s.clientId = clientId;
  }

  markFrame(durationMs: number): void {
    this.s.stats.xpFrameCount += 1;
    const bucket = BucketListUtil.Find(frameTimeBuckets, durationMs);
    if (bucket) {
      this.s.stats[bucket[1]] += 1;
      this.s.stats[bucket[2]] += durationMs;
      this.s.sessionStats[bucket[1]] += 1;
      this.s.sessionStats[bucket[2]] += durationMs;
    }
    if (LONG_FRAME_BUCKET && durationMs >= LONG_FRAME_BUCKET[0]) {
      this.markDetractor('long-frame', durationMs);
    }
  }

  markGameLatency(durationMs: number): void {
    this.s.stats.xpGameLatencyCount += 1;
    const bucket = BucketListUtil.Find(gameLatencyBuckets, durationMs);
    if (bucket) {
      this.s.stats[bucket[1]] += 1;
      this.s.stats[bucket[2]] += durationMs;
      this.s.sessionStats[bucket[1]] += 1;
      this.s.sessionStats[bucket[2]] += durationMs;
    }
    if (LONG_GAME_LATENCY_BUCKET && durationMs >= LONG_GAME_LATENCY_BUCKET[0]) {
      this.markDetractor('long-latency', durationMs);
    }
  }

  flush(
    end: ISO8601String = new this.DateImpl().toISOString()
  ): ExperienceMetrics {
    const endMs = new this.DateImpl(end).getTime();
    const startMs = new this.DateImpl(this.s.start).getTime();
    const xpWindowMs = endMs - startMs;
    const xpDetractorPct =
      xpWindowMs === 0 ? 0 : this.s.stats.xpDetractorMs / xpWindowMs;

    const out = {
      ...this.s.stats,
      xpWindowMs,
      xpDetractorPct,
    };

    // Reset majority of internal data, except for session stats.

    // Update instead of reset is ok because the stats are shallow
    ValtioUtils.update(this.s.stats, InitialStats);
    this.s.start = end;
    this.s.persistedAt.length = 0;

    // Safe to purge localStorage now, so be a good citizen and cleanup.
    this.cleanup();

    return out;
  }

  flushSession(end: ISO8601String = new this.DateImpl().toISOString()) {
    const endMs = new this.DateImpl(end).getTime();
    const startMs = new this.DateImpl(this.s.sessionStart).getTime();
    const xpWindowMs = endMs - startMs;
    const xpDetractorPct =
      xpWindowMs === 0 ? 0 : this.s.stats.xpDetractorMs / xpWindowMs;

    const out = {
      ...this.s.stats,
      xpWindowMs,
      xpDetractorPct,
    };

    // Reset session data

    // Update instead of reset is ok because the stats are shallow
    ValtioUtils.update(this.s.sessionStats, InitialStats);
    this.s.sessionStart = end;

    // Safe to purge localStorage now, so be a good citizen and cleanup.
    this.cleanup();

    return out;
  }

  /**
   * Call this on unload to ensure the entire window is tracked
   */
  persist(persistedAt = new this.DateImpl().toISOString()): void {
    this.s.persistedAt.push(persistedAt);
    this.s.firebaseTracker = this.firebaseTracker.intoStorage();
    this.storage.set('xp-metrics', this.s);
  }

  cleanup(): void {
    this.storage.remove('xp-metrics');
    this.undevtools?.();
  }

  listenable = (): Readonly<ValtioSnapshottable<Ext>> => {
    return this.s;
  };

  private markRefreshDetractor(overrideDurationMs: number | null = null): void {
    const now = this.DateImpl.now();
    const persistedAt = new this.DateImpl(
      this.s.persistedAt.shift() ?? this.DateImpl.now()
    ).getTime();

    // This will be zero if there was no previously successful persist.
    const durationMs = overrideDurationMs
      ? overrideDurationMs
      : now - persistedAt;
    // Clear out all records of persistence.
    this.s.persistedAt.length = 0;
    this.markDetractor('refresh', durationMs);
  }

  private markDetractor(name: Detractors, durationMs: number): void {
    switch (name) {
      case 'long-frame': {
        this.s.stats.xpLongFrameCount += 1;
        this.s.stats.xpLongFrameMs += durationMs;
        this.s.sessionStats.xpLongFrameCount += 1;
        this.s.sessionStats.xpLongFrameMs += durationMs;
        break;
      }

      case 'long-latency': {
        this.s.stats.xpLongGameLatencyCount += 1;
        this.s.stats.xpLongGameLatencyMs += durationMs;
        this.s.sessionStats.xpLongGameLatencyCount += 1;
        this.s.sessionStats.xpLongGameLatencyMs += durationMs;
        break;
      }

      case 'reconnect-game': {
        this.s.stats.xpGameReconnectCount += 1;
        this.s.stats.xpGameReconnectMs += durationMs;
        this.s.sessionStats.xpGameReconnectCount += 1;
        this.s.sessionStats.xpGameReconnectMs += durationMs;
        break;
      }

      case 'refresh': {
        this.s.stats.xpRefreshCount += 1;
        this.s.stats.xpRefreshMs += durationMs;
        this.s.sessionStats.xpRefreshCount += 1;
        this.s.sessionStats.xpRefreshMs += durationMs;
        break;
      }

      default:
        assertExhaustive(name);
    }

    // All detractors increment the totals. Do it here instead of afterwards to
    // avoid iteration.
    this.s.stats.xpDetractorMs += durationMs;
    this.s.stats.xpDetractorCount += 1;
  }
}
