import {
  defaultBoundingBoxSettings,
  defaultGreenScreenSettings,
  type VideoEffectsSettingsBlock,
  type VideoEffectsSettingsUser,
} from '@lp-lib/game';

import { copy } from '../../utils/common';
import {
  FallbackIds,
  IntroMedias,
  OutroMedias,
  Podiums,
  Stages,
} from './content';
import {
  type VideoEffectsSettings,
  type VideoEffectsSettingsEnableable,
} from './types';

export class VideoEffectsSettingsUtils {
  static FromBlock(block: {
    videoEffectsSettings?: VideoEffectsSettingsBlock | null;
  }): VideoEffectsSettings | null {
    const ves = block.videoEffectsSettings;
    if (!ves) return null;

    const { podium, stage } = ves;
    const podiumConfig = copy(
      Podiums.select(podium.id) ?? Podiums.selectOrThrow(FallbackIds.Podium)
    );

    const stageConfig =
      Stages.select(stage.id) ?? Stages.selectOrThrow(FallbackIds.OndStage);

    return {
      greenScreen: ves.greenScreen,
      boundingBox: ves.boundingBox,
      podium: {
        enabled: podium.enabled,
        config: podiumConfig,
      },
      stage: {
        enabled: stage.enabled,
        config: stageConfig,
      },
      intro: null,
      outro: null,
    };
  }

  static ToBlock(ves: VideoEffectsSettings): VideoEffectsSettingsBlock {
    return {
      greenScreen: ves.greenScreen,
      boundingBox: ves.boundingBox,
      podium: {
        enabled: ves.podium?.enabled ?? false,
        id: ves.podium?.config.id ?? null,
      },
      stage: {
        enabled: ves.stage?.enabled ?? false,
        id: ves.stage?.config.id ?? null,
      },
    };
  }

  static FromUser(
    user: VideoEffectsSettingsUser | null
  ): VideoEffectsSettings | null {
    if (!user) return null;

    const podium =
      Podiums.select(user.podium.id) ??
      Podiums.selectOrThrow(FallbackIds.Podium);
    const stage =
      Stages.select(user.stage.id) ??
      Stages.selectOrThrow(FallbackIds.LiveStage);
    const intro =
      IntroMedias.select(user.intro.id) ??
      IntroMedias.selectOrThrow(FallbackIds.LiveIntro);
    const outro =
      OutroMedias.select(user.outro.id) ??
      OutroMedias.selectOrThrow(FallbackIds.LiveOutro);

    const ves: VideoEffectsSettings = {
      ...user,
      podium: {
        ...(user.podium ?? { enabled: false }),
        config: podium,
      },
      stage: {
        ...(user.stage ?? { enabled: false }),
        config: stage,
      },
      intro: {
        ...(user.intro ?? { enabled: false }),
        config: intro,
      },
      outro: {
        ...(user.outro ?? { enabled: false }),
        config: outro,
      },
    };

    return ves;
  }

  static ToUser(ves: VideoEffectsSettings): VideoEffectsSettingsUser {
    return {
      greenScreen: ves.greenScreen,
      boundingBox: ves.boundingBox,
      podium: {
        enabled: ves.podium?.enabled ?? false,
        id: ves.podium?.config.id ?? null,
      },
      stage: {
        enabled: ves.stage?.enabled ?? false,
        id: ves.stage?.config.id ?? null,
      },
      intro: {
        enabled: ves.intro?.enabled ?? false,
        id: ves.intro?.config.id ?? null,
      },
      outro: {
        enabled: ves.outro?.enabled ?? false,
        id: ves.outro?.config.id ?? null,
      },
    };
  }

  static WithDefaults(
    ves?: Partial<VideoEffectsSettings> | null
  ): VideoEffectsSettings {
    return {
      greenScreen: {
        ...defaultGreenScreenSettings(),
        ...ves?.greenScreen,
      },
      boundingBox: {
        ...defaultBoundingBoxSettings(),
        ...ves?.boundingBox,
      },
      podium: ves?.podium ?? null,
      stage: ves?.stage ?? null,
      intro: ves?.intro ?? null,
      outro: ves?.outro ?? null,
    };
  }

  static HasAnyEnabled(
    ves: VideoEffectsSettingsEnableable | undefined | null
  ): boolean {
    return (
      (ves?.greenScreen.enabled ||
        (ves && 'intro' in ves && ves?.intro?.enabled) ||
        (ves && 'outro' in ves && ves?.outro?.enabled) ||
        ves?.podium?.enabled ||
        ves?.stage?.enabled) ??
      false
    );
  }

  static PodiumFromUser(
    user: VideoEffectsSettingsUser['podium']
  ): VideoEffectsSettings['podium'] {
    const podium =
      Podiums.select(user.id) ?? Podiums.selectOrThrow(FallbackIds.Podium);
    return {
      enabled: user.enabled,
      config: podium,
    };
  }
}
