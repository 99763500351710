import { useLayoutEffect } from 'react';

import { type QuestionBlock } from '@lp-lib/game';

import { type GameControlProps } from '../Common/GameControl/types';
import { useTrackSubmissionGraded } from '../Common/grader';
import { useGradeAPI } from './grader';

export function QuestionBlockGameControl(
  props: GameControlProps<QuestionBlock>
): JSX.Element | null {
  const block = props.block;

  const gradeAPI = useGradeAPI(block);
  const onSubmissionGraded = useTrackSubmissionGraded(
    block,
    gradeAPI.gameaudit
  );

  useLayoutEffect(() => {
    gradeAPI.start();
    return () => {
      gradeAPI.stop();
    };
  }, [gradeAPI, onSubmissionGraded]);

  useLayoutEffect(() => {
    if (!gradeAPI.aiGrade.enabled) return;
    return gradeAPI.on('submission-graded', onSubmissionGraded);
  }, [gradeAPI, onSubmissionGraded]);

  return null;
}
