import isEqual from 'lodash/isEqual';
import { useMemo } from 'react';
import Select, { type FormatOptionLabelMeta } from 'react-select';

import { type ModelsBlockLifecycleRule } from '@lp-lib/api-service-client/public';
import {
  AIChatBlockGameSessionStatus,
  assertExhaustive,
  type Block,
  BlockType,
  CreativePromptBlockGameSessionStatus,
  DrawingPromptBlockGameSessionStatus,
  GuessWhoBlockGameSessionStatus,
  HeadToHeadBlockGameSessionStatus,
  HiddenPictureBlockGameSessionStatus,
  IcebreakerBlockGameSessionStatus,
  InstructionBlockGameSessionStatus,
  JeopardyBlockGameSessionStatus,
  MarketingBlockGameSessionStatus,
  MemoryMatchBlockGameSessionStatus,
  MultipleChoiceGameSessionStatus,
  OverRoastedBlockGameSessionStatus,
  PuzzleBlockGameSessionStatus,
  QuestionBlockGameSessionStatus,
  RandomizerGameSessionStatus,
  RapidBlockGameSessionStatus,
  RoundRobinQuestionBlockGameSessionStatus,
  ScoreboardBlockGameSessionStatus,
  SpotlightBlockGameSessionStatus,
  SpotlightBlockV2GameSessionStatus,
  TeamRelayBlockGameSessionStatus,
  TitleBlockV2GameSessionStatus,
} from '@lp-lib/game';

import { useLiveCallback } from '../../../../../hooks/useLiveCallback';
import { type TownhallMode } from '../../../../../types/townhall';
import { uuidv4 } from '../../../../../utils/common';
import { buildReactSelectStyles } from '../../../../../utils/react-select';
import {
  type CohostNamedPosition,
  type CohostVisibility,
} from '../../../../Cohost/types';
import { DragDropList } from '../../../../common/DragDrop';
import { type Option } from '../../../../common/Utilities';
import { DeleteIcon } from '../../../../icons/DeleteIcon';
import { MenuIcon } from '../../../../icons/MenuIcon';
import { PlusIcon } from '../../../../icons/PlusIcon';
import {
  FieldContainer,
  FieldDescription,
  FieldPanel,
  FieldTitle,
} from '../Editor/FieldEditorUtilities';
import {
  type BlockLifecycleCondition,
  type BlockLifecycleOperation,
  type SetValueOperation,
} from './types';
import { isKnownOperation, isOnStatusCondition } from './utils';

export function BlockLifecycleRulesEditor(props: {
  block: Block;
  value: ModelsBlockLifecycleRule[] | null | undefined;
  onChange: (rules: ModelsBlockLifecycleRule[]) => void;
}) {
  const { block, value, onChange } = props;
  const handleAddRule = useLiveCallback(() => {
    const operation = createOperation('set-cohost-position');
    if (!operation) return;

    const next = [...(value ?? [])];
    next.push({
      id: uuidv4(),
      condition: {
        type: 'on-status',
        props: {
          value: 0,
        },
      },
      operation,
    });
    onChange(next);
  });
  const handleDeleteRule = useLiveCallback((ruleId: string) => {
    if (!value) return;
    onChange(value.filter((rule) => rule.id !== ruleId));
  });
  const handleRuleChange = useLiveCallback((rule: ModelsBlockLifecycleRule) => {
    if (!value) return;
    onChange(value.map((r) => (r.id === rule.id ? rule : r)));
  });
  const handleMoveRule = useLiveCallback((from: number, to: number) => {
    if (!value) return;
    const rules = [...value];
    const [removed] = rules.splice(from, 1);
    rules.splice(to, 0, removed);
    onChange(rules);
  });
  return (
    <FieldContainer styles={{ layout: 'flex flex-col gap-4' }}>
      <FieldPanel>
        <FieldTitle>Lifecycle Rules</FieldTitle>
        <FieldDescription>
          Define operations to be triggered at various points in the block
          lifecycle.
        </FieldDescription>
      </FieldPanel>
      <div className='w-full space-y-4'>
        <DragDropList
          type='rule-editors'
          items={value ?? []}
          onMove={handleMoveRule}
          render={({ item, index, drag, ref, style }) => (
            <div
              className='w-full flex items-center group'
              ref={ref}
              style={style}
            >
              <div className='flex-none font-bold tabular-nums text-icon-gray text-2xs min-w-5 mt-1'>
                <div className='group-hover:hidden'>{index + 1}.</div>
                <div className='hidden group-hover:flex cursor-move' ref={drag}>
                  <MenuIcon />
                </div>
              </div>

              <BlockLifecycleRuleEditor
                block={block}
                rule={item}
                onChange={handleRuleChange}
                onDelete={() => handleDeleteRule(item.id)}
              />
            </div>
          )}
        />
        <button
          type='button'
          className='btn flex justify-center items-center text-primary gap-1 text-sm hover:bg-light-gray px-2 py-1 rounded transition-colors'
          onClick={handleAddRule}
        >
          <PlusIcon />
          Add Rule
        </button>
      </div>
    </FieldContainer>
  );
}

function createOperation(
  type: BlockLifecycleOperation['type']
): BlockLifecycleOperation | null {
  switch (type) {
    case 'set-cohost-position':
      return {
        type,
        props: {
          value: 'default',
        },
      };
    case 'set-cohost-visibility':
      return {
        type,
        props: { value: 'hidden' },
      };
    case 'set-townhall-mode':
      return {
        type,
        props: { value: 'team' },
      };
    case 'mute-audience':
    case 'cycle-team-captains':
      return {
        type,
      };
    default:
      assertExhaustive(type);
      return null;
  }
}

function BlockLifecycleRuleEditor(props: {
  block: Block;
  rule: ModelsBlockLifecycleRule;
  onChange: (rule: ModelsBlockLifecycleRule) => void;
  onDelete: () => void;
}) {
  const { block, rule, onChange, onDelete } = props;
  return (
    <div className='w-full px-3 py-2 bg-layer-002 rounded-md flex items-center text-white'>
      <div className='flex-1 flex items-center gap-1'>
        <ConditionEditor
          block={block}
          condition={rule.condition}
          onChange={(condition) => {
            onChange({
              ...rule,
              condition,
            });
          }}
        />
        <OperationEditor
          operation={rule.operation}
          onChange={(operation) => {
            onChange({
              ...rule,
              operation,
            });
          }}
        />
      </div>
      <button
        type='button'
        className='flex-none ml-auto pr-2 btn text-red-002 flex items-center justify-center transform transition-transform hover:scale-110'
        onClick={onDelete}
      >
        <DeleteIcon className='fill-current w-4 h-4' />
      </button>
    </div>
  );
}

function ConditionEditor(props: {
  block: Block;
  condition: ModelsBlockLifecycleRule['condition'];
  onChange: (condition: ModelsBlockLifecycleRule['condition']) => void;
}) {
  const { block, condition, onChange } = props;

  // only one condition type supported.
  if (!isOnStatusCondition(condition)) return <div>Unknown condition type</div>;

  return (
    <>
      <div>When</div>
      <SelectBlockSessionStatus
        block={block}
        value={condition}
        onChange={(v) => {
          if (!v) return;
          onChange(v.value);
        }}
      />
    </>
  );
}

const sessionMap = {
  [BlockType.QUESTION]: QuestionBlockGameSessionStatus,
  [BlockType.RAPID]: RapidBlockGameSessionStatus,
  [BlockType.CREATIVE_PROMPT]: CreativePromptBlockGameSessionStatus,
  [BlockType.SCOREBOARD]: ScoreboardBlockGameSessionStatus,
  [BlockType.SPOTLIGHT]: SpotlightBlockGameSessionStatus,
  [BlockType.SPOTLIGHT_V2]: SpotlightBlockV2GameSessionStatus,
  [BlockType.TEAM_RELAY]: TeamRelayBlockGameSessionStatus,
  [BlockType.RANDOMIZER]: RandomizerGameSessionStatus,
  [BlockType.MULTIPLE_CHOICE]: MultipleChoiceGameSessionStatus,
  [BlockType.MEMORY_MATCH]: MemoryMatchBlockGameSessionStatus,
  [BlockType.PUZZLE]: PuzzleBlockGameSessionStatus,
  [BlockType.ROUND_ROBIN_QUESTION]: RoundRobinQuestionBlockGameSessionStatus,
  [BlockType.TITLE_V2]: TitleBlockV2GameSessionStatus,
  [BlockType.INSTRUCTION]: InstructionBlockGameSessionStatus,
  [BlockType.OVERROASTED]: OverRoastedBlockGameSessionStatus,
  [BlockType.DRAWING_PROMPT]: DrawingPromptBlockGameSessionStatus,
  [BlockType.HIDDEN_PICTURE]: HiddenPictureBlockGameSessionStatus,
  [BlockType.AI_CHAT]: AIChatBlockGameSessionStatus,
  [BlockType.GUESS_WHO]: GuessWhoBlockGameSessionStatus,
  [BlockType.ICEBREAKER]: IcebreakerBlockGameSessionStatus,
  [BlockType.MARKETING]: MarketingBlockGameSessionStatus,
  [BlockType.JEOPARDY]: JeopardyBlockGameSessionStatus,
  [BlockType.HEAD_TO_HEAD]: HeadToHeadBlockGameSessionStatus,
} as const;

function SelectBlockSessionStatus(props: {
  block: Block;
  value: BlockLifecycleCondition;
  onChange: (value: Option<BlockLifecycleCondition> | null) => void;
}) {
  const options: Option<BlockLifecycleCondition>[] = useMemo(() => {
    if (props.block.type === BlockType.TITLE_V2) {
      const options: Option<BlockLifecycleCondition>[] = [
        {
          label: 'LOADED',
          value: {
            type: 'on-status',
            props: { value: TitleBlockV2GameSessionStatus.LOADED },
          },
        },
      ];

      const numCards = props.block.fields.cards?.length ?? 0;
      let value = TitleBlockV2GameSessionStatus.PRESENT_START;
      for (let i = 0; i < numCards; i++) {
        options.push({
          label: `CARD ${value}`,
          value: {
            type: 'on-status',
            props: { value },
          },
        });
        value++;
      }
      options.push({
        label: 'END',
        value: {
          type: 'on-status',
          props: { value: TitleBlockV2GameSessionStatus.END },
        },
      });
      return options;
    }

    return Object.values(sessionMap[props.block.type])
      .filter((t) => typeof t !== 'string')
      .map((t) => ({
        label: sessionMap[props.block.type][t],
        value: {
          type: 'on-status',
          props: { value: t },
        },
      }));
  }, [props.block]);

  const styles = useMemo(
    () =>
      buildReactSelectStyles<Option<BlockLifecycleCondition>>({
        override: {
          menu: {
            width: 'max-content',
            minWidth: '100%',
          },
          control: {
            width: 'max-content',
            minWidth: '100%',
          },
        },
      }),
    []
  );
  const value = useMemo(() => {
    return (
      options.find((o) => isEqual(o.value, props.value)) ?? {
        value: props.value,
        label: `Unknown Condition Type – ${props.value.type}`,
      }
    );
  }, [options, props.value]);
  const formatOptionLabel = useLiveCallback(
    (
      option: Option<BlockLifecycleCondition>,
      meta: FormatOptionLabelMeta<Option<BlockLifecycleCondition>>
    ) => {
      if (
        props.block.type === BlockType.TITLE_V2 &&
        option.value.type === 'on-status' &&
        meta.context === 'menu'
      ) {
        const card = props.block.fields.cards?.[option.value.props.value - 1];
        if (card?.text) {
          return (
            <div className='w-full space-y-1'>
              <div>{option.label}</div>
              <div className='text-xs text-icon-gray max-w-60 truncate'>
                {card.text}
              </div>
            </div>
          );
        }
      }
      return option.label;
    }
  );

  return (
    <Select<Option<BlockLifecycleCondition>, false>
      classNamePrefix='select-box-v2'
      className='w-max text-xs'
      styles={styles}
      value={value}
      options={options}
      onChange={props.onChange}
      isSearchable={false}
      formatOptionLabel={formatOptionLabel}
    />
  );
}

function OperationEditor(props: {
  operation: ModelsBlockLifecycleRule['operation'];
  onChange: (operation: ModelsBlockLifecycleRule['operation']) => void;
}) {
  const { operation, onChange } = props;
  const body = useMemo(() => {
    if (!isKnownOperation(operation)) return null;
    const type = operation.type;
    switch (type) {
      case 'set-cohost-position':
        return (
          <SetValueEditor<CohostNamedPosition>
            options={cohostPositionOptions}
            operation={operation}
            onChange={onChange}
          />
        );
      case 'set-cohost-visibility':
        return (
          <SetValueEditor<CohostVisibility>
            options={cohostVisibilityOptions}
            operation={operation}
            onChange={onChange}
          />
        );
      case 'set-townhall-mode':
        return (
          <SetValueEditor<TownhallMode>
            options={townhallModeOptions}
            operation={operation}
            onChange={onChange}
          />
        );
      case 'mute-audience':
      case 'cycle-team-captains':
        return null;
      default:
        assertExhaustive(type);
        return null;
    }
  }, [onChange, operation]);

  if (!isKnownOperation(operation)) {
    return <div>Unknown operation type</div>;
  }

  return (
    <>
      <SelectOperation
        value={operation.type}
        onChange={(v) => {
          if (!v) return;
          const nextOp = createOperation(v.value);
          if (!nextOp) return;
          onChange(nextOp);
        }}
      />
      {body}
    </>
  );
}

const operationOptions: Option<BlockLifecycleOperation['type']>[] = [
  {
    label: 'Set Cohost Position',
    value: 'set-cohost-position',
  },
  {
    label: 'Set Cohost Visibility',
    value: 'set-cohost-visibility',
  },
  {
    label: 'Set Townhall Mode',
    value: 'set-townhall-mode',
  },
  {
    label: 'Mute Audience',
    value: 'mute-audience',
  },
  {
    label: 'Cycle Team Captains',
    value: 'cycle-team-captains',
  },
];

function SelectOperation(props: {
  value:
    | Option<BlockLifecycleOperation['type']>
    | BlockLifecycleOperation['type'];
  onChange: (value: Option<BlockLifecycleOperation['type']> | null) => void;
}) {
  const styles = useMemo(
    () =>
      buildReactSelectStyles<Option<BlockLifecycleOperation['type']>>({
        override: {
          menu: {
            width: 'max-content',
            minWidth: '100%',
          },
          control: {
            width: 'max-content',
            minWidth: '100%',
          },
        },
      }),
    []
  );
  const value = useMemo(() => {
    if (typeof props.value === 'string') {
      return (
        operationOptions.find((o) => o.value === props.value) ?? {
          label: props.value,
          value: props.value,
        }
      );
    }
    return props.value;
  }, [props.value]);
  return (
    <Select<Option<BlockLifecycleOperation['type']>, false>
      classNamePrefix='select-box-v2'
      className='text-xs'
      styles={styles}
      value={value}
      options={operationOptions}
      onChange={props.onChange}
      isSearchable={false}
    />
  );
}

function SetValueEditor<T>(props: {
  options: Option<T>[];
  operation: SetValueOperation<T>;
  onChange: (rule: SetValueOperation<T>) => void;
}) {
  const { operation, onChange } = props;
  const handleValueChange = useLiveCallback((value: T) => {
    onChange({
      ...operation,
      props: { value },
    });
  });
  const value = useMemo(() => {
    return (
      props.options.find((o) => o.value === operation.props.value) ?? {
        label: String(operation.props.value),
        value: operation.props.value,
      }
    );
  }, [operation.props.value, props.options]);
  const styles = useMemo(
    () =>
      buildReactSelectStyles<Option<T>>({
        override: {
          menu: {
            width: 'max-content',
            minWidth: '100%',
          },
          control: {
            width: 'max-content',
            minWidth: '100%',
          },
        },
      }),
    []
  );
  return (
    <>
      <div>to</div>
      <Select<Option<T>, false>
        classNamePrefix='select-box-v2'
        className='text-xs'
        styles={styles}
        value={value}
        options={props.options}
        onChange={(v) => {
          if (v === null) return;
          handleValueChange(v.value);
        }}
        isSearchable={false}
      />
    </>
  );
}

const cohostVisibilityOptions: Option<CohostVisibility>[] = [
  {
    label: 'Visible',
    value: 'visible',
  },
  {
    label: 'Hidden',
    value: 'hidden',
  },
  {
    label: 'Placeholder',
    value: 'placeholder',
  },
];

const cohostPositionOptions: Option<CohostNamedPosition>[] = [
  {
    label: 'Corner',
    value: 'default',
  },
  {
    label: 'Center',
    value: 'center',
  },
  {
    label: 'Fullscreen Solo',
    value: 'fullscreen-solo',
  },
  {
    label: 'Fullscreen Interview',
    value: 'fullscreen-interview',
  },
];

const townhallModeOptions: Option<TownhallMode>[] = [
  {
    label: 'Team',
    value: 'team',
  },
  {
    label: 'Crowd',
    value: 'crowd',
  },
];
