import { useEffect, useState } from 'react';

import {
  type HiddenPictureBlock,
  HiddenPictureBlockGameSessionStatus,
} from '@lp-lib/game';

import type { TeamId } from '../../../../types';
import { assertExhaustive } from '../../../../utils/common';
import { DoubleRightArrow } from '../../../icons/Arrows';
import { PlayIcon } from '../../../icons/PlayIcon';
import { RefreshIcon } from '../../../icons/RefreshIcon';
import { FilledSquareIcon } from '../../../icons/SquareIcon';
import { TimerIcon } from '../../../icons/TimerIcon';
import {
  useGameSessionLocalTimer,
  useGameSessionStatus,
  useIsEndedBlock,
} from '../../hooks';
import { countdownV2, next, present, triggerBlockTitleAnim } from '../../store';
import {
  BlockControllerActionButton,
  BlockControllerActionNone,
  BlockControllerActionTimeRemaining,
  useBlockControllerBlockTitlePlaying,
} from '../Common/Controller';
import {
  type MediaPlayback,
  MediaPreviewer,
  useControllerMediaPlayback,
  useControllerMediaPlayText,
} from '../Common/Controller/ControllerMedia';
import { ControllerProgress } from '../Common/Controller/ControllerProgress';
import { type ControllerProps } from '../Common/Controller/types';
import { ControllerLayout } from '../Common/Controller/Utilities';
import { useStableBlock } from '../Common/hooks';
import { BlockKnifeUtils } from '../Shared';
import {
  useHiddenPictureAllTeamsProgressData,
  useHiddenPictureGamePictures,
  useHiddenPictureTeamProgressData,
} from './HiddenPictureProvider';
import { HiddenPictureUtils } from './utils';

type SharedProps = ControllerProps<HiddenPictureBlock>;
type StageProps = SharedProps & {
  playback: MediaPlayback;
};

export function HiddenPictureBlockTeamProgressSummary(props: {
  block: HiddenPictureBlock;
  teamId: TeamId;
}) {
  const progress = useHiddenPictureTeamProgressData(props.teamId);
  const pictures = useHiddenPictureGamePictures();
  const numPictures = pictures.length;
  const numPicturesCompleted = progress?.numPicturesCompleted ?? 0;
  const hotSpots = pictures[progress?.currentPictureIndex ?? 0]?.hotSpots;
  const progressableHotSpots =
    HiddenPictureUtils.GetProgressableHotSpots(hotSpots);
  const numItems = progressableHotSpots.length;
  const numFound = HiddenPictureUtils.CountFoundProgressableHotSpots(
    hotSpots,
    progress?.found
  );
  return `Progress: ${numPicturesCompleted}/${numPictures} pictures – ${numFound}/${numItems} items`;
}

function GameProgress(
  props: StageProps & { revealResults?: boolean; hideProgressDetail?: boolean }
): JSX.Element | null {
  const {
    selectedBlock: block,
    setEditPointsData,
    hideProgressDetail,
    revealResults,
  } = props;

  const pictures = useHiddenPictureGamePictures();
  const progress = useHiddenPictureAllTeamsProgressData();
  const numPictures = pictures.length;
  const maxPoints = pictures
    .flatMap((p) => p.hotSpots ?? [])
    .reduce((accum, curr) => accum + curr.points, 0);

  return (
    <ControllerProgress
      blockId={block.id}
      setEditPointsData={setEditPointsData}
      header={`${numPictures} Pictures – Max ${maxPoints} Points`}
      revealResults={revealResults}
      hideProgressDetail={hideProgressDetail}
      progressDetail={(teamId: string) => {
        const numPicturesCompleted =
          progress[teamId]?.numPicturesCompleted ?? 0;
        const hotSpots =
          pictures[progress[teamId]?.currentPictureIndex ?? 0]?.hotSpots;
        const progressableHotSpots =
          HiddenPictureUtils.GetProgressableHotSpots(hotSpots);
        const numItems = progressableHotSpots.length;
        const numFound = HiddenPictureUtils.CountFoundProgressableHotSpots(
          hotSpots,
          progress[teamId]?.found
        );
        return `[${numPicturesCompleted}/${numPictures} pictures – ${numFound}/${numItems} items]`;
      }}
    />
  );
}

function Loaded(props: StageProps): JSX.Element {
  const { selectedBlock: block, playback } = props;

  const { switchMedia, reset: resetPlayback } = playback.api;

  useEffect(() => {
    resetPlayback();
    const media = BlockKnifeUtils.Media(block, 'introMedia');
    switchMedia(media);
  }, [block, switchMedia, resetPlayback]);

  const onPresent = async () => {
    await triggerBlockTitleAnim(block);
    await present(block);
  };

  const actionComponent = useBlockControllerBlockTitlePlaying(block) ?? (
    <BlockControllerActionButton onClick={onPresent} icon={PlayIcon}>
      Present Block
    </BlockControllerActionButton>
  );
  return (
    <ControllerLayout action={actionComponent}>
      <div className='w-full flex flex-col'>
        <MediaPreviewer
          playback={playback}
          alt={block.type}
          text='Hidden Pictures'
        />
      </div>
    </ControllerLayout>
  );
}

function Intro(props: StageProps): JSX.Element | null {
  const { selectedBlock: block, playback } = props;
  const mediaActionText = useControllerMediaPlayText(playback);
  const [presentIntro, setPresentIntro] = useState(!!playback.preview);
  const { play: playMedia } = playback.api;

  useEffect(() => {
    if (!playback.isVideoPlayed) return;
    setPresentIntro(false);
  }, [playback.isVideoPlayed]);

  useEffect(() => {
    playMedia();
  }, [playMedia]);

  useEffect(() => {
    if (presentIntro) return;
    next();
  }, [presentIntro]);

  if (presentIntro) {
    return (
      <ControllerLayout
        action={
          <BlockControllerActionNone icon={PlayIcon}>
            {mediaActionText}
          </BlockControllerActionNone>
        }
      >
        <div className='w-full flex flex-col'>
          <MediaPreviewer
            playback={playback}
            alt={block.type}
            text='Hidden Pictures'
          />
        </div>
      </ControllerLayout>
    );
  }
  return null;
}

function GameInit(props: StageProps): JSX.Element {
  const { selectedBlock: block, playback } = props;

  const { switchMedia } = playback.api;
  const [processing, setProcessing] = useState(false);
  const gameTimeSec = useGameSessionLocalTimer();

  useEffect(() => {
    switchMedia(HiddenPictureUtils.GetBackgroundMedia(block));
  }, [switchMedia, block]);

  const onStartGame = async () => {
    if (gameTimeSec === 0) return;
    setProcessing(true);
    await countdownV2({
      debug: 'HiddenPictureBlockGameControl',
      startTimeWorker: true,
      flushCountingStatus: true,
      triggerTimesup: true,
    });
  };

  return (
    <ControllerLayout
      action={
        <BlockControllerActionButton
          onClick={onStartGame}
          icon={TimerIcon}
          disable={processing || gameTimeSec === 0}
        >
          {`Start Game Timer (${gameTimeSec}s)`}
        </BlockControllerActionButton>
      }
    >
      <div className='w-full flex flex-col'>
        <MediaPreviewer
          playback={playback}
          alt={block.type}
          text='Hidden Pictures'
        />
      </div>
    </ControllerLayout>
  );
}

function GameStart(props: StageProps): JSX.Element {
  const time = useGameSessionLocalTimer();

  return (
    <ControllerLayout
      action={<BlockControllerActionTimeRemaining remainingSec={time} />}
    >
      <GameProgress {...props} />
    </ControllerLayout>
  );
}

function GameEnd(props: StageProps): JSX.Element {
  const [processing, setProcessing] = useState(false);

  const onRevealResult = async () => {
    setProcessing(true);
    await next();
  };
  return (
    <ControllerLayout
      action={
        <BlockControllerActionButton
          onClick={onRevealResult}
          icon={DoubleRightArrow}
          disable={processing}
        >
          Reveal Results
        </BlockControllerActionButton>
      }
    >
      <GameProgress {...props} revealResults />
    </ControllerLayout>
  );
}

function Outro(props: StageProps): JSX.Element | null {
  const { selectedBlock: block, playback } = props;
  const mediaActionText = useControllerMediaPlayText(playback);
  const { switchMedia, play: playMedia } = playback.api;
  useEffect(() => {
    if (!playback.isVideoPlayed) return;
    next();
  }, [playback.isVideoPlayed]);

  useEffect(() => {
    const media = BlockKnifeUtils.Media(block, 'outroMedia');
    if (media) {
      switchMedia(media);
      requestAnimationFrame(() => playMedia());
    } else {
      next();
    }
  }, [block, switchMedia, playMedia]);

  if (playback.preview) {
    return (
      <ControllerLayout
        action={
          <BlockControllerActionNone icon={PlayIcon}>
            {mediaActionText}
          </BlockControllerActionNone>
        }
      >
        <GameProgress {...props} revealResults />
      </ControllerLayout>
    );
  }
  return null;
}

function Results(props: StageProps): JSX.Element {
  const onShowScoreboard = async () => {
    await next();
  };

  return (
    <ControllerLayout
      action={
        <>
          <BlockControllerActionButton
            onClick={onShowScoreboard}
            isSecondary={true}
          >
            Show Scoreboard
          </BlockControllerActionButton>
          <BlockControllerActionButton
            onClick={props.onEndBlock}
            icon={FilledSquareIcon}
          >
            End Block Sequence
          </BlockControllerActionButton>
        </>
      }
    >
      <GameProgress {...props} revealResults />
    </ControllerLayout>
  );
}

function Scoreboard(props: StageProps): JSX.Element {
  return (
    <ControllerLayout
      action={
        <BlockControllerActionButton
          onClick={props.onEndBlock}
          icon={FilledSquareIcon}
        >
          End Block Sequence
        </BlockControllerActionButton>
      }
    >
      <GameProgress {...props} revealResults />
    </ControllerLayout>
  );
}

function Ended(props: StageProps): JSX.Element {
  return (
    <ControllerLayout
      action={
        <BlockControllerActionButton
          onClick={() => props.setShowResetConfirmation(true)}
          icon={RefreshIcon}
          isDelete={true}
        >
          Reset Completed Block
        </BlockControllerActionButton>
      }
    >
      <GameProgress {...props} hideProgressDetail revealResults />
    </ControllerLayout>
  );
}

export function HiddenPictureBlockController(
  props: ControllerProps<HiddenPictureBlock>
): JSX.Element | null {
  const { selectedBlockIndex } = props;
  const block = useStableBlock(props.selectedBlock);
  const isEndedBlock = useIsEndedBlock(block.id);
  const gameSessionStatus =
    useGameSessionStatus<HiddenPictureBlockGameSessionStatus>();
  const playback = useControllerMediaPlayback();

  if (selectedBlockIndex === null) return null;

  if (isEndedBlock) {
    return <Ended {...props} playback={playback} />;
  }

  switch (gameSessionStatus) {
    case HiddenPictureBlockGameSessionStatus.LOADED:
      return <Loaded {...props} playback={playback} />;
    case HiddenPictureBlockGameSessionStatus.INTRO:
      return <Intro {...props} playback={playback} />;
    case HiddenPictureBlockGameSessionStatus.GAME_INIT:
      return <GameInit {...props} playback={playback} />;
    case HiddenPictureBlockGameSessionStatus.GAME_START:
      return <GameStart {...props} playback={playback} />;
    case HiddenPictureBlockGameSessionStatus.GAME_END:
      return <GameEnd {...props} playback={playback} />;
    case HiddenPictureBlockGameSessionStatus.OUTRO:
      return <Outro {...props} playback={playback} />;
    case HiddenPictureBlockGameSessionStatus.RESULTS:
      return <Results {...props} playback={playback} />;
    case HiddenPictureBlockGameSessionStatus.SCOREBOARD:
      return <Scoreboard {...props} playback={playback} />;
    case HiddenPictureBlockGameSessionStatus.END:
    case null:
    case undefined:
      break;
    default:
      assertExhaustive(gameSessionStatus);
      break;
  }

  return null;
}
