import {
  type Block,
  type BlockAction,
  type BlockRecording,
  type GameSessionStatus,
  GameSessionUtil,
  GreenScreenValuesUtils,
  type Media,
  type MediaFormat,
  MediaType,
  VolumeLevelUtils,
} from '@lp-lib/game';
import { type Logger } from '@lp-lib/logger-base';

import {
  getFeatureQueryParam,
  getFeatureQueryParamNumber,
} from '../../../hooks/useFeatureQueryParam';
import { assertExhaustive, xDomainifyUrl } from '../../../utils/common';
import { MediaPickPriorityFHD, MediaUtils } from '../../../utils/media';
import {
  UnplayableImageImpl,
  type UnplayableMedia,
  UnplayableMediaFactory,
  UnplayableVideoImpl,
} from '../../../utils/unplayable';
import { FallbackIds, Stages } from '../../VideoEffectsSettings/content';
import { type VideoEffectsSettings } from '../../VideoEffectsSettings/types';
import { VideoEffectsSettingsUtils } from '../../VideoEffectsSettings/VideoEffectsSettingsUtils';
import {
  type TrackId,
  TrackInitConfigBuilder,
  type VideoMixer,
} from '../../VideoMixer';
import { type VoiceOverRegistry } from '../../VoiceOver/VoiceOverRegistry';
import { type BlockPlaybackPlan } from '../OndPhaseRunner/ond-timing';
import { type BlockRecordingCreator } from '../OndPhaseRunner/OndPhaseRunner';
import { OndVersionChecks } from '../OndVersionChecks';
import { type PlaybackDescItem } from '../Playback/intoPlayback';
import { asBlockId } from './asBlockId';
import { type BlockToVideoMixerTrackMap } from './BlockToVideoMixerTrackMap';
import {
  OND_INTRO_VIDEO_TRANSITION_HALF_MS,
  OND_MAX_OUTRO_WAIT_MS,
  OND_MIN_PRELOAD_NEXT_HOST_VIDEO_SECONDS,
  ondBlockIntro,
  ondBlockOutro,
  OndTransitionConfig,
} from './ondPlaybackConfig';
import { recordingNeedsPreparation } from './recordingGenerator';
import { uncheckedIndexAccess_UNSAFE } from '../../../utils/uncheckedIndexAccess_UNSAFE';

export const pickRecordingMediaUrl = (
  recording: BlockRecording | null,
  useRawFormat = getFeatureQueryParam('game-use-raw-format')
): string | null => {
  const url = useRawFormat
    ? recording?.media?.url ?? null
    : MediaUtils.PickMediaUrl(recording?.media, {
        priority: MediaPickPriorityFHD,
      });
  return url;
};

type VideoMixerTrackGroupItem = {
  trackId: TrackId;
  media: UnplayableMedia;
  started: Promise<void>; // track awaiter
  ended: Promise<void>; // track awaiter
  ancillaryMedias?: UnplayableMedia[];
};

export type VideoMixerTrackMapGroupItems = {
  // can be intro, outro, or block
  primary: VideoMixerTrackGroupItem | null;
  stage: VideoMixerTrackGroupItem | null;
  podium: VideoMixerTrackGroupItem | null;
  voiceovers: VideoMixerTrackGroupItem | null;
};

export class VideoMixerTrackMapGroup {
  items: VideoMixerTrackMapGroupItems = {
    primary: null,
    stage: null,
    podium: null,
    voiceovers: null,
  };

  set(
    name: keyof VideoMixerTrackMapGroupItems,
    item: VideoMixerTrackGroupItem
  ) {
    this.items[name] = item;
  }

  get(name: keyof VideoMixerTrackMapGroupItems) {
    return this.items[name];
  }

  async intoPlayables() {
    await Promise.allSettled([
      this.items.primary?.media.intoPlayable(),
      this.items.stage?.media.intoPlayable(),
      this.items.podium?.media.intoPlayable(),
      this.items.primary?.ancillaryMedias?.map((m) => m.intoPlayable()),
      this.items.voiceovers?.media.intoPlayable(),
      this.items.voiceovers?.ancillaryMedias?.map((m) => m.intoPlayable()),
    ]);
  }

  /**
   * NOTE: does not track ancillary media starts
   */
  async allStarted() {
    await Promise.all([
      this.items.primary?.started,
      this.items.stage?.started,
      this.items.podium?.started,
      this.items.voiceovers?.started,
    ]);
  }
}

type PreloadedUnplayable = {
  unplayable: UnplayableMedia;
  mediaId: Media['id'];
  mediaFormat: MediaFormat;
};

export class BlockToPreloadedMediasMap {
  private m = new Map<`${Block['id']}:${Media['id']}`, PreloadedUnplayable>();

  preload(block: Block, recording: BlockRecording): void {
    // TODO(drew): mark that this block has been fully preload-attempted?
    for (const a of recording.actions) {
      const preloaded = this.getOrCreate(block, a);
      preloaded?.unplayable.intoPlayable(false);
    }
  }

  getOrCreate(block: Block, action: BlockAction): PreloadedUnplayable | null {
    const mediaId = action.voiceOver?.mediaId;
    const mediaFormat = MediaUtils.PickMediaFormat(action.voiceOver?.media, {
      priority: MediaPickPriorityFHD,
    });

    if (!mediaFormat || !mediaId) return null;

    const unplayable = UnplayableMediaFactory.From(
      mediaFormat.url.startsWith('blob')
        ? mediaFormat.url
        : xDomainifyUrl(mediaFormat.url, 'gameplay'),
      MediaType.Audio
    );

    const preloaded: PreloadedUnplayable = { unplayable, mediaId, mediaFormat };

    this.m.set(`${block.id}:${mediaId}`, preloaded);
    return preloaded;
  }
}

function createTrackStartAwaiter(videoMixer: VideoMixer, trackId: TrackId) {
  return new Promise<void>((resolve) => {
    videoMixer.on('track-start', function handler(startedTrackId, _playheadMs) {
      if (startedTrackId !== trackId) return;
      videoMixer.off('track-start', handler);
      resolve();
    });
  });
}
function createTrackEndAwaiter(videoMixer: VideoMixer, trackId: TrackId) {
  return new Promise<void>((resolve) => {
    videoMixer.on('track-end', function handler(endedTrackId, _playheadMs) {
      if (endedTrackId !== trackId) return;
      videoMixer.off('track-end', handler);
      resolve();
    });
  });
}

function managePersistentVideoMixerLayer(
  name: keyof VideoMixerTrackMapGroupItems,
  type: MediaType.Video | MediaType.Image,
  zLayer: number,
  leaf:
    | VideoEffectsSettings['podium']
    | VideoEffectsSettings['stage']
    | undefined,
  operation: HostVideoScheduleOperation,
  ondTrackIds: BlockToVideoMixerTrackMap,
  videoMixer: VideoMixer,
  logODG: Logger
): VideoMixerTrackGroupItem | null {
  const previousTrackItem = ondTrackIds.lastNonNull(name);

  const layerUrl = leaf?.config.mediaFormat.url;
  const needsLayer = !!(leaf && leaf?.enabled && layerUrl);
  const matchesLayer = ondTrackIds.matches(previousTrackItem, name, layerUrl);

  if (
    (!matchesLayer || !needsLayer) &&
    previousTrackItem &&
    previousTrackItem.get('podium')
  ) {
    // Schedule the current block's podium to stop

    const prevBlockActive = videoMixer.getTrackActiveTimelineTimeMs(
      previousTrackItem.get('primary')?.trackId ?? null
    );
    const activeEndTime = prevBlockActive?.[1];

    // [1] is the active "end time" of the track
    if (activeEndTime !== undefined) {
      const patch = {
        timelineTimeEndMs: activeEndTime,
      };

      videoMixer.patchTrack(
        uncheckedIndexAccess_UNSAFE(previousTrackItem)[name]?.trackId ?? null,
        patch
      );

      logODG.info(
        `video-mixer: scheduling current ${name} to stop with current block`,
        {
          playheadMs: videoMixer.playheadMs.toFixed(4),
          patch,
        }
      );
    }
  }

  let trackIdSource;
  let trackMedia;
  let trackStarted;
  let trackEnded;

  // Note: TS Will not narrow this type without first assigning it. Something
  // about the optional property operator.
  const previousTrackItemSource = previousTrackItem
    ? uncheckedIndexAccess_UNSAFE(previousTrackItem)[name]
    : undefined;

  if (matchesLayer && needsLayer && previousTrackItemSource) {
    // reuse previous, do nearly nothing: just reference previous items
    trackIdSource = {
      ...previousTrackItemSource,
    };

    logODG.info(`video-mixer: reusing previous ${name}`);
  } else if (needsLayer && leaf) {
    // schedule new

    logODG.info(`video-mixer: pushing ${name} track`, { layerUrl });

    const timelineTimeStartMs = computeTimelineTimeStartMsForOperation(
      operation,
      videoMixer
    );

    trackMedia = UnplayableMediaFactory.From(xDomainifyUrl(layerUrl), type);

    const durationMs =
      type === MediaType.Video ? leaf.config.mediaFormat.length : Infinity;

    const layerTrackId = videoMixer.pushTrack(
      trackMedia.media,
      new TrackInitConfigBuilder()
        .setTimelineTimeStartMs(timelineTimeStartMs)
        .setDurationMs(durationMs)
        .setLoop(isFinite(durationMs) ? true : false)
        .setZLayer(zLayer)
        .build()
    );

    trackStarted = createTrackStartAwaiter(videoMixer, layerTrackId);
    trackEnded = createTrackEndAwaiter(videoMixer, layerTrackId);

    trackIdSource = {
      trackId: layerTrackId,
      started: trackStarted,
      ended: trackEnded,
      media: trackMedia,
    };
  } else {
    return null;
  }

  return trackIdSource;
}

/**
 * Schedule preloaded voiceovers to be played, likely starting immediately. This
 * currently schedules the entire block's voice overs, and assumes it is being
 * called at the beginning of the block.
 */
function scheduleVoiceOvers(
  cacheMap: BlockToPreloadedMediasMap | null,
  block: Block,
  videoMixer: VideoMixer,
  playbackPlan: BlockPlaybackPlan | null,
  blockProgressSec: number | null
): VideoMixerTrackGroupItem | null {
  if (!playbackPlan || !cacheMap || blockProgressSec === null) return null;

  let trackId;
  let media;
  const ancillaryMedias: UnplayableMedia[] = [];
  const ancillaryTrackIds: TrackId[] = [];

  for (const actions of Object.values(playbackPlan.actionMap)) {
    for (const action of actions) {
      const preloaded = cacheMap.getOrCreate(block, action.original);
      preloaded?.unplayable.intoPlayable(); // just in case
      if (!preloaded) continue;

      const timelineTimeStartMs =
        blockProgressSec === 0
          ? // This is the start of the block, we are scheduling all actions!
            videoMixer.playheadMs +
            (action.second * 1000 + action.fractionalShiftMs) +
            action.voiceOverDelayStartMs
          : // This is a recovery, use the recovery math
            computeTimelineTimeStartMsForOperation(
              {
                kind: 'midway',
                timer: action.second,
                blockRemainderMs: playbackPlan.blockRemainderMs,
                blockEndingSec: playbackPlan.blockEndingSec,
                recordingDurationMs:
                  playbackPlan.extra.correctedRecordingDurationMs,
                withForcedFadeIn: false,
              },
              videoMixer
            );

      // TODO: this conversion doesn't make human sense, it's not logarithmic.
      const volume = VolumeLevelUtils.ConvertToScale(
        action.original.voiceOver?.mediaData.volumeLevel
      );

      const durationMs = preloaded.mediaFormat.length;

      const fadeInDurationMs = 50;

      const builder = new TrackInitConfigBuilder()
        .setDurationMs(durationMs)
        .setTimelineTimeStartMs(timelineTimeStartMs)
        .addAudioGainEnvelope(
          { ms: 0, value: 0 },
          { ms: fadeInDurationMs, value: volume },
          'linear'
        )
        .addAudioGainEnvelope(
          { ms: durationMs - fadeInDurationMs, value: volume },
          { ms: durationMs, value: 0 },
          'linear'
        );

      const id = videoMixer.pushTrack(
        preloaded.unplayable.media,
        builder.build()
      );
      if (!trackId) {
        trackId = id;
        media = preloaded.unplayable;
      } else {
        ancillaryMedias.push(preloaded.unplayable);
        ancillaryTrackIds.push(id);
      }
    }
  }

  if (!trackId || !media) return null;

  // If there is only one voice over, then `ended` will use the same track as
  // `started`, like elsewhere.
  const lastTrackId =
    ancillaryTrackIds[ancillaryTrackIds.length - 1] ?? trackId;

  // NOTE: the `trackId` here is always the first voiceover within the block,
  // but that doesn't mean this voiceover plays directly at the start of the
  // block (e.g. at tick 0). It likely plays 500ms or 1000ms into the block. The
  // first card of a title block could also not have a voiceover. It might
  // contain only gameplaymedia. So it's unsafe to `await allStarted` or the
  // `started` property if this is the first block of the entire game (i.e. it
  // will cause the start of gameplay to halt until the voiceover begins).
  // However, the same structure is kept to maintain consistency with the other
  // playback modes.

  // TODO(drew): it's time to actually create a separate implementation, rather
  // than relying on these same structures / conventions.

  return {
    trackId,
    media,
    started: createTrackStartAwaiter(videoMixer, trackId),
    ended: createTrackEndAwaiter(videoMixer, lastTrackId),
    ancillaryMedias,
  };
}

export async function maybePreloadNextVoiceOvers(
  cacheMap: BlockToPreloadedMediasMap,
  currentBlock: Block,
  nextPlaybackItem: PlaybackDescItem | null,
  playbackVersion: number,
  aiHostVoiceId: Nullable<string>,
  ondVoiceOverRegistry: VoiceOverRegistry,
  blockRecordingCreator: BlockRecordingCreator,
  currentGSSStatus: GameSessionStatus,
  logODG: Logger
): Promise<void> {
  const map = GameSessionUtil.StatusMapFor(currentBlock.type);
  const targetStatus = map?.pointDistributed ?? null;

  if (
    (targetStatus !== null && targetStatus !== currentGSSStatus) ||
    !nextPlaybackItem ||
    !recordingNeedsPreparation(nextPlaybackItem.block)
  )
    return;

  let gRecording;

  try {
    gRecording = await blockRecordingCreator.prepare(nextPlaybackItem, {
      playbackVersion,
      aiHostVoiceId,
      ondVoiceOverRegistry,
    });
  } catch (err) {
    logODG.warn('Could not generate recording for block', {
      blockId: nextPlaybackItem.block.id,
      playbackItemId: nextPlaybackItem.id,
    });
    return;
  }

  if (!gRecording || gRecording.recording.version <= 3) return;

  for (const action of gRecording.recording.actions) {
    const preloaded = cacheMap.getOrCreate(nextPlaybackItem.block, action);
    preloaded?.unplayable.intoPlayable();
  }
}

type HostVideoScheduleOperation =
  | { kind: 'after-track-id'; trackId: TrackId; withForcedFadeIn: boolean }
  | {
      kind: 'midway';
      timer: number;
      blockEndingSec: number;
      blockRemainderMs: number;
      recordingDurationMs: number;
      withForcedFadeIn: boolean;
    }
  | {
      kind: 'after-current-block';
      timer: number;
      blockEndingSec: number;
      blockRemainderMs: number;
      withForcedFadeIn: boolean;
    };

function computeTimelineTimeStartMsForOperation(
  operation: HostVideoScheduleOperation,
  videoMixer: VideoMixer,
  transitionDuration = operation.withForcedFadeIn
    ? OND_INTRO_VIDEO_TRANSITION_HALF_MS
    : OndTransitionConfig.videoDurationHalfMs
) {
  let timelineTimeStartMs = videoMixer.playheadMs;

  if (operation.kind === 'after-track-id') {
    const trackInfo = videoMixer.getTrackActiveTimelineTimeMs(
      operation.trackId
    );

    timelineTimeStartMs =
      (trackInfo ? trackInfo[1] : videoMixer.playheadMs) - transitionDuration;
  } else if (operation.kind === 'midway') {
    // -------------------|
    //                playheadMs (separate coordinate space)
    //
    //   0 ---------| blockProgressTime (seconds coordinate space)
    //
    //   |----------------------------------|
    //                                    durationMs (recording)
    //
    //              |-----------------------| remainingMs
    //
    // `durationMs - remainingMs` == how much time has passed in "millisecond
    // space". blockProgressTime (timer) is in "second space" which is lower
    // fidelity, so it must be converted to MS space first.
    //
    // `playheadMs - (durationMs - remainingMs)` == how much time has passed in
    // videomixer coordinate space. This allows scheduling something that should
    // have started in the past to be midway through its progress.
    //
    // NOTE: this assumes that blockProgressSec(timer) is the source of truth,
    // which is usually very inaccurate. Other operations generally assume the
    // VideoMixer playhead is the source of truth. This specific operation is
    // generally only used for "recovery" modes, such as a host-refresh or some
    // sort of skipping operation.

    const durationMs = operation.recordingDurationMs;
    const remainingMs =
      (operation.blockEndingSec - operation.timer) * 1000 +
      operation.blockRemainderMs;
    timelineTimeStartMs = videoMixer.playheadMs - (durationMs - remainingMs);
  } else if (operation.kind === 'after-current-block') {
    const remainingMs =
      (operation.blockEndingSec - operation.timer) * 1000 +
      operation.blockRemainderMs;
    timelineTimeStartMs =
      videoMixer.playheadMs + remainingMs - transitionDuration;
  } else {
    assertExhaustive(operation);
  }

  return timelineTimeStartMs;
}

function scheduleHostVideo(
  playbackVersion: number,
  precedingBlockHasHostVideoRecording: boolean,
  succeedingBlockHasHostVideoRecording: boolean,
  recording: BlockRecording | null,
  ves: VideoEffectsSettings | null,
  operation: HostVideoScheduleOperation,
  videoMixer: VideoMixer,
  firstFreezeFrameDurationMs = getFeatureQueryParamNumber(
    'game-on-demand-host-first-freeze-frame-duration'
  ),
  finalFreezeFrameDurationMs = getFeatureQueryParamNumber(
    'game-on-demand-host-final-freeze-frame-duration'
  )
): VideoMixerTrackGroupItem | null {
  const recUrl = pickRecordingMediaUrl(recording);
  const firstFrameUrl = MediaUtils.PickMediaUrl(recording?.media, {
    priority: MediaPickPriorityFHD,
    videoThumbnail: 'first',
  });
  const finalFrameUrl = MediaUtils.PickMediaUrl(recording?.media, {
    priority: MediaPickPriorityFHD,
    videoThumbnail: 'last',
  });
  const recordingMediaDurationMs = MediaUtils.GetMediaDurationMs(
    recording?.media ?? null
  );

  if (
    !recording ||
    !recUrl ||
    !firstFrameUrl ||
    !finalFrameUrl ||
    recordingMediaDurationMs === 0 ||
    playbackVersion < recording.version
  )
    return null;

  const trackVideo = new UnplayableVideoImpl(xDomainifyUrl(recUrl));
  const trackFirstFreezeFrame = new UnplayableImageImpl(
    xDomainifyUrl(firstFrameUrl)
  );
  const trackFinalFreezeFrame = new UnplayableImageImpl(
    xDomainifyUrl(finalFrameUrl)
  );

  const timelineTimeStartMs = computeTimelineTimeStartMsForOperation(
    operation,
    videoMixer
  );

  const firstFreezeFrameBuilder = new TrackInitConfigBuilder()
    .setDurationMs(firstFreezeFrameDurationMs)
    .setTimelineTimeStartMs(timelineTimeStartMs - firstFreezeFrameDurationMs)
    .addVideoOpacityEnvelope(
      { ms: 0, value: 0 },
      { ms: firstFreezeFrameDurationMs, value: 1 },
      'in'
    );

  const finalFreezeFrameBuilder = new TrackInitConfigBuilder()
    .setDurationMs(finalFreezeFrameDurationMs)
    .setTimelineTimeStartMs(timelineTimeStartMs + recordingMediaDurationMs)
    .addVideoOpacityEnvelope(
      { ms: 0, value: 1 },
      { ms: finalFreezeFrameDurationMs, value: 0 },
      'out'
    );

  const configBuilder = new TrackInitConfigBuilder()
    .setDurationMs(recordingMediaDurationMs)
    .setTimelineTimeStartMs(timelineTimeStartMs)
    .addAudioGainEnvelope(
      { ms: 0, value: OndTransitionConfig.enabled ? 0 : 1 },
      { ms: OndTransitionConfig.audioDurationHalfMs, value: 1 },
      'linear'
    )
    .addAudioGainEnvelope(
      {
        ms: recordingMediaDurationMs - OndTransitionConfig.audioDurationHalfMs,
        value: 1,
      },
      {
        ms: recordingMediaDurationMs,
        value: OndTransitionConfig.enabled ? 0 : 1,
      },
      'linear'
    );

  if (ves && ves.greenScreen.enabled) {
    configBuilder
      .setChromakey(GreenScreenValuesUtils.ToGLCompat(ves.greenScreen))
      .setRectMask(ves.greenScreen.maskPct);

    firstFreezeFrameBuilder
      .setChromakey(GreenScreenValuesUtils.ToGLCompat(ves.greenScreen))
      .setRectMask(ves.greenScreen.maskPct);

    finalFreezeFrameBuilder
      .setChromakey(GreenScreenValuesUtils.ToGLCompat(ves.greenScreen))
      .setRectMask(ves.greenScreen.maskPct);
  }

  if (ves) {
    configBuilder.setBoundingBox(ves.boundingBox);

    firstFreezeFrameBuilder.setBoundingBox(ves.boundingBox);
    finalFreezeFrameBuilder.setBoundingBox(ves.boundingBox);
  }

  configBuilder
    .addVideoOpacityEnvelope(
      { ms: 0, value: OndTransitionConfig.enabled ? 0 : 1 },
      {
        ms: operation.withForcedFadeIn
          ? OND_INTRO_VIDEO_TRANSITION_HALF_MS
          : OndTransitionConfig.videoDurationHalfMs,
        value: 1,
      },
      'in'
    )
    .addVideoOpacityEnvelope(
      {
        ms: recordingMediaDurationMs - OndTransitionConfig.videoDurationHalfMs,
        value: 1,
      },
      {
        ms: recordingMediaDurationMs,
        value: OndTransitionConfig.enabled ? 0 : 1,
      },
      'out'
    );

  const blockTrackId = videoMixer.pushTrack(
    trackVideo.media,
    configBuilder.build()
  );

  const ancillaryMedias = [];

  if (!precedingBlockHasHostVideoRecording && firstFreezeFrameDurationMs > 0) {
    // This video only gets an intro freeze frame if there was not a previous
    // (continuous) host video
    videoMixer.pushTrack(
      trackFirstFreezeFrame.media,
      firstFreezeFrameBuilder.build()
    );
    ancillaryMedias.push(trackFirstFreezeFrame);
  }

  if (!succeedingBlockHasHostVideoRecording && finalFreezeFrameDurationMs > 0) {
    // This video only gets an outro freeze frame if there will not be a next
    // (continuous) host video
    videoMixer.pushTrack(
      trackFinalFreezeFrame.media,
      finalFreezeFrameBuilder.build()
    );
    ancillaryMedias.push(trackFinalFreezeFrame);
  }

  const trackVideoStarted = createTrackStartAwaiter(videoMixer, blockTrackId);
  const trackVideoEnded = createTrackEndAwaiter(videoMixer, blockTrackId);

  return {
    trackId: blockTrackId,
    media: trackVideo,
    started: trackVideoStarted,
    ended: trackVideoEnded,
    ancillaryMedias,
  };
}

export function scheduleBlockTrackMap(
  playbackVersion: number,
  preceedingBlock: Block | null | undefined,
  forBlock: Block,
  succeedingBlock: Block | null | undefined,
  recording: BlockRecording | null,
  blockPlaybackPlan: BlockPlaybackPlan | null,
  blockProgressSec: number | null,
  operation: HostVideoScheduleOperation,
  ondTrackIds: BlockToVideoMixerTrackMap,
  cacheMap: BlockToPreloadedMediasMap | null,
  videoMixer: VideoMixer,
  logODG: Logger
): VideoMixerTrackMapGroup | null {
  const existing = ondTrackIds.get(asBlockId(forBlock.id));

  let ves = forBlock ? VideoEffectsSettingsUtils.FromBlock(forBlock) : null;

  const checks = OndVersionChecks(playbackVersion);

  if (!ves) {
    ves = VideoEffectsSettingsUtils.WithDefaults({
      stage: {
        enabled:
          checks.ondAllowFallbackAnimatedStage && checks.ondManageHostVisuals,
        config: Stages.selectOrThrow(FallbackIds.OndStage),
      },
      // More thought is needed here. Podium may be something that is configured
      // on a minigame or gamepack level?

      // podium: {
      //   enabled: true,
      //   config: Podiums.selectOrThrow(FallbackIds.Podium),
      // },
    });
  }

  // Only if an actual host video recording exists should the stage or podium be
  // managed (including the defaults). Otherwise, it could be a v3 gameplay (no
  // actual VES configured) or v3.1 block (no in-stream visuals required).
  const hasHostRecording = !!pickRecordingMediaUrl(recording);

  // During v3.1 playback, existing recordings prevent any recordings from being
  // generated. Voiceovers are only scheduled when a recording is generated, as
  // they are attached to the generated Actions. Therefore, voiceovers will not
  // play even during 3.1 playback if there is an existing host recording.

  const voiceovers = existing?.get('voiceovers')
    ? null
    : scheduleVoiceOvers(
        cacheMap,
        forBlock,
        videoMixer,
        blockPlaybackPlan,
        blockProgressSec
      );

  const primary =
    !existing?.get('primary') && checks.ondManageHostVisuals && hasHostRecording
      ? scheduleHostVideo(
          playbackVersion,
          Boolean(preceedingBlock?.recording),
          Boolean(succeedingBlock?.recording),
          recording,
          ves,
          operation,
          videoMixer
        )
      : null;

  const stageLayer =
    !existing?.get('stage') && checks.ondManageHostVisuals && hasHostRecording
      ? managePersistentVideoMixerLayer(
          'stage',
          MediaType.Video,
          -1,
          ves?.stage,
          operation,
          ondTrackIds,
          videoMixer,
          logODG
        )
      : null;

  const podiumLayer =
    !existing?.get('podium') && checks.ondManageHostVisuals && hasHostRecording
      ? managePersistentVideoMixerLayer(
          'podium',
          MediaType.Image,
          2,
          ves?.podium,
          operation,
          ondTrackIds,
          videoMixer,
          logODG
        )
      : null;

  // If somehow a recorded video and a voiceover exists, then use the recorded
  // video as the main source of timing/ended awaiting. Otherwise, consider
  // the voiceovers the "primary" so the majority of the rest of the system
  // doesn't need to know which to wait for.
  const group = existing ?? new VideoMixerTrackMapGroup();
  if (primary) group.set('primary', primary);
  // TODO: revisit doing this here, it probably needs to be conditional.
  if (voiceovers) {
    group.set('primary', voiceovers);
    group.set('voiceovers', voiceovers);
  }
  if (stageLayer) group.set('stage', stageLayer);
  if (podiumLayer) group.set('podium', podiumLayer);
  ondTrackIds.set(asBlockId(forBlock.id), group);

  return group;
}

export function scheduleHostIntroVideo(
  ondTrackIds: BlockToVideoMixerTrackMap,
  videoMixer: VideoMixer
): VideoMixerTrackMapGroup | null {
  const group = ondTrackIds.get('intro') ?? new VideoMixerTrackMapGroup();
  const introVideo = new UnplayableVideoImpl(xDomainifyUrl(ondBlockIntro.url));

  const trackId = videoMixer.pushTrack(
    introVideo.media,
    new TrackInitConfigBuilder()
      .setTimelineTimeStartMs(videoMixer.playheadMs)
      .setDurationMs(ondBlockIntro.length)
      .setZLayer(3)
      // Intro always has a default fadein/fadeout
      .addVideoOpacityEnvelope(
        { ms: 0, value: 0 },
        { ms: OND_INTRO_VIDEO_TRANSITION_HALF_MS, value: 1 },
        'in'
      )
      .addVideoOpacityEnvelope(
        {
          ms: ondBlockIntro.length - OND_INTRO_VIDEO_TRANSITION_HALF_MS,
          value: 1,
        },
        { ms: ondBlockIntro.length, value: 0 },
        'out'
      )
      .build()
  );

  group.set('primary', {
    trackId,
    media: introVideo,
    started: createTrackStartAwaiter(videoMixer, trackId),
    ended: createTrackEndAwaiter(videoMixer, trackId),
  });

  ondTrackIds.set('intro', group);
  return group;
}

// This could be required if the organizer has just refreshed
export const ensureCurrentHostMediaIsScheduled = (
  playbackVersion: number,
  block: Block,
  succeedingBlock: Block | null | undefined,
  ondTrackIds: BlockToVideoMixerTrackMap,
  cacheMap: BlockToPreloadedMediasMap,
  recording: BlockRecording,
  blockPlaybackPlan: BlockPlaybackPlan,
  videoMixer: VideoMixer,
  timer: number,
  blockEndingSec: number,
  blockRemainderMs: number,
  logODG: Logger
): VideoMixerTrackMapGroup | null => {
  const blockId = block.id;
  const existingTrackId = ondTrackIds.get(asBlockId(blockId));
  const active = videoMixer.getTrackActiveTimelineTimeMs(
    existingTrackId?.get('primary')?.trackId ?? null
  );

  if (
    active ||
    // active may be null if the tracks have been preloaded but not actually
    // scheduled in the video mixer
    existingTrackId ||
    timer === blockEndingSec ||
    playbackVersion < recording.version
  )
    return null;

  if (OndVersionChecks(playbackVersion).ondAbsenceOfHostVideoIndicatesError) {
    logODG.info(
      'video-mixer: no host video found for block, pushing. Maybe refresh-resume?',
      {
        playheadMs: videoMixer.playheadMs.toFixed(4),
        timer,
        blockEndingSec,
        blockRemainderMs,
      }
    );
  }

  const desc = scheduleBlockTrackMap(
    playbackVersion,
    null,
    block,
    succeedingBlock,
    recording,
    blockPlaybackPlan,
    timer,
    {
      kind: 'midway',
      timer,
      blockEndingSec,
      blockRemainderMs,
      recordingDurationMs: recording.durationMs,
      withForcedFadeIn: false,
    },
    ondTrackIds,
    cacheMap,
    videoMixer,
    logODG
  );

  // Auto preload
  desc?.intoPlayables();

  return desc;
};

export const maybePreloadAndScheduleNextHostVideo = (
  playbackVersion: number,
  currBlock: Block,
  nextPlayableBlock: Block | null,
  followingPlayableBlock: Block | null,
  timer: number,
  blockEndingSec: number,
  blockRemainderMs: number,
  videoMixer: VideoMixer,
  ondTrackIds: BlockToVideoMixerTrackMap,
  logODG: Logger
): VideoMixerTrackMapGroup | null => {
  const remainingSec = blockEndingSec - timer;

  // If the current rec is less than MIN_PRELOAD_SECONDS, the next video will
  // never be loaded!
  const minTargetSec = Math.min(
    OND_MIN_PRELOAD_NEXT_HOST_VIDEO_SECONDS,
    remainingSec
  );

  if (remainingSec !== minTargetSec) return null;

  const currBlockId = asBlockId(currBlock.id);
  const currTrackId = ondTrackIds.get(currBlockId);
  const nextRecording = nextPlayableBlock?.recording;

  if (
    !nextPlayableBlock ||
    !nextRecording ||
    // If there is a recording mismatch, do not schedule the video. The block is
    // likely still playable, such as a Question Block with a V1 recording while
    // in V3 playback mode.
    nextRecording.version !== playbackVersion ||
    ondTrackIds.get(asBlockId(nextPlayableBlock.id))
  )
    return null;

  logODG.info('video-mixer: preloading next on-demand video', {
    blockId: nextPlayableBlock?.id,
  });

  const primary = currTrackId?.get('primary');

  const desc = scheduleBlockTrackMap(
    playbackVersion,
    currBlock,
    nextPlayableBlock,
    followingPlayableBlock,
    nextRecording,
    // NOTE: the absense of the playbackPlan purposefully prevents voiceovers
    // from being scheduled. This is too tricky.
    null,
    null,
    primary
      ? {
          kind: 'after-track-id',
          trackId: primary.trackId,
          withForcedFadeIn: false,
        }
      : {
          kind: 'after-current-block',
          timer,
          blockEndingSec,
          blockRemainderMs,
          withForcedFadeIn: false,
        },
    ondTrackIds,
    null,
    videoMixer,
    logODG
  );

  // Auto preload
  desc?.intoPlayables();

  return desc;
};

export function maybeScheduleOutroHostVideo(
  playbackVersion: number,
  currBlock: Block,
  nextPlayableBlock: Block | null,
  timer: number,
  blockEndingSec: number,
  blockRemainderMs: number,
  videoMixer: VideoMixer,
  ondTrackIds: BlockToVideoMixerTrackMap,
  logODG: Logger
): VideoMixerTrackMapGroup | null {
  const currBlockId = asBlockId(currBlock.id);
  const currTrackId = ondTrackIds.get(currBlockId);
  const currTrackActive = videoMixer.getTrackActiveTimelineTimeMs(
    currTrackId?.get('primary')?.trackId ?? null
  );

  if (
    nextPlayableBlock ||
    ondTrackIds.get('outro') ||
    !OndVersionChecks(playbackVersion).ondExpectOutro ||
    OndVersionChecks(playbackVersion).ondOutroRequiresInjectedBlock
  )
    return null;

  // end of game, preload and schedule outro
  logODG.info('video-mixer: pushing on-demand outro video');
  const outroVideo = new UnplayableVideoImpl(xDomainifyUrl(ondBlockOutro.url));

  const remainingMs = (blockEndingSec - timer) * 1000 + blockRemainderMs;
  const timelineTimeStartMs =
    currTrackActive === null
      ? videoMixer.playheadMs + remainingMs
      : currTrackActive[1] - OndTransitionConfig.videoDurationHalfMs;

  const trackId = videoMixer.pushTrack(
    outroVideo.media,
    new TrackInitConfigBuilder()
      .setZLayer(3)
      .setDurationMs(ondBlockOutro.length)
      .setTimelineTimeStartMs(timelineTimeStartMs)
      .build()
  );

  const group = new VideoMixerTrackMapGroup();
  group.set('primary', {
    trackId,
    media: outroVideo,
    started: createTrackStartAwaiter(videoMixer, trackId),
    ended: createTrackEndAwaiter(videoMixer, trackId),
  });

  ondTrackIds.set('outro', group);
  group.intoPlayables();

  return group;
}

export async function maybeWaitForOutroVideoToEnd(
  videoMixer: VideoMixer,
  ondTrackIds: BlockToVideoMixerTrackMap,
  logODG: Logger
): Promise<void> {
  const outro = ondTrackIds.get('outro');
  if (!outro) return;

  logODG.info('waiting for outro to end');

  const timeout = new Promise<void>((resolve) =>
    setTimeout(resolve, OND_MAX_OUTRO_WAIT_MS)
  );

  await Promise.race([timeout, outro.get('primary')?.ended]);

  // HACK(drew): remove all stages and podiums until there is a better solution
  for (const [, group] of ondTrackIds.entries()) {
    const stage = group.get('stage');
    if (stage) {
      videoMixer.removeTrack(stage.trackId ?? null);
      logODG.info('video-mixer: removed stage');
    }

    const podium = group.get('podium');
    if (podium) {
      videoMixer.removeTrack(podium.trackId ?? null);
      logODG.info('video-mixer: removed podium');
    }
  }
}
