import {
  EnumsBrandPredefinedBlockScenario,
  type ModelsBlockLifecycleRule,
} from '@lp-lib/api-service-client/public';
import { BlockType, GameSessionUtil } from '@lp-lib/game';

import { uuidv4 } from '../../../../../utils/common';
import type { PlaybackDescItem } from '../../../Playback/intoPlayback';
import { type BlockLifecycleRule, type OnStatusCondition } from './types';

const cohostHiddenScenarios: EnumsBrandPredefinedBlockScenario[] = [
  EnumsBrandPredefinedBlockScenario.BrandPredefinedBlockScenarioOpeningTitle,
  EnumsBrandPredefinedBlockScenario.BrandPredefinedBlockScenarioHostedInstructions,
];

export function getBlockLifecycleRules(
  pbi: PlaybackDescItem
): BlockLifecycleRule[] {
  const { block, scenario } = pbi;
  // use what's defined on the block.
  if (block.fields.lifecycleRules && block.fields.lifecycleRules.length > 0)
    return fromModels(block.fields.lifecycleRules);

  // NOTE: below are the "implicit" rules for blocks to be used when the block does not specify any.

  // spotlight block will control the cohost directly.
  if (block.type === BlockType.SPOTLIGHT) return [];

  // default rules for brand scenarios.
  if (
    block.type === BlockType.TITLE_V2 &&
    scenario &&
    cohostHiddenScenarios.includes(scenario)
  ) {
    return [
      {
        id: uuidv4(),
        condition: { type: 'on-status', props: { value: 0 } },
        operation: {
          type: 'set-cohost-visibility',
          props: { value: 'hidden' },
        },
      },
    ];
  }

  // if we have a gameEnd, we can control the cohost visibility to show on gameEnd
  const statusMap = GameSessionUtil.StatusMapFor(block);
  if (statusMap && statusMap.gameEnd !== null) {
    return [
      {
        id: uuidv4(),
        condition: { type: 'on-status', props: { value: statusMap.loaded } },
        operation: {
          type: 'set-cohost-visibility',
          props: { value: 'hidden' },
        },
      },
      {
        id: uuidv4(),
        condition: { type: 'on-status', props: { value: statusMap.gameEnd } },
        operation: {
          type: 'set-cohost-visibility',
          props: { value: 'visible' },
        },
      },
    ];
  }

  // otherwise just default to showing the cohost.
  return [
    {
      id: uuidv4(),
      condition: { type: 'on-status', props: { value: 0 } },
      operation: { type: 'set-cohost-visibility', props: { value: 'visible' } },
    },
  ];
}

export function isOnStatusCondition(
  condition: ModelsBlockLifecycleRule['condition']
): condition is OnStatusCondition {
  return condition.type === 'on-status';
}

const knownOperationTypes = [
  'set-cohost-visibility',
  'set-cohost-position',
  'set-townhall-mode',
  'mute-audience',
  'cycle-team-captains',
];

export function isKnownOperation(
  operation: ModelsBlockLifecycleRule['operation']
): operation is BlockLifecycleRule['operation'] {
  return knownOperationTypes.includes(operation.type);
}

function fromModels(rules: ModelsBlockLifecycleRule[]): BlockLifecycleRule[] {
  return rules as unknown as BlockLifecycleRule[];
}
