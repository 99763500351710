import { useEffect, useState } from 'react';

import { createProvider } from '../../utils/createProvider';
import { useOndVideoMixer_UNSTABLE } from '../Game/hooks';
import { useGlobalSubtitlesManager } from './SubtitlesManagerProvider';
import { VoiceOverRegistry } from './VoiceOverRegistry';

const { Provider, useCreatedContext: useOndVoiceOverRegistry } =
  createProvider<VoiceOverRegistry>('OndVoiceOverRegistryProvider');

export { useOndVoiceOverRegistry };

export function OndVoiceOverRegistryProvider(props: {
  children?: React.ReactNode;
}) {
  const [instance] = useState(() => new VoiceOverRegistry());
  const vm = useOndVideoMixer_UNSTABLE();
  const sub = useGlobalSubtitlesManager();

  useEffect(() => {
    instance.setVideoMixer(vm);
    instance.setSubtitlesManager('broadcast', sub);
  }, [instance, sub, vm]);

  return <Provider value={instance}>{props.children}</Provider>;
}
