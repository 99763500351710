import { type ReactNode } from 'react';

import { getFeatureQueryParamArray } from '../../hooks/useFeatureQueryParam';
import { useVenue } from '../Venue/VenueProvider';
import { VideoEffectsSettingsCore } from '../VideoEffectsSettings/Core';
import { useLocalVideoEffectsSettingsStore } from '../VideoEffectsSettings/LocalSettings';
import { PreviewVideoEffectsSettingsProvider } from '../VideoEffectsSettings/Provider';

export function VideoEffectsSettingsDeviceCheckPanel(props: {
  mediaStream: MediaStream | null;
  onContinue: () => void;
  introOutroSettings?: boolean;
  title?: ReactNode;
  stagePodiumSettings?: boolean | 'stage' | 'podium';
  headerAccessory?: ReactNode;
  settingsEditable?: boolean;
}): JSX.Element | null {
  const [venue] = useVenue();
  const store = useLocalVideoEffectsSettingsStore();

  if (!venue) return null;

  return (
    <PreviewVideoEffectsSettingsProvider initialSettings={store.settings}>
      <VideoEffectsSettingsCore
        streamOrSrc={props.mediaStream}
        targetVideoProfile={getFeatureQueryParamArray('host-video-profile')}
        muted
        autoplay
        controls={false}
        initialEditingDisabled={true}
        settingsEditable={props.settingsEditable}
        initialSettings={store.settings}
        onSave={async (values) => {
          store.replaceFromExternal(values);
        }}
        deviceSelect
        headerAccessory={
          <>
            {props.headerAccessory}
            <button
              type='button'
              className='btn-primary min-w-36 min-h-10'
              onClick={props.onContinue}
            >
              Continue
            </button>
          </>
        }
        introOutroSettings={props.introOutroSettings}
        stagePodiumSettings={props.stagePodiumSettings}
        title={props.title}
      />
    </PreviewVideoEffectsSettingsProvider>
  );
}
