import { useEffect, useRef } from 'react';
import { useEffectOnce } from 'react-use';

import {
  assertExhaustive,
  type HeadToHeadBlock,
  HeadToHeadBlockGameSessionStatus,
} from '@lp-lib/game';

import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { BrowserTimeoutCtrl } from '../../../../utils/BrowserTimeoutCtrl';
import { TagQuery } from '../../../../utils/TagQuery';
import { useClock } from '../../../Clock';
import { increment } from '../../../Firebase/utils';
import {
  useLastJoinedParticipantByUserId,
  useLastJoinedParticipantGetter,
} from '../../../Player';
import {
  StageMode,
  useBringTeamOnStageAPI,
  useStageControlAPI,
  useSwitchStageMode,
} from '../../../Stage';
import { useOndVoiceOverRegistry } from '../../../VoiceOver/OndVoiceOverRegistryProvider';
import { VariableRegistry } from '../../../VoiceOver/VariableRegistry';
import {
  useGameSessionStatus,
  useGetOndGameCurrentPlaybackItem,
  useIsLiveGamePlay,
  useOndWaitMode,
  useOndWaitModeInfo,
} from '../../hooks';
import { ondWaitEnd } from '../../OndPhaseRunner';
import { updateBlockDetailScore } from '../../store';
import { type GameControlProps } from '../Common/GameControl/types';
import { useGamePlayEmitter } from '../Common/GamePlay/GamePlayProvider';
import { useStableBlock } from '../Common/hooks';
import {
  useCurrentHeadToHeadGameCard,
  useGroupVoters,
  useHeadToHeadGameControlAPI,
  useHeadToHeadGamePlayAPI,
  useHeadToHeadGameProgress,
  useNextRoundPhaseAfterPlaying,
  useTotalVoters,
  useWinnerGroupIds,
} from './HeadToHeadBlockProvider';
import {
  useCleanupOnStageGroups,
  useTrackPlayedGroups,
} from './HeadToHeadGameGroup';
import { HeadToHeadUtils, log } from './utils';

type SharedProps = GameControlProps<HeadToHeadBlock>;

function Loaded(_props: SharedProps) {
  const api = useHeadToHeadGameControlAPI();

  useEffectOnce(() => {
    api.resetGame();
  });

  return null;
}

function Init(props: SharedProps) {
  const api = useHeadToHeadGameControlAPI();

  useEffectOnce(() => {
    api.initGame(props.block);
  });

  return null;
}

function Intro(props: SharedProps) {
  const { block } = props;
  const isLive = useIsLiveGamePlay();
  const voiceOverRegistry = useOndVoiceOverRegistry();
  const getCurrentPlaybackItem = useGetOndGameCurrentPlaybackItem();
  const emitter = useGamePlayEmitter();

  useEffectOnce(() => {
    async function run() {
      if (isLive) return;

      const gameplaymediaEnded = block.fields.introMedia
        ? emitter.oncep('h2h-intro-media-ended')
        : Promise.resolve();

      try {
        const playbackItem = getCurrentPlaybackItem();
        const query = new TagQuery(playbackItem?.voiceOverPlans);
        const vo = query.selectFirst(['intro']);
        if (!vo) {
          await gameplaymediaEnded;
          return;
        }

        const group = await voiceOverRegistry.getOrCreateGroup(vo.plan);
        const info = await group.play(new VariableRegistry(), { noLoad: true });
        const trackEnded = (await info?.trackEnded) ?? Promise.resolve();
        await Promise.all([trackEnded, gameplaymediaEnded]);
      } finally {
        await ondWaitEnd();
      }
    }
    run();
  });
  return null;
}

function useTriggerJudging(block: HeadToHeadBlock, ended?: boolean) {
  const judgingEnabled = HeadToHeadUtils.JudgingEnabled(block);
  const judgingAfterGame = HeadToHeadUtils.JudgingAfterGame(block);
  const turnsMode = HeadToHeadUtils.TurnsMode(block);
  const progress = useHeadToHeadGameProgress();
  const api = useHeadToHeadGamePlayAPI();
  const nextPhase = judgingAfterGame
    ? 'judging-after-playing'
    : 'reveal-winner';

  // Trigger judging after X turns in turns mode
  useEffect(() => {
    if (
      !judgingEnabled ||
      !turnsMode ||
      block.fields.judgingAfterXTurns <= 0 ||
      progress.roundPhase !== 'playing'
    ) {
      return;
    }
    if (progress.currentCardIndex >= block.fields.judgingAfterXTurns) {
      api.setRoundPhase(nextPhase, 'auto-trigger-judging-after-x-turns');
    }
  }, [
    api,
    block.fields.judgingAfterXTurns,
    judgingEnabled,
    nextPhase,
    progress.currentCardIndex,
    progress.roundPhase,
    turnsMode,
  ]);

  // Trigger judging when times up in debate mode
  useEffect(() => {
    if (
      turnsMode ||
      !judgingEnabled ||
      !block.fields.judgingWhenTimesUp ||
      block.fields.subTimeSec === 0 ||
      progress.currentCardPhase !== 'revealed' ||
      progress.roundPhase !== 'playing'
    ) {
      return;
    }
    const ctrl = new BrowserTimeoutCtrl();
    ctrl.set(async () => {
      await api.setRoundPhase(nextPhase, 'auto-trigger-judging-when-times-up');
    }, block.fields.subTimeSec * 1000);
    return () => ctrl.clear();
  }, [
    api,
    block.fields.judgingWhenTimesUp,
    block.fields.subTimeSec,
    judgingEnabled,
    nextPhase,
    progress.currentCardPhase,
    progress.roundPhase,
    turnsMode,
  ]);

  // Trigger judging if gss becomes _ended_
  useEffect(() => {
    if (!judgingEnabled || progress.roundPhase !== 'playing' || !ended) {
      return;
    }
    api.setRoundPhase(nextPhase, 'auto-trigger-judging-when-gss-ended');
  }, [api, ended, judgingEnabled, nextPhase, progress.roundPhase]);
}

function useAutoUpdateDetailScore(block: HeadToHeadBlock) {
  const progress = useHeadToHeadGameProgress();
  const winnerGroupIds = useWinnerGroupIds(
    HeadToHeadUtils.JudgingSentiment(block)
  );
  const getLastJoinedParticipant = useLastJoinedParticipantGetter();
  const clock = useClock();

  const uodateScore = useLiveCallback(async () => {
    const singleMode = HeadToHeadUtils.SingleMode(block);
    const promises = [];
    for (const groupId of winnerGroupIds) {
      const teamId = singleMode
        ? getLastJoinedParticipant(groupId, false)?.teamId
        : groupId;
      log.info('update block detail score', {
        winnerGroupId: groupId,
        winnerTeamId: teamId ?? null,
      });
      if (!teamId) continue;
      promises.push(
        updateBlockDetailScore(teamId, {
          score: increment(block.fields.judgingPoints),
          submittedAt: clock.now(),
        })
      );
      try {
        await Promise.all(promises);
      } catch (err) {
        log.error('fail to update score', err);
      }
    }
  });
  useEffect(() => {
    if (
      progress.roundPhase !== 'reveal-winner' ||
      winnerGroupIds.length === 0 ||
      block.fields.judgingPoints === 0
    ) {
      return;
    }
    uodateScore();
  }, [
    block.fields.judgingPoints,
    progress.roundPhase,
    uodateScore,
    winnerGroupIds.length,
  ]);
}

function useAutoProgressToShowResults(
  _block: HeadToHeadBlock,
  ended?: boolean
) {
  const progress = useHeadToHeadGameProgress();
  const mode = useOndWaitMode();
  const autoProgress =
    progress.roundPhase === 'select-next' && !!ended && mode === 'wait';

  useEffect(() => {
    if (!autoProgress) return;
    ondWaitEnd();
  }, [autoProgress, ended]);
}

function useAutoProgressMajorityVote(block: HeadToHeadBlock) {
  const progress = useHeadToHeadGameProgress();
  const totalVoters = useTotalVoters(block);
  const numOfAVotes = useGroupVoters(progress.currentAGroupId).length;
  const numOfBVotes = useGroupVoters(progress.currentBGroupId).length;
  const api = useHeadToHeadGamePlayAPI();

  const judgingDuringGame = HeadToHeadUtils.JudgingDuringGame(block);
  const minimalTurnsPlayed =
    block.fields.judgingAfterXTurns > 0
      ? progress.currentCardIndex > block.fields.judgingAfterXTurns
      : true;

  useEffect(() => {
    if (
      !judgingDuringGame ||
      !minimalTurnsPlayed ||
      progress.roundPhase !== 'playing'
    )
      return;
    const majority = totalVoters / 2;
    if (numOfAVotes > majority) {
      log.info('majority vote for group A', {
        totalVoters,
        numOfAVotes,
        numOfBVotes,
        majority,
      });
      api.setRoundPhase('reveal-winner', 'auto-progress-majority-vote');
    }
    if (numOfBVotes > majority) {
      log.info('majority vote for group B', {
        totalVoters,
        numOfAVotes,
        numOfBVotes,
        majority,
      });
      api.setRoundPhase('reveal-winner', 'auto-progress-majority-vote');
    }
  }, [
    api,
    judgingDuringGame,
    minimalTurnsPlayed,
    numOfAVotes,
    numOfBVotes,
    progress.roundPhase,
    totalVoters,
  ]);
}

function useAutoProgressNoMoreCard(block: HeadToHeadBlock) {
  const progress = useHeadToHeadGameProgress();
  const card = useCurrentHeadToHeadGameCard();
  const nextPhase = useNextRoundPhaseAfterPlaying(block);
  const api = useHeadToHeadGamePlayAPI();

  const autoProgress = useLiveCallback(async () => {
    if (nextPhase) {
      await api.setRoundPhase(nextPhase, 'auto-progress-no-more-cards');
      return;
    }
    await ondWaitEnd();
  });

  useEffect(() => {
    if (progress.roundPhase !== 'playing' || !!card?.id) {
      return;
    }
    autoProgress();
  }, [autoProgress, card?.id, progress.roundPhase]);
}

function GameStarted(props: SharedProps & { ended?: boolean }) {
  const { block } = props;

  useTriggerJudging(block, props.ended);
  useAutoUpdateDetailScore(block);
  useTrackPlayedGroups(block);
  useCleanupOnStageGroups(block);
  useAutoProgressToShowResults(block, props.ended);
  useAutoProgressMajorityVote(block);
  useAutoProgressNoMoreCard(block);

  return null;
}

function ShowResults(props: SharedProps) {
  const { block } = props;
  const { readyForSkip } = useOndWaitModeInfo();
  const triggered = useRef(false);

  useEffect(() => {
    if (block.fields.showResults || !readyForSkip) return;
    triggered.current = true;
    const ctrl = new BrowserTimeoutCtrl();
    ctrl.set(() => {
      ondWaitEnd();
    }, 2000);
    return () => ctrl.clear();
  }, [block.fields.showResults, readyForSkip]);

  return null;
}

function useBringPlayerToStage(groupId: string, enabled: boolean) {
  const api = useStageControlAPI();
  const clientId = useLastJoinedParticipantByUserId(groupId)?.clientId;

  useEffect(() => {
    if (!enabled || !clientId) return;

    api.join(clientId, StageMode.BLOCK_CONTROLLED);
    return () => {
      api.leave(clientId);
    };
  }, [api, clientId, enabled]);
}

function useBringTeamToStage(groupId: string, enabled: boolean) {
  const api = useBringTeamOnStageAPI();
  useEffect(() => {
    if (!enabled) return;
    api.join(groupId, StageMode.BLOCK_CONTROLLED);
    return () => {
      api.leave(groupId);
    };
  }, [api, enabled, groupId]);
}

function useSyncStage(
  block: HeadToHeadBlock,
  gss: Nullable<HeadToHeadBlockGameSessionStatus>
) {
  const { currentAGroupId, currentBGroupId } = useHeadToHeadGameProgress();
  const singleMode = HeadToHeadUtils.SingleMode(block);
  const enabled =
    !!gss &&
    HeadToHeadBlockGameSessionStatus.GAME_INIT <= gss &&
    gss <= HeadToHeadBlockGameSessionStatus.GAME_END;
  useSwitchStageMode();
  useBringPlayerToStage(currentAGroupId, enabled && singleMode);
  useBringPlayerToStage(currentBGroupId, enabled && singleMode);
  useBringTeamToStage(currentAGroupId, enabled && !singleMode);
  useBringTeamToStage(currentBGroupId, enabled && !singleMode);
}

export function HeadToHeadBlockGameControl(
  props: SharedProps
): JSX.Element | null {
  const block = useStableBlock(props.block);
  const gss = useGameSessionStatus<HeadToHeadBlockGameSessionStatus>();
  useSyncStage(block, gss);

  const api = useHeadToHeadGameControlAPI();

  useEffect(() => {
    const aborter = new AbortController();
    api.on(aborter);
    return () => aborter.abort();
  }, [api]);

  useEffect(() => {
    return () => {
      api.resetGame();
    };
  }, [api]);

  switch (gss) {
    case HeadToHeadBlockGameSessionStatus.LOADED:
      return <Loaded block={block} />;
    case HeadToHeadBlockGameSessionStatus.GAME_INIT:
      return <Init block={block} />;
    case HeadToHeadBlockGameSessionStatus.GAME_INTRO:
      return <Intro block={block} />;
    case HeadToHeadBlockGameSessionStatus.GAME_START:
      return <GameStarted block={block} />;
    case HeadToHeadBlockGameSessionStatus.GAME_END:
      return <GameStarted block={block} ended />;
    case HeadToHeadBlockGameSessionStatus.RESULTS:
      return <ShowResults block={block} />;
    case HeadToHeadBlockGameSessionStatus.END:
      return null;
    case null:
    case undefined:
      break;
    default:
      assertExhaustive(gss);
      break;
  }

  return null;
}
