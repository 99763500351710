import { useMemo } from 'react';

import {
  type DtoBlock,
  type DtoGamePack,
  type DtoNarrowedRunStep,
  type DtoSharedAsset,
  type OpenaiFunctionCall,
  OpenaiRunStepStatus,
  OpenaiRunStepType,
} from '@lp-lib/api-service-client/public';
import { type Block } from '@lp-lib/game';

import { useAnalytics } from '../../../analytics/AnalyticsContext';
import { UGCAnalytics } from '../../../analytics/ugc';
import { getLogger } from '../../../logger/logger';
import { type GamePack } from '../../../types/game';
import { fromDTOBlock } from '../../../utils/api-dto';
import { uuidv4 } from '../../../utils/common';
import { BlockKnifeUtils } from '../Blocks/Shared';
import { type IndexedActivity } from './types';

export const log = getLogger().scoped('ugc');

export class UGCUtils {
  static FromSharedAsset(asset: DtoSharedAsset) {
    return {
      id: asset.id,
      primaryText: asset.data?.showcaseCard.primaryText ?? '',
      secondaryText: asset.data?.showcaseCard.secondaryText ?? '',
      hint: asset.data?.showcaseCard.hint,
      media: asset.media,
    };
  }
  static MakeActivities(
    pack: DtoGamePack | GamePack | undefined,
    blocks: DtoBlock[],
    sharedAssets: DtoSharedAsset[]
  ): IndexedActivity[] {
    const customizableBlocks = blocks.filter((b) =>
      pack?.childrenIds?.includes(b.id)
    );
    return customizableBlocks.map((b, index) => {
      const narrowed = fromDTOBlock(b);
      const summary = BlockKnifeUtils.Summary(narrowed);
      const asset = sharedAssets.find(
        (a) => a.id === narrowed.fields.ugcAssetId
      );
      return {
        index,
        block: narrowed,
        asset: asset
          ? this.FromSharedAsset(asset)
          : {
              id: uuidv4(),
              primaryText: summary.title ?? 'Untitled',
              secondaryText: '',
              media: undefined,
            },
      };
    });
  }
  static VaildRunStep(step: DtoNarrowedRunStep) {
    return (
      step.type === OpenaiRunStepType.RunStepTypeToolCalls &&
      step.status === OpenaiRunStepStatus.RunStepStatusCompleted &&
      step.stepDetails.tool_calls.length > 0
    );
  }
  static async UpdateWithLogging(
    packId: string,
    block: Block,
    functionCall: OpenaiFunctionCall,
    callback: (data: unknown) => Promise<void>
  ) {
    log.info('apply changes from function call', {
      packId: packId,
      blockId: block.id,
      type: block.type,
      functionCall,
    });
    try {
      await callback(JSON.parse(functionCall.arguments));
    } catch (error) {
      log.error('Failed to parse arguments', error, {
        packId: packId,
        blockId: block.id,
        type: block.type,
        functionCall,
      });
      throw error;
    }
  }
}

export function useUGCAnalytics() {
  const analytics = useAnalytics();
  return useMemo(() => new UGCAnalytics(analytics), [analytics]);
}
