import { useMemo } from 'react';
import { match, P } from 'ts-pattern';
import { proxy } from 'valtio';

import { EnumsGamePackVersion } from '@lp-lib/api-service-client/public';
import { type Block } from '@lp-lib/game';
import { type Logger } from '@lp-lib/logger-base';

import { getFeatureQueryParam } from '../../hooks/useFeatureQueryParam';
import { useInstance } from '../../hooks/useInstance';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import logger from '../../logger/logger';
import { apiService } from '../../services/api-service';
import { GameStorage } from '../../store/gameStorage';
import { SessionMode } from '../../types';
import { type Game, type GamePack } from '../../types/game';
import { fromDTOGamePack } from '../../utils/api-dto';
import { rethrows } from '../../utils/throws';
import {
  markSnapshottable,
  useSnapshot,
  type ValtioSnapshottable,
  ValtioUtils,
} from '../../utils/valtio';
import { GameUtils } from './GameUtils';
import {
  intoPlayback,
  makePlaybackIdGen,
  type OndPlaybackGenConfigGamePack2,
  type PlaybackDesc,
  type PlaybackDescItem,
} from './Playback/intoPlayback';
import { intoPlaybackV1 } from './Playback/intoPlaybackV1';

const LAST_LOADED_GAME_ID_KEY = 'lastLoadedGameId';
const LAST_LOADED_GAME_PACK_ID_KEY = 'lastLoadedGamePackIdKey';

function lookupGame(games: Game[], gameId: string | null) {
  return games.find((g) => g.id === gameId);
}

async function getGameById(id: string) {
  return (await apiService.game.getGameById(id))?.data.game;
}

async function fetchAndAttachBlocks(game: Game | undefined, log: Logger) {
  if (!game) return;

  try {
    const blocks = (await apiService.block.getBlocksByGameId(game.id)).data
      .blocks;
    game.blocks = blocks;
  } catch (err: UnassertedUnknown) {
    log.error('fetch blocks error', err);
  }
}

type GamePlayStoreState = {
  loading: boolean;
  gameLike: Nullable<Game | GamePack>;
  resolvedPlayback: Nullable<{
    [SessionMode.Live]: PlaybackDesc;
    [SessionMode.OnDemand]: PlaybackDesc;
  }>;
  // Only set when a gamepack v1 or minigame is loaded.
  loadedGames: Nullable<Game[]>;
};

export class GamePlayStore {
  private _state = markSnapshottable(
    proxy<GamePlayStoreState>(this.initialState())
  );

  constructor(
    /**
     * Allow passing in `null` to prevent global side effects!
     */
    private gameStorage: typeof GameStorage | null = GameStorage,
    private log = logger.scoped('game-play-store')
  ) {}

  private initialState(): GamePlayStoreState {
    return {
      loading: false,
      gameLike: null,
      resolvedPlayback: null,
      loadedGames: null,
    };
  }

  private setIsLoading(val: boolean) {
    this._state.loading = val;
  }

  async load(
    command:
      | Game
      | GamePack
      | {
          pack: GamePack | string;
          playbackConfig?: OndPlaybackGenConfigGamePack2 | null;
        }
      | { game: Game | string }
      | { playback: PlaybackDesc }
  ) {
    try {
      this.setIsLoading(true);

      const gp = [
        { type: 'gamePack' },
        { pack: P.not(P.nullish) },
        { playback: { genConfig: { gameLikeType: 'gamePack' } } },
      ] as const;

      const mg = [
        { type: 'game' },
        { game: P.not(P.nullish) },
        { playback: { genConfig: { gameLikeType: 'game' } } },
      ] as const;

      return await match(command)
        .with(...gp, (c) => this.loadGamePack(c))
        .with(...mg, (c) => this.loadGame(c))
        .exhaustive();
    } catch (err) {
      return rethrows('GameStoreLoadError', err);
    } finally {
      this.setIsLoading(false);
    }
  }

  private async loadGame(
    command: Game | { game: Game | string } | { playback: PlaybackDesc }
  ) {
    this.gameStorage?.Get().remove(LAST_LOADED_GAME_ID_KEY);

    const game = await match(command)
      .with({ type: 'game' }, (c) => c)
      .with({ game: P.string }, (c) => getGameById(c.game))
      .with({ game: { type: 'game' } }, (c) => c.game)
      .with({ playback: P.not(P.nullish) }, (c) =>
        getGameById(c.playback.genConfig.gameLikeId)
      )
      .exhaustive();

    await fetchAndAttachBlocks(game, this.log);

    this._state.gameLike = game;
    this.resolvePlaybackForMiniGame(game);

    this.gameStorage?.Get().set(LAST_LOADED_GAME_ID_KEY, game.id);
  }

  private async loadGamePack(
    command:
      | GamePack
      | {
          pack: GamePack | string;
          playbackConfig?: OndPlaybackGenConfigGamePack2 | null;
        }
      | {
          playback: PlaybackDesc;
        }
  ) {
    this.gameStorage?.Get().remove(LAST_LOADED_GAME_PACK_ID_KEY);

    const packId = match(command)
      .with({ id: P.string }, (c) => c.id)
      .with({ pack: P.string }, (c) => c.pack)
      .with({ pack: { type: 'gamePack' } }, (c) => c.pack.id)
      .with(
        { playback: P.not(P.nullish) },
        (c) => c.playback.genConfig.gameLikeId
      )
      .exhaustive();

    const incomingPlayback = match(command)
      .with({ playback: P.not(P.nullish) }, (c) => c.playback)
      .otherwise(() => null);

    const playbackConfig = match(command)
      .with({ playbackConfig: P.not(P.nullish) }, (c) => c.playbackConfig)
      .otherwise(() => null);

    const [
      {
        data: { games },
      },
      { data: gamePackData },
      {
        data: {
          data: { ttsScripts: sharedTTSScripts },
        },
      },
    ] = await Promise.all([
      apiService.gamePack.getLinkedGames(packId),
      apiService.gamePack.getGamePackById(packId, {
        brands: true,
        blocks: true,
        playHistory: playbackConfig?.playHistoryTargetId,
        subscriberId: playbackConfig?.subscriberId,
      }),
      apiService.settings.getData('shared-tts-scripts'),
    ]);

    const { gamePack } = gamePackData;

    await match(gamePack)
      .with({ version: 1 }, async (pack) => {
        if (!games || games.length === 0) return;
        await Promise.all(games.map((g) => fetchAndAttachBlocks(g, this.log)));
        this._state.loadedGames = games;
        if (incomingPlayback) {
          this._state.resolvedPlayback = {
            [SessionMode.Live]: incomingPlayback,
            [SessionMode.OnDemand]: incomingPlayback,
          };
        } else {
          this.resolvePlaybackForGamePackV1(pack.id, games);
        }
      })
      .with({ version: 2 }, async () => {
        const marketingInjectionEnabled = getFeatureQueryParam(
          'game-on-demand-inject-marketing-blocks'
        );
        const injectMarketingBlocks =
          marketingInjectionEnabled &&
          (playbackConfig?.injectMarketingBlocks ??
            gamePackData.playbackConfig?.shouldInjectMarketingBlocks ??
            false);

        // Do not recompute ond if we already have v2 playback! Note that this
        // ignores the most recent results just queried/returned in
        // gamePackData.
        const ondPlayback = incomingPlayback
          ? incomingPlayback
          : intoPlayback(gamePackData, {
              prngSeed: playbackConfig?.prngSeed ?? null,
              playHistoryTargetId: playbackConfig?.playHistoryTargetId ?? '',
              subscriberId: playbackConfig?.subscriberId ?? '',
              requestedUnitCount:
                playbackConfig?.requestedUnitCount ??
                gamePack.playbackSettings?.defaultUnitsPerSession,
              startUnitIndex: playbackConfig?.startUnitIndex,
              injectMarketingBlocks,
              sharedTTSScripts,
              skipHostedTutorial: playbackConfig?.skipHostedTutorial ?? false,
            });

        const livePlayback = intoPlayback(
          gamePackData,
          makeHostPlaybackConfig()
        );

        this._state.resolvedPlayback = {
          [SessionMode.Live]: livePlayback,
          [SessionMode.OnDemand]: ondPlayback,
        };
      })
      .exhaustive();

    this._state.gameLike = fromDTOGamePack(gamePack);
    this.gameStorage?.Get().set(LAST_LOADED_GAME_PACK_ID_KEY, packId);
  }

  private resolvePlaybackForMiniGame(game: Game) {
    const playbackId = makePlaybackIdGen();

    const items = intoPlaybackV1([game], playbackId);
    const startItemId = items[0].id;

    const ondPlaybackVersion = GameUtils.DeriveOndPlaybackVersionFromBlocks(
      items.map((item) => item.block)
    );

    const pbd: PlaybackDesc = {
      genConfig: {
        gameLikeId: game.id,
        gameLikeType: 'game',
      },
      preGame: null,
      uiInfo: {
        unitLabel: 'game',
        remainingUnits: 0,
        unitsThisSession: 1,
        allUnitsPlayed: false,
      },
      startItemId: startItemId,
      items: items,
      ondPlaybackVersion,
    };

    this._state.resolvedPlayback = {
      [SessionMode.Live]: pbd,
      [SessionMode.OnDemand]: pbd,
    };
  }

  private resolvePlaybackForGamePackV1(packId: string, games: Game[]) {
    const playbackId = makePlaybackIdGen();

    const items = intoPlaybackV1(games, playbackId);
    const startItemId = items[0].id;

    // NOTE(drew): if we need an intro or outro block again, it can be added
    // here and in the corresponding gp2 version.

    const ondPlaybackVersion = GameUtils.DeriveOndPlaybackVersionFromBlocks(
      items.map((item) => item.block)
    );

    const pbd: PlaybackDesc = {
      genConfig: {
        gameLikeId: packId,
        gameLikeType: 'gamePack',
      },
      preGame: null,
      uiInfo: {
        unitLabel: 'game',
        remainingUnits: 0,
        unitsThisSession: 1,
        allUnitsPlayed: false,
      },
      startItemId: startItemId,
      items: items,
      ondPlaybackVersion,
    };

    this._state.resolvedPlayback = {
      [SessionMode.Live]: pbd,
      [SessionMode.OnDemand]: pbd,
    };
  }

  unload() {
    ValtioUtils.reset(this._state, this.initialState());
    this.gameStorage?.Get().remove(LAST_LOADED_GAME_ID_KEY);
    this.gameStorage?.Get().remove(LAST_LOADED_GAME_PACK_ID_KEY);
  }

  async autoloadLastGameLike() {
    if (this._state.gameLike) return;

    const lastLoadedGameId = this.gameStorage
      ?.Get()
      .get(LAST_LOADED_GAME_ID_KEY);
    const lastLoadedGamePackId = this.gameStorage
      ?.Get()
      .get(LAST_LOADED_GAME_PACK_ID_KEY);

    try {
      // Prioritize a gamepack then a standalone minigame
      if (lastLoadedGamePackId) {
        await this.load({
          pack: lastLoadedGamePackId,
        });
      } else if (lastLoadedGameId) {
        await this.load({ game: lastLoadedGameId });
      }
    } catch (err) {
      this.log.error('autoload last game error', err);
    }
  }

  // this function is only relevant when the loaded gamelike is a minigame or a v1 gamepack.
  async updateGame(source: Game) {
    if (
      this._state.gameLike?.type === 'game' ||
      (this._state.gameLike?.type === 'gamePack' &&
        this._state.gameLike.version === EnumsGamePackVersion.GamePackVersionV1)
    ) {
      const target =
        this._state.gameLike?.type === 'game'
          ? this._state.gameLike
          : lookupGame(this._state.loadedGames ?? [], source.id);
      if (!target) return;

      // we should refetch in case blocks were added or removed.
      await fetchAndAttachBlocks(source, this.log);
      ValtioUtils.update(target, source);
      this.resolvePlaybackForMiniGame(source);
    }
  }

  //
  // getters, that can be hooked into react with useSnapshot
  //

  isLoadingGame(snap = this._state): boolean {
    return snap.loading;
  }

  hasLoadedGameLike(snap = this._state): boolean {
    return Boolean(snap.gameLike?.id);
  }

  getLoadedGameLike(snap = this._state): Nullable<Game | GamePack> {
    return snap.gameLike;
  }

  getLoadedGamePack(snap = this._state): Nullable<GamePack> {
    const gameLike = this.getLoadedGameLike(snap);
    return gameLike?.type === 'gamePack' ? gameLike : undefined;
  }

  getLoadedBlocks(mode: SessionMode | null, snap = this._state): Block[] {
    if (!mode) return [];
    return snap.resolvedPlayback?.[mode].items.map((i) => i.block) ?? [];
  }

  /**
   * Returns the loaded minigames for the loaded game pack if there's a v1
   * gamepack loaded.
   */
  getLoadedGamePackGames(snap = this._state): Game[] {
    if (
      snap.gameLike?.type === 'gamePack' &&
      snap.gameLike.version === EnumsGamePackVersion.GamePackVersionV1
    )
      return snap.loadedGames ?? [];
    return [];
  }

  /**
   * Returns the minigame for the given id. This function is only relevant when
   * the loaded gamelike is a v1 gamepack or minigame.
   *
   * Currently only needed for the host rec / host controller. PLEASE REFACTOR
   */
  findGame(gameId: Nullable<Game['id']>, snap = this._state): Nullable<Game> {
    if (!gameId) return null;

    if (
      snap.gameLike?.type === 'gamePack' &&
      snap.gameLike.version === EnumsGamePackVersion.GamePackVersionV1 &&
      snap.loadedGames
    ) {
      return lookupGame(snap.loadedGames, gameId);
    } else if (snap.gameLike?.type === 'game' && snap.gameLike.id === gameId) {
      return snap.gameLike;
    }
    return null;
  }

  /**
   * Returns the subsequent minigame for the given blockId in the loaded game
   * pack. this function is only relevant for v1 gamepacks.
   */
  getNextGamePackGame(
    blockId: Block['id'],
    mode: SessionMode,
    snap = this._state
  ): Nullable<Game> {
    if (
      snap.gameLike?.type === 'gamePack' &&
      snap.gameLike.version === EnumsGamePackVersion.GamePackVersionV1 &&
      snap.loadedGames
    ) {
      // find the block.
      const block = this.getLoadedBlocks(mode, snap).find(
        (b) => b.id === blockId
      );
      if (!block) return null;

      let nextMiniGame = null;
      const gamePackGames = snap.loadedGames;
      const currentMiniGameIndex = gamePackGames.findIndex(
        (gamePackGame) => gamePackGame.id === block.gameId
      );
      if (
        currentMiniGameIndex > -1 &&
        currentMiniGameIndex < gamePackGames.length - 1
      ) {
        nextMiniGame = gamePackGames[currentMiniGameIndex + 1];
      }
      return nextMiniGame;
    }
    return null;
  }

  getLoadedGameLikeHasThingsToPlay = (
    mode: SessionMode,
    snap = this._state
  ): boolean => {
    const length = snap.resolvedPlayback?.[mode]?.items?.length ?? 0;
    return Boolean((length ?? 0) > 0);
  };

  getResolvedPlayback(
    mode: SessionMode | null,
    snap = this._state
  ): PlaybackDesc | null {
    if (!mode) return null;
    return snap.resolvedPlayback?.[mode] ?? null;
  }

  getDerivedOndPlaybackVersion = (
    mode: SessionMode | null,
    snap = this._state
  ): number => {
    const resolvedPlaybackVersion = this.getResolvedPlayback(
      mode,
      snap
    )?.ondPlaybackVersion;

    if (resolvedPlaybackVersion) return resolvedPlaybackVersion;

    // we won't have a playback version if nothing is loaded. rather than introduce a new
    // fallback version, let "derive" be the source of truth on how to fallback.
    return GameUtils.DeriveOndPlaybackVersionFromBlocks(
      this.getLoadedBlocks(mode, snap)
    );
  };

  getDerivedBlockRecorderVersion = (
    mode: SessionMode | null,
    snap = this._state
  ): number => {
    return GameUtils.DeriveBlockRecorderVersionFromBlocks(
      this.getLoadedBlocks(mode, snap)
    );
  };
}

export function makeHostPlaybackConfig(): OndPlaybackGenConfigGamePack2 {
  return {
    playHistoryTargetId: '',
    subscriberId: '',
    startUnitIndex: 0,
    requestedUnitCount: Number.MAX_SAFE_INTEGER,
    injectMarketingBlocks: false,
  };
}

const store = new GamePlayStore();

export function getLocalGamePlayStore() {
  return store;
}

export function useLocalGamePlayStore() {
  return useInstance(() => getLocalGamePlayStore());
}

// note: this should never be externally exposed. it's a bit of a hack to expose
// the _state in the store without also exposing it outside the store itself.
function useExposeGamePlayStoreState(store: GamePlayStore) {
  const exposed = store as unknown as {
    _state: ValtioSnapshottable<GamePlayStoreState>;
  };

  return useSnapshot(exposed._state) as ValtioSnapshottable<GamePlayStoreState>;
}

export function useIsLoadingGameLike(store = getLocalGamePlayStore()): boolean {
  const snap = useExposeGamePlayStoreState(store);
  return store.isLoadingGame(snap);
}

export function useHasLoadedGameLike(store = getLocalGamePlayStore()): boolean {
  const snap = useExposeGamePlayStoreState(store);
  return store.hasLoadedGameLike(snap);
}

export function useLoadedGameLikeHasThingsToPlay(
  mode: SessionMode,
  store = getLocalGamePlayStore()
): boolean {
  const snap = useExposeGamePlayStoreState(store);
  return store.getLoadedGameLikeHasThingsToPlay(mode, snap);
}

export function useLocalLoadedGameLike(
  store = getLocalGamePlayStore()
): Nullable<Game | GamePack> {
  const snap = useExposeGamePlayStoreState(store);
  return store.getLoadedGameLike(snap);
}

export function useLocalLoadedGamePack(
  store = getLocalGamePlayStore()
): Nullable<GamePack> {
  const snap = useExposeGamePlayStoreState(store);
  return store.getLoadedGamePack(snap);
}

/**
 * This is currently only used for the gamepack preview! PLEASE REFACTOR
 */
export function useLocalLoadedGamePackGames(
  store = getLocalGamePlayStore()
): Game[] {
  const snap = useExposeGamePlayStoreState(store);
  return store.getLoadedGamePackGames(snap);
}

/**
 * NOTE: Only used for rec host videos / host controller. PLEASE REFACTOR
 */
export function useFindLocalLoadedGame(
  gameId: Nullable<Game['id']>,
  store = getLocalGamePlayStore()
): Nullable<Game> {
  const snap = useExposeGamePlayStoreState(store);
  return store.findGame(gameId, snap);
}

/**
 * Retrieves the loadedBlocks for the SessionMode, aka playback mode. LIVE
 * playback always includes all blocks of the gamepack. OND is filtered by play
 * history and requested unit count.
 */
export function useLocalLoadedBlocks(
  mode: SessionMode | null,
  store = getLocalGamePlayStore()
): Block[] {
  const snap = useExposeGamePlayStoreState(store);
  const empty = useMemo(() => [], []);
  return mode ? store.getLoadedBlocks(mode, snap) : empty;
}

export function useLocalDerivedOndPlaybackVersion(
  mode: SessionMode | null,
  store = getLocalGamePlayStore()
): number {
  const snap = useExposeGamePlayStoreState(store);
  return store.getDerivedOndPlaybackVersion(mode, snap);
}

export function useLocalDerivedBlockRecorderVersion(
  mode: SessionMode | null,
  store = getLocalGamePlayStore()
): number {
  const snap = useExposeGamePlayStoreState(store);
  return store.getDerivedBlockRecorderVersion(mode, snap);
}

export function useLocalResolvedPlayback(
  mode: SessionMode | null,
  store = getLocalGamePlayStore()
) {
  const snap = useExposeGamePlayStoreState(store);
  return store.getResolvedPlayback(mode, snap);
}

export const useLocalSelectedPlaybackItem = (
  selectedBlockIndex: number | null,
  mode: SessionMode | null
): PlaybackDescItem | null => {
  const playback = useLocalResolvedPlayback(mode);

  if (
    mode &&
    playback &&
    selectedBlockIndex !== null &&
    selectedBlockIndex >= 0 &&
    selectedBlockIndex < playback?.items.length
  ) {
    return playback.items[selectedBlockIndex];
  }
  return null;
};

export function useRefreshLocalBlocksForGamePlay(
  gameId?: Game['id']
): (overrideGameId?: Game['id']) => void {
  const store = useLocalGamePlayStore();
  return useLiveCallback(async (overrideGameId?: Game['id']) => {
    const finalGameId = overrideGameId ?? gameId;
    if (!finalGameId) return;
    const game = await getGameById(finalGameId);
    if (!game) return;
    store.load({ game });
  });
}
